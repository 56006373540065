import React, {useEffect, useState} from "react";

import {ReactComponent as ArrowBackIcon} from "../../../images/arrow_back_24.svg";
import {ReactComponent as ArrowForwardIcon} from "../../../images/arrow_forward_24.svg";
import NmButton from "../../NmButton";
import {Loader} from "semantic-ui-react";

import bem from "../../../utils/bem";

import "./style.sass";

const ImageSlider = (props) => {
    const {
        onClose,
        images = [],
        totalCount,
        fetchFile,
    } = props;

    const [block] = bem("image-slider");

    const [activeIndex, setActiveIndex] = useState(0);

    const base64str = images[activeIndex]?.base64str;

    useEffect(() => {
        if (!base64str) {
            fetchFile(activeIndex);
        }
    }, [
        activeIndex,
    ]);

    const slideNext = () => {
        if (activeIndex >= totalCount - 1) {
            setActiveIndex(0);

            return;
        }

        setActiveIndex(activeIndex + 1);
    };

    const slidePrev = () => {
        if (activeIndex <= 0) {
            setActiveIndex(totalCount - 1);

            return;
        }

        setActiveIndex(activeIndex - 1);
    };

    return (
        <div
            className={block()}
        >
            <NmButton
                size="xl"
                onlyIcon={true}
                isRound={true}
                icon={<ArrowBackIcon />}
                onClick={slidePrev}
                className="me-6"
            />
            {
                base64str ?
                    <img
                        alt="Cкан"
                        src={`data:image/jpeg;charset=utf-8;base64, ${base64str}`}
                        onClick={onClose}
                    /> :
                    <Loader
                        active={true}
                        inverted={true}
                        content="Загрузка"
                    />
            }
            <NmButton
                size="xl"
                onlyIcon={true}
                isRound={true}
                icon={<ArrowForwardIcon />}
                onClick={slideNext}
                className="ms-6"
            />
        </div>
    );
};

export default ImageSlider;