import {isEmpty} from "lodash";

import {addDays, getUtcDateFilter} from "./dateFormat";
import {pluralizeYears} from "./pluralize";
import {isNullOrWhitespace} from "./stringHelper";

import {getFullRegistrationSteps} from "../components/FullRegistration/utils/getSteps";

import {FULL_REGISTRATION_STEP} from "../components/FullRegistration/constants";
import {citizenshipsDict} from "../constants/citizenships";
import {
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
    CONTRACTOR_FILE_TYPES,
} from "../constants/clientList";
import {
    CONTRACTOR_CHECK_PASSPORT_TEXT,
    CONTRACTOR_FAMILY_STATUS,
    CONTRACTOR_LAST_LOGIN_TYPE,
    CONTRACTOR_MIGRATION_STATUS,
    CONTRACTOR_STATUS as CONTRACTOR_EDM_STATUS,
    CONTRACTOR_STATUS,
    CONTRACTOR_TAX_STATUS_TRANSLATE,
    EDM_PASSPORT_TYPE,
} from "../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE, GENDER_DICT} from "../constants/contractorInfo";
import {CLIENT_TYPE_CODE} from "../constants/dicts";
import {EMPTY_OPTION_KEY} from "../constants/dropdown";
import {ADMIN, NM_MANAGER} from "../constants/roles";
import {CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES} from "../containers/contractor/contractor-verification-data/constants";

import {SUB_PAGE_ORDER_CONTRACTOR} from "../constants/link-params";

export const showInviteContractorsWarning = (contractors, clientType) => {
    /*показываем предупреждение, если И
        * 1 - иностранный гражданин
        * 2 - нет рамочного договора
        * 3 - тип компании не является иностранной*/
    if (clientType === CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY) {
        return false;
    }

    return contractors.filter((contractor) => {
        if (contractor.locatedOutsideRussia) {
            return false;
        }

        return contractor.citizenship !== citizenshipsDict.RU.value && !isNullOrWhitespace(contractor.citizenship) && !contractor.frameContractSigned;
    }).length !== 0;
};

export const getFnsCheckTooltip = (taxStatus, t) => {
    if (isNullOrWhitespace(taxStatus)) {
        return t("contractor-list.contractor-fns-error");
    }

    return t(CONTRACTOR_TAX_STATUS_TRANSLATE[taxStatus]) || t("contractor-list.unknownError");
};

export const clearEmptyOptionsFilterData = (filterData) => {
    const cloneFilterData = {...filterData};
    Object.keys(filterData).forEach(key => {
        if ([EMPTY_OPTION_KEY, EMPTY_OPTION_KEY.toUpperCase()].includes(filterData[key]) || isEmpty(filterData[key])) {
            cloneFilterData[key] = null;
        }
    });

    return cloneFilterData;
};

export const getApplicationCount = countMap => {
    return Object.keys(countMap).reduce((sum, key) => key === SUB_PAGE_ORDER_CONTRACTOR.REFUSALS.TAB_TYPE ? sum : sum + countMap[key], 0);
};

export const SCAN_LIST_BY_CITIZENSHIP = {
    [citizenshipsDict.RU.value]: [
        CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO,
        CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION,
        CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
        CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN,
    ],
    [citizenshipsDict.BY.value]: [
        CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
        CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
        CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
        CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
        CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
        CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS,
        CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
        CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN,
        CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
        CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
    ],
};

export const isShowParentDocumentScans = (contractor, role) => {
    const {
        age,
        edmStatus,
        status,
    } = contractor;

    /*
    Блоки отображаются, если:
    Пользователь прошел полную регистрацию (как НПД или как ЭДО) и указал документы (т.е. пользователь был младше 18 лет);
    или Администратор заполняет данные пользователя в веб интерфейсе, указывает дату рождения младше 18 лет (но старше 14), и успешно сохраняет данные блока. Вне зависимости от гражданства.
     */

    if ((age < 14 || age >= 18)) {
        return false;
    }

    const successStatuses = [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES, CONTRACTOR_STATUS.BANK_CHECK_PASSED];

    return ([ADMIN, NM_MANAGER].includes(role) || successStatuses.includes(status) || successStatuses.includes(edmStatus));
};

export const getContractorParentDocumentScans = (contractor, getFileContractorScan, hasPendingPersonalData) => {
    const {
        age,
        contractorId,
    } = contractor;

    if (age < 14 || age >= 18) {
        return;
    }

    [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
        CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA].forEach(contractorFileType => {
        getFileContractorScan({
            contractorId,
            contractorFileType,
            isPending: true,
            includingPending: hasPendingPersonalData,
        });
    });
};

export const getContractorAdditionalScans = (contractor, getFileContractorScan) => {
    const {
        contractorId,
        citizenship,
        passportType,
        edmPassportType,
    } = contractor;

    const scanTypesList = [CONTRACTOR_FILE_TYPES.INN];
    const isInternalPassport = passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT || edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE;

    if (citizenship === citizenshipsDict.RU.value) {
        scanTypesList.push(CONTRACTOR_FILE_TYPES.PREVIOUS_PASSPORT);
    } else if (isInternalPassport) {
        scanTypesList.push(CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA);
    } else {
        scanTypesList.push(CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE);
        scanTypesList.push(CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE);
    }

    scanTypesList.forEach(contractorFileType => {
        getFileContractorScan({
            contractorId,
            contractorFileType,
            thumbnail: true,
        });
    });
};

export const getContractorScans = (params) => {
    const {
        contractorId,
        getFileContractorScan,
        getContractorAdditionalDocumentFile,
        citizenship,
        migrationStatus,
        passportType,
        edmPassportType,
        hasPendingPersonalData,
        exceptionsForFetch,
        locatedOutsideRussia,
    } = params;

    const isPending = true;

    const steps = getFullRegistrationSteps({
        citizenship,
        migrationStatus,
        locatedOutsideRussia,
    });

    if (steps.includes(FULL_REGISTRATION_STEP.VHI_POLICY_SCAN)) {
        [
            CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
            CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
            CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if (steps.includes(FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT)) {
        [
            CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN,
            CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN,
            CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });

        getContractorAdditionalDocumentFile({
            contractorId,
            type: CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECK_SCAN,
            isPending,
            index: 0,
            includingPending: hasPendingPersonalData,
            thumbnail: true,
        });
    }

    if (steps.includes(FULL_REGISTRATION_STEP.MIGRATION_CARD)) {
        [
            CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD,
            CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if (steps.includes(FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA)) {
        getFileContractorScan({
            contractorId,
            contractorFileType: CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
            isPending,
            includingPending: hasPendingPersonalData,
        });
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.REFUGEE) {
        [
            CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD,
            CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK,
            CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE) {
        [
            CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA,
            CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.STUDENT) {
        [
            CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE,
            CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE,
            CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE,
            CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE,
            CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER,
            CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.RESIDENCE_PERMIT) {
        getFileContractorScan({
            contractorId,
            contractorFileType: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
            isPending,
            includingPending: hasPendingPersonalData,
        });
    }

    if ([CONTRACTOR_MIGRATION_STATUS.VISA, CONTRACTOR_MIGRATION_STATUS.STUDENT].includes(migrationStatus)) {
        getFileContractorScan({
            contractorId,
            contractorFileType: CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD,
            isPending,
            includingPending: hasPendingPersonalData,
        });
    }

    if ([citizenshipsDict.RU.value, citizenshipsDict.BY.value].includes(citizenship)) {
        SCAN_LIST_BY_CITIZENSHIP[citizenship].filter(value => !exceptionsForFetch[value]).forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
        return;
    }

    if (
        passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT ||
        edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE
    ) {
        [
            CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
            CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    } else {
        getFileContractorScan({
            contractorId,
            contractorFileType: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
            isPending,
            includingPending: hasPendingPersonalData,
        });
    }
    [
        CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
        CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
        CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
        CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS,
        CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
    ].filter(value => !exceptionsForFetch[value]).forEach(contractorFileType => {
        getFileContractorScan({
            contractorId,
            contractorFileType,
            isPending,
            includingPending: hasPendingPersonalData,
        });
    });

    if ([citizenshipsDict.AM.value].includes(citizenship) || migrationStatus === CONTRACTOR_MIGRATION_STATUS.STUDENT) {
        [
            CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION,
            CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }

    if ([citizenshipsDict.NOT_SPECIFIED.value].includes(citizenship)) {
        [
            CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD,
            CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE,
        ].forEach(contractorFileType => {
            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending,
                includingPending: hasPendingPersonalData,
            });
        });
    }
};

//маппим данные замкнутого контура для отображения на странице исполнителя
export const getClientGroupListForContractorInfo = (list = []) => {
    return list.map((item) => {
        return {text: item.name};
    });
};

export const isContractorPassportCheckedSuccess = (data) => {
    const {status, edmStatus} = data;
    const isFirstCondition = [CONTRACTOR_STATUS.BANK_CHECK, CONTRACTOR_STATUS.BANK_CHECK_PASSED, CONTRACTOR_STATUS.BANK_CHECK_FAILED].includes(status) && [CONTRACTOR_EDM_STATUS.BASIC_REGISTRATION, CONTRACTOR_EDM_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(edmStatus);
    const isSecondCondition = [CONTRACTOR_STATUS.BASIC_REGISTRATION].includes(status) && [CONTRACTOR_EDM_STATUS.ADMINISTRATOR_CHECK_PASSED].includes(edmStatus);

    return isFirstCondition || isSecondCondition;
};

export const getPassportErrorText = (status, edmStatus) => {
    if (status === CONTRACTOR_STATUS.BASIC_REGISTRATION && edmStatus === CONTRACTOR_EDM_STATUS.BASIC_REGISTRATION) {
        return CONTRACTOR_CHECK_PASSPORT_TEXT[status];
    }

    if ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status)) {
        return CONTRACTOR_CHECK_PASSPORT_TEXT[status];
    }

    if ([CONTRACTOR_EDM_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_EDM_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(edmStatus)) {
        return CONTRACTOR_CHECK_PASSPORT_TEXT[edmStatus];
    }

    return "";
};

/***
 * возвращает строку - ЮАР, Мужской, 39 лет
 * @param params
 * @returns {(*|string)[]}
 */
export const getContractorCitizenGenderAgeInfo = (params) => {
    const {
        citizenship,
        gender,
        age,
        familyStatus,
    } = params;

    return [
        citizenship,
        GENDER_DICT[gender],
        age && pluralizeYears(age),
        familyStatus && CONTRACTOR_FAMILY_STATUS[familyStatus]?.TEXT,
    ].filter(item => Boolean(item)).join(", ");
};

export const getContractorLastLoginFilters = (filterType) => {
    const currentDate = new Date();

    if (filterType === CONTRACTOR_LAST_LOGIN_TYPE.LESS_THAN_ONE_DAY) {
        return {
            lastLoginDateFromFilter: getUtcDateFilter(addDays(currentDate, -1)),
            lastLoginDateToFilter: getUtcDateFilter(currentDate),
        };
    }

    if (filterType === CONTRACTOR_LAST_LOGIN_TYPE.LAST_SEVEN_DAYS) {
        return {
            lastLoginDateFromFilter: getUtcDateFilter(addDays(currentDate, -7)),
            lastLoginDateToFilter: getUtcDateFilter(currentDate),
        };
    }

    if (filterType === CONTRACTOR_LAST_LOGIN_TYPE.LAST_THIRTY_DAYS) {
        return {
            lastLoginDateFromFilter: getUtcDateFilter(addDays(currentDate, -30)),
            lastLoginDateToFilter: getUtcDateFilter(currentDate),
        };
    }

    if (filterType === CONTRACTOR_LAST_LOGIN_TYPE.MORE_THAN_THIRTY_DAYS) {
        return {
            lastLoginDateToFilter: getUtcDateFilter(addDays(currentDate, -30)),
        };
    }

    return {};
};