import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_COMMON_DICTS_CLIENT_NAMES_ERROR,
    BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST,
    BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS,
    BFF_COMMON_DICTS_CLIENT_USERS_FIO_ERROR,
    BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST,
    BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS,
    BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_ERROR,
    BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST,
    BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST,
    BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_BRANDS_ERROR,
    BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_ERROR,
    BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS,
    BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_ERROR,
    BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST,
    BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS,
    BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_ERROR,
    BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_ERROR,
    BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_ERROR,
    BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST,
    BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS,
    BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_ERROR,
    BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST,
    BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS,
    BFF_COMMON_DICTS_GET_POSITIONS_PAGE_ERROR,
    BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_ERROR,
    BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_GET_TASK_TYPES_ERROR,
    BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST,
    BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_ERROR,
    BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST,
    BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_ERROR,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_ERROR,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST,
    BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_ERROR,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST,
    BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS,
    BFF_COMMON_DICTS_KEDO_LOG_TYPES_ERROR,
    BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST,
    BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS,
    BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST,
    BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
    BFF_COMMON_DICTS_MENU_DESIGN_ERROR,
    BFF_COMMON_DICTS_MENU_DESIGN_REQUEST,
    BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_ERROR,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_ERROR,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST,
    BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS,
    BFF_GET_REGISTRY_PAYMENT_STATUSES_ERROR,
    BFF_GET_REGISTRY_PAYMENT_STATUSES_REQUEST,
    BFF_GET_REGISTRY_PAYMENT_STATUSES_SUCCESS,
    GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_ERROR,
    GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST,
    GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS,
    GET_DICTIONARIES_EDUCATION_FORM_ERROR,
    GET_DICTIONARIES_EDUCATION_FORM_REQUEST,
    GET_DICTIONARIES_EDUCATION_FORM_SUCCESS,
    GET_DICTIONARIES_TASK_STATUSES_ERROR,
    GET_DICTIONARIES_TASK_STATUSES_REQUEST,
    GET_DICTIONARIES_TASK_STATUSES_SUCCESS,
    GET_DICTIONARIES_TASK_TYPES_ERROR,
    GET_DICTIONARIES_TASK_TYPES_REQUEST,
    GET_DICTIONARIES_TASK_TYPES_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/common/dicts";

//GET bff/adm/common/dicts/getOnboardingScenarioFrameContractTypeDict
// Получить словарь типов РД сценариев онбординга
export const getCommonOnBoardingScenarioFrameContractTypeDictSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/getOnboardingScenarioFrameContractTypeDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_ERROR,
            payload: error,
        });
    }
};

//GET bff/adm/common/dicts/getOnboardingScenarioFrameContractTypeDict
// Получить словарь типов источников исполнителя в онбординг
export const getOnboardingContractorSourceDictSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/getOnboardingContractorSourceDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_ERROR,
            payload: error,
        });
    }
};

//GET bff/adm/common/dicts/getOnboardingScenarioFrameContractTypeDict
// Получить словарь типов источников исполнителя в онбординг
export const getOnboardingLeadRegistrationStatusDictSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/getOnboardingLeadRegistrationStatusDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/menuDesign
// Получить словарь вариантов дизайна меню
export const getMenuDesignSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/menuDesign`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_MENU_DESIGN_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_MENU_DESIGN_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getPhoneCodeAllList
// Получить список всех кодов стран
export const getPhoneCodeAllSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/getPhoneCodeAllList`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getPhoneCodePage
// Получить страницу кодов стран с фильтрацией
export const getPageDictionaryFlashCall = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPhoneCodePage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/updatePhoneCode
// Изменить настройки кода страны
export const updateDictionaryFlashCall = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            onError = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/updatePhoneCode?id=${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            onError();

            yield put({
                type: BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getClientWorkType
// Получение словаря режимов работы компании
const getCommonClientWorkTypeSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/getClientWorkType`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getSubdivisionsPage
// Получение словаря подразделений
const getCommonSubdivisionsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getSubdivisionsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getPositionsPage
// Получение словаря должностей
const getCommonPositionsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPositionsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_POSITIONS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_POSITIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getKedoRoleDict
// Получение словаря ролей
const getKedoRoleDictSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoRoleDict`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_ERROR,
            payload: error,
        });
    }
};

// GET /bff/kedo/common/dicts/getKedoDocumentStates
// Получение словаря статуса документов
const getKedoDocumentStatesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoDocumentStates`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/kedo/common/dicts/getKedoDocumentFileSourceTypes
// Получение словаря источников файла документов
const getKedoDocumentFileSourceTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoDocumentFileSourceTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getKedoParticipantStates
// Получение словаря статуса участников документа КЭДО
const getKedoParticipantStatesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoParticipantStates`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/office-types
// Получение словаря типов кабинета КЭДО
const getKedoOfficeTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/office-types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/signatures/types
// Получение словаря типов подписи КЭДО
const getKedoSignaturesTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/signatures/types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/documents/created-via-types
// Получение словаря типов создания документов КЭДО
const getKedoDocumentsCreatedViaTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/documents/created-via-types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/signature-transactions/action-types
// Получение словаря КЭДО журнала подписания документа: действие подписанта
const getKedoSignatureTransactionsActionTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/signature-transactions/action-types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/signature-transactions/decide-types
// Получение словаря КЭДО журнала подписания документа: статус
const getKedoSignatureTransactionsDecideTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/signature-transactions/decide-types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getKedoDocumentHistoryEventTypes
// Получение словаря событий истории документа КЭДО
const getKedoDocumentHistoryEventTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoDocumentHistoryEventTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getKedoDocumentTypeUsedForType
// Получение типо задач (джобов)
const getTaskTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getTaskTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_TASK_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_TASK_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getKedoExportStateTypes
// Получение статусов экспорта КЭДО
const getKedoExportStateTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoExportStateTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getKedoExportTypes
// Получение типов экспорта КЭДО
const getKedoExportTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getKedoExportTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getCustomDocumentTemplateLogEventTypes
// Получение словаря типов событий шаблонов
const getCustomDocumentTemplateLogEventTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getCustomDocumentTemplateLogEventTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getClientUsersFio
// Получение словаря ФИО сотрудников
const getClientUsersFioSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getClientUsersFio`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_CLIENT_USERS_FIO_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_CLIENT_USERS_FIO_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getClientNames
// Получение словаря компаний
const getClientNamesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getClientNames`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_CLIENT_NAMES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_CLIENT_NAMES_ERROR,
            payload: error,
        });
    }
};


// POST /bff/adm/common/dicts/getCustomDocumentTemplateNamesPage
// Получение словаря наименований шаблонов
const getCustomDocumentTemplateNamesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getCustomDocumentTemplateNamesPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getTemplateDocumentClientUsagesOrderTypes
// Получение словаря типа сортировки шаблонов
const getTemplateDocumentClientUsagesOrderTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getTemplateDocumentClientUsagesOrderTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getTemplateDocumentClientUsagesLogTypes
// Получение словаря типа события лога настройки клиентов шаблонов
const getTemplateDocumentClientUsagesLogTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getTemplateDocumentClientUsagesLogTypes`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getClientBrandsByClientId
// Получение словаря имен брендов по clientId
const getClientBrandsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getClientBrandsByClientId`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_CLIENT_BRANDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_BRANDS_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/getClientsCategories
// Получение словаря категорий клиентов
const getClientsCategoriesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/getClientsCategories`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/contractor-application-types
// Типы заявок исполнителей на заказе
const getContractorsApplicationTypesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/contractor-application-types`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/document/code/get-all-map
// Получение мапы кодов документов КЭДО
const getKedoAllDocumentCodes = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/kedo/document/code/get-all-map`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/kedo/department/get-names-page
// Получение наименований отделов КЭДО
const getKedoDepartmentNames = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/kedo/department/get-names-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/document-type/common/get-all
// Получение списка типов документов КЭДО (объединение сущностей)
const getKedoAllDocumentTypes = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/kedo/document-type/common/get-all`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/kedo/staff/fio-page
// Получение сотрудников по ФИО
const getKedoStaffFioPage = function* ({payload}) {
    const {
        onlyDepartmentHead,
        onlyDepartmentDeputyHead,
        isFetchAll,
        ...reqData
    } = payload;

    const data = isFetchAll
        ? reqData
        : {
            onlyDepartmentHead,
            onlyDepartmentDeputyHead,
            ...reqData,
        };

    try {
        const result = yield request.bff.post(
            `${controller}/kedo/staff/fio-page`,
            data,
        );
        const {errorMessage, results} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS,
            payload: {
                results,
                onlyDepartmentHead,
                onlyDepartmentDeputyHead,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_ERROR,
            payload: error,
        });
    }
};

const getDictionariesTaskStatuses = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/task-statuses`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_DICTIONARIES_TASK_STATUSES_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_DICTIONARIES_TASK_STATUSES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DICTIONARIES_TASK_STATUSES_ERROR, payload: error});
    }
};

const getDictionariesTaskTypes = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/task-types`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_DICTIONARIES_TASK_TYPES_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_DICTIONARIES_TASK_TYPES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DICTIONARIES_TASK_TYPES_ERROR, payload: error});
    }
};

const getContractorEducationDict = function* () {
    try {
        const result = yield request.bff.get(`${controller}/education-form`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_DICTIONARIES_EDUCATION_FORM_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({
            type: GET_DICTIONARIES_EDUCATION_FORM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DICTIONARIES_EDUCATION_FORM_ERROR,
            payload: error,
        });
    }
};

const getDictionariesContractorLanguageLevels = function* () {
    try {
        const result = yield request.bff.get(`${controller}/contractor-language-levels`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({
            type: GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getPositionsPage
// Получение словаря должностей КЭДО
const getKedoStaffPositionsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPositionsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/kedo/document-route/get-page
// Получение страницы маршрутов для фильтрации
const getKedoDocumentRoutePage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/kedo/document-route/get-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/department/log/types
// Получение типов логов отделов КЭДО
const getKedoDepartmentLogTypes = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/department/log/types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_LOG_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_LOG_TYPES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getKedoUploadedDocumentTypesPage
// Получение словаря типов загруженных документов КЭДО
const getKedoUploadedDocumentTypesPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getKedoUploadedDocumentTypesPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/common/dicts/getKedoTemplateDocumentTypesPage
// Получение словаря типов шаблонных документов КЭДО
const getKedoTemplateDocumentTypesPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getKedoTemplateDocumentTypesPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/kedo/department/log/merge-types
// Получение типов логов отделов КЭДО
const getKedoDepartmentLogMergeTypes = function* () {
    try {
        const result = yield request.bff.get(`${controller}/kedo/department/log/merge-types`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/dicts/registry-payment-statuses/all-for-filters
export const getRegistryPaymentStatusesForFilterSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/registry-payment-statuses/all-for-filters`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_REGISTRY_PAYMENT_STATUSES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_REGISTRY_PAYMENT_STATUSES_SUCCESS,
            payload: result,
        });
    } catch (event) {
        yield put({
            type: BFF_GET_REGISTRY_PAYMENT_STATUSES_ERROR,
            payload: event,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST, getCommonOnBoardingScenarioFrameContractTypeDictSaga),
        takeEvery(BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST, getOnboardingContractorSourceDictSaga),
        takeEvery(BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST, getOnboardingLeadRegistrationStatusDictSaga),
        takeEvery(BFF_COMMON_DICTS_MENU_DESIGN_REQUEST, getMenuDesignSaga),
        takeEvery(BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST, getPhoneCodeAllSaga),
        takeEvery(BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST, getPageDictionaryFlashCall),
        takeEvery(BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST, updateDictionaryFlashCall),
        takeEvery(BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST, getCommonClientWorkTypeSaga),
        takeEvery(BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST, getCommonSubdivisionsPageSaga),
        takeEvery(BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST, getCommonPositionsPageSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST, getKedoRoleDictSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST, getKedoDocumentStatesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST, getKedoDocumentFileSourceTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST, getKedoParticipantStatesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST, getKedoSignaturesTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST, getKedoOfficeTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST, getKedoDocumentsCreatedViaTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST, getKedoSignatureTransactionsActionTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST, getKedoSignatureTransactionsDecideTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST, getKedoDocumentHistoryEventTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST, getTaskTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST, getKedoExportStateTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST, getKedoExportTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST, getCustomDocumentTemplateLogEventTypesSaga),
        takeEvery(BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST, getClientUsersFioSaga),
        takeEvery(BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST, getClientNamesSaga),
        takeEvery(BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST, getCustomDocumentTemplateNamesSaga),
        takeEvery(BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST, getTemplateDocumentClientUsagesOrderTypesSaga),
        takeEvery(BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST, getTemplateDocumentClientUsagesLogTypesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST, getClientBrandsSaga),
        takeEvery(BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST, getClientsCategoriesSaga),
        takeEvery(BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST, getContractorsApplicationTypesSaga),
        takeEvery(BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST, getKedoAllDocumentCodes),
        takeEvery(BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST, getKedoDepartmentNames),
        takeEvery(GET_DICTIONARIES_TASK_STATUSES_REQUEST, getDictionariesTaskStatuses),
        takeEvery(GET_DICTIONARIES_TASK_TYPES_REQUEST, getDictionariesTaskTypes),
        takeEvery(BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST, getKedoStaffFioPage),
        takeEvery(GET_DICTIONARIES_EDUCATION_FORM_REQUEST, getContractorEducationDict),
        takeEvery(GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST, getDictionariesContractorLanguageLevels),
        takeEvery(BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST, getKedoStaffPositionsPageSaga),
        takeEvery(BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST, getKedoAllDocumentTypes),
        takeEvery(BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST, getKedoDocumentRoutePage),
        takeEvery(BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST, getKedoDepartmentLogTypes),
        takeEvery(BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST, getKedoUploadedDocumentTypesPage),
        takeEvery(BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST, getKedoTemplateDocumentTypesPage),
        takeEvery(BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST, getKedoDepartmentLogMergeTypes),
        takeEvery(BFF_GET_REGISTRY_PAYMENT_STATUSES_REQUEST, getRegistryPaymentStatusesForFilterSaga),
    ]);
}