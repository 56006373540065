import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {AddContractorToRegistry} from "../../../../../components/ActualComponents/AddContractorToRegistry";
import {EditRegistryPayment} from "../edit";

import {getBasePageState} from "../../../../../utils/redux";

import {
    searchRegistryPaymentContractors,
    updateRegistryPaymentsStore,
} from "../../../../../ducks/bff/finances/registry-payments/actionCreators";
import {
    bffFinancesRegistryPaymentsContractorsSelector,
} from "../../../../../ducks/bff/finances/registry-payments/selectors";

export const AddContractorPayment = (props) => {
    const {
        clientId,
        onClose,
        modalData,
    } = props;

    const dispatch = useDispatch();

    const contractors = useSelector(bffFinancesRegistryPaymentsContractorsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateRegistryPaymentsStore({
                contractor: {},
                error: null,
                contractors: getBasePageState(),
            }));
        };
    }, []);

    return (
        <AddContractorToRegistry
            clientId={clientId}
            onClose={onClose}
            totalPages={contractors.totalPages}
            list={contractors.list}
            totalCount={contractors.totalCount}
            progressSearch={contractors.progress}
            getContractors={(data) => {
                dispatch(searchRegistryPaymentContractors(data));
            }}
        >
            {
                (params) => {
                    const {
                        goBack,
                    } = params;

                    return (
                        <EditRegistryPayment
                            isEditRealContractor={modalData.isEditRealContractor}
                            contractorData={modalData.contractorData}
                            paymentParams={modalData.paymentParams}
                            onClose={onClose}
                            isEdit={true}
                            onClickBack={goBack}
                        />
                    );
                }
            }
        </AddContractorToRegistry>
    );
};