import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {ReactComponent as BlockedIcon} from "../../../images/blocked-icon.svg";
import {ReactComponent as ChatIcon} from "../../../images/chat_24.svg";
import {ReactComponent as PhoneIcon} from "../../../images/phone_24.svg";
import {ReactComponent as AttachedLogo} from "../../../images/push_pin_24.svg";
import NmAdvancedTooltip from "../../ActualComponents/NmAdvancedTooltip";
import NmLabelText from "../../ActualComponents/NmLabelText";
import NmListCard from "../../ActualComponents/NmList/Card";
import {NmListCardAvatar} from "../../ActualComponents/NmList/Card/components/Avatar";
import BankIndicator from "../../BankIndicator";
import ContractorIndividualEntrepreneurIndicator from "../../ContractorIndividualEntrepreneurIndicator";
import ContractorNoteForClientTooltip from "../../ContractorNoteForClientTooltip";
import ExtLink from "../../ExtLink";
import IconBlacklist from "../../IconBlacklist";
import InstrumentPaymentIndicator from "../../InstrumentPaymentIndicator";
import LocatedOutsideRussiaTooltip from "../../LocatedOutsideRussiaTooltip";
import NmBadge from "../../NmBadge";
import NmCheck from "../../NmCheck";
import NmReadonlyRating from "../../NmReadonlyRating";
import NmSelectedList from "../../NmSelectedList";
import SelfEmployedIndicator from "../../SelfEmployedIndicator";
import {ACTION} from "../index";

import {
    useContractorPassportCheckingForm,
} from "../../../containers/contractor/contractor-general-info/components/passport-checking/hooks/useForm";

import formatDate, {convertUtcToLocal, formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls} from "../../../utils/localstorage";
import {pluralizeYears} from "../../../utils/pluralize";
import {
    formatAmount,
    formatNumber,
    getFullName,
    phoneFormat,
    phoneFormatWithoutSevenNumber,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {CHAT_LINK_PARAMS} from "../../../constants/disputes";
import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../constants/finance";
import {
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {
    COMPLETE_REASON,
    ORDER_CARD_PERFORMERS_TABS,
    ORDER_SOURCE_TYPES,
    REASONS_BY_STATUS,
} from "../../../constants/order";
import {
    FOREMAN,
    isUserFromNm,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    RECRUITER,
    RNKO,
} from "../../../constants/roles";
import {STATUS} from "../../../constants/status";
import {COMPONENT} from "../../ActualComponents/MediaControls/constants";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {getCitizenshipSelector} from "../../../ducks/contractor";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR, TAB_TYPE_BY_SUB_PAGE} from "../../../constants/link-params";

export const STATUS_COLOR_TRANSCRIPT = {
    HIRED: "light-green",
    CONTRACTOR_DECLINED: "red",
    CLIENT_DECLINED: "red",
    AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION: "orange",
    AWAITING_CLIENT_CONFIRMATION: "orange",
    AWAITING_CONTRACTOR_CONFIRMATION: "orange",
    AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION: "orange",
    CLIENT_PREAPPROVED: "orange",
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "orange",
    CLIENT_REVOKED: "gray",
    CONTRACTOR_REVOKED: "gray",
    BLOCKED: "gray",
    COMPLETED: "green",
};

const APPLICATION_TYPE = {
    // Откликнулся СЗ
    CONTRACTOR: "CONTRACTOR",
    // Пригласила компания
    CLIENT: "CLIENT",
};

//Done
const getLabelStatusDateTextByTab = (subpage, applicationType) => {
    const {
        PRE_APPROVED,
        SIGN_AGREEMENT,
        HIRED,
        REFUSALS,
        RESPONSES,
    } = ORDER_CARD_PERFORMERS_TABS;

    if (
        subpage === RESPONSES ||
        [PRE_APPROVED, SIGN_AGREEMENT, HIRED, REFUSALS].includes(subpage) &&
        applicationType === APPLICATION_TYPE.CONTRACTOR
    ) {
        return "Дата отклика";
    }

    return "Дата приглашения";
};

const OrderContractorsCard = (props) => {
    const {
        contractor,
        isVisibleResponsesActions = true,
        isVisibleCreateAct = true,
        contractor: {
            blacklist,
            taxStatus,
            blacklistReason,
            blocked,
            blockedReason,
            contractorId,
            lastName,
            firstName,
            patronymic,
            fullName,
            overallRating,
            age,
            citizenship,
            lastPaymentDate,
            lastPaymentSum,
            orderNum,
            orderName,
            orderId,
            clientId,
            contractorStatus,
            reasonForRefusal,
            completeReason,
            reason,
            phone,
            chatId,
            orderManagerId,
            checkInRequired,
            checkOutRequired,
            orderCompletedCount,
            actDate,
            currentClientOrderCompletedCount,
            workAddressInfo,
            applicationType,
            applicationDate,
            contractDate,
            refusedDate,
            smzTaxOffer,
            projectId,
            projectName,
            objectAddress,
            hasPaymentMethodCard,
            locatedOutsideRussia,
            individualEntrepreneurStatus,
            individualEntrepreneurRegistrationIssuedDateTime,
            individualEntrepreneurBankRequisitesPresent,
            noteForClient,
            pin,
        },
        currentMember: {
            paymentsCreationProhibited,
        },
        notification,
        handleCopyContact,
        showChatList,
        actionSwitcher,
        contractorStatusDict,
        isShowOrderInfo,
        role,
        subpage,
        orderStatus,
        orderSource,
        orderContractPaymentType,
        contractorsPossibility,
    } = props;

    const citizenshipsDict = useSelector(getCitizenshipSelector);
    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);

    const _fullName = lastName ? getFullName(lastName, firstName, patronymic) : fullName;

    const isIndividualEntrepreneur = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    const {t} = useTranslation();

    const {
        isPassportSuccess,
        passportSuccessText,
        passportErrorText,
    } = useContractorPassportCheckingForm({contractor});

    const getSuggestion = () => {
        if ([STATUS.CLIENT_DECLINED, STATUS.CONTRACTOR_DECLINED].includes(contractorStatus)) {
            return reasonForRefusal;
        }

        const {[contractorStatus]: status = {}} = REASONS_BY_STATUS;

        const message = status[completeReason] || "";

        if (!reason && completeReason === COMPLETE_REASON.CONTRACTOR_REFUSE_ORDER) {
            return "Заказчик отказался от работы с исполнителем";
        }

        return !reason ? message : message.replace(":reason", reason);
    };

    const getInvitationAndSignAgreementTabsMediaControlButtons = () => {
        if (!isShowInvitationAndSignTabs()) {
            return [];
        }

        return [
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            actionType: ACTION.REJECT,
                            ...props.contractor,
                            fullName: _fullName,
                        });
                    },
                    children: "Отозвать приглашение",
                    color: "red",
                },
            },
        ];
    };

    const getResponsesTabMediaControlButtons = () => {
        const {
            RESPONSES,
        } = ORDER_CARD_PERFORMERS_TABS;

        const showButtons = isVisibleResponsesActions
            && subpage === TAB_TYPE_BY_SUB_PAGE[RESPONSES].LINK
            && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role);

        if (!showButtons) {
            return [];
        }

        return [
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            ...props.contractor,
                            fullName: _fullName,
                            actionType: ACTION.APPROVE,
                        });
                    },
                    children: "Одобрить",
                    color: "light-green",

                },
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            actionType: ACTION.DECLINE,
                            ...props.contractor,
                            fullName: _fullName,
                        });
                    },
                    children: "Отказать",
                    color: "red",
                },
            },
        ];
    };

    const getPreApprovedTabMediaControlButtons = () => {
        const {
            PRE_APPROVED,
        } = ORDER_CARD_PERFORMERS_TABS;

        if (![TAB_TYPE_BY_SUB_PAGE[PRE_APPROVED].LINK].includes(subpage) || [NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return [];
        }

        return [{
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    actionSwitcher({
                        actionType: ACTION.DECLINE,
                        ...props.contractor,
                        fullName: _fullName,
                    });
                },
                children: "Отказать",
                color: "red",
            },
        }];
    };

    const getHiredTabMediaControlButtons = () => {
        if (![TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.HIRED].LINK].includes(subpage)) {
            return [];
        }

        const isHideBySource = [ORDER_SOURCE_TYPES.API, ORDER_SOURCE_TYPES.REGISTRY].includes(orderSource);

        const isAdditionalButtonsVisible = !isHideBySource;

        const isEndOrderVisible = [STATUS.HIRED, STATUS.BLOCKED].includes(contractorStatus) &&
            ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_CONSULTANT, RNKO, NM_PARTNER].includes(role);

        const isOpenTimeSheetVisible = checkInRequired || checkOutRequired;

        const isApproveEndOrderVisible = [STATUS.AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION].includes(contractorStatus);

        const result = [];

        if (isShowPayActButton()) {
            result.push({
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            actionType: ACTION.PAY,
                            ...props.contractor,
                            fullName: _fullName,
                        });
                    },
                    children: "Сформировать акт",
                    color: "white",
                },
            });
        }

        if (isAdditionalButtonsVisible) {
            result.push({
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            actionType: ACTION.SHOW_HISTORY,
                            ...props.contractor,
                        });
                    },
                    children: "История выплат",
                },
            });

            if (isOpenTimeSheetVisible) {
                result.push(
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                actionSwitcher({
                                    actionType: ACTION.TIME_SHEET,
                                    ...props.contractor,
                                });
                            },
                            children: "Журнал учета времени",
                        },
                    },
                );
            }

            if (isEndOrderVisible) {
                result.push({
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            actionSwitcher({
                                actionType: ACTION.END_ORDER,
                                ...props.contractor,
                            });
                        },
                        children: "Завершить заказ",
                    },
                });
            }

            if (isApproveEndOrderVisible) {
                result.push({
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            actionSwitcher({
                                actionType: ACTION.APPROVE_END_ORDER,
                                ...props.contractor,
                            });
                        },
                        children: "Подтвердить завершение заказа",
                    },
                });
            }
        }

        return result;
    };

    const getChatLink = () => {
        if (chatId) {
            return LINK_CLIENT_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, clientId)
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
                .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatId ? chatId: "")
                .replace("/:newChatContractorId?", "");
        }

        return LINK_CLIENT_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, clientId)
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
            .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, chatId)
            .replace(CHAT_LINK_PARAMS.LINK_NEW_CHAT_PARAM, contractorId);
    };
    
    const getMediaControlButtons = () => {
        return [
            ...getResponsesTabMediaControlButtons(),
            ...getInvitationAndSignAgreementTabsMediaControlButtons(),
            ...getPreApprovedTabMediaControlButtons(),
            ...getHiredTabMediaControlButtons(),
            {
                component: COMPONENT.BUTTON_CONTACT,
                props: {
                    onClick: () => {
                        handleCopyContact(phoneFormatWithoutSevenNumber(phone));
                    },
                    size: "lg",
                    color: "grey",
                    popup: phoneFormat(phone),
                    icon: <PhoneIcon />,
                    children: "Позвонить",
                },
                visible: !!phone
                    && (canViewContractorContacts || isUserFromNm()),
            },
            {
                component: COMPONENT.BUTTON_CONTACT,
                props: {
                    isLink: true,
                    href: getChatLink(),
                    useExtLink: true,
                    size: "lg",
                    popup: "Перейти в чат",
                    icon: <ChatIcon />,
                    children: "Перейти в чат",
                },
                visible: isShowChatButton({contractorStatus, orderManagerId}),
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        actionSwitcher({
                            actionType: ACTION.ATTACH_TO_ORDER,
                            ...props.contractor,
                        });
                    },
                    color: "white",
                    children: pin ? "Открепить с заказа" : "Закрепить на заказе",
                },
                visible: isShowInvitationAndSignTabs(),
            },
        ];
    };

    const getMediaControlsRenderCount = () => {
        const {
            RESPONSES,
            HIRED,
        } = ORDER_CARD_PERFORMERS_TABS;

        if (TAB_TYPE_BY_SUB_PAGE[RESPONSES].LINK === subpage) {
            return {
                mobile: 0,
                tablet: 2,
                preDesktop: 4,
                desktop: 4,
            };
        }

        if (TAB_TYPE_BY_SUB_PAGE[HIRED].LINK === subpage) {
            const isPayActButtonVisible = isShowPayActButton();

            return {
                mobile: 0,
                tablet: Number(isPayActButtonVisible),
                preDesktop: Number(isPayActButtonVisible),
                desktop: Number(isPayActButtonVisible),
            };
        }

        return ({
            mobile: 0,
            tablet: 1,
            preDesktop: 3,
            desktop: 3,
        });
    };

    const isShowChatButton = ({contractorStatus, orderManagerId}) => {
        const {
            REFUSALS,
        } = ORDER_CARD_PERFORMERS_TABS;

        const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

        if ((role === FOREMAN && currentClientUserId !== orderManagerId) || [ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(orderSource)) {
            return false;
        }

        const {
            AWAITING_CONTRACTOR_CONFIRMATION,
            AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING,
            AWAITING_CLIENT_CONFIRMATION,
            CLIENT_PREAPPROVED,
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
            COMPLETED,
            BLOCKED,
        } = STATUS;

        return subpage !== TAB_TYPE_BY_SUB_PAGE[REFUSALS].LINK &&
            [AWAITING_CONTRACTOR_CONFIRMATION, AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING, AWAITING_CLIENT_CONFIRMATION,
                CLIENT_PREAPPROVED, HIRED, AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
                AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION, NEGOTIATIONS_ON_THE_DISPUTE, COMPLETED, BLOCKED].includes(contractorStatus);
    };

    const isShowPayActButton = () => {
        const {
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
        } = STATUS;

        const isHideByOrderStatus = orderStatus === ORDER_STATUS.CLOSED;

        const isHideBySource = [ORDER_SOURCE_TYPES.API, ORDER_SOURCE_TYPES.REGISTRY].includes(orderSource);

        const isHideByRole = (paymentsCreationProhibited && role === FOREMAN) || [
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            NM_OPERATOR,
            RECRUITER,
        ].includes(role);

        return [
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
        ].includes(contractorStatus) &&
            !isHideByRole &&
            !isHideByOrderStatus &&
            !isHideBySource && isVisibleCreateAct;
    };

    const isShowInvitationAndSignTabs = () => {
        const {
            INVITED,
            SIGN_AGREEMENT,
        } = ORDER_CARD_PERFORMERS_TABS;

        return (
            [
                TAB_TYPE_BY_SUB_PAGE[INVITED].LINK,
                TAB_TYPE_BY_SUB_PAGE[SIGN_AGREEMENT].LINK,
            ].includes(subpage) &&
            ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
            orderSource !== ORDER_SOURCE_TYPES.REGISTRY
        );
    };

    const renderStatus = () => {
        if (![ORDER_CARD_PERFORMERS_TABS.HIRED, ORDER_CARD_PERFORMERS_TABS.REFUSALS].includes(subpage)) {
            return null;
        }

        const suggestion = getSuggestion();

        return (
            <NmBadge
                condition
                mod={STATUS_COLOR_TRANSCRIPT[contractorStatus]}
                text={contractorStatusDict[contractorStatus]}
                rightTooltipText={suggestion}
            />
        );
    };

    const renderPrimaryHeaderTooltips = () => {
        return (
            <div className="flex align-items-center">
                {pin && <AttachedLogo color={COLOR.PASSIVE_50} />}
                {
                    blacklist &&
                    <NmAdvancedTooltip
                        hover
                        position="top"
                        children={blacklistReason}
                        trigger={
                            <IconBlacklist className="mt-1" />
                        }
                    />

                }
                {
                    blocked &&
                    <NmAdvancedTooltip
                        hover
                        position="top"
                        children={blockedReason}
                        className={!!blacklist && "ms-2"}
                        trigger={<BlockedIcon />}
                    />
                }
                <ContractorNoteForClientTooltip
                    className="ms-2"
                    note={noteForClient}
                />
            </div>
        );
    };

    const renderStatusesAndRating = () => {
        return (
            <div className="d-flex flex-column flex-md-row flex-md-wrap">
                <NmLabelText
                    alignItems="center"
                    className="mb-md-0 mb-1"
                    label="Общий рейтинг"
                    text={
                        <NmReadonlyRating
                            rating={overallRating || 0}
                            maxRating={1}
                            isMiniRatingInfo
                        />
                    }
                />
                {
                    isShowOrderInfo &&
                    <NmCheck
                        isCheck={smzTaxOffer}
                        label="Автоматическая уплата налогов"
                        labelProps={{
                            medium: true,
                        }}
                        blockClassName="me-md-1 mb-1 mb-md-0"
                    />
                }
                {
                    isIndividualEntrepreneur ?
                        <ContractorIndividualEntrepreneurIndicator
                            status={individualEntrepreneurStatus}
                            statusDate={individualEntrepreneurRegistrationIssuedDateTime}
                        /> :
                        <SelfEmployedIndicator
                            taxStatus={taxStatus}
                            className="me-md-1 mb-1 mb-md-0"
                            labelProps={{
                                medium: true,
                                level: "2",
                            }}
                        />
                }
                <NmAdvancedTooltip
                    hover
                    position="bottom-left"
                    className="me-md-1 mb-1 mb-md-0"
                    trigger={
                        <NmCheck
                            isCheck={isPassportSuccess}
                            labelProps={{
                                medium: true,
                            }}
                            label="Паспорт проверен"
                        />
                    }
                    children={isPassportSuccess ? passportSuccessText : passportErrorText}
                />
                {
                    Boolean(orderCompletedCount || currentClientOrderCompletedCount) &&
                    <NmAdvancedTooltip
                        position="bottom-left"
                        children={t("contractor-list.completed-orders-help")}
                        trigger={
                            <NmCheck
                                isCheck={true}
                                blockClassName="me-md-1 mb-1 mb-md-0"
                                labelProps={{
                                    medium: true,
                                }}
                                label={`${t("contractor-list.completed-orders")} ${orderCompletedCount || 0}/${currentClientOrderCompletedCount || 0}`}
                            />
                        }
                        hover
                    />
                }
                {
                    isUserFromNm() &&
                    !isIndividualEntrepreneur &&
                    <BankIndicator
                        textMedium
                        className="me-md-1 mb-1 mb-md-0"
                        hideLoaderContent
                        level="2"
                        size="lg"
                        contractorsPossibility={contractorsPossibility}
                        contractorId={contractorId}
                        showLabel
                    />
                }
                <InstrumentPaymentIndicator
                    level="2"
                    className="me-md-1 mb-1 mb-md-0"
                    textMedium
                    size="lg"
                    value={
                        isIndividualEntrepreneur
                            ? individualEntrepreneurBankRequisitesPresent
                            : hasPaymentMethodCard
                    }
                    isIndividualEntrepreneur={isIndividualEntrepreneur}
                />
                {renderFrameContractStatus()}
            </div>
        );
    };

    const renderFrameContractStatus = () => {
        const isFcExist = !isNullOrWhitespace(contractDate);

        return (subpage !== ORDER_CARD_PERFORMERS_TABS.SIGN_AGREEMENT &&
            <NmAdvancedTooltip
                position="bottom-left"
                children={isFcExist ? "С исполнителем заключен рамочный договор" : "Рамочный договор с исполнителем отсутствует"}
                trigger={
                    <NmCheck
                        labelProps={{
                            medium: true,
                        }}
                        isCheck={isFcExist}
                        label={
                            isFcExist ?
                                `РД ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]} от ${formatDate(convertUtcToLocal(contractDate), "dd.MM.yyyy")}` :
                                `РД ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]}`
                        }
                    />
                }
                hover
            />
        );
    };

    const renderOrderInfo = () => {
        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                to={orderLink}
                historyEnabled
            >
                {`${orderNum} ${orderName}`}
            </ExtLink>
        );
    };

    const renderProjectInfo = () => {
        const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId);

        return (
            <ExtLink
                to={projectLink}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    return (
        <NmListCard
            notification={notification}
            primaryHeader={_fullName}
            primaryHeaderTooltip={renderPrimaryHeaderTooltips()}
            secondaryHeaderStatus={renderStatus()}
            link={LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId)}
            primaryHeaderLink
            noDivider
            isFixedActions
            avatarComponent={
                <NmListCardAvatar
                    contractorId={contractorId}
                    addedToMyClientGroup={contractor.addedToMyClientGroup}
                    avatarPresent={contractor.avatarPresent}
                />
            }
            actionsClassName="px-0"
            mediaControls={{
                renderCount: getMediaControlsRenderCount(),
                buttons: getMediaControlButtons(),
            }}
            labels={[
                {
                    label: getLabelStatusDateTextByTab(subpage, applicationType),
                    text: formatLocalDate(applicationDate, "dd.MM.yyyy HH:mm"),
                },
                subpage === ORDER_CARD_PERFORMERS_TABS.REFUSALS && {
                    label: "Дата отказа",
                    text: formatLocalDate(refusedDate, "dd.MM.yyyy HH:mm"),
                },
                {label: "Возраст", text: age ? pluralizeYears(age) : "-"},
                {
                    label: "Гражданство",
                    text: citizenship ? citizenshipsDict[citizenship] : "-",
                    classNameText: "flex align-items-center",
                    textOverflowUnset: true,
                    textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
                },
                {
                    text: !isEmpty(workAddressInfo) ?
                        <NmSelectedList
                            showedItemsCount={3}
                            showListWithoutValue
                            label="Города ведения деятельности"
                            list={workAddressInfo}
                            dropdownWidth={250}
                        /> : "-",
                },
                subpage === ORDER_CARD_PERFORMERS_TABS.HIRED && {
                    label: "Дата последней отправки акта (дата акта)",
                    text: !lastPaymentDate && !actDate ?
                        "-" :
                        `${formatDate(convertUtcToLocal(lastPaymentDate), "dd.MM.yyyy") || "-"} (${formatDate(convertUtcToLocal(actDate), "dd.MM.yyyy") || "-"})`,
                    inline: true,
                    columnOnMobile: !!lastPaymentDate || !!actDate,
                    classNameText: "me-md-2",
                },
                subpage === ORDER_CARD_PERFORMERS_TABS.HIRED && {
                    label: "Сумма",
                    text: formatAmount(formatNumber(lastPaymentSum)) || "-",
                    inline: true,
                },
                isShowOrderInfo && orderNum && {
                    label: "Заказ",
                    text: renderOrderInfo(),
                },
                isShowOrderInfo && projectId && {
                    label: "Проект",
                    text: renderProjectInfo(),
                },
                isShowOrderInfo && objectAddress && {
                    label: "Объект",
                    text: objectAddress,
                },
                {render: renderStatusesAndRating},
            ]}
        />
    );
};

export default OrderContractorsCard;