import {
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS,
} from "./actions";

import {getBasePageState} from "../../../../utils/redux";

const initial = {
    pageData: {},
    registryPayments: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    nmContractDateConclusion: null,
    userAvatarDict: {},
    card: {},
    contractor: {},
    progressCard: false,
    error: null,
    contractors: getBasePageState(),
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    progress: true,
                },
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR:
            return {
                ...state,
                contractors: {
                    ...state.contractors,
                    progress: false,
                },
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractor: payload,
                progressCard: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS: {
            const {
                registryPayments,
                totalCount,
                nmContractDateConclusion,
            } = payload;

            return {
                ...state,
                registryPayments,
                nmContractDateConclusion,
                totalCount,
                progress: false,
            };
        }
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS: {
            return {
                ...state,
                userAvatarDict: {
                    ...state.userAvatarDict,
                    ...payload,
                },
                getAvatarProgress: false,
            };
        }
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST:
            return {
                ...state,
                getAvatarProgress: true,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR:
            return {
                ...state,
                getAvatarProgress: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS:
        case BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS:
        case BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS:
        case BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR:
        case BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR:
        case BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR:
        case BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST:
        case BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST:
        case BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST:
        case BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST:
            return {
                ...state,
                error: null,
                progressAction: true,
            };
        case BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};