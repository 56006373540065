import {downloadFile} from "./file";

import {
    APP_NAIMIX_INFO_LINK,
    LINK_CMS_STRAPI_PROD,
    LOCAL_PROD_NAIMIX_INFO_LINK,
    NAIMIX_INFO_LINK,
} from "../constants/links";

const isProd = [
    LOCAL_PROD_NAIMIX_INFO_LINK,
    NAIMIX_INFO_LINK,
    APP_NAIMIX_INFO_LINK,
    process.env.REACT_APP_PROMO_POISK,
].includes(window.location.origin);

const domain = isProd ? LINK_CMS_STRAPI_PROD : "https://strapi.mmtr.ru";
export const downloadHandlerPaymentReport = async () => {
    try {
        const result = await fetch(`${domain}/api/handler-1-c-payment-report/?populate=*`);
        const data = await result.json();
        const {name, url} = data?.data?.attributes?.File.data[0].attributes;

        downloadStrapiFile(url, name);
    } catch (e) {
        console.error(e);
    }
};

export const downloadTermsOfUse = async () => {
    try {
        const result = await fetch(`${domain}/api/terms-of-use/?populate=*`);
        const data = await result.json();
        const {name, url} = data?.data?.attributes?.doc.data.attributes;

        return {
            fileName: name,
            url: `${domain}${url}`,
        };
    } catch (e) {
        console.error(e);
    }
};

export const downloadPrivacyPolicy = async () => {
    try {
        const result = await fetch(`${domain}/api/privacy-policy/?populate=*`);
        const data = await result.json();
        const {name, url} = data?.data?.attributes?.doc.data.attributes;

        return {
            fileName: name,
            url: `${domain}${url}`,
        };
    } catch (e) {
        console.error(e);
        return {
            fileName: "",
            url: "",
        };
    }
};

export const getDocumentsPlatformCustomerSds = async (params) => {
    try {
        const {
            isHoReCa,
        } = params;

        const url = isHoReCa
            ? "/api/ho-re-ca-documents-platform-customer-sds"
            : "/api/documents-platform-customer-sds";
        const result = await fetch(`${domain}${url}/?populate=*&pagination[pageSize]=100`);
        const data = await result.json();

        return data?.data || [];
        // const {name, url} = data?.data?.attributes?.Document.data[0].attributes;
    } catch (e) {
        console.error(e);
        return [];
    }
};


export const downloadStrapiFile = (url, name) => {
    downloadFile(`${domain}${url}`, name);
};

export const mapActivesDocumentsPlatformCustomerSds = (list = [], statusFilter = "active") => {
    const filteredListByStatus = list.filter((params) => {
        const {
            attributes: {
                Status: status,
            },
        } = params;

        return status === statusFilter;
    });

    const sortedList = filteredListByStatus.sort(compareDocuments);

    return sortedList.map((item) => {
        const {
            attributes: {
                Document: {
                    data,
                },
            },
        } = item;

        const [docOne] = data || [];

        if (!docOne) {
            return null;
        }

        return docOne.attributes;
    }).filter(Boolean);
};

export const compareDocuments = (a, b) => {
    if (a.attributes.Sequence > b.attributes.Sequence) {
        return 1;
    }

    if (a.attributes.Sequence < b.attributes.Sequence) {
        return -1;
    }

    return 0;
};

export const getRightsStatus = async () => {
    try {
        const result = await fetch(`${domain}/api/rights-status/?populate=*`);
        const data = await result.json();

        return data?.data?.attributes?.Text || "";
        // const {name, url} = data?.data?.attributes?.Document.data[0].attributes;
    } catch (e) {
        console.error(e);
        return "";
    }
};

export const getHandlersOneS = async () => {
    try {
        const result = await fetch(`${domain}/api/one-s-handlers/?populate=*&pagination[pageSize]=100`);
        const data = await result.json();

        return data?.data || [];
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const mapHandlersOneS = (list = []) => {
    return list.map(({attributes}) => {
        const {
            handler: {
                data: {
                    attributes: handler,
                },
            },
            instruction: {
                data: {
                    attributes: instruction,
                },
            },
        } = attributes;

        return {
            ...attributes,
            handler,
            instruction,
        };
    }).filter(Boolean);
};

export const getRoleRestrictions = async () => {
    try {
        const result = await fetch(`${domain}/api/restriction-descriptions/?populate=*&pagination[pageSize]=100`);
        const data = await result.json();

        return data?.data || [];
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const getRestrictionDescriptions = (list = []) => {
    return list.reduce(function(result, item) {
        const {
            attributes = {},
        } = item;

        result[attributes.variable] = attributes.description;

        return result;
    }, {});
};
