import {isNull} from "lodash";
import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

const validationSchema = () => {
    return yup.object().shape({
        name: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(100, "Не более 100 символов"),
        statusList: yup.array().of(
            yup.object().shape({
                statusDurationDays: yup.string()
                    .when("isDefault", {
                        is: (value) => !value,
                        then: yup.string()
                            .nullable()
                            .test(
                                "isRequired",
                                VALIDATIONS_MESSAGE.REQUIRED,
                                function (value) {
                                    if (isNull(value)) {
                                        return true;
                                    }

                                    return value;
                                },
                            ),
                    }),
            }),
        ),
    });
};

export default validationSchema;