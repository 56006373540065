import React, {FC} from "react";

import {ReactComponent as NoOnIcon} from "../../../../images/no_on.svg";
import {ReactComponent as NotOnIcon} from "../../../../images/not_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../images/yes_on.svg";

import bem from "../../../../utils/bem";

import {COLOR} from "../../../../constants/color";
import {CONTRACTOR_TAX_STATUS} from "../../../../constants/contractor";

import "./style.sass";

interface ISelfEmployedIndicatorIcon extends React.SVGProps<SVGSVGElement> {
    taxStatus?: string,
    isClearStyles?: boolean,
}

export const SelfEmployedIndicatorIcon: FC<ISelfEmployedIndicatorIcon> = (props) => {
    const {
        taxStatus,
        isClearStyles,
        ...svgProps
    } = props;

    const [block ] = bem("self-employed-indicator-icon");

    const blockClassName = block({clear: isClearStyles});

    if (taxStatus === CONTRACTOR_TAX_STATUS.OK) {
        return (
            <YesOnIcon
                color={COLOR.PRIMARY_100}
                className={blockClassName}
                {...svgProps}
            />
        );
    }

    if (taxStatus === CONTRACTOR_TAX_STATUS.TAXPAYER_UNBOUND) {
        return (
            <NotOnIcon
                color={COLOR.INERT_70}
                className={blockClassName}
                {...svgProps}
            />
        );
    }

    return (
        <NoOnIcon
            color={COLOR.NEGATIVE_100}
            className={blockClassName}
            {...svgProps}
        />
    );
};