import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {
    addRecruitmentDirectoryFunnel,
    getPageRecruitmentDirectoryStatus,
    updateRecruitmentDirectoryFunnel,
    updateRecruitmentDirectoryStore,
} from "../../../../../../../ducks/bff/recruitment/directory/actionCreators";
import {bffRecruitmentDirectoryStatusOptionsSelector} from "../../../../../../../ducks/bff/recruitment/directory/selectors";
import validationSchema from "../validation";

import {toastSuccess} from "../../../../../../../utils/toastHelper";
import {getRecruitmentFunnelDuration} from "../utils/getDuration";

const useRecruitmentDirectoriesFunnelEditForm = (props) => {
    const {
        onClose,
        fetchList,
        isEdit,
        editData,
        clientId,
    } = props;

    const statusOptions = useSelector(bffRecruitmentDirectoryStatusOptionsSelector);

    const initialValues = useMemo(() => {
        if (!isEmpty(editData)) {
            return {
                name: "",
                ...editData,
                statusList: editData?.statusList?.map((item) => {
                    return {
                        isDefault: item?.statusModel.isDefault,
                        statusId: item?.statusModel.id,
                        statusDurationDays: item.statusDurationDays ? String(item.statusDurationDays) : "",
                    };
                }) || [],
            };
        }

        const defaultStatusOption = statusOptions.find(item => item.isDefault);

        return {
            name: "",
            statusList: defaultStatusOption
                ? [
                    {
                        ...defaultStatusOption,
                        statusDurationDays: "",
                    },
                ]
                : [],
        };
    }, [
        editData,
        statusOptions,
    ]);

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema: validationSchema(),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPageRecruitmentDirectoryStatus({clientId}));

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                statusPageData: {},
                statusList: [],
                statusTotalCount: 0,
            }));
        };
    }, []);

    function onSubmit(values) {
        if (!isValid) {
            return;
        }

        const {
            name,
            statusList,
        } = values;

        const reqData = {
            name,
            statusList: statusList.map((item, index) => {
                return {
                    statusModel: {id: item.statusId},
                    statusDurationDays: getRecruitmentFunnelDuration(item),
                    orderNumber: index + 1,
                };
            }),
            onSuccess: () => {
                fetchList();
                onClose();
                toastSuccess("Воронка статусов успешно сохранена");
            },
        };

        if (isEdit) {
            dispatch(updateRecruitmentDirectoryFunnel({
                id: editData.id,
                ...reqData,
            }));

            return;
        }

        dispatch(addRecruitmentDirectoryFunnel({
            clientId,
            ...reqData,
        }));
    }

    const handleChange = (e, {name, value, checked}) => {
        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const addItemStatusList = () => {
        setFieldValue(
            "statusList",
            [
                ...values.statusList,
                {
                    isDefault: false,
                    statusId: "",
                    statusDurationDays: "",
                },
            ],
        );
    };

    return {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        handleChange,
        addItemStatusList,
        initialValues,
        validationSchema,
        onSubmit,
    };
};

export default useRecruitmentDirectoriesFunnelEditForm;