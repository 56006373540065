import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import RegistryEditContractorPayment from "../../../registry-edit-contractor-payment";

import {getBasePageState} from "../../../../../utils/redux";

import {
    getRegistryPaymentCard,
    getRegistryPaymentContractor, updateRegistryPaymentsStore,
} from "../../../../../ducks/bff/finances/registry-payments/actionCreators";
import {
    bffFinancesRegistryPaymentsCardSelector,
    bffFinancesRegistryPaymentsContractorSelector,
    bffFinancesRegistryPaymentsProgressCardSelector,
} from "../../../../../ducks/bff/finances/registry-payments/selectors";

export const EditRegistryPayment = (props) => {
    const {
        onClose,
        contractorData,
        isEdit,
        paymentParams: {
            clientId,
            registryId,
            registrySeqNum,
        },
        isEditRealContractor,
        onClickBack,
    } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(bffFinancesRegistryPaymentsProgressCardSelector);
    const card = useSelector(bffFinancesRegistryPaymentsCardSelector);
    const contractor = useSelector(bffFinancesRegistryPaymentsContractorSelector);

    console.log("++");
    useEffect(() => {
        // для сценария редактирования строки реестра, получение общей карточки с полями платежа + контрактора
        if (isEditRealContractor) {
            dispatch(getRegistryPaymentCard({
                clientId,
                registryId,
                registrySeqNum,
            }));

            return;
        }

        // для сценария, когда в строке реестра невалидный контрактор, получение карточки контрактора
        dispatch(getRegistryPaymentContractor({
            contractorId: contractorData.contractorId,
        }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateRegistryPaymentsStore({
                card: {},
            }));
        };
    }, []);

    return (
        <RegistryEditContractorPayment
            isAllRegistryPayments={true}
            contractor={
                isEditRealContractor
                    ? card.contractor
                    : contractor
            }
            isLoading={isLoading}
            isEditRealContractor={isEditRealContractor}
            contractorData={contractorData}
            paymentParams={card}
            onClose={onClose}
            isEdit={isEdit}
            onClickBack={onClickBack}
        />
    );
};