import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../../../components/ActualComponents/Tabs";

import {
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
} from "../../../../../constants/links";

import {
    getSubscribeNotificationsCount,
} from "../../../../../ducks/bff/notifications/subscribe/actionCreators";
import {notificationSubscribesAllTotalCountSelector} from "../../../../../ducks/bff/notifications/subscribe/selectors";

export const NotificationsSubscribesTabs = (props) => {
    const {
        clientId,
    } = props;

    const dispatch = useDispatch();

    const totalCount = useSelector(notificationSubscribesAllTotalCountSelector);

    useEffect(() => {
        dispatch(getSubscribeNotificationsCount({clientId}));
    }, []);

    return (
        <Tabs
            className="mb-4"
            panes={[
                {
                    link: LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS.replace(":clientId", clientId),
                    name: "Мои подписки",
                    exceptions: [
                        LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL.replace(":clientId", clientId),
                    ],
                },
                {
                    isSearchActiveInAllPath: true,
                    link: LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL.replace(":clientId", clientId),
                    name: "Все подписки",
                    count: totalCount,
                },
            ]}
        />
    );
};