import React from "react";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import Tabs from "../../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import ContractorFinanceIncomePaymentListCard from "../income-payment-list-card";
import ContractorCardFinancesTabs from "../tabs";

import {usePagination} from "../../../../hooks/usePagination";
import {useContractorIncomeCustomerPaymentsAmountData} from "../balance/hooks/useAmountData";
import {useContractorFinanceIncomeFetch} from "./hooks/useFetch";
import {useContractorFinanceIncomeFilter} from "./hooks/useFilter";
import {useContractorFinanceIncomeTabs} from "./hooks/useTabs";

const ContractorFinanceIncome = (props) => {
    const {
        fetchList,
        clearStore,
        tabsLinks,
        subTabsLinks,
        list,
        progress,
        totalPages,
        totalCount,
        isShowBankStatusFilter,
        isShowNaimixStatusFilter,
        isShowRegistryNumberFilter,
        isShowItemActions,
        balanceSelector,
        fetchSummaryData,
        naimixStatusFilterOptions,
        isNdfl = false,
        isIndividual = false,
        isCrowd = false,
        isRegistryPaymentsPage = false,
        isHiddenFilter = false,
    } = props;

    const {match} = props;
    const {params} = match;
    const {contractorId} = params;

    const {
        tabs,
        subTabs,
    } = useContractorFinanceIncomeTabs({
        tabsLinks,
        subTabsLinks,
        contractorId,
    });

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        filter,
        submitFilter,
        clearFilter,
        filtersBase,
        isSearch,
        filterAmountBlocks,
    } = useContractorFinanceIncomeFilter({
        setPagination,
        pageSize,
        isShowBankStatusFilter,
        isShowNaimixStatusFilter,
        isShowRegistryNumberFilter,
        naimixStatusFilterOptions,
        isCrowd,
    });

    const {
        cards,
    } = useContractorIncomeCustomerPaymentsAmountData({
        clearStore,
        balanceSelector,
        fetchData: fetchSummaryData,
        contractorId,
        filter: filterAmountBlocks,
        isHiddenTax: isNdfl || isIndividual,
    });

    const {} = useContractorFinanceIncomeFetch({
        pageNum,
        pageSize,
        contractorId,
        filter,
        fetchList,
        clearStore,
        isCrowd,
        isIndividual,
    });

    const getRows = () => {
        return list.map(payment => {
            return {
                ...payment,
                key: payment.orderWorkReportId,
                contentRow: (
                    <ContractorFinanceIncomePaymentListCard
                        isNdfl={isNdfl}
                        isIndividual={isIndividual}
                        isCrowd={isCrowd}
                        isRegistryPaymentsPage={isRegistryPaymentsPage}
                        payment={payment}
                        isShowActions={isShowItemActions}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            noPadding={true}
            overflowUnset={true}
            className="contractor-income-client-payments"
            isLoaded={progress}
            header={<ContractorCardFinancesTabs{...props} />}
            filtersBase={
                !isHiddenFilter &&
                <Filter
                    initState={filter}
                    filters={filtersBase}
                    onSubmit={submitFilter}
                    clearFilter={clearFilter}
                />
            }
            typeFilter={!isHiddenFilter && "vertical"}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
        >
            <NmPageInfoCardsAccordion
                desktopViewFrom="xxl"
                isEachCardAccordion
                className="col-16 mb-4 mb-xl-5"
                cards={cards}
            />
            <Tabs
                className="col-16 mb-4 mb-xl-5"
                panes={tabs}
            />
            {
                !isNdfl && subTabsLinks &&
                <Tabs
                    className="col-16 mb-4 mb-xl-5"
                    panes={subTabs}
                    secondary={true}
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    /> :
                    <CheckboxList
                        rows={getRows()}
                        isFullMinHeight={false}
                    />
            }
        </NmPage>
    );
};

export default ContractorFinanceIncome;