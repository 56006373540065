import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {NotificationsSubscribesPage} from "./components/page";
import {NotificationsSubscribesTabs} from "./components/tabs";

import {getUserRole} from "../../../utils/access";

import {
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
} from "../../../constants/links";
import {ADMIN, CLIENT_ADMIN, isClientUser} from "../../../constants/roles";

import {
    clearNotificationsSubscribeStore,
    getNotificationsTypes,
    getSubscribeNotificationsChannelTypes,
    getSubscribeNotificationsClientRoles,
    getSubscribeNotificationsTypes,
} from "../../../ducks/bff/notifications/subscribe/actionCreators";

export const NotificationsSubscribes = (props) => {
    const {clientId} = props.match.params;

    const dispatch = useDispatch();
    const role = getUserRole();

    useEffect(() => {
        dispatch(getSubscribeNotificationsClientRoles());
        dispatch(getSubscribeNotificationsTypes());
        dispatch(getSubscribeNotificationsChannelTypes());
        dispatch(getNotificationsTypes());

        return () => {
            dispatch(clearNotificationsSubscribeStore());
        };
    }, []);

    if (role === ADMIN) {
        return (
            <NotificationsSubscribesPage
                headerText="Подписки на уведомления"
                isAllSubscriptions={true}
                clientId={clientId}
            />
        );
    }

    if (isClientUser(role) && role !== CLIENT_ADMIN) {
        return (
            <NotificationsSubscribesPage
                clientId={clientId}
                headerText="Подписки на уведомления"
            />
        );
    }

    return (
        <NmPage
            header={<NmPageHeader text="Подписки на уведомления" />}
            subHeader={<NotificationsSubscribesTabs clientId={clientId} />}
        >
            <Switch>
                <Route
                    exact
                    path={LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS}
                    component={NotificationsSubscribesPage}
                />
                <CheckRoleRoute
                    exact
                    path={LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL}
                    render={() => {
                        return (
                            <NotificationsSubscribesPage
                                headerText="Все подписки"
                                isAllSubscriptions={true}
                                clientId={clientId}
                            />
                        );
                    }}
                />
            </Switch>
        </NmPage>
    );
};