import React from "react";
import {Draggable, DraggableStateSnapshot} from "react-beautiful-dnd";

import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as DragIndicatorIcon} from "../../../../images/drag-indicator.svg";
import {IconButton} from "../../IconButton";

import bem from "../../../../utils/bem";
import {getClassNames} from "../../../../utils/classNames";

import {COLOR} from "../../../../constants/color";

import "./style.sass";

interface VerticalMovingListDraggableItem {
    index: number,
    children: React.ReactNode,
    isHideDeleteButton?: boolean,
    deleteItem: (index: number) => void,
    itemContainerClassName?: string,
    dragButtonClassName?: string,
    deleteButtonClassName?: string,
    draggableId: string,
}

export const VerticalMovingListDraggableItem: React.FC<VerticalMovingListDraggableItem> = (props) => {
    const {
        index,
        children,
        isHideDeleteButton,
        deleteItem,
        itemContainerClassName,
        dragButtonClassName,
        deleteButtonClassName,
    } = props;

    const [, element] = bem("vertical-moving-list-draggable");

    return (
        <Draggable
            key={String(index)}
            draggableId={String(index)}
            index={index}
        >
            {
                (provided, snapshot: DraggableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="d-flex flex-column mt-4"
                        style={{
                            borderRadius: 7,
                            backgroundColor: snapshot.isDragging
                                ? COLOR.SECONDARY_5
                                : COLOR.WHITE,
                            ...provided.draggableProps.style,
                            ...snapshot,
                        }}
                    >
                        <div
                            key={index}
                            className={getClassNames([
                                element("item-container"),
                                itemContainerClassName,
                            ])}
                        >
                            <div
                                {...provided.dragHandleProps}
                                className={getClassNames([
                                    dragButtonClassName,
                                    element("drag-button"),
                                ])}
                            >
                                <IconButton
                                    icon={DragIndicatorIcon}
                                    width={24}
                                    height={24}
                                />
                            </div>
                            <div className={element("central-block")}>
                                {children}
                            </div>
                            <div className={element("right-block")}>
                                <div
                                    className={getClassNames([
                                        element("icon-container"),
                                        deleteButtonClassName,
                                    ])}
                                >
                                    {
                                        !isHideDeleteButton &&
                                        <IconButton
                                            icon={DeleteIcon}
                                            width={24}
                                            height={24}
                                            className={element("delete-icon")}
                                            onClick={() => deleteItem(index)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Draggable>
    );
};