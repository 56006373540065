import React, {KeyboardEvent,useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import AmountInput from "../../AmountInput";
import ClientUsersFioDropdown from "../../ClientUsersFioDropdown";
import PhoneWithCodeFilter from "../../PhoneWithCodeFilter";
import FilterButtonsV2 from "../FilterButtonsV2";
import FilterContractor from "../FilterContractor";
import FilterCustomer from "../FilterCustomer";
import FilterObject from "../FilterObject";
import FilterProject from "../FilterProject";
import FilterSpecialities from "../FilterSpecialities";
import NmCheckboxV2 from "../NmCheckboxV2";
import NmDadata from "../NmDadata";
import NmDatePicker from "../NmDatePicker";
import NmDateRangePickerV2 from "../NmDateRangePickerV2";
import NmDropdownV2 from "../NmDropdownV2";
import NmForm from "../NmForm";
import NmInputV2 from "../NmInputV2";
import NmRangeInput from "../NmRangeInput";

import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";
import {getDadataAddress} from "../../../utils/dadata";

import {
    FilterComponent,
    FilterComponentParams,
    FilterFormState,
    FilterGetDefaultStateParams,
    FilterGetValueParams,
    FilterGetValueParamsReturn,
    FiltersConfig,
    FilterValueType,
} from "./types";

import "./style.sass";

export const FILTER: Record<string, keyof typeof FilterComponent> = {
    // фильтр Заказчик
    CUSTOMER: "customer",
    // фильтр Вид деятельности
    SPECIALITIES: "specialities",
    // фильтр Проект
    PROJECT: "project",
    // фильтр Объект
    OBJECT: "object",
    // обычный инпут
    INPUT: "input",
    // от до
    RANGE_INPUT: "rangeInput",
    // обычный дропдаун
    DROPDOWN: "dropdown",
    // датапикер
    DATE: "date",
    // период дат
    DATE_RANGE: "dateRange",
    //дадата
    DADATA: "dadata",
    // чекбокс
    CHECKBOX: "checkbox",
    // фильтр Номер телефона с выбором страны
    PHONE_WITH_CODE: "phoneWithCode",
    // фильтр сотрудников
    CLIENT_USERS_FIO: "clientUsersFio",
    // фильтр "Исполнитель" для поиска по ФИО, ИНН или номеру телефона
    CONTRACTOR: "contractor",
    // целых или дробных чисел до 2 знаков в дробной части
    AMOUNT_INPUT: "amountInput",
};

// Все компоненты которые могут использоваться
const controls = {
    [FILTER.CUSTOMER]: {
        render: FilterCustomer,
    },
    [FILTER.PROJECT]: {
        render: FilterProject,
    },
    [FILTER.OBJECT]: {
        render: FilterObject,
    },
    [FILTER.SPECIALITIES]: {
        render: FilterSpecialities,
    },
    [FILTER.INPUT]: {
        render: NmInputV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.RANGE_INPUT]: {
        render: NmRangeInput,
        props: {
            size: "lg",
        },
    },
    [FILTER.DROPDOWN]: {
        render: NmDropdownV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE]: {
        render: NmDatePicker,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE_RANGE]: {
        render: NmDateRangePickerV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DADATA]: {
        render: NmDadata,
        props: {
            size: "lg",
            formatter: getDadataAddress,
        },
    },
    [FILTER.PHONE_WITH_CODE]: {
        render: PhoneWithCodeFilter,
    },
    [FILTER.CHECKBOX]: {
        render: NmCheckboxV2,
    },
    [FILTER.CLIENT_USERS_FIO]: {
        render: ClientUsersFioDropdown,
    },
    [FILTER.CONTRACTOR]: {
        render: FilterContractor,
        props: {
            size: "lg",
        },
    },
    [FILTER.AMOUNT_INPUT]: {
        render: AmountInput,
        props: {
            size: "lg",
            newInput: true,
        },
    },
};

// Преобразует из конфига начальный state
const getDefaultState = (params: FilterGetDefaultStateParams) => {
    const {
        filters,
        wrapFilters,
        initState,
    } = params;

    if (initState) {
        return {...initState};
    }

    const handleComponentDefaultStateValue = (componentConfig: FilterComponentParams, prev: any) => {
        const {component} = componentConfig;

        switch (component) {
            case FILTER.DATE_RANGE: {
                const startFieldName = componentConfig.startFieldName;
                const endFieldName = componentConfig.endFieldName;

                prev[startFieldName] = componentConfig.value[startFieldName];
                prev[endFieldName] = componentConfig.value[endFieldName];

                break;
            }
            case FILTER.RANGE_INPUT: {
                const startFieldName = componentConfig.nameStart;
                const endFieldName = componentConfig.nameEnd;

                prev[startFieldName] = componentConfig.valueStart;
                prev[endFieldName] = componentConfig.valueEnd;

                break;
            }
            default: {
                const fieldName = componentConfig.name;

                prev[fieldName] = componentConfig.value || "";
            }
        }
    };

    if (wrapFilters) {
        return wrapFilters.reduce((result, item) => {
            handleComponentDefaultStateValue(item, result);

            return result;
        }, {} as FilterFormState);
    }

    return filters?.reduce((prev, currentElem) => {
        const {row} = currentElem;

        row.map((componentConfig) => {
            handleComponentDefaultStateValue(componentConfig, prev);
        });

        return prev;
    }, {});
};

const getValue = (params: FilterGetValueParams): FilterGetValueParamsReturn => {
    const {
        name,
        props,
        form,
    } = params;

    switch (name) {
        case FILTER.DATE_RANGE:
            return {
                value: {
                    [props.startFieldName]: form[props.startFieldName],
                    [props.endFieldName]: form[props.endFieldName],
                },
            };
        case FILTER.RANGE_INPUT:
            return {
                valueStart: form[props.nameStart],
                valueEnd: form[props.nameEnd],
            };
        case FILTER.PROJECT:
            return {
                projectIdsFilter: form[props.name],
            };
        case FILTER.OBJECT:
            return {
                objectIdsFilter: form[props.name],
            };
        case FILTER.INPUT:
            return {
                value: form[props.name] || "",
            };
        default:
            return {
                value: form[props.name],
            };
    }
};

interface IFilter {
    filters?: FiltersConfig,
    wrapFilters?: Array<FilterComponentParams>,
    filterState?: Record<string, FilterValueType>,
    onSubmit: (params?: Record<string, FilterValueType>) => void,
    updateFilter?: (params?: Record<string, FilterValueType>) => void,
    onKeyDown?: (event: KeyboardEvent<HTMLFormElement>) => void,
    initState?: Record<string, FilterValueType>,
    isInitStateEqualEmpty?: boolean,
    className?: string,
    clearFilter: (params?: Record<string, FilterValueType>) => void,
    actionsBottomPosition?: boolean,
    classNameField?: string,
}

const Filter = (props: IFilter) => {
    const {
        onKeyDown,
        filters,
        initState,
        filterState,
        onSubmit,
        updateFilter,
        className = "",
        isInitStateEqualEmpty = false,
        clearFilter,
        actionsBottomPosition,
        wrapFilters,
        classNameField,
    } = props;

    const {t} = useTranslation();
    const [form, setForm] = useState<FilterFormState>(getDefaultState({
        filters,
        wrapFilters,
        initState,
    }));
    const [block, element] = bem("filter", className);

    useEffect(() => {
        if (filterState) {
            setForm(filterState);
        }
    }, [
        filterState,
    ]);

    const onChange = (event: any, {name, value, checked}: any) => {
        const _value = typeof checked === "boolean" ? checked : value;

        if (updateFilter) {
            updateFilter({
                ...form,
                [name]: _value,
            });

            if (filterState) {
                return;
            }
        }

        setForm({
            ...form,
            [name]: _value,
        });
    };

    const _onClear = () => {
        if (isInitStateEqualEmpty) {
            if (!filterState) {
                setForm({
                    ...initState,
                });
            }

            clearFilter({
                ...initState,
            });

            return;
        }

        if (!filterState) {
            setForm({
                ...getDefaultState({
                    filters,
                    initState: {},
                }),
            });
        }

        clearFilter({
            ...getDefaultState({
                filters,
                initState: {},
            }),
        });
    };

    const _onSubmit = () => {
        onSubmit(form);
    };

    const getProps = (
        componentName: keyof typeof FilterComponent,
        props: Omit<FilterComponentParams, "component">,
        t: (name: string) => string,
    ) => {
        const {
            useTranslation,
            disabledDependsName,
            ...otherProps
        } = props;
        const defaultProps = controls[componentName].props;
        const value = getValue({
            name: componentName,
            props: otherProps,
            form,
        });
        const disabledDependsValue = disabledDependsName && form[disabledDependsName];
        const disabled = Array.isArray(disabledDependsValue) ?
            Boolean(disabledDependsValue.length) :
            Boolean(disabledDependsValue);

        let resultProps: Omit<FilterComponentParams, "component" | "useTranslation" | "disabledDependsName"> = {
            ...otherProps,
            ...defaultProps,
            ...value,
            disabled: disabledDependsName && disabled,
            onChange,
        };

        if (useTranslation) {
            resultProps.label = t(resultProps.label);
        }

        if (componentName === FILTER.CHECKBOX) {
            resultProps = {
                ...resultProps,
                checked: Boolean(value.value),
            };
        }

        // Пробрасываем автоматически кнопки покзазать, очистить
        if (componentName === FILTER.DROPDOWN) {
            resultProps = {
                ...resultProps,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        if (componentName === FILTER.DADATA) {
            resultProps = {
                ...resultProps,
                initialAddressIds: value.value,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        return resultProps;
    };

    const getFilters = () => {
        if (wrapFilters) {
            const filters = wrapFilters.map((item, index) => {
                const {
                    component: componentName,
                    ...props
                } = item;

                const component = controls[componentName].render;
                const resultProps = getProps(componentName, props, t);

                return (
                    <div
                        className={getClassNames([
                            classNameField,
                            "filter__wrap-item",
                        ])}
                        key={index}
                    >
                        {React.createElement(component, resultProps)}
                    </div>
                );
            });

            return (
                <div className="row gx-2">
                    {filters}
                </div>
            );
        }

        const filteredFilters = filters?.filter(({isVisible = true}) => isVisible);

        return filteredFilters?.map((data, index: number) => {
            const {
                row,
            } = data;

            if (row.length === 1) {
                const [{
                    component: componentName,
                    ...props
                }] = row;

                const component = controls[componentName].render;
                const resultProps = getProps(componentName, props, t);

                return (
                    <div
                        key={index}
                        className={element("row")}
                    >
                        {React.createElement(component, resultProps)}
                    </div>
                );
            }

            if (row.length === 2) {
                return (
                    <div
                        key={index}
                        className={element("row", {divide: true})}
                    >
                        {
                            row.map((componentConfig) => {
                                const {
                                    component: componentName,
                                    ...props
                                } = componentConfig;

                                const component = controls[componentName].render;
                                const resultProps = getProps(componentName, props, t);

                                return React.createElement(component, resultProps);
                            })
                        }
                    </div>
                );
            }

            return (
                <div style={{color: "red"}}>
                    Максимальное количество столбцов 2
                </div>
            );
        });
    };

    const getButtons = () => {
        return (
            <FilterButtonsV2
                className={element("actions", {wrap: Boolean(wrapFilters)})}
                onClear={_onClear}
                onSearch={_onSubmit}
            />
        );
    };

    return (
        <NmForm
            className={block({
                wrap: Boolean(wrapFilters),
            })}
            onKeyDown={onKeyDown}
        >
            {
                !actionsBottomPosition && !wrapFilters &&
                getButtons()
            }
            {getFilters()}
            {
                actionsBottomPosition &&
                getButtons()
            }
        </NmForm>
    );
};

export default Filter;