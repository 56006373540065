import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import CoordsInput from "../../../../components/ActualComponents/CoordsInput";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmTitle from "../../../../components/NmTitle";
import OpenStreetMap from "../../../../components/OpenStreetMap";
import TemplatesDropdown from "../../../../components/TemplatesDropdown";

import {getAddress} from "../../../../utils/map";
import {isValidCoordinate} from "../../../../utils/mathHelper";
import {getKeyFromMigrationDictByDadata} from "../../../../utils/objectHelper";
import {clearSpace} from "../../../../utils/stringFormat";
import {
    handleFormString,
    handleNumber,
} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {OBJECT_FIELD_NAME} from "../../../../constants/clientList";
import {DOCUMENT_TEMPLATES_LIST_FIELD, DOCUMENT_TYPE} from "../../../../constants/documentType";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {headersObjectUserList} from "../../../../constants/headersTable";
import {blueIcon, darkBlueIcon} from "../../../../constants/map";
import {PROJECT_STATUS_DICT} from "../../../../constants/projects";
import {
    objectIdRequiredRule,
    objectRule,
    otherDocumentRequiredRule,
    requiredMessage,
} from "../../../../constants/validationRules";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../../templates/constants";

import {getClientCardInfo} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {clientProgressSelector} from "../../../../ducks/client";
import {clientMemberListSelector} from "../../../../ducks/clientMember";
import {
    addClientObject,
    clientObjectIsSuccessAddSelector,
    clientObjectProgressAddSelector,
    clientObjectProgressUpdateSelector,
    updateClientObject,
    updateFieldClientObjectStore,
} from "../../../../ducks/clientObject";
import {
    dadataGeoDataSelector,
    getAddressByCoords,
    getCoordsByAddress,
    nominatimGeoDataSelector,
    updateGeoDataStore,
} from "../../../../ducks/dadata";
import {
    documentCustomTemplateAggregationShortTemplatesSelector,
} from "../../../../ducks/documentsCustomTemplate";
import {
    clearStoreField,
    federalMigrationMarkersOVMSelector,
    getByIdMigration,
    getFederalMigrationOptionsSelector,
    getFederalRegionDictSelector,
    getFederalRegionOptionsSelector,
    getListMigrations,
    nearestMigrationOVMSelector,
} from "../../../../ducks/federalMigration";
import {
    cardProgressSelector,
    getEnrichedObjectById,
    objectsCardSelector,
    updateFieldsObjectsStore,
} from "../../../../ducks/objects";
import {
    getObjectUserList,
    getObjectUserListSelector,
    getProjectSelector,
    getProjectsListForObject,
    getProjectsOptionsForObjectEditSelector,
    projectListForObjectEditSelector,
} from "../../../../ducks/projects";

import {clientObjectType, memberType} from "../../../../types";
import PropTypes from "prop-types";

import "./style.sass";

class ObjectsEdit extends Component {
    static propTypes = {
        handleCancel: PropTypes.func,
        selectedClientId: PropTypes.string,
        editObject: clientObjectType,
        clientMemberList: PropTypes.arrayOf(memberType),
        progressUpdate: PropTypes.bool,
        updateClientObject: PropTypes.func,
        isGetList: PropTypes.bool,
        isGetCard: PropTypes.bool,
        isGetProjectList: PropTypes.bool,
    };

    static defaultProps = {
        editObject: {},
        handleCancel: () => {
        },
        selectedClientId: "",
        clientMemberList: [],
        progressUpdate: false,
        updateClientObject: () => {
        },
        isGetList: false,
        isGetCard: false,
        isGetProjectList: false,
    };

    initState = {
        name: "",
        longitude: null,
        latitude: null,
        address: "",
        managerId: null,
        objectId: undefined,
        clientId: null,
        region: undefined,
        [OBJECT_FIELD_NAME.REGION]: "",
        bankCheckAccount: "",
        addObjectAddress: false,
        useOtherDocument: false,
    };

    constructor(props) {
        super(props);

        this.isEdit = !isEmpty(props.editObject);

        this.state = {
            isRemoveAction: false,
            editObject: {
                ...this.initState,
            },
            placeMarks: [],
            nameDepartmentFilter: "",
            formError: {},
            regionState: undefined,
            pageNum: 1,
            pageSize: 5,
            isLoadOVMInEditMode: this.isEdit,
            mergeUsersOnSelectedProject: false,
            currentProjectId: props.project.projectId || props.editObject.projectId,
        };
    }

    updateForm = data => {
        this.setState(prevState => ({
            ...prevState,
            editObject: {
                ...prevState.editObject,
                ...data,
            },
        }));
    };

    componentDidMount() {
        const {selectedClientId} = this.props;

        if (this.isEdit) {
            this.getObject();
        }

        if (!this.isEdit) {
            const {
                currentProjectId,
                getClientCardInfo,
            } = this.props;

            getClientCardInfo({clientId: selectedClientId});

            this.setState(prevState => ({
                ...prevState,
                editObject: {
                    ...prevState.editObject,
                    projectId: currentProjectId,
                },
            }));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            placeMarks,
            nearestOVM,
            dadataGeoData,
            clientCard,
            progressClientCard,
        } = this.props;

        const {
            isLoadOVMInEditMode,
        } = this.state;

        const {
            placeMarks: _placeMarks,
            dadataGeoData: _dadataGeoData,
        } = prevProps;

        if (
            !this.state.bankCheckAccount &&
            !this.isEdit &&
            !isEmpty(clientCard) &&
            progressClientCard !== prevProps.progressClientCard &&
            !progressClientCard
        ) {
            const {
                bankCheckAccount,
            } = clientCard;

            this.setState(prevState => ({
                bankCheckAccount,
                editObject: {
                    ...prevState.editObject,
                    bankCheckAccount,
                },
            }));
        }

        // При получении ближайших ОВМ
        if (!isEmpty(placeMarks) && !isEqual(placeMarks, _placeMarks)) {
            const {
                editObject: {
                    federalId,
                },
            } = this.state;

            // В случае если форма открыта на редактирование, то первый раз при прогрузке не проставляем
            // ближайший ОВМ на форме, а используем тот что уже сохранен
            this.setState(prevState => ({
                ...prevState,
                placeMarks,
                editObject: {
                    ...prevState.editObject,
                    federalId: !isLoadOVMInEditMode ? nearestOVM : prevState.editObject.federalId,
                },
            }), () => {
                if (isLoadOVMInEditMode) {
                    this.updateMarkers(federalId);

                    this.setState({
                        isLoadOVMInEditMode: false,
                    });
                }
            });
        }

        // Обновляем поля формы при вводе адреса с дадаты, то же самое можно было сделать и при обратном геокодировании
        // Но в том случае используем колбэк
        if (!isEqual(dadataGeoData, _dadataGeoData) && !isEmpty(dadataGeoData)) {
            const {getListMigrations} = this.props;
            const [
                {
                    region,
                    geo_lat: latitude,
                    geo_lon: longitude,
                },
            ] = dadataGeoData;

            if (!region || !latitude || !longitude) {
                return;
            }

            const regionId = getKeyFromMigrationDictByDadata(region, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                editObject: {
                    ...prevState.editObject,
                    latitude,
                    longitude,
                    region: regionId,
                },
            }));

            getListMigrations({
                pageNum: 1,
                pageSize: 200,
                longitudeFilter: longitude,
                latitudeFilter: latitude,
                region: regionId,
            });
        }
    }

    componentWillUnmount() {
        const {
            clearStoreField,
            updateGeoDataStore,
        } = this.props;

        clearStoreField({
            federalMigrationList: {
                totalCount: 0,
                pageData: {
                    pageSize: 25,
                    pageNum: 1,
                },
                list: [],
            },
        });

        updateGeoDataStore({
            dadataGeoData: [{}],
            nominatimGeoData: {},
        });
    }

    onSuccessGettingObject = (object) => {
        const {getListMigrations} = this.props;

        const {
            region,
            longitude: longitudeFilter,
            latitude: latitudeFilter,
        } = object;

        this.setState({
            editObject: {
                ...object,
                useSmzFrameContract: Boolean(object.smzFrameContractTemplateId),
                useCivilFrameContract: Boolean(object.civilFrameContractTemplateId),
                useIndividualFrameContract: Boolean(object.individualFrameContractTemplateId),
                useOtherDocument: Boolean(object.otherDocumentTemplateId),
            },
        });

        getListMigrations({
            pageNum: 1,
            pageSize: 200,
            longitudeFilter,
            latitudeFilter,
            region,
        });
    };

    getObject = () => {
        const {
            getEnrichedObjectById,
            selectedClientId: clientId,
            editObject: {objectId},
        } = this.props;

        getEnrichedObjectById({
            clientId,
            objectId,
            onSuccess: this.onSuccessGettingObject,
        });
    };

    fetchAddressByCoords = ({lat, lng}) => {
        const {getAddressByCoords} = this.props;

        const onSuccess = (result) => {
            const {getListMigrations} = this.props;
            const {
                address: {
                    state,
                },
            } = result;

            const address = getAddress(result);
            const regionId = getKeyFromMigrationDictByDadata(state, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                editObject: {
                    ...prevState.editObject,
                    latitude: lat,
                    longitude: lng,
                    address,
                    region: regionId,
                },
            }));

            getListMigrations({
                pageNum: 1,
                pageSize: 200,
                longitudeFilter: lng,
                latitudeFilter: lat,
                region: regionId,
            });
        };

        getAddressByCoords({
            lat,
            lng,
            onSuccess,
        });
    };

    fetchCoordsByAddress = (address) => {
        const {getCoordsByAddress} = this.props;

        getCoordsByAddress([address]);
    };

    fetchProjectList = () => {
        const {
            getProjectsListForObject,
            selectedClientId: clientId,
        } = this.props;
        const {
            projectNameSubstringFilter,
        } = this.state;

        getProjectsListForObject({
            fieldName: "projectsForObjectEdit",
            pageNum: 1,
            pageSize: 25,
            clientId,
            status: PROJECT_STATUS_DICT.IN_WORK.VALUE,
            nameSubstringFilter: handleFormString(projectNameSubstringFilter),
        });
    };

    onSearchChangeProgress = (projectNameSubstringFilter) => {
        this.setState(prevState => ({
            ...prevState,
            projectNameSubstringFilter,
        }), this.fetchProjectList);
    };

    getListMigrations = (regionId) => {
        const {getListMigrations} = this.props;
        const {
            editObject: {
                region,
                longitude: longitudeFilter,
                latitude: latitudeFilter,
            },
        } = this.state;

        getListMigrations({
            pageNum: 1,
            pageSize: 200,
            longitudeFilter,
            latitudeFilter,
            region: regionId || region,
        });
    };

    getProjectName(projectId) {
        const {projectOptions} = this.props;

        const {text} = projectOptions.find(item => (item.value === projectId)) || {};

        return text;
    }

    submitForm = () => {
        const {
            editObject,
            mergeUsersOnSelectedProject,
        } = this.state;

        const {
            name,
            address,
            managerId,
            longitude,
            latitude,
            objectId,
            contactNumber,
            federalId,
            projectId,
            status,
            bankCheckAccount,
            addObjectAddress,
        } = editObject;

        const isValid = this.validationForm();

        if (!isValid) {
            return;
        }

        const {
            selectedClientId: clientId,
            updateClientObject,
            addClientObject,
        } = this.props;

        const requestData = {
            objectId,
            contactNumber,
            clientId,
            name: name.trim(),
            address: address.trim(),
            managerId,
            longitude,
            latitude,
            projectId,
            federalId,
            status,
            [OBJECT_FIELD_NAME.OBJECT_EXT_ID]: editObject[OBJECT_FIELD_NAME.OBJECT_EXT_ID] ? editObject[OBJECT_FIELD_NAME.OBJECT_EXT_ID].trim() : null,
            [OBJECT_FIELD_NAME.REGION]: editObject[OBJECT_FIELD_NAME.REGION],
            [OBJECT_FIELD_NAME.AMOUNT]: handleNumber(clearSpace(editObject[OBJECT_FIELD_NAME.AMOUNT])),
            mergeUsersOnSelectedProject,
            bankCheckAccount,
            addObjectAddress,
            otherDocumentTemplateId: editObject.useOtherDocument ? editObject.otherDocumentTemplateId : null,
            smzFrameContractTemplateId: editObject.useSmzFrameContract ? editObject.smzFrameContractTemplateId : null,
            civilFrameContractTemplateId: editObject.useCivilFrameContract ? editObject.civilFrameContractTemplateId : null,
            individualFrameContractTemplateId: editObject.useIndividualFrameContract ? editObject.individualFrameContractTemplateId : null,
        };

        const onSuccess = () => {
            const {
                fetchList,
                fetchCard,
                handleCancel,
            } = this.props;

            if (fetchCard) {
                fetchCard();
            }

            if (fetchList) {
                fetchList();
            }

            updateFieldClientObjectStore("isSuccessAdd", false);
            handleCancel();
        };

        if (this.isEdit) {
            updateClientObject({
                data: requestData,
                handleResponse: this.toggleConfirmWindow,
                onSuccess,
            });

            return;
        }

        addClientObject({
            data: requestData,
            onSuccess,
        });
    };

    updateMarkers = (federalId) => {
        const {placeMarks} = this.state;

        const _placeMarks = placeMarks.map(value => {
            return {
                ...value,
                icon: blueIcon,
            };
        });

        const clickedOVM = _placeMarks.find(value => (value.federalId === federalId)) || {};

        clickedOVM.icon = darkBlueIcon;

        this.setState(prevState => ({
            ...prevState,
            editObject: {
                ...prevState.editObject,
                federalId,
            },
            placeMarks: _placeMarks,
        }));
    };

    onDadataChange = (suggestion) => {
        const {
            value,
            event,
            ...otherSuggestion
        } = suggestion;

        this.onChange(event, {
            name: OBJECT_FIELD_NAME.ADDRESS,
            value,
            ...otherSuggestion,
        });
    };

    onChange = (event, {checked, value, name: field, data}) => {
        if (field === OBJECT_FIELD_NAME.ADDRESS) {
            const address = data ? this.selectedValueFormatter(data, value) : value;

            if (data) {
                this.fetchCoordsByAddress(address);
            }

            this.setState(prevState => ({
                ...prevState,
                editObject: {
                    ...prevState.editObject,
                    address,
                },
            }));

            return;
        }

        if ([
            "useOtherDocument",
            "useCivilFrameContract",
            "useSmzFrameContract",
            "useIndividualFrameContract",
        ].includes(field)) {
            this.onChangeUseTemplate({
                field,
                checked,
            });

            return;
        }

        if ([OBJECT_FIELD_NAME.LATITUDE, OBJECT_FIELD_NAME.LONGITUDE].includes(field) && !isValidCoordinate(value) && value !== "") {
            return;
        }

        if (field === "federalId") {
            this.updateMarkers(value);
        }

        this.setState(prevState =>
            ({
                ...prevState,
                editObject: {
                    ...prevState.editObject,
                    [field]: value || checked,
                },
            }),
        );
    };

    onChangeUseTemplate = ({field, checked}) => {
        const {allOptionsInfo} = this.props;

        const templateIdFieldDict = {
            useOtherDocument: "otherDocumentTemplateId",
            useCivilFrameContract: "civilFrameContractTemplateId",
            useIndividualFrameContract: "individualFrameContractTemplateId",
            useSmzFrameContract: "smzFrameContractTemplateId",
        };

        const templateIdField = templateIdFieldDict[field];

        this.setState(prevState => ({
            ...prevState,
            editObject: {
                ...prevState.editObject,
                [field]: checked,
                [templateIdField]: checked && allOptionsInfo[templateIdField].length === 1 ?
                    allOptionsInfo[templateIdField][0].value : null,
            },
        }));
    };

    toggleConfirmWindow = () => {
        this.setState(prevState => ({
            ...prevState,
            openConfirmWindow: !prevState.openConfirmWindow,
        }));
    };

    onConfirm = () => {
        this.setState({
            mergeUsersOnSelectedProject: true,
        }, this.submitForm);
    };

    validationForm() {
        const {
            clientProperties: {
                objectIdRequired,
            },
        } = this.props;
        const {
            editObject,
        } = this.state;

        const otherDocumentRule = editObject.useOtherDocument ? otherDocumentRequiredRule : {};
        const smzFrameContractRule = editObject.useSmzFrameContract ? {
            smzFrameContractTemplateId: {
                required: requiredMessage,
            },
        } : {};
        const civilFrameContractRule = editObject.useCivilFrameContract ? {
            civilFrameContractTemplateId: {
                required: requiredMessage,
            },
        } : {};
        const individualFrameContractRule = editObject.useIndividualFrameContract ? {
            individualFrameContractTemplateId: {
                required: requiredMessage,
            },
        } : {};

        const rule = objectIdRequired ? {
            ...objectRule,
            ...objectIdRequiredRule,
            ...otherDocumentRule,
            ...smzFrameContractRule,
            ...civilFrameContractRule,
            ...individualFrameContractRule,
        } : {
            ...objectRule,
            ...otherDocumentRule,
            ...smzFrameContractRule,
            ...civilFrameContractRule,
            ...individualFrameContractRule,
        };

        const errorMessage = validate(editObject, rule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    onChangeRegion = (e, {value}) => {
        this.setState(prevState => ({
            ...prevState,
            editObject: {
                ...prevState.editObject,
                region: value,
            },
        }),
        this.getListMigrations,
        );
    };

    searchFederalMigration = (nameDepartmentFilter) => {
        this.setState({nameDepartmentFilter}, this.getListMigrations);
    };

    mapTableData() {
        const {userList} = this.props;

        const rows = userList.map(item => {

            return {
                ...item,
                name: `${item.firstName} ${item.lastName} ${item.patronymic || ""}`,
            };
        });
        return {
            headers: headersObjectUserList,
            rows,
        };
    }

    selectedValueFormatter = (data, value) => {
        const {
            fias_level,
            region_with_type,
            city_with_type,
        } = data;

        let cityAddress = [];

        // уровень детализации - город
        if (fias_level === "4") {
            cityAddress = [region_with_type, city_with_type];
        }

        return fias_level === "4" ? cityAddress.filter(value => Boolean(value)).join(", ") : value;
    };

    renderForm() {
        const {
            federalMigrationOptions,
            federalRegionsOptions,
            clientProperties: {
                customDocumentTemplatesAvailable,
            },
            selectedClientId,
            allOptionsInfo,
            projectOptions,
            t,
        } = this.props;
        const {
            editObject,
            formError,
        } = this.state;
        const {
            name,
            address,
            region,
            federalId,
            addObjectAddress,
            projectId,
            projectName,
        } = editObject;

        return (
            <NmForm addMargin>
                <NmDropdownV2
                    required
                    size="xl"
                    className="field object-edit-form__field object-edit-form__field_full"
                    name={OBJECT_FIELD_NAME.PROJECT_ID}
                    value={projectId}
                    options={projectOptions}
                    onChange={this.onChange}
                    label="Проект"
                    placeholder="Выберите проект"
                    error={formError[OBJECT_FIELD_NAME.PROJECT_ID]}
                    search={true}
                    onSearchChange={this.onSearchChangeProgress}
                    initialOption={
                        projectId && {
                            key: projectId,
                            value: projectId,
                            text: projectName,
                        }
                    }
                />
                <NmInputV2
                    size="xl"
                    label="Название объекта"
                    name={OBJECT_FIELD_NAME.NAME}
                    className="field object-edit-form__field object-edit-form__field_full"
                    onChange={this.onChange}
                    type="text"
                    error={formError[OBJECT_FIELD_NAME.NAME]}
                    value={name}
                    placeholder="Введите название объекта"
                    required
                />
                <Text
                    level="4"
                    bold
                >
                    Параметры объекта
                </Text>
                <NmInputV2
                    size="xl"
                    label="ID объекта"
                    placeholder="Введите ID объекта"
                    className="object-edit-form__field object-edit-form__field_full"
                    type="text"
                    disabled={this.isEdit && editObject[OBJECT_FIELD_NAME.CAN_EDIT_EXT_ID] === false}
                    name={OBJECT_FIELD_NAME.OBJECT_EXT_ID}
                    error={formError[OBJECT_FIELD_NAME.OBJECT_EXT_ID]}
                    value={editObject[OBJECT_FIELD_NAME.OBJECT_EXT_ID] || ""}
                    onChange={this.onChange}
                />
                <div className="object-edit-form__row">
                    <NmInputV2
                        size="xl"
                        label="р/с"
                        placeholder="Введите р/с"
                        className="object-edit-form__field object-edit-form__field_short"
                        type="text"
                        name={OBJECT_FIELD_NAME.BANK_CHECK_ACCOUNT}
                        error={formError[OBJECT_FIELD_NAME.BANK_CHECK_ACCOUNT]}
                        value={editObject[OBJECT_FIELD_NAME.BANK_CHECK_ACCOUNT] || ""}
                        onChange={this.onChange}
                    />
                    <NmCheckboxV2
                        name={OBJECT_FIELD_NAME.ADD_OBJECT_ADDRESS}
                        className="object-edit-form__budget-checkbox"
                        label={t("client-object-new.add-object-address")}
                        checked={addObjectAddress}
                        onChange={this.onChange}
                    />
                </div>
                {
                    customDocumentTemplatesAvailable &&
                    <>
                        <NmCheckboxV2
                            checked={editObject.useSmzFrameContract}
                            name="useSmzFrameContract"
                            label="Заключать отдельный договор НПД на объекте"
                            info={true}
                            tooltipOutline={false}
                            tooltipText="Исполнители, выполняющие работы или оказывающие услуги по договорам НПД на заказах текущего объекта, будут подписывать договор НПД независимо от наличия действующего договора НПД по компании или на других объектах"
                            onChange={this.onChange}
                        />
                        <TemplatesDropdown
                            required={editObject.useSmzFrameContract}
                            disabled={!editObject.useSmzFrameContract}
                            className="mb-3 mb-md-4"
                            clientIdFilter={selectedClientId}
                            isFetch={true}
                            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT}
                            orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.SMZ}
                            fieldName="aggregationShortTemplates"
                            aggregationFieldName="smzFrameContractTemplateId"
                            isAggregation={true}
                            buttonText="Скачать документ"
                            placeholder="Выберите шаблон"
                            label="Шаблон договора НПД"
                            name="smzFrameContractTemplateId"
                            onChange={this.onChange}
                            options={allOptionsInfo.smzFrameContractTemplateId}
                            value={editObject.smzFrameContractTemplateId}
                            error={formError.smzFrameContractTemplateId}
                            size="xl"
                        />
                        <NmCheckboxV2
                            checked={editObject.useCivilFrameContract}
                            name="useCivilFrameContract"
                            label="Заключать отдельный договор НДФЛ на объекте"
                            info={true}
                            tooltipOutline={false}
                            tooltipText="Исполнители, выполняющие работы или оказывающие услуги по договорам НДФЛ на заказах текущего объекта, будут подписывать договор НДФЛ независимо от наличия действующего договора НДФЛ по компании или на других объектах"
                            onChange={this.onChange}
                        />
                        <TemplatesDropdown
                            required={editObject.useCivilFrameContract}
                            disabled={!editObject.useCivilFrameContract}
                            className="mb-3 mb-md-4"
                            clientIdFilter={selectedClientId}
                            isFetch={true}
                            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT}
                            orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.CIVIL}
                            fieldName="aggregationShortTemplates"
                            aggregationFieldName="civilFrameContractTemplateId"
                            isAggregation={true}
                            buttonText="Скачать документ"
                            placeholder="Выберите шаблон"
                            label="Шаблон договора НДФЛ"
                            name="civilFrameContractTemplateId"
                            onChange={this.onChange}
                            options={allOptionsInfo.civilFrameContractTemplateId}
                            value={editObject.civilFrameContractTemplateId}
                            error={formError.civilFrameContractTemplateId}
                            size="xl"
                        />
                        <NmCheckboxV2
                            checked={editObject.useIndividualFrameContract}
                            name="useIndividualFrameContract"
                            label="Заключать отдельный договор ИП на объекте"
                            info={true}
                            tooltipOutline={false}
                            tooltipText="Исполнители, выполняющие работы или оказывающие услуги по договорам ИП на заказах текущего объекта, будут подписывать договор ИП независимо от наличия действующего договора ИП по компании или на других объектах"
                            onChange={this.onChange}
                        />
                        <TemplatesDropdown
                            required={editObject.useIndividualFrameContract}
                            disabled={!editObject.useIndividualFrameContract}
                            className="mb-3 mb-md-4"
                            clientIdFilter={selectedClientId}
                            isFetch={true}
                            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.FRAME_CONTRACT}
                            orderContractPaymentTypeFilter={ORDER_WORK_REPORT_TYPE.INDIVIDUAL}
                            fieldName="aggregationShortTemplates"
                            aggregationFieldName="individualFrameContractTemplateId"
                            isAggregation={true}
                            buttonText="Скачать документ"
                            placeholder="Выберите шаблон"
                            label="Шаблон договора ИП"
                            name="individualFrameContractTemplateId"
                            onChange={this.onChange}
                            options={allOptionsInfo.individualFrameContractTemplateId}
                            value={editObject.individualFrameContractTemplateId}
                            error={formError.individualFrameContractTemplateId}
                            size="xl"
                        />
                        <NmCheckboxV2
                            checked={editObject.useOtherDocument}
                            name="useOtherDocument"
                            label="Запрашивать подписание иного документа на объекте"
                            info
                            tooltipOutline={false}
                            tooltipText="Исполнителям, выполняющим работы или оказывающие услуги на заказах текущего объекта, будет предложено подписать дополнительно иной документ"
                            onChange={this.onChange}
                        />
                        <TemplatesDropdown
                            required={editObject.useOtherDocument}
                            disabled={!editObject.useOtherDocument}
                            className="mb-3 mb-md-4"
                            clientIdFilter={selectedClientId}
                            isFetch
                            documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.OTHER_DOCUMENTS}
                            buttonText="Скачать документ"
                            placeholder="Выберите шаблон"
                            label="Шаблон иного документа"
                            name="otherDocumentTemplateId"
                            fieldName="aggregationShortTemplates"
                            aggregationFieldName="otherDocumentTemplateId"
                            isAggregation
                            onChange={this.onChange}
                            options={allOptionsInfo.otherDocumentTemplateId}
                            value={editObject.otherDocumentTemplateId}
                            error={formError.otherDocumentTemplateId}
                            size="xl"
                        />
                    </>
                }
                <div className="object-edit-form__address">
                    <NmDadataInput
                        className="object-edit-form__field__address-input"
                        name={OBJECT_FIELD_NAME.ADDRESS}
                        label={t("client-object-new.address")}
                        placeholder="Введите адрес"
                        error={formError[OBJECT_FIELD_NAME.ADDRESS]}
                        onChange={this.onDadataChange}
                        query={address || ""}
                        required
                    />
                    <div className="object-edit-form__coords">
                        <CoordsInput
                            nameStart={OBJECT_FIELD_NAME.LATITUDE}
                            nameEnd={OBJECT_FIELD_NAME.LONGITUDE}
                            size="xl"
                            valueStart={editObject[OBJECT_FIELD_NAME.LATITUDE] || ""}
                            valueEnd={editObject[OBJECT_FIELD_NAME.LONGITUDE] || ""}
                            onChange={this.onChange}
                            label={t("client-object-new.coordinates")}
                            placeholderStart={t("client-object-new.location-latitude")}
                            placeholderEnd={t("client-object-new.location-longitude")}
                            error={formError[OBJECT_FIELD_NAME.LATITUDE] || formError[OBJECT_FIELD_NAME.LONGITUDE]}
                            required
                        />
                    </div>
                </div>
                {this.renderMap()}
                <div className="object-edit-form__row">
                    <NmDropdownV2
                        size="xl"
                        search
                        label={t("client-object-new.ovm-region")}
                        name={OBJECT_FIELD_NAME.REGION}
                        error={formError[OBJECT_FIELD_NAME.REGION]}
                        options={federalRegionsOptions}
                        className="object-edit-form__field object-edit-form__field_short object-edit-form__field_mr"
                        placeholder={t("client-object-new.ovm-region")}
                        onChange={this.onChangeRegion}
                        value={region}
                        required
                    />
                    <NmDropdownV2
                        label={t("client-object-new.ovm")}
                        name={OBJECT_FIELD_NAME.FEDERAL_ID}
                        error={formError[OBJECT_FIELD_NAME.FEDERAL_ID]}
                        isMountLoad={false}
                        onSearchChange={this.searchFederalMigration}
                        onChange={this.onChange}
                        className="object-edit-form__field object-edit-form__field_long"
                        placeholder={t("client-object-new.ovm")}
                        search
                        value={federalId}
                        options={federalMigrationOptions}
                        required
                    />
                </div>
            </NmForm>
        );
    }

    renderMap() {
        const {
            placeMarks,
            editObject: {
                latitude,
                longitude,
                address,
            },
        } = this.state;

        return (
            <OpenStreetMap
                className="object-edit-form__map"
                zoom={10}
                center={[55.75, 37.57]}
                scrollWheelZoom={true}
                placeMarks={placeMarks}
                updateMarkers={this.updateMarkers}
                address={address}
                longitude={longitude}
                tooltipContent={address}
                latitude={latitude}
                fetchAddressByCoords={this.fetchAddressByCoords}
                updateForm={this.updateForm}
                OVMs
            />
        );
    }

    renderActions() {
        const {
            t,
            handleCancel,
        } = this.props;

        return (
            <ApplyButtons
                submitBtnContent={t("button.save")}
                cancelBtnContent={t("button.cancel")}
                onClose={handleCancel}
                submit={this.submitForm}
            />
        );
    }

    renderConfirmWindow() {
        const {t} = this.props;
        const {
            openConfirmWindow,
            editObject: {
                projectId,
            },
            currentProjectId,
        } = this.state;

        return openConfirmWindow &&
            <NmConfirmV2
                onCancel={this.toggleConfirmWindow}
                onConfirm={this.onConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.cancel")}
                content={`На проекте "${this.getProjectName(currentProjectId)}" найдены сотрудники, которые не добавлены в команду выбранного проекта "${this.getProjectName(projectId)}". Требуется добавить сотрудников в команду выбранного проекта. Вы подтверждаете данную операцию?`}
                size="sm"
            />;
    }

    render() {
        const {
            progressAdd,
            progressUpdate,
            progressCard,
            handleCancel,
            editObject,
            t,
        } = this.props;

        return (
            <NmModal
                size="lg"
                onClose={handleCancel}
                className="object-edit-form"
                header={
                    <NmTitle size="lg">
                        {
                            !isEmpty(editObject)
                                ? `${t("client-object-new.title-edit")}`
                                : `${t("client-object-new.title-new")}`
                        }
                    </NmTitle>
                }
                footer={this.renderActions()}
                loaderInverted
                loading={progressAdd || progressUpdate || progressCard}
            >
                {this.renderConfirmWindow()}
                {this.renderForm()}
            </NmModal>
        );
    }
}

export default connect(state => ({
    object: objectsCardSelector(state),
    clientMemberList: clientMemberListSelector(state),
    progressUpdate: clientObjectProgressUpdateSelector(state),
    progressAdd: clientObjectProgressAddSelector(state),
    federalMigrationOptions: getFederalMigrationOptionsSelector(state),
    placeMarks: federalMigrationMarkersOVMSelector(state),
    federalRegionDict: getFederalRegionDictSelector(state),
    federalRegionsOptions: getFederalRegionOptionsSelector(state),
    nearestOVM: nearestMigrationOVMSelector(state),
    userList: getObjectUserListSelector(state),
    projectOptions: getProjectsOptionsForObjectEditSelector(state),
    projects: projectListForObjectEditSelector(state),
    isSuccessAddObject: clientObjectIsSuccessAddSelector(state),
    project: getProjectSelector(state),
    markersOVMS: federalMigrationMarkersOVMSelector(state),
    dadataGeoData: dadataGeoDataSelector(state),
    nominatimGeoData: nominatimGeoDataSelector(state),
    clientCard: clientCardInfoSelector(state),
    progressClientCard: clientProgressSelector(state),
    progressCard: cardProgressSelector(state),
    clientProperties: clientCardPropertiesSelector(state),
    allOptionsInfo: documentCustomTemplateAggregationShortTemplatesSelector(state),
}), {
    clearStoreField,
    updateClientObject,
    addClientObject,
    getListMigrations,
    getByIdMigration,
    getObjectUserList,
    updateFieldClientObjectStore,
    getProjectsListForObject,
    getCoordsByAddress,
    getAddressByCoords,
    getEnrichedObjectById,
    updateGeoDataStore,
    updateFieldsObjectsStore,
    getClientCardInfo,
})(withTranslation()(ObjectsEdit));