import React from "react";

import {RECRUITMENT_CONTACTS} from "../../../constants/recruitment";
import {ReactComponent as TelegramIcon} from "../../../images/telegram_20.svg";
import {ReactComponent as WhatsappIcon} from "../../../images/whatsapp.svg";
import ContextMenu from "../../ActualComponents/ContextMenu";
import DictIcon from "../../ActualComponents/DictIcon";
import Text from "../../ActualComponents/Text";
import {RecruitmentTemplateMessage} from "../TemplateMessage";

import {useModal} from "../../../hooks/useModal";

import {getUserRole} from "../../../utils/access";
import {getClassNames} from "../../../utils/classNames";
import {openLinkByUrl} from "../../../utils/downloadBlob";
import {isLastElementInArray} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";
import {ADMIN, CLIENT_ADMIN, RECRUITER, RECRUITMENT_OBSERVER} from "../../../constants/roles";

const ICONS = {
    WHATSAPP: WhatsappIcon,
    TELEGRAM: TelegramIcon,
};

export const RecruitmentContacts = (props) => {
    const {
        contacts,
        fio,
        clientId,
        vacancyId,
        responseId,
        candidateId,
    } = props;

    const role = getUserRole();
    const isAccess = [
        ADMIN,
        CLIENT_ADMIN,
        RECRUITER,
    ].includes(role) || role === RECRUITMENT_OBSERVER && vacancyId;

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    return (
        <div className="d-flex align-items-center">
            {
                modalData?.isOpenTemplateMessage &&
                <RecruitmentTemplateMessage
                    clientId={clientId}
                    vacancyId={vacancyId}
                    name={modalData.name}
                    fio={modalData.fio}
                    onClose={onCloseModal}
                    url={modalData.url}
                    candidateId={candidateId}
                    responseId={responseId}
                />
            }
            {
                contacts.map((item, index) => {
                    const isLast = isLastElementInArray(contacts, index);
                    const name = RECRUITMENT_CONTACTS[item.type];

                    return (
                        <ContextMenu
                            key={name}
                            disabled={!isAccess}
                            position="bottom-left"
                            trigger={
                                <div
                                    key={item.id}
                                    className={
                                        getClassNames([
                                            "d-flex align-items-center",
                                            !isLast && "me-1",
                                        ])
                                    }
                                >
                                    <DictIcon
                                        dict={ICONS}
                                        name={item.type}
                                        color={
                                            isAccess
                                                ? COLOR.PASSIVE_50
                                                : COLOR.SECONDARY_90
                                        }
                                    />
                                    <Text
                                        color={
                                            isAccess
                                                ? COLOR.PASSIVE_100
                                                : COLOR.SECONDARY_90
                                        }
                                        level="3"
                                        className="ms-1"
                                    >
                                        {name}
                                    </Text>
                                </div>
                            }
                            options={
                                [
                                    {
                                        text: `Перейти в ${name}`,
                                        onClick: () => {
                                            if (!item.url) {
                                                return;
                                            }

                                            openLinkByUrl(item.url);
                                        },
                                    },
                                    {
                                        text: "Отправить сообщение по шаблону",
                                        onClick: () => {
                                            onOpenModal({
                                                isOpenTemplateMessage: true,
                                                name,
                                                fio,
                                                url: item.url,
                                            });
                                        },
                                    },
                                ]
                            }
                        />
                    );
                })
            }
        </div>
    );
};