import {isNull} from "lodash";

/**
 * Хелпер для формирования значения в поле "Длительность статуса" для отправки на BE
 * @param item
 * @returns {number|null}
 */
export const getRecruitmentFunnelDuration = (item) => {
    if (isNull(item.statusDurationDays)) {
        return null;
    }

    if (item.statusDurationDays) {
        return +item.statusDurationDays;
    }
};