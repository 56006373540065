import React from "react";
import {Route, Switch, withRouter} from "react-router";

import NotificationsList from "../../../notifications/list";
import NotificationMailingList from "../../../notifications/mailing-list";
import {NotificationsSubscribes} from "../../../notifications/subscribes";

import {
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
} from "../../../../constants/links";

export const ClientCardNotificationsRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_NOTIFICATIONS_LIST}
                component={NotificationsList}
            />
            <Route
                exact
                path={LINK_CLIENT_SETTING_NOTIFICATIONS_LIST}
                component={NotificationMailingList}
            />
            <Route
                path={LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS}
                component={NotificationsSubscribes}
            />
        </Switch>
    );
};

export default withRouter(ClientCardNotificationsRoute);