import React from "react";
import {useSelector} from "react-redux";

import NmDivider from "../../../../components/ActualComponents/NmDivider";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import NmReadonlyRating from "../../../../components/NmReadonlyRating";

import {COLOR} from "../../../../constants/color";

import {forbiddenOrderClientListSelector} from "../../../../ducks/bff/contractor-сard/profile/selectors";

export const ContractorBanOnInvitations = (props) => {
    const {
        className,
    } = props;

    const forbiddenOrderClientList = useSelector(forbiddenOrderClientListSelector);

    const getContent = () => {
        if (!forbiddenOrderClientList.length) {
            return (
                <Text level="1">
                    Сведения пока не добавлены
                </Text>
            );
        }

        return forbiddenOrderClientList.map(item => {
            const {
                clientId,
                name,
                rating,
            } = item;

            return (
                <div key={clientId}>
                    <Text
                        type="title"
                        level="4"
                        className="mb-2"
                    >
                        {name}
                    </Text>
                    <NmLabelText
                        key={clientId}
                        label="Общий рейтинг"
                        text={
                            <NmReadonlyRating rating={rating || 0} />
                        }
                        className="mb-2"
                    />
                    <NmDivider
                        color={COLOR.SECONDARY_5}
                        className="mb-4"
                    />
                </div>
            );
        });
    };

    return (
        <NmMiniInfoCard
            title="Запрет на приглашения"
            className={className}
        >
            {getContent()}
        </NmMiniInfoCard>
    );
};