import React from "react";
import {useSelector} from "react-redux";

import ContractorFinanceIncome from "../income";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_REGISTRY_PAYMENTS_LIST,
} from "../../../../constants/links";

import {
    contractorFinanceIncomeIndividualListSelector,
    contractorFinanceIncomeIndividualProgressSelector,
    contractorFinanceIncomeIndividualTotalCountSelector,
    contractorFinanceIncomeIndividualTotalPagesSelector,
    contractorSummaryFinanceIncomeIndividualSelector,
    getSummaryContractorFinanceIncomeIndividual,
    updateContractorFinanceIncomeIndividualStore,
} from "../../../../ducks/contractorFinanceIncomeIndividual";

export const ContractorFinanceIncomeIndividual = (props) => {
    const list = useSelector(contractorFinanceIncomeIndividualListSelector);
    const progress = useSelector(contractorFinanceIncomeIndividualProgressSelector);
    const totalPages = useSelector(contractorFinanceIncomeIndividualTotalPagesSelector);
    const totalCount = useSelector(contractorFinanceIncomeIndividualTotalCountSelector);

    return (
        <ContractorFinanceIncome
            isIndividual={true}
            tabsLinks={{
                awaiting: LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
                completed: LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
                inProcess: LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
                registryPayments: LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_REGISTRY_PAYMENTS_LIST,
            }}
            list={list}
            progress={progress}
            totalPages={totalPages}
            totalCount={totalCount}
            clearStore={updateContractorFinanceIncomeIndividualStore}
            balanceSelector={contractorSummaryFinanceIncomeIndividualSelector}
            fetchSummaryData={getSummaryContractorFinanceIncomeIndividual}
            {...props}
        />
    );
};