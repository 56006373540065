export const SSE_EVENT_TYPE = {
    INIT_EVENT: "INIT_EVENT", //Начальное событие при подписке
    NEW_MESSAGE_CHAT_CRM: "NEW_MESSAGE_CHAT_CRM", //Новое сообщение в чате CRM
    NEW_MESSAGE_CHAT: "NEW_MESSAGE_CHAT", //Новое сообщение в чате
    NEW_MESSAGE_CROWD_CHAT: "NEW_MESSAGE_CROWD_CHAT", //Новое сообщение в чате по заданиям
    READ_CHAT: "READ_CHAT", //Прочитано сообщение в чате
    NEW_MESSAGE_DISPUTE_CHAT: "NEW_MESSAGE_DISPUTE_CHAT", //Новое сообщение в чате спора
    DISPUTES_CHANGE_STATUS: "DISPUTES_CHANGE_STATUS", //Изменение статуса спора
    AVITO_PUBLISHED_STATUS: "AVITO_PUBLISHED_STATUS", //Изменение статуса публикации вакансии на авито
    //Событие автоматического удаления подписок на уведомления при отключении модуля "Рекрутмент"
    UPDATE_SUBSCRIBE_NOTIFICATIONS: "UPDATE_SUBSCRIBE_NOTIFICATIONS",
};