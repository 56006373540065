import React from "react";

import ClientCardPage from "../../../../components/ClientCardPage";
import ClientCardNotificationsRoute from "./Route";

import {
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
} from "../../../../constants/links";

import "./style.sass";

const ClientCardNotifications = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const getLinks = () => {
        const notifications = LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId);
        const mailing = LINK_CLIENT_SETTING_NOTIFICATIONS_LIST.replace(":clientId", clientId);
        const subscribeNotifications = LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL.replace(":clientId", clientId);

        return {
            notifications,
            mailing,
            subscribeNotifications,
        };
    };

    const getTabLinks = () => {
        const {
            notifications,
            mailing,
            subscribeNotifications,
        } = getLinks();

        return [
            {
                active: notifications,
                link: notifications,
                name: "Уведомления",
            },
            {
                active: mailing,
                link: mailing,
                name: "Рассылки на почту",
            },
            {
                active: subscribeNotifications,
                link: subscribeNotifications,
                name: "Подписки на уведомления",
            },
        ];
    };

    return (
        <ClientCardPage
            title="Уведомления"
            tabs={getTabLinks()}
        >
            <ClientCardNotificationsRoute {...props} />
        </ClientCardPage>
    );
};

export default ClientCardNotifications;
