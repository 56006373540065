import React from "react";

import ApplyButtons from "../../ApplyButtons";
import NmButton from "../../NmButton";
import NmTitle from "../../NmTitle";
import NmDropdownV2 from "../NmDropdownV2";
import NmForm from "../NmForm";
import NmInputV2 from "../NmInputV2";
import NmModal from "../NmModal";
import NmModalCardList from "../NmModal/CardList";
import NmPagination from "../NmPagination";

import {usePagination} from "../../../hooks/usePagination";
import {useAddContractorToRegistry} from "./hooks/useForm";

import {getFullName, phoneFormat} from "../../../utils/stringFormat";

import {PHONE_MASK} from "../../../constants/phone";
import {CONTRACTOR_SEARCH_TYPE} from "../../../constants/registry";
import {ACT_REGISTRY_CONTRACTOR_SEARCH_TYPE_OPTIONS} from "../../../constants/registry";
import {SEARCH_CONTRACTOR_STEP} from "./constants";

import {AddContractorToRegistryProps} from "./types";

export function AddContractorToRegistry(props: AddContractorToRegistryProps) {
    const {
        clientId,
        onClose,
        children,
        totalPages,
        list,
        totalCount,
        progressSearch,
        getContractors,
    } = props;

    const {
        pageNum,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        setFieldTouched,
        selectContractor,
        step,
        onSetStep,
        contractorData,
        goBack,
    } = useAddContractorToRegistry({
        clientId,
        list,
        totalCount,
        progressSearch,
        pageNum,
        getContractors,
    });

    const {
        searchType,
        phoneNumber,
        fullName,
        inn,
    } = values;

    const getCards = () => {
        return list.map(item => {
            return {
                ...item,
                singleValues: [
                    {
                        label: "ФИО",
                        value: item.lastName ? getFullName(item.lastName, item.firstName, item.patronymic) : item.fullName,
                    },
                    {label: "Телефон", value: phoneFormat(item.phone) as string},
                    {label: "ИНН", value: item.inn},
                ],
                actions: (
                    <NmButton
                        color="light-green"
                        className="registry-found-contractors__pick"
                        onClick={() => selectContractor(item)}
                    >
                        Выбрать
                    </NmButton>
                ),
            };
        });
    };

    const contractorListForm = () => {
        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Добавление исполнителя
                    </NmTitle>
                }
                footer={
                    <div className="flex flex-content-spaced fluid-flex-grow">
                        <NmButton
                            color="grey"
                            size="xl"
                            onClick={() => {
                                onSetStep(SEARCH_CONTRACTOR_STEP.SEARCH);
                            }}
                        >
                            Назад
                        </NmButton>
                        <NmButton
                            color="grey"
                            size="xl"
                            onClick={onClose}
                        >
                            Отменить
                        </NmButton>
                    </div>
                }
                onClose={onClose}
            >
                <NmForm>
                    <NmModalCardList
                        list={getCards()}
                        pagination={
                            <NmPagination
                                pageNum={pageNum}
                                totalPages={totalPages}
                                onChangePagination={onPaginationChange}
                            />
                        }
                    />
                </NmForm>
            </NmModal>
        );
    };

    const renderSearchTypeInput = () => {
        if (searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
            return (
                <NmInputV2
                    size="xl"
                    className="ms-3"
                    placeholder="Телефон"
                    mask={PHONE_MASK.FOREIGN}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("phoneNumber", true)}
                    maskChar={null}
                    name="phoneNumber"
                    value={phoneNumber}
                    error={touched?.phoneNumber && errors.phoneNumber}
                />
            );
        }

        if (searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
            return (
                <NmInputV2
                    size="xl"
                    className="ms-3"
                    placeholder="ФИО"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("fullName", true)}
                    maskChar={null}
                    name="fullName"
                    value={fullName}
                    error={touched?.fullName && errors?.fullName}
                />
            );
        }

        return (
            <NmInputV2
                size="xl"
                className="ms-3"
                placeholder="ИНН"
                mask="99 99 999999 99"
                maskChar={null}
                onChange={handleChange}
                onBlur={() => setFieldTouched("inn", true)}
                name="inn"
                value={inn}
                error={touched?.inn && errors?.inn}
            />
        );
    };

    const renderSearchForm = () => {
        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Поиск исполнителя
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        isHiddenCancelOnMobile
                        mobile="column"
                        loading={progressSearch}
                        disabled={progressSearch}
                        onClose={onClose}
                        submit={handleSubmit}
                        cancelBtnContent="Отменить"
                        submitBtnContent="Подтвердить"
                    />
                }
                onClose={onClose}
            >
                <NmForm onSubmit={handleSubmit}>
                    <div className="flex flex-content-spaced fluid-flex-grow">
                        <NmDropdownV2
                            size="xl"
                            name="searchType"
                            value={searchType}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            options={ACT_REGISTRY_CONTRACTOR_SEARCH_TYPE_OPTIONS}
                        />
                        {renderSearchTypeInput()}
                    </div>
                </NmForm>
            </NmModal>
        );
    };

    const renderModalByStep = () => {
        switch (step) {
            case SEARCH_CONTRACTOR_STEP.SEARCH: {
                return renderSearchForm();
            }
            case SEARCH_CONTRACTOR_STEP.SELECT: {
                return contractorListForm();
            }
            case SEARCH_CONTRACTOR_STEP.CUSTOM: {
                return children({
                    contractorData,
                    goBack,
                });
            }
            // no default
        }

        return null;
    };

    return renderModalByStep();
}