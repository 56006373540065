import {createSelector} from "reselect";

import {getDadataAddressObj} from "../../../../utils/dadata";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

const contractorProfileSelector = state => state.bff.contractorCardProfile;
export const contractorProfileWorkExperiencesSelector = createSelector(contractorProfileSelector, ({workExperiences}) => workExperiences);
export const contractorEducationsSelector = createSelector(contractorProfileSelector, ({educations}) => educations);
export const contractorEducationLevelDictSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => educationLevelDict);
export const contractorEducationLevelDictWithNoneSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => {
    return {
        NONE: "Нет",
        ...educationLevelDict,
    };
});
export const contractorEducationLevelDictOptionsSelector = createSelector(contractorProfileSelector, ({educationLevelDict}) => dictionaryToOptions(educationLevelDict));
export const contractorDrivingLicenseSelector = createSelector(contractorProfileSelector, ({drivingLicenseObj}) => drivingLicenseObj);
export const contractorDrivingLicenseDictOptionsSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => dictionaryToOptions(driverLicenseDict));
export const contractorDrivingLicenseDictSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => driverLicenseDict);
export const contractorDrivingLicenseDictWithKeySelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => {
    return Object.keys(driverLicenseDict).reduce((result, key) => {
        return {
            ...result,
            [key]: `${key}, ${driverLicenseDict[key]}`,
        };
    }, {});
});
export const contractorDrivingLicenseDictKeyDictSelector = createSelector(contractorProfileSelector, ({driverLicenseDict}) => {
    return Object.keys(driverLicenseDict).reduce((result, key) => {
        return {
            ...result,
            [key]: key,
        };
    }, {});
});
export const contractorLanguagesDictSelector = createSelector(contractorProfileSelector, ({contractorLanguagesDict}) => contractorLanguagesDict);
export const contractorLanguageSelector = createSelector(contractorProfileSelector, ({contractorLanguage}) => contractorLanguage);
export const contractorLanguagesDictOptionsSelector = createSelector(contractorProfileSelector, ({contractorLanguagesDict}) => dictionaryToOptions(contractorLanguagesDict));
export const contractorAdditionalSpecialitySelector = createSelector(contractorProfileSelector, ({additionalSpeciality}) => additionalSpeciality);
export const contractorCourseCertificateListSelector = createSelector(contractorProfileSelector, ({contractorCourseCertificateList}) => contractorCourseCertificateList);
export const contractorFamilyStatusSelector = createSelector(contractorProfileSelector, ({familyStatus}) => familyStatus);
export const contractorKeySkillsSelector = createSelector(contractorProfileSelector, ({keySkills}) => keySkills);
export const contractorMedicalBookSelector = createSelector(contractorProfileSelector, ({medicalBook}) => medicalBook);
export const contractorVaccinationCertificateSelector = createSelector(contractorProfileSelector, ({vaccinationCertificate}) => vaccinationCertificate);
export const contractorPortfolioListSelector = createSelector(contractorProfileSelector, ({portfolioList}) => portfolioList);
export const contractorWorkContraindicationSelector = createSelector(contractorProfileSelector, ({workContraindication}) => workContraindication);
export const contractorMainSpecialitySelector = createSelector(contractorProfileSelector, ({mainSpeciality}) => mainSpeciality);
export const contractorSchedulesSelector = createSelector(contractorProfileSelector, ({schedules}) => schedules);
export const contractorDepartureAddressesInfoSelector = createSelector(contractorProfileSelector, ({departureAddressesInfo}) => departureAddressesInfo);
export const contractorDepartureAddressesInfoListSelector = createSelector(contractorProfileSelector, ({departureAddressesInfo: {departureAddressesInfo}}) => departureAddressesInfo ? Object.values(departureAddressesInfo).map(item => getDadataAddressObj(item)) : null);
export const contractorWorkAddressInfoSelector = createSelector(contractorProfileSelector, ({workAddressInfo}) => getDadataAddressObj(workAddressInfo));
export const contractorRemoteWorkSelector = createSelector(contractorProfileSelector, ({remoteWork}) => remoteWork);
export const forbiddenOrderClientListSelector = createSelector(contractorProfileSelector, ({forbiddenOrderClientList}) => forbiddenOrderClientList);