import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getInfoContractorCard} from "./bff/contractor-сard/actionCreators";

import {getApplicationCount} from "../utils/contractors";
import {getFiasIdByAddressType, getWorkAddressInfoArr} from "../utils/dadata";
import {ls, USER_ROLE} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {citizenshipToOptions, dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {getFullName, phoneFormat} from "../utils/stringFormat";
import {toastError, toastSuccess, toastWarning} from "../utils/toastHelper";

import {
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE,
    REMOTE_WORK_OPTION,
} from "../constants/contractor";
import {CONTRACTOR_MESSAGE} from "../constants/messages";
import {CONTRACTOR, isClientUser} from "../constants/roles";

// // TODO: при переносе файла в структуру bff не переносить этот хелпер, а использовать его из общих утилит
const getBffUrl = (urlsAssociation, defaultUrl, isReverseComparison = false) => {
    const pageUrl = Object.keys(urlsAssociation).find(url => {
        if (isReverseComparison) {
            return window.location.pathname.includes(url);
        }

        return url.includes(window.location.pathname);
    });

    if (!pageUrl && urlsAssociation.isClientCard) {
        const role = ls(USER_ROLE);
        const isClientRole = isClientUser(role);

        const {
            clientRolesUrl,
            adminRolesUrl,
        } = urlsAssociation;

        if (isClientRole) {
            return clientRolesUrl;
        }

        return adminRolesUrl;
    }

    if (!pageUrl) {
        return defaultUrl;
    }

    if (urlsAssociation[pageUrl]) {
        return urlsAssociation[pageUrl];
    }

    return defaultUrl;
};

export const getController = () => {
    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: "/client-adm/orders/act-registry/card",
            adminRolesUrl: "/adm/clients/client-card/orders/act-registry/card",
        },
    );
};

const controller = "/contractors";
//*  TYPES  *//

const CONTRACTOR_READ_REQUEST = "CONTRACTOR_READ_REQUEST";
const CONTRACTOR_READ_SUCCESS = "CONTRACTOR_READ_SUCCESS";
const CONTRACTOR_READ_ERROR = "CONTRACTOR_READ_ERROR";

const CONTRACTOR_UPDATE_REQUEST = "CONTRACTOR_UPDATE_REQUEST";
const CONTRACTOR_UPDATE_SUCCESS = "CONTRACTOR_UPDATE_SUCCESS";
const CONTRACTOR_UPDATE_ERROR = "CONTRACTOR_UPDATE_ERROR";

const CONTRACTOR_LIST_READ_REQUEST = "CONTRACTOR_LIST_READ_REQUEST";
const CONTRACTOR_LIST_READ_SUCCESS = "CONTRACTOR_LIST_READ_SUCCESS";
const CONTRACTOR_LIST_READ_ERROR = "CONTRACTOR_LIST_READ_ERROR";

const CONTRACTOR_ORDER_STATUS_DICT_REQUEST = "CONTRACTOR_ORDER_STATUS_DICT_REQUEST";
const CONTRACTOR_ORDER_STATUS_DICT_SUCCESS = "CONTRACTOR_ORDER_STATUS_DICT_SUCCESS";
const CONTRACTOR_ORDER_STATUS_DICT_ERROR = "CONTRACTOR_ORDER_STATUS_DICT_ERROR";

const CONTRACTOR_ADD_FAVORITE_REQUEST = "CONTRACTOR_ADD_FAVORITE_REQUEST";
const CONTRACTOR_ADD_FAVORITE_SUCCESS = "CONTRACTOR_ADD_FAVORITE_SUCCESS";
const CONTRACTOR_ADD_FAVORITE_ERROR = "CONTRACTOR_ADD_FAVORITE_ERROR";

const CONTRACTOR_ADD_TO_BLACKLIST_REQUEST = "CONTRACTOR_ADD_TO_BLACKLIST_REQUEST";
const CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS = "CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS";
const CONTRACTOR_ADD_TO_BLACKLIST_ERROR = "CONTRACTOR_ADD_TO_BLACKLIST_ERROR";

const CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST = "CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST";
const CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS = "CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS";
const CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR = "CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR";

const CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST = "CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST";
const CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS = "CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS";
const CONTRACTOR_DELETE_FROM_FAVORITE_ERROR = "CONTRACTOR_DELETE_FROM_FAVORITE_ERROR";

const CONTRACTOR_RICH_LIST_READ_REQUEST = "CONTRACTOR_RICH_LIST_READ_REQUEST";
const CONTRACTOR_RICH_LIST_READ_SUCCESS = "CONTRACTOR_RICH_LIST_READ_SUCCESS";
const CONTRACTOR_RICH_LIST_READ_ERROR = "CONTRACTOR_RICH_LIST_READ_ERROR";

const CONTRACTOR_SHORT_LIST_READ_REQUEST = "CONTRACTOR_SHORT_LIST_READ_REQUEST";
const CONTRACTOR_SHORT_LIST_READ_SUCCESS = "CONTRACTOR_SHORT_LIST_READ_SUCCESS";
const CONTRACTOR_SHORT_LIST_READ_ERROR = "CONTRACTOR_SHORT_LIST_READ_ERROR";

const CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST = "CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST";
const CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS = "CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS";
const CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR = "CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR";

const CONTRACTOR_CLEAR_STORE = "CONTRACTOR_CLEAR_STORE";

const CONTRACTOR_UPDATE_FIELD_STORE = "CONTRACTOR_UPDATE_FIELD_STORE";

const CONTRACTOR_CITIZENSHIP_REQUEST = "CONTRACTOR_CITIZENSHIP_REQUEST";
const CONTRACTOR_CITIZENSHIP_SUCCESS = "CONTRACTOR_CITIZENSHIP_SUCCESS";
const CONTRACTOR_CITIZENSHIP_ERROR = "CONTRACTOR_CITIZENSHIP_ERROR";

const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST";
const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS";
const CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR = "CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR";

const CONTRACTOR_VHI_POLICY_FORMAT_REQUEST = "CONTRACTOR_VHI_POLICY_FORMAT_REQUEST";
const CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS = "CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS";
const CONTRACTOR_VHI_POLICY_FORMAT_ERROR = "CONTRACTOR_VHI_POLICY_FORMAT_ERROR";

const CONTRACTOR_VHI_POLICY_TYPE_REQUEST = "CONTRACTOR_VHI_POLICY_TYPE_REQUEST";
const CONTRACTOR_VHI_POLICY_TYPE_SUCCESS = "CONTRACTOR_VHI_POLICY_TYPE_SUCCESS";
const CONTRACTOR_VHI_POLICY_TYPE_ERROR = "CONTRACTOR_VHI_POLICY_TYPE_ERROR";

const CONTRACTOR_WORKREGION_REQUEST = "CONTRACTOR_WORKREGION_REQUEST";
const CONTRACTOR_WORKREGION_SUCCESS = "CONTRACTOR_WORKREGION_SUCCESS";
const CONTRACTOR_WORKREGION_ERROR = "CONTRACTOR_WORKREGION_ERROR";

const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST";
const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS";
const CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR = "CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR";

const CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST = "CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST";
const CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS = "CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS";
const CONTRACTOR_SUPPORT_CASE_STATUS_ERROR = "CONTRACTOR_SUPPORT_CASE_STATUS_ERROR";

const CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST = "CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST";
const CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS = "CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS";
const CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR = "CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR";

const GET_CONTRACTOR_BY_PHONE_REQUEST = "GET_CONTRACTOR_BY_PHONE_REQUEST";
const GET_CONTRACTOR_BY_PHONE_SUCCESS = "GET_CONTRACTOR_BY_PHONE_SUCCESS";
const GET_CONTRACTOR_BY_PHONE_ERROR = "GET_CONTRACTOR_BY_PHONE_ERROR";

const GET_CONTRACTOR_ORDER_STATISTICS = "GET_CONTRACTOR_ORDER_STATISTICS";
const SET_CONTRACTOR_ORDER_STATISTICS = "SET_CONTRACTOR_ORDER_STATISTICS";
const GET_CONTRACTOR_ORDER_STATISTICS_ERROR = "GET_CONTRACTOR_ORDER_STATISTICS_ERROR";

const GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST";
const GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS";
const GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR = "GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR";

const GET_CONTRACTOR_COUNT_REQUEST = "GET_CONTRACTOR_COUNT_REQUEST";
const GET_CONTRACTOR_COUNT_SUCCESS = "GET_CONTRACTOR_COUNT_SUCCESS";
const GET_CONTRACTOR_COUNT_ERROR = "GET_CONTRACTOR_COUNT_ERROR";

const CONTRACTOR_ARCHIVE_PHONES_REQUEST = "CONTRACTOR_ARCHIVE_PHONES_REQUEST";
const CONTRACTOR_ARCHIVE_PHONES_SUCCESS = "CONTRACTOR_ARCHIVE_PHONES_SUCCESS";
const CONTRACTOR_ARCHIVE_PHONES_ERROR = "CONTRACTOR_ARCHIVE_PHONES_ERROR";

const CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST";
const CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS";
const CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR";

const CONTRACTOR_STATUS_DICT_REQUEST = "CONTRACTOR_STATUS_DICT_REQUEST";
const CONTRACTOR_STATUS_DICT_SUCCESS = "CONTRACTOR_STATUS_DICT_SUCCESS";
const CONTRACTOR_STATUS_DICT_ERROR = "CONTRACTOR_STATUS_DICT_ERROR";

const GET_CONTRACTORS_BY_ORDER_REQUEST = "GET_CONTRACTORS_BY_ORDER_REQUEST";
const GET_CONTRACTORS_BY_ORDER_SUCCESS = "GET_CONTRACTORS_BY_ORDER_SUCCESS";
const GET_CONTRACTORS_BY_ORDER_ERROR = "GET_CONTRACTORS_BY_ORDER_ERROR";

const GET_ALL_APPLICATIONS_REQUEST = "GET_ALL_APPLICATIONS_REQUEST";
const GET_ALL_APPLICATIONS_SUCCESS = "GET_ALL_APPLICATIONS_SUCCESS";
const GET_ALL_APPLICATIONS_ERROR = "GET_ALL_APPLICATIONS_ERROR";

const UPDATE_CONTRACTOR_RETAIL_REQUEST = "UPDATE_CONTRACTOR_RETAIL_REQUEST";
const UPDATE_CONTRACTOR_RETAIL_SUCCESS = "UPDATE_CONTRACTOR_RETAIL_SUCCESS";
const UPDATE_CONTRACTOR_RETAIL_ERROR = "UPDATE_CONTRACTOR_RETAIL_ERROR";

const GET_PHONES_BY_XLSX_FILE_REQUEST = "GET_PHONES_BY_XLSX_FILE_REQUEST";
const GET_PHONES_BY_XLSX_FILE_SUCCESS = "GET_PHONES_BY_XLSX_FILE_SUCCESS";
const GET_PHONES_BY_XLSX_FILE_ERROR = "GET_PHONES_BY_XLSX_FILE_ERROR";

const GET_BLOCKED_CONTRACTORS_REQUEST = "GET_BLOCKED_CONTRACTORS_REQUEST";
const GET_BLOCKED_CONTRACTORS_SUCCESS = "GET_BLOCKED_CONTRACTORS_SUCCESS";
const GET_BLOCKED_CONTRACTORS_ERROR = "GET_BLOCKED_CONTRACTORS_ERROR";

const POST_PENDING_PERSONAL_DATA_REQUEST = "POST_PENDING_PERSONAL_DATA_REQUEST";
const POST_PENDING_PERSONAL_DATA_SUCCESS = "POST_PENDING_PERSONAL_DATA_SUCCESS";
const POST_PENDING_PERSONAL_DATA_ERROR = "POST_PENDING_PERSONAL_DATA_ERROR";

const CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST = "CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST";
const CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS = "CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS";
const CONTRACTOR_EDM_PASSPORT_TYPE_ERROR = "CONTRACTOR_EDM_PASSPORT_TYPE_ERROR";

const CONTRACTOR_REGISTRATION_STATE_REQUEST = "CONTRACTOR_REGISTRATION_STATE_REQUEST";
const CONTRACTOR_REGISTRATION_STATE_SUCCESS = "CONTRACTOR_REGISTRATION_STATE_SUCCESS";
const CONTRACTOR_REGISTRATION_STATE_ERROR = "CONTRACTOR_REGISTRATION_STATE_ERROR";

const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST";
const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS";
const GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR = "GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR";

const GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST = "GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST";
const GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS = "GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS";

const CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST = "CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST";

const UPDATE_LEADS_COMPLETED_REQUEST = "UPDATE_LEADS_COMPLETED_REQUEST";
const UPDATE_LEADS_COMPLETED_SUCCESS = "UPDATE_LEADS_COMPLETED_SUCCESS";
const UPDATE_LEADS_COMPLETED_ERROR = "UPDATE_LEADS_COMPLETED_ERROR";

const UPDATE_PREVIOUS_PASSPORT_REQUEST = "UPDATE_PREVIOUS_PASSPORT_REQUEST";
const UPDATE_PREVIOUS_PASSPORT_SUCCESS = "UPDATE_PREVIOUS_PASSPORT_SUCCESS";
const UPDATE_PREVIOUS_PASSPORT_ERROR = "UPDATE_PREVIOUS_PASSPORT_ERROR";

const GET_ACTIVE_ORDER_NUMBERS_REQUEST = "GET_ACTIVE_ORDER_NUMBERS_REQUEST";
const GET_ACTIVE_ORDER_NUMBERS_SUCCESS = "GET_ACTIVE_ORDER_NUMBERS_SUCCESS";
const GET_ACTIVE_ORDER_NUMBERS_ERROR = "GET_ACTIVE_ORDER_NUMBERS_ERROR";

const GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST";
const GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS";
const GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR = "GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR";

const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST";
const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS";
const CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR = "CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR";

const CONTRACTOR_INN_VALIDATE_REQUEST = "CONTRACTOR_INN_VALIDATE_REQUEST";
const CONTRACTOR_INN_VALIDATE_SUCCESS = "CONTRACTOR_INN_VALIDATE_SUCCESS";
const CONTRACTOR_INN_VALIDATE_ERROR = "CONTRACTOR_INN_VALIDATE_ERROR";

const GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST = "GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST";
const GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS = "GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS";
const GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR = "GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR";

const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST";
const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS";
const CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR = "CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR";

// метод из хотфикса, добавил бфф в старую структуру
const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST";
const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS";
const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR";

const GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST = "GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    shortModelList: [],
    totalCount: 0,
    card: {
        departureAddressesInfo: {},
    },
    pageData: {},
    error: null,
    progress: false,
    progressAdd: false,
    listByIdsParams: {},
    citizenship: {},
    citizenshipSmzOnlyDict: {},
    contractorMigrationStatusDict: {},
    migrationStatusDict: {},
    vhiPolicyFormatDict: {},
    vhiPolicyTypeDict: {},
    workRegion: {},
    supports: [],
    contractorList: [],
    pageDataSupport: {},
    totalCountSupport: 0,
    cardSupport: {},
    callTypeStatus: {},
    caseStatus: {},
    progressSupportUpdate: false,
    contractorOrderWorkReportInfo: {},
    progressCard: false,
    contractorsNotForeman: [],
    progressNotForeman: false,
    pageDataNotForeman: {},
    totalCountNotForeman: 0,
    actionProgress: false,
    orderStatistics: {
        categoryStatistics: [],
        isLoaded: false,
    },
    archivePhones: {
        isLoaded: false,
        allTotalCount: null,
        blackListCount: null,
        favoriteCount: null,
        phoneModels: [],
        totalCount: 0,
    },
    contractorsByOrder: [],
    pageDataContractorsByOrder: {},
    totalCountContractorsByOrder: 0,
    applicationCountMap: {},
    contractorCount: 0,
    progressApplications: false,
    note: {
        value: "",
        isNoteLoaded: false,
    },
    blockedHistory: {
        list: [],
        isBlockedHistoryLoaded: false,
    },
    allApplications: [],
    statusDict: {},
    pageDataAllApplications: {},
    allApplicationCountMap: {},
    totalCountAllApplications: 0,
    progressAllApplications: false,
    timeSheetContractors: [],
    contractorOrderStatusDict: {},
    blockedContractors: {
        isBlockedContractorsLoaded: false,
        contractors: [],
    },
    isContractorLoaded: false,
    contractorsRetail: [],
    totalCountRetail: 0,
    pageDataRetail: {},
    progressRetail: false,
    contractorDataBankState: {
        isBankStatesLoaded: false,
        bankStates: {},
    },
    contractorEdmPassportType: {},
    registrationStates: {},
    innStatus: {
        status: null,
        isLoaded: false,
    },
    secondTryingIndicator: false,
    passportStatus: {
        status: null,
        isLoaded: false,
    },
    progressComparison: false,
    progressEdmConfirm: false,
    progressPendingDataConfirm: false,
    mapContractorsList: [],
    mapContractorsTotalCount: 0,
    mapContractorsPageData: {},
    mapContractorsProgress: false,
    emptyRecordProgress: false,
    importContractorsProgress: false,
    updateLeadsCompletedProgress: false,
    recommendationModelType: "",
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACTOR_GET_OPTIMIZED_HIRING_RICH_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case GET_CONTRACTOR_COUNT_SUCCESS:
            return {
                ...state,
                contractorCount: payload,
            };
        case CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS:
            return {
                ...state,
                contractorList: payload,
            };
        case GET_PHONES_BY_XLSX_FILE_REQUEST:
        case GET_CONTRACTOR_BY_PHONE_REQUEST:
        case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST:
        case UPDATE_CONTRACTOR_RETAIL_REQUEST:
        case CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST:
        case CONTRACTOR_ADD_TO_BLACKLIST_REQUEST:
        case CONTRACTOR_ADD_FAVORITE_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST:
        case CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST:
        case CONTRACTOR_RICH_LIST_READ_REQUEST:
        case CONTRACTOR_SHORT_LIST_READ_REQUEST:
        case CONTRACTOR_LIST_READ_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST:
            return {
                ...state,
                progressNotForeman: true,
                pageDataNotForeman: payload,
            };
        case CONTRACTOR_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
                progress: false,
            };
        case CONTRACTOR_UPDATE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CONTRACTOR_READ_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case POST_PENDING_PERSONAL_DATA_REQUEST:
            return {
                ...state,
                progressPendingDataConfirm: true,
            };
        case CONTRACTOR_INN_VALIDATE_REQUEST:
        case GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS:
            return {
                ...state,
                progressNotForeman: false,
                contractorsNotForeman: payload.contractors,
                totalCountNotForeman: payload.totalCount,
            };
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS:
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR:
        case CONTRACTOR_UPDATE_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS:
            return {
                ...state,
                actionProgress: false,
                contractorOrderWorkReportInfo: payload,
            };
        case GET_PHONES_BY_XLSX_FILE_SUCCESS:
        case GET_CONTRACTOR_BY_PHONE_SUCCESS:
        case UPDATE_CONTRACTOR_RETAIL_SUCCESS:
        case CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS:
        case CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS:
        case CONTRACTOR_ADD_FAVORITE_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case CONTRACTOR_ORDER_STATUS_DICT_SUCCESS:
            return {
                ...state,
                contractorOrderStatusDict: payload,
            };
        case CONTRACTOR_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
            };
        case CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS:
            return {
                ...state,
                vhiPolicyFormatDict: payload,
            };
        case CONTRACTOR_VHI_POLICY_TYPE_SUCCESS:
            return {
                ...state,
                vhiPolicyTypeDict: payload,
            };
        case CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS:
            return {
                ...state,
                citizenshipSmzOnlyDict: payload,
            };
        case CONTRACTOR_CITIZENSHIP_SUCCESS:
            return {
                ...state,
                citizenship: payload,
            };
        case CONTRACTOR_WORKREGION_SUCCESS:
            return {
                ...state,
                workRegion: payload,
            };
        case CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS:
            return {
                ...state,
                caseStatus: payload,
            };
        case CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS:
            return {
                ...state,
                callTypeStatus: payload,
            };
        case CONTRACTOR_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
                isContractorLoaded: true,
            };
        case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS:
        case CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS:
        case CONTRACTOR_RICH_LIST_READ_SUCCESS:
        case CONTRACTOR_LIST_READ_SUCCESS:
            const {
                contractors = [],
                totalCount = 0,
                secondTry = false,
                recommendationModelType,
            } = payload;

            return {
                ...state,
                progress: false,
                list: contractors,
                totalCount,
                secondTryingIndicator: secondTry,
                recommendationModelType,
            };
        case CONTRACTOR_SHORT_LIST_READ_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                shortModelList: payload.results,
                progress: false,
            };
        case POST_PENDING_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                progressPendingDataConfirm: false,
            };
        case GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS: {
            const {
                result,
                nameField = "migrationStatusDict",
            } = payload;

            return {
                ...state,
                [nameField]: result,
            };
        }
        case CONTRACTOR_INN_VALIDATE_SUCCESS:
        case GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progressNotForeman: false,
            };
        case CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR:
        case CONTRACTOR_LIST_READ_ERROR:
        case CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR:
        case CONTRACTOR_RICH_LIST_READ_ERROR:
        case CONTRACTOR_SHORT_LIST_READ_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case CONTRACTOR_UPDATE_ERROR:
        case CONTRACTOR_READ_ERROR:
        case GET_PHONES_BY_XLSX_FILE_ERROR:
        case GET_CONTRACTOR_BY_PHONE_ERROR:
        case GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR:
        case UPDATE_CONTRACTOR_RETAIL_ERROR:
        case CONTRACTOR_ADD_TO_BLACKLIST_ERROR:
        case CONTRACTOR_DELETE_FROM_FAVORITE_ERROR:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR:
        case CONTRACTOR_ADD_FAVORITE_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR:
        case GET_CONTRACTOR_COUNT_ERROR:
        case CONTRACTOR_WORKREGION_ERROR:
        case CONTRACTOR_CITIZENSHIP_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SET_CONTRACTOR_ORDER_STATISTICS:
            return {
                ...state,
                orderStatistics: {
                    ...payload,
                    isLoaded: true,
                },
            };
        case CONTRACTOR_REGISTRATION_STATE_SUCCESS:
            return {
                ...state,
                registrationStates: payload,
            };
        case CONTRACTOR_ARCHIVE_PHONES_REQUEST:
            return {
                ...state,
                archivePhones: {
                    isLoaded: false,
                },
            };
        case GET_ALL_APPLICATIONS_REQUEST:
            return {
                ...state,
                progressAllApplications: true,
                pageDataAllApplications: payload,
            };
        case GET_CONTRACTORS_BY_ORDER_REQUEST:
            return {
                ...state,
                progressApplications: true,
                pageDataContractorsByOrder: payload,
            };
        case GET_ALL_APPLICATIONS_SUCCESS: {
            const {
                contractors: allApplications,
                totalCount: totalCountAllApplications,
                applicationCountMap: allApplicationCountMap,
            } = payload;

            return {
                ...state,
                allApplications,
                totalCountAllApplications,
                allApplicationCountMap,
                progressAllApplications: false,
            };
        }
        case GET_CONTRACTORS_BY_ORDER_SUCCESS:
            const {
                contractors: contractorsByOrder,
                totalCount: totalCountContractorsByOrder,
                applicationCountMap,
                nameField = "contractorsByOrder",
            } = payload;

            return {
                ...state,
                [nameField]: contractorsByOrder,
                totalCountContractorsByOrder,
                applicationCountMap,
                progressApplications: false,
            };
        case GET_ALL_APPLICATIONS_ERROR:
            return {
                ...state,
                progressAllApplications: false,
            };
        case GET_CONTRACTORS_BY_ORDER_ERROR:
            return {
                ...state,
                progressApplications: false,
            };
        case CONTRACTOR_ORDER_STATUS_DICT_ERROR:
        case CONTRACTOR_STATUS_DICT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CONTRACTOR_ARCHIVE_PHONES_SUCCESS:
            return {
                ...state,
                archivePhones: {
                    ...payload,
                    isLoaded: true,
                },
            };
        case GET_BLOCKED_CONTRACTORS_SUCCESS:
            return {
                ...state,
                blockedContractors: {
                    contractors: payload,
                    isBlockedContractorsLoaded: true,
                },
            };
        case CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS:
            return {
                ...state,
                contractorEdmPassportType: payload,
            };
        case GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS:
            return {
                ...state,
                passportStatus: {
                    status: payload,
                    isLoaded: true,
                },
            };
        case POST_PENDING_PERSONAL_DATA_ERROR:
            return {
                ...state,
                progressPendingDataConfirm: false,
            };
        case UPDATE_LEADS_COMPLETED_REQUEST:
            return {
                ...state,
                updateLeadsCompletedProgress: true,
            };
        case UPDATE_LEADS_COMPLETED_SUCCESS:
            return {
                ...state,
                updateLeadsCompletedProgress: false,
            };
        case UPDATE_LEADS_COMPLETED_ERROR:
            return {
                ...state,
                updateLeadsCompletedProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getContractorOrderWorkReportInfo(payload) {
    return {
        type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST,
        payload,
    };
}

export function getContractorCount(payload) {
    return {
        type: GET_CONTRACTOR_COUNT_REQUEST,
        payload,
    };
}

export function getContractorById(payload) {
    return {
        type: CONTRACTOR_READ_REQUEST,
        payload,
    };
}

export function getContractorOrderStatusDict(payload) {
    return {
        type: CONTRACTOR_ORDER_STATUS_DICT_REQUEST,
        payload,
    };
}

export function updateLeadsCompleted(payload) {
    return {
        type: UPDATE_LEADS_COMPLETED_REQUEST,
        payload,
    };
}

export function getContractorStatusDict() {
    return {
        type: CONTRACTOR_STATUS_DICT_REQUEST,
    };
}

export function addFavoriteContractors(payload) {
    return {
        type: CONTRACTOR_ADD_FAVORITE_REQUEST,
        payload,
    };
}

export function addContractorToBlackList(payload) {
    return {
        type: CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
        payload,
    };
}

export function deleteContractorFromBlackList(payload) {
    return {
        type: CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
        payload,
    };
}

export function deleteFavoriteContractors(payload) {
    return {
        type: CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
        payload,
    };
}

export function getVhiPolicyFormats() {
    return {
        type: CONTRACTOR_VHI_POLICY_FORMAT_REQUEST,
    };
}

export function getVhiPolicyTypes() {
    return {
        type: CONTRACTOR_VHI_POLICY_TYPE_REQUEST,
    };
}

export function getCitizenships() {
    return {
        type: CONTRACTOR_CITIZENSHIP_REQUEST,
    };
}

export function getCitizenshipsSmzOnly() {
    return {
        type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST,
    };
}

export function getWorkRegions() {
    return {
        type: CONTRACTOR_WORKREGION_REQUEST,
    };
}

export function getCaseStatus() {
    return {
        type: CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST,
    };
}

export function getCallTypeStatus() {
    return {
        type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST,
    };
}

export function clearContractorStore() {
    return {
        type: CONTRACTOR_CLEAR_STORE,
    };
}

export function getContractorByIds(payload) {
    return {
        type: CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST,
        payload,
    };
}

export function updateFieldContractorStore(payload) {
    return {
        type: CONTRACTOR_UPDATE_FIELD_STORE,
        payload,
    };
}

export function updateContractor(payload) {
    return {
        type: CONTRACTOR_UPDATE_REQUEST,
        payload,
    };
}

export function getContractorList(payload) {
    return {
        type: CONTRACTOR_LIST_READ_REQUEST,
        payload,
    };
}

export function getContractorRichList(payload) {
    return {
        type: CONTRACTOR_RICH_LIST_READ_REQUEST,
        payload,
    };
}

export function getContractorShortList(payload) {
    return {
        type: CONTRACTOR_SHORT_LIST_READ_REQUEST,
        payload,
    };
}

export function getHiringContractorOptimizedList(payload) {
    return {
        type: CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST,
        payload,
    };
}

export function getContractorByPhone(payload) {
    return {
        type: GET_CONTRACTOR_BY_PHONE_REQUEST,
        payload,
    };
}

export function getNotForemanContractorsPage(payload) {
    return {
        type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST,
        payload,
    };
}

export function getArchivePhonesByContractorId(payload) {
    return {
        type: CONTRACTOR_ARCHIVE_PHONES_REQUEST,
        payload,
    };
}

export const getOrderStatistics = (payload) => ({
    type: GET_CONTRACTOR_ORDER_STATISTICS,
    payload,
});

export function getContractorsByOrder(payload) {
    return {
        type: GET_CONTRACTORS_BY_ORDER_REQUEST,
        payload,
    };
}

export function getAllApplications(payload) {
    return {
        type: GET_ALL_APPLICATIONS_REQUEST,
        payload,
    };
}

export function getPhonesByXlsxFile(payload) {
    return {
        type: GET_PHONES_BY_XLSX_FILE_REQUEST,
        payload,
    };
}

export function getBlockedContractors(payload) {
    return {
        type: GET_BLOCKED_CONTRACTORS_REQUEST,
        payload,
    };
}

export function postPendingPersonalData(payload) {
    return {
        type: POST_PENDING_PERSONAL_DATA_REQUEST,
        payload,
    };
}

export function updateContractorRetail(payload) {
    return {
        type: UPDATE_CONTRACTOR_RETAIL_REQUEST,
        payload,
    };
}

export function getContractorEdmPassportType(payload) {
    return {
        type: CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST,
        payload,
    };
}

export function getContractorRegistrationStates() {
    return {
        type: CONTRACTOR_REGISTRATION_STATE_REQUEST,
    };
}

export function getPassportStatus(payload) {
    return {
        type: GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST,
        payload,
    };
}

export function getCanViewEventLogClientAdmin(payload) {
    return {
        type: GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST,
        payload,
    };
}

export function updatePreviousPassport(payload) {
    return {
        type: UPDATE_PREVIOUS_PASSPORT_REQUEST,
        payload,
    };
}

export function getActiveOrderNumbers(payload) {
    return {
        type: GET_ACTIVE_ORDER_NUMBERS_REQUEST,
        payload,
    };
}

export function getMigrationStatusDict(payload) {
    return {
        type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST,
        payload,
    };
}

export function getPendingAdditionalDocumentsRichPage(payload) {
    return {
        type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST,
        payload,
    };
}

export function updateContractorRegistrationStatus(payload) {
    return {
        type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
        payload,
    };
}

export function checkInnBeforeAddition(payload) {
    return {
        type: CONTRACTOR_INN_VALIDATE_REQUEST,
        payload,
    };
}

export function getContractorFilesRequiredList(payload) {
    return {
        type: GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST,
        payload,
    };
}

export function contractorPersonalDataPendingDraftComplete(payload) {
    return {
        type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST,
        payload,
    };
}

// метод из хотфикса, добавил бфф в старую структуру
export const checkForbiddenContractor = (payload) => {
    return {
        type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST,
        payload,
    };
};

//*  SELECTORS  *//

export const contractorSelector = state => state.contractor;
export const contractorListSelector = createSelector(contractorSelector, ({list}) => {
    return list.map(item => {
        const {
            lastName,
            firstName,
            patronymic,
            phone,
            fullName,
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            isSelected: false,
            fullName: lastName ? `${lastName} ${firstName} ${patronymic || ""}` : fullName,
            phone: phoneFormat(phone),
            workAddressInfoArr: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});

export const contractorsSecondTryingIndicatorSelector = createSelector(contractorSelector, ({secondTryingIndicator}) => secondTryingIndicator);

// Заявки
export const contractorStatusOptionsSelector = createSelector(contractorSelector, ({statusDict}) => {
    const order = [
        "BASIC_REGISTRATION",
        "ADMINISTRATOR_CHECK",
        "ADMINISTRATOR_CHECK_CHANGES",
        "ADMINISTRATOR_CHECK_PASSED",
    ];

    return dictionaryToOptions(statusDict).sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
});

export const contractorsOrderStatusOptionsSelector = createSelector(contractorSelector, ({contractorOrderStatusDict}) => dictionaryToOptions(contractorOrderStatusDict));
export const contractorProgressApplicationsSelector = createSelector(contractorSelector, ({progressApplications}) => progressApplications);
export const contractorCountOrderTabSelector = createSelector(contractorSelector, ({applicationCountMap}) => getApplicationCount(applicationCountMap));
export const contractorStatusDictSelector = createSelector(contractorSelector, ({statusDict}) => statusDict);
export const contractorsTimeSheetSelector = createSelector(contractorSelector, ({timeSheetContractors, contractorsByOrder}) =>
    timeSheetContractors.length === 0 ? contractorsByOrder : timeSheetContractors,
);
export const contractorsApplicationsOptionsSelector = createSelector(contractorSelector, ({timeSheetContractors, contractorsByOrder}) => {
    const dataSource = timeSheetContractors.length === 0 ? contractorsByOrder : timeSheetContractors;

    return dataSource.map(item => ({
        key: item.contractorId,
        value: item.contractorId,
        text: getFullName(`${item.lastName} ${item.firstName} ${item.patronymic || ""}`),
    }));
});
export const progressPendingDataConfirmProgressSelector = createSelector(contractorSelector, ({progressPendingDataConfirm}) => progressPendingDataConfirm);

// Все заявки
export const contractorsAllApplicationsSelector = createSelector(contractorSelector, ({allApplications}) => {
    return allApplications.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});
export const contractorsAllApplicationsTotalPagesSelector = createSelector(contractorSelector, ({totalCountAllApplications, pageDataAllApplications: {pageSize}}) => getTotalPages(totalCountAllApplications, pageSize));
export const contractorsAllApplicationCountMap = createSelector(contractorSelector, ({allApplicationCountMap}) => allApplicationCountMap);
export const contractorsAllApplicationsProgressSelector = createSelector(contractorSelector, ({progressAllApplications}) => progressAllApplications);
export const contractorsAllApplicationsTotalCountSelector = createSelector(contractorSelector, ({totalCountAllApplications}) => totalCountAllApplications);

export const allApplicationsCountSelector = createSelector(contractorSelector, ({allApplicationCountMap}) => getApplicationCount(allApplicationCountMap));

export const contractorSearchListSelector = createSelector(contractorSelector, ({list}) => list);

export const contractorNotForemanListSelector = createSelector(contractorSelector, ({contractorsNotForeman}) => contractorsNotForeman);
export const contractorProgressNotForemanListSelector = createSelector(contractorSelector, ({progressNotForeman}) => progressNotForeman);
export const contractorTotalPagesNotForemanListSelector = createSelector(contractorSelector, ({totalCountNotForeman, pageDataNotForeman: {pageSize}}) => getTotalPages(totalCountNotForeman, pageSize));
export const contractorCardSelector = createSelector(contractorSelector, ({card}) => card);
export const contractorListWithFullNameSelector = createSelector(contractorSelector, ({contractorList}) => {
    return contractorList.map(item => ({
        ...item,
        fullName: getFullName(item.lastName, item.firstName, item.patronymic),
    }));
});
// fullName условие - первичная или не первичная регистрация исполнителя
export const contractorListHistoryPaySelector = createSelector(contractorSelector, ({contractorList}) => {
    return contractorList.map(value => ({...value, clientUserId: value.contractorId, role: CONTRACTOR}));
});
export const contractorTotalPagesSelector = createSelector(contractorSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const contractorTotalCountSelector = createSelector(contractorSelector, ({totalCount}) => totalCount);
export const contractorActionProgressSelector = createSelector(contractorSelector, ({actionProgress}) => actionProgress);
export const contractorPageDataSelector = createSelector(contractorSelector, ({pageData}) => pageData);
export const contractorProgressSelector = createSelector(contractorSelector, state => state.progress);

export const getCitizenshipSelector = createSelector(contractorSelector, ({citizenship}) => citizenship);
export const getCitizenshipWithFlagsOptionsSelector = createSelector(contractorSelector, ({citizenship}) => citizenshipToOptions(citizenship));
export const getCitizenshipOptionsSelector = createSelector(contractorSelector, ({citizenship}) => dictionaryToOptions(citizenship));
export const getCitizenshipSmzOnlyOptionsSelector = createSelector(contractorSelector, ({citizenshipSmzOnlyDict}) => dictionaryToOptions(citizenshipSmzOnlyDict));
export const getWorkRegionsOptionsSelector = createSelector(contractorSelector, ({workRegion}) => dictionaryToOptions(workRegion));
export const getWorkRegionsWithRemoteWorkOptionsSelector = createSelector(contractorSelector, ({workRegion}) => {
    return [REMOTE_WORK_OPTION].concat(dictionaryToOptions(workRegion));
});
export const getCallTypeStatusDictSelector = createSelector(contractorSelector, ({callTypeStatus}) => callTypeStatus);
export const getCaseStatusDictSelector = createSelector(contractorSelector, ({caseStatus}) => caseStatus);
export const getCaseStatusOptionsSelector = createSelector(contractorSelector, ({caseStatus}) => dictionaryToOptions(caseStatus));
export const orderStatisticsSelector = createSelector(contractorSelector, ({orderStatistics}) => orderStatistics);
export const contractorCountSelector = createSelector(contractorSelector, ({contractorCount}) => contractorCount);
export const getBlockedContractorsIdsSelector = createSelector(
    contractorSelector,
    ({blockedContractors}) => blockedContractors.contractors
        .filter(({blocked}) => blocked)
        .map(({contractorId}) => contractorId));
export const contractorEdmStatusesSelector = createSelector(contractorSelector, ({statusDict}) => statusDict);
export const contractorRegistrationStatesOptionsSelector = createSelector(contractorSelector, ({registrationStates}) => dictionaryToOptions(registrationStates));
export const contractorOrderWorkReportInfoSelector = createSelector(contractorSelector, ({contractorOrderWorkReportInfo}) => contractorOrderWorkReportInfo);

export const importContractorsProgressSelector = createSelector(contractorSelector, ({importContractorsProgress}) => importContractorsProgress);
export const updateLeadsCompletedProgressSelector = createSelector(contractorSelector, ({updateLeadsCompletedProgress}) => updateLeadsCompletedProgress);
export const contractorVhiPolicyFormatsDictSelector = createSelector(contractorSelector, ({vhiPolicyFormatDict}) => vhiPolicyFormatDict);
export const contractorVhiPolicyTypesDictSelector = createSelector(contractorSelector, ({vhiPolicyTypeDict}) => vhiPolicyTypeDict);
export const migrationStatusDictSelector = createSelector(contractorSelector, ({migrationStatusDict}) => migrationStatusDict);
export const contractorMigrationStatusDictSelector = createSelector(contractorSelector, ({contractorMigrationStatusDict}) => contractorMigrationStatusDict);
export const migrationStatusOptionsSelector = createSelector(contractorSelector, ({migrationStatusDict}) => dictionaryToOptions(migrationStatusDict));
export const recommendationModelTypeSelector = createSelector(contractorSelector, ({recommendationModelType}) => recommendationModelType);
export const contractorShortModelListSelector = createSelector(contractorSelector, ({shortModelList}) => shortModelList);

//*  SAGA  *//

export const contractorReadSaga = function* (action) {
    try {
        const {payload: contractorId} = action;
        const result = yield request.get(`${controller}/getById`, {params: {contractorId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_READ_ERROR,
            payload: error,
        });
    }
};

//GET
// /api/contractors/canViewEventLogClientAdmin
// Есть ли доступ к журналу событий исполнителя, который относится к замкнутом контуру заказчика
export const getCanViewEventLogClientAdminSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.get(`${controller}/canViewEventLogClientAdmin`, {params});

        yield onSuccess(result);
    } catch (error) {
        console.error(error);
        yield put({
            type: CONTRACTOR_READ_ERROR,
            payload: error,
        });
    }
};

export const updateContractorSaga = function* (action) {
    try {
        const {
            payload: {
                handleResponse = () => {
                },
                onSuccess = () => {
                },
                onError,
                isGetAfter = true,
                successText,
                ...data
            },
        } = action;

        const {
            contractorId,
            workAddressFiasId,
            workAddressInfo,
            departureAddressesFiasIds,
            departureAddressesInfo,
        } = data;

        const dataFormatted = {
            ...data,
            workAddressFiasId: workAddressFiasId ? workAddressFiasId : (workAddressInfo ? getFiasIdByAddressType(workAddressInfo) : null),
            departureAddressesFiasIds: departureAddressesFiasIds ? departureAddressesFiasIds : (departureAddressesInfo ? Object.keys(departureAddressesInfo) : null),
            departureAddressesInfo: null,
            workAddressInfo: null,
        };

        const {
            errorMessage,
            errorCode,
            invalidFieldNames,
        } = yield request.post(`${controller}/update`, dataFormatted);

        handleResponse(errorMessage, errorCode);

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError({errorCode, invalidFieldNames});
            }

            yield put({
                type: CONTRACTOR_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(successText || CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_SUCCESS,
        });

        yield onSuccess();

        if (isGetAfter) {
            yield put(getInfoContractorCard(contractorId));
        }
    } catch (error) {
        toastError(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_ERROR_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const getHiringContractorOptimizedListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getOptimizedHiringRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_HIRING_OPTI_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_HIRING_OPTI_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const contractorRichListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichPage`, payload);

        yield put({
            type: CONTRACTOR_RICH_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_RICH_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const getContractorShortListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getShortPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_SHORT_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const contractorListReadSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_LIST_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_LIST_READ_ERROR,
            payload: error,
        });
    }
};

export const deleteContractorFromBlackListSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.delete(`${controller}/blacklist/${contractorId ? "delete" : "deleteAll"}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Удаление из чёрного списка выполнено успешно");
        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

export const deleteFavoriteContractorsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.delete(`${controller}/favourite/${contractorId ? "delete" : "deleteAll"}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Удаление из избранных выполнено успешно");
        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
            payload: error,
        });
    }
};

export const addContractorToBlackListSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.post(`${controller}/blacklist/${contractorId ? "add" : "addAll"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в чёрный список выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

export const addFavoriteContractorsSaga = function* (action) {
    try {
        const {
            payload: {
                data,
                onSuccess,
            },
        } = action;

        const {contractorId} = data;

        const result = yield request.post(`${controller}/favourite/${contractorId ? "add" : "addAll"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (action.payload.onError) {
                action.payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в избранные выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (action.payload.onError) {
            action.payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_ERROR,
            payload: error,
        });
    }
};

export const contractorListSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getListByIds`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/citizenship
export const getContractorCitizenshipReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/v2/citizenship`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CITIZENSHIP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_CITIZENSHIP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_CITIZENSHIP_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/citizenship
export const getCitizenshipsSmzOnlySaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/v2/citizenship/smz-only`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_CITIZENSHIP_SMZ_ONLY_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/vhi-policy-format
// Список форм страхового полиса
export const getContractorVhiPolicyFormatsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/vhi-policy-format`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_VHI_POLICY_FORMAT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_VHI_POLICY_FORMAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_VHI_POLICY_FORMAT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/vhi-policy-type
// Список типов страхового полиса
export const getContractorVhiPolicyTypesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/vhi-policy-type`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_VHI_POLICY_TYPE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_VHI_POLICY_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_VHI_POLICY_TYPE_ERROR,
            payload: error,
        });
    }
};

export const getContractorWorkRegionsReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/workRegion`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_WORKREGION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_WORKREGION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_WORKREGION_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/contractorSupportCaseStatus
export const getCaseStatusReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorSupportCaseStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_CASE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_CASE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_CASE_STATUS_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/dicts/contractorSupportCallTypeStatus
export const getSupportCallTypeStatusSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorSupportCallTypeStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getByPhone
export const getContractorByPhoneSaga = function* (action) {
    try {
        const {
            payload: {
                data: params,
                handleResponse,
            },
        } = action;

        const result = yield request.get(`${controller}/getByPhone`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_BY_PHONE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_CONTRACTOR_BY_PHONE_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CONTRACTOR_BY_PHONE_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/getNotForemanContractorsPage
export const getNotForemanContractorsPageSaga = function* (action) {
    try {
        const {
            payload,
        } = action;

        const result = yield request.post(`${controller}/getNotForemanContractorsPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CONTRACTORS_NOT_FOREMAN_PAGE_ERROR,
            payload: error,
        });
    }
};

const getContractorCountSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/count`, payload);

        yield put({
            type: GET_CONTRACTOR_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_COUNT_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getOrderStatistics
export const getOrdersStatisticsByContractorIdSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getOrderStatistics?contractorId=${payload}`);

        yield put({type: SET_CONTRACTOR_ORDER_STATISTICS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_ORDER_STATISTICS_ERROR, payload: error});
    }
};

// POST /api/contractors/phones/getArchivePhones
export const getArchivePhonesSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/phones/getArchivePhones`, payload);

        yield put({type: CONTRACTOR_ARCHIVE_PHONES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACTOR_ARCHIVE_PHONES_ERROR, payload: error});
    }
};

//POST /api/contractors/getApplicationsRichPage
export const getContractorsByOrderSaga = function* ({payload}) {
    try {
        const {nameField, ...reqData} = payload;
        const result = yield request.post(`${controller}/getApplicationsRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CONTRACTORS_BY_ORDER_SUCCESS,
            payload: {
                ...result,
                nameField,
            },
        });
    } catch (error) {
        yield put({type: GET_CONTRACTORS_BY_ORDER_ERROR, payload: error});
    }
};

//POST /bff/adm/clients/client-card/reports/analytics-order/responses/getAllApplicationsRichPage
//POST /bff/client-adm/reports/order-analytics/responses/get-all-applications-rich-page
export const getAllApplicationsSaga = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/reports/order-analytics/responses/get-all-applications-rich-page",
                adminRolesUrl: "/adm/clients/client-card/reports/analytics-order/responses/getAllApplicationsRichPage",
            },
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return {
                done: true,
            };
        }

        yield put({type: GET_ALL_APPLICATIONS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_ALL_APPLICATIONS_ERROR, payload: error});
    }
};

export const getContractorOrderStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorOrderStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_ORDER_STATUS_DICT_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: CONTRACTOR_ORDER_STATUS_DICT_ERROR, payload: error});
    }
};

export const getContractorStatusDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getRegistrationStatusDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: CONTRACTOR_STATUS_DICT_SUCCESS, payload: result});

    } catch (error) {
        yield put({type: CONTRACTOR_STATUS_DICT_ERROR, payload: error});
    }
};

//POST /api/contractors/getPhonesByXlsxFile
export const getPhonesByXlsxFileSaga = function* ({payload}) {
    const {
        clientId,
        formData,
        handleResponse,
    } = payload;

    try {
        const result = yield request.post(
            `${controller}/getPhonesByXlsxFileAndOldPhones`,
            formData,
            {
                ...getMultipartHeaders(),
                params: {
                    clientId,
                },
            },
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PHONES_BY_XLSX_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        handleResponse(result);

        yield put({
            type: GET_PHONES_BY_XLSX_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_PHONES_BY_XLSX_FILE_ERROR,
            payload: error,
        });
    }
};

//GET /api/contractors/getContractorBlockedList
export const getBlockedContractorsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getContractorBlockedListPost`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({type: GET_BLOCKED_CONTRACTORS_SUCCESS, payload: result.contractors});
    } catch (error) {
        yield put({type: GET_BLOCKED_CONTRACTORS_ERROR, payload: error});
    }
};

//POST /api/contractors/retail/update
export const updateContractorRetailSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const result = yield request.post(`${controller}/retail/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        getSuccess();
        yield put({type: UPDATE_CONTRACTOR_RETAIL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_RETAIL_ERROR, payload: error});
    }
};

// POST /api/contractors/personaldata/pending/decide
export const postPendingPersonalDataSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
            onError,
        } = payload;

        const result = yield request.post(`${controller}/personaldata/pending/decide`, data);

        const {errorMessage, warningMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: POST_PENDING_PERSONAL_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (warningMessage) {
            toastWarning(warningMessage);
        }

        onSuccess();

        yield put({type: POST_PENDING_PERSONAL_DATA_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: POST_PENDING_PERSONAL_DATA_ERROR, payload: error,
        });
    }
};

export const getContractorOrderWorkReportInfoSaga = function* (action) {
    try {
        const {
            payload: {
                onSuccess = () => {
                },
                data,
            },
        } = action;

        const result = yield request.get(`${controller}/getContractorOrderWorkReport`, {params: data});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_ERROR, payload: error.message});
    }
};

//GET /api/contractors/dicts/contractorEdmPassportType
export const getContractorEdmPassportTypeSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorEdmPassportType`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONTRACTOR_EDM_PASSPORT_TYPE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_EDM_PASSPORT_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: CONTRACTOR_EDM_PASSPORT_TYPE_ERROR, payload: error.message});
    }
};


export const getContractorRegistrationStatesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/getRegistrationStateFilterValuesDict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: CONTRACTOR_REGISTRATION_STATE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_REGISTRATION_STATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: CONTRACTOR_REGISTRATION_STATE_ERROR, payload: error.message});
    }
};

//POST /api/contractors/personaldata/pending/getPersonalDataBasedIdDocStatus
export const getPassportStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;

        const result = yield request.post(`${controller}/personaldata/pending/getPersonalDataBasedIdDocStatus`, {...data});

        onSuccess(result);

        yield put({
            type: GET_PERSONAL_DATA_PASSPORT_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
    }
};

//POST /api/contractors/updateLeadsCompleted //Обновление поля завершения обработки исполнителя
export const updateLeadsCompletedSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/updateLeadsCompleted`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_LEADS_COMPLETED_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield onSuccess();
        yield put(getInfoContractorCard(payload.contractorId));
        yield put({
            type: UPDATE_LEADS_COMPLETED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: UPDATE_LEADS_COMPLETED_ERROR, payload: error.message});
    }
};

//POST /api/contractors/updatePreviousPassport
export const updatePreviousPassportSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...data
        } = payload;
        const result = yield request.post(`${controller}/updatePreviousPassport`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: UPDATE_PREVIOUS_PASSPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put(getInfoContractorCard(payload.contractorId));
        yield put({
            type: UPDATE_PREVIOUS_PASSPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: UPDATE_PREVIOUS_PASSPORT_ERROR, payload: error.message});
    }
};

//POST /api/contractors/getActiveOrderNumbersByContractor
export const getActiveOrderNumbersSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            contractorId,
        } = payload;
        const result = yield request.post(`${controller}/getActiveOrderNumbersByContractor?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: GET_ACTIVE_ORDER_NUMBERS_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: GET_ACTIVE_ORDER_NUMBERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({type: GET_ACTIVE_ORDER_NUMBERS_ERROR, payload: error.message});
    }
};

//GET /api/contractors/dicts/migration-status
// Список миграционных статусов исполнителя
export const getMigrationStatusDictSaga = function* ({payload = {}}) {
    try {
        const {
            nameField,
            citizenship,
            getResult = () => {},
        } = payload;

        const result = yield request.get(`${controller}/dicts/migration-status`, {params: {citizenship}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_SUCCESS,
            payload: {
                result,
                nameField,
            },
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_MIGRATION_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

export const getPendingAdditionalDocumentsRichPageSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getRichPage/pendingAdditionalDocuments`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_ERROR,
            payload: error,
        });
    }
};

export const updateContractorRegistrationStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            onError = () => {},
        } = payload;
        const result = yield request.post(`${controller}/registrationStatus/update`, payload);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            errorCode !== CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE.SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED &&
            toastError(errorMessage);

            onError(result);
            yield put({
                type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
            payload: error,
        });
    }
};

export const checkInnBeforeAdditionSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/inn/validateBeforeAddition`, reqData);
        getResult(result);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_INN_VALIDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_INN_VALIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_INN_VALIDATE_ERROR,
            payload: error,
        });
    }
};

export const getContractorFilesRequiredListSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/personalData/files/required/list`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: GET_CONTRACTOR_FILES_REQUIRED_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_FILES_REQUIRED_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /api/contractors/personaldata/pending/draft/complete
export const contractorPersonalDataPendingDraftCompleteSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.post(`${controller}/personaldata/pending/draft/complete?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_ERROR,
            payload: error,
        });
    }
};

// метод из хотфикса, добавил бфф в старую структуру
// POST /bff/adm/clients/client-card/orders/act-registry/card/is-client-forbidden-by-contractor
// POST /bff/client-adm/orders/act-registry/card/is-client-forbidden-by-contractor
const checkForbiddenContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/is-client-forbidden-by-contractor`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACTOR_READ_REQUEST, contractorReadSaga),
        takeEvery(CONTRACTOR_LIST_READ_REQUEST, contractorListReadSaga),
        takeEvery(CONTRACTOR_RICH_LIST_READ_REQUEST, contractorRichListSaga),
        takeEvery(CONTRACTOR_SHORT_LIST_READ_REQUEST, getContractorShortListSaga),
        takeEvery(CONTRACTOR_HIRING_OPTI_LIST_READ_REQUEST, getHiringContractorOptimizedListSaga),
        takeEvery(CONTRACTOR_UPDATE_REQUEST, updateContractorSaga),
        takeEvery(CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST, contractorListSaga),
        takeEvery(CONTRACTOR_CITIZENSHIP_REQUEST, getContractorCitizenshipReadSaga),
        takeEvery(CONTRACTOR_VHI_POLICY_FORMAT_REQUEST, getContractorVhiPolicyFormatsSaga),
        takeEvery(CONTRACTOR_VHI_POLICY_TYPE_REQUEST, getContractorVhiPolicyTypesSaga),
        takeEvery(CONTRACTOR_WORKREGION_REQUEST, getContractorWorkRegionsReadSaga),
        takeEvery(CONTRACTOR_SUPPORT_CALL_TYPE_STATUS_REQUEST, getSupportCallTypeStatusSaga),
        takeEvery(CONTRACTOR_SUPPORT_CASE_STATUS_REQUEST, getCaseStatusReadSaga),
        takeEvery(GET_CONTRACTOR_BY_PHONE_REQUEST, getContractorByPhoneSaga),
        takeEvery(GET_CONTRACTOR_ORDER_STATISTICS, getOrdersStatisticsByContractorIdSaga),
        takeEvery(GET_CONTRACTORS_NOT_FOREMAN_PAGE_REQUEST, getNotForemanContractorsPageSaga),
        takeEvery(CONTRACTOR_ARCHIVE_PHONES_REQUEST, getArchivePhonesSaga),
        takeEvery(CONTRACTOR_ADD_FAVORITE_REQUEST, addFavoriteContractorsSaga),
        takeEvery(CONTRACTOR_ADD_TO_BLACKLIST_REQUEST, addContractorToBlackListSaga),
        takeEvery(CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST, deleteContractorFromBlackListSaga),
        takeEvery(CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST, deleteFavoriteContractorsSaga),
        takeEvery(GET_CONTRACTORS_BY_ORDER_REQUEST, getContractorsByOrderSaga),
        takeEvery(GET_CONTRACTOR_COUNT_REQUEST, getContractorCountSaga),
        takeEvery(GET_ALL_APPLICATIONS_REQUEST, getAllApplicationsSaga),
        takeEvery(CONTRACTOR_STATUS_DICT_REQUEST, getContractorStatusDictSaga),
        takeEvery(CONTRACTOR_ORDER_STATUS_DICT_REQUEST, getContractorOrderStatusDictSaga),
        takeEvery(GET_PHONES_BY_XLSX_FILE_REQUEST, getPhonesByXlsxFileSaga),
        takeEvery(GET_BLOCKED_CONTRACTORS_REQUEST, getBlockedContractorsSaga),
        takeEvery(UPDATE_CONTRACTOR_RETAIL_REQUEST, updateContractorRetailSaga),
        takeEvery(POST_PENDING_PERSONAL_DATA_REQUEST, postPendingPersonalDataSaga),
        takeEvery(GET_CONTRACTOR_ORDER_WORK_REPORT_INFO_REQUEST, getContractorOrderWorkReportInfoSaga),
        takeEvery(CONTRACTOR_EDM_PASSPORT_TYPE_REQUEST, getContractorEdmPassportTypeSaga),
        takeEvery(CONTRACTOR_REGISTRATION_STATE_REQUEST, getContractorRegistrationStatesSaga),
        takeEvery(GET_PERSONAL_DATA_PASSPORT_STATUS_REQUEST, getPassportStatusSaga),
        takeEvery(GET_VIEW_EVENT_LOG_CLIENT_ADMIN_REQUEST, getCanViewEventLogClientAdminSaga),
        takeEvery(UPDATE_LEADS_COMPLETED_REQUEST, updateLeadsCompletedSaga),
        takeEvery(UPDATE_PREVIOUS_PASSPORT_REQUEST, updatePreviousPassportSaga),
        takeEvery(GET_ACTIVE_ORDER_NUMBERS_REQUEST, getActiveOrderNumbersSaga),
        takeEvery(GET_CONTRACTOR_MIGRATION_STATUS_DICT_REQUEST, getMigrationStatusDictSaga),
        takeEvery(CONTRACTOR_PENDING_ADDITIONAL_DOCUMENTS_GET_RICH_PAGE_REQUEST, getPendingAdditionalDocumentsRichPageSaga),
        takeEvery(CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST, updateContractorRegistrationStatusSaga),
        takeEvery(CONTRACTOR_CITIZENSHIP_SMZ_ONLY_REQUEST, getCitizenshipsSmzOnlySaga),
        takeEvery(CONTRACTOR_INN_VALIDATE_REQUEST, checkInnBeforeAdditionSaga),
        takeEvery(GET_CONTRACTOR_FILES_REQUIRED_LIST_REQUEST, getContractorFilesRequiredListSaga),
        takeEvery(CONTRACTOR_PERSONAL_DATA_PENDING_DRAFT_COMPLETE_REQUEST, contractorPersonalDataPendingDraftCompleteSaga),
        takeEvery(BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST, checkForbiddenContractorSaga),
    ]);
}