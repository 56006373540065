import {
    BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_SUCCESS,
    CLEAR_CONTRACTOR_PROFILE_STORE,
    GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS,
    GET_CONTRACTOR_PROFILE_ERROR,
    GET_CONTRACTOR_PROFILE_SUCCESS,
    GET_DRIVER_LICENSE_DICTIONARY_SUCCESS,
    GET_EDUCATION_LEVEL_DICT_ERROR,
    GET_EDUCATION_LEVEL_DICT_SUCCESS,
} from "./actions";

const initial = {
    workExperiences: {
        workExperiences: [],
    },
    educationLevelDict: {},
    driverLicenseDict: {},
    educations: [],
    drivingLicenseObj: {
        driverLicenses: [],
        ownCar: false,
    },
    contractorLanguagesDict: {},
    contractorLanguage: {
        foreignLanguagePresent: false,
        foreignLanguages: [],
    },
    additionalSpeciality: {
        additionalSpecialities: [],
        additionalSpecialityPresent: false,
    },
    contractorCourseCertificateList: [],
    familyStatus: null,
    keySkills: [],
    medicalBook: {},
    vaccinationCertificate: {},
    portfolioList: [],
    workContraindication: {},
    mainSpeciality: "",
    schedules: [],
    departureAddressesInfo: {},
    workAddressInfo: {},
    remoteWork: false,
    forbiddenOrderClientList: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CONTRACTOR_PROFILE_SUCCESS:
            return {
                ...state,
                remoteWork: payload.remoteWork || false,
                workExperiences: {
                    workExperiences: payload.workExperiences || [],
                    workExperiencePresent: payload.workExperiencePresent,
                },
                educations: payload.educations || [],
                drivingLicenseObj: {
                    driverLicenses: payload.driverLicenses || [],
                    ownCar: payload.ownCar,
                    driverLicensePresent: payload.driverLicensePresent,
                },
                contractorLanguage: {
                    foreignLanguagePresent: payload.foreignLanguagePresent,
                    foreignLanguages: payload.foreignLanguages || [],
                },
                additionalSpeciality: {
                    additionalSpecialities: payload.additionalSpecialities || [],
                    additionalSpecialityPresent: payload.additionalSpecialityPresent,
                },
                contractorCourseCertificateList: payload.contractorCourseCertificateList || [],
                familyStatus: payload.familyStatus,
                keySkills: payload.keySkills || [],
                medicalBook: {
                    medicalBookFileName: payload.medicalBookFileName,
                    medicalBookFileType: payload.medicalBookFileType,
                    medicalBookNumber: payload.medicalBookNumber,
                    medicalBookPresent: payload.medicalBookPresent,
                    medicalBookQRFileName: payload.medicalBookQRFileName,
                    medicalBookQRFileType: payload.medicalBookQRFileType,
                },
                vaccinationCertificate: {
                    vaccinationCertificateFileName: payload.vaccinationCertificateFileName,
                    vaccinationCertificateFileType: payload.vaccinationCertificateFileType,
                    vaccinationCertificatePresent: payload.vaccinationCertificatePresent,
                },
                portfolioList: payload.contractorPortfolioList || [],
                workContraindication: {
                    workContraindications: payload.workContraindications,
                    workContraindicationsPresent: payload.workContraindicationsPresent,
                },
                mainSpeciality: payload.mainSpeciality,
                schedules: payload.schedules || [],
                departureAddressesInfo: {
                    departureAddressesInfo: payload.departureAddressesInfo,
                    departureAddressesPresent: payload.departureAddressesPresent,
                },
                workAddressInfo: payload.workAddressInfo || {},
            };
        case GET_CONTRACTOR_PROFILE_ERROR:
        case GET_EDUCATION_LEVEL_DICT_ERROR:
            return {
                ...state,
                error: payload,
            };
        case GET_DRIVER_LICENSE_DICTIONARY_SUCCESS:
            return {
                ...state,
                driverLicenseDict: payload,
            };
        case GET_EDUCATION_LEVEL_DICT_SUCCESS:
            return {
                ...state,
                educationLevelDict: payload,
            };
        case GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS:
            return {
                ...state,
                contractorLanguagesDict: payload,
            };
        case BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                forbiddenOrderClientList: payload,
            };
        case CLEAR_CONTRACTOR_PROFILE_STORE:
            return {
                ...initial,
            };
        default:
            return state;
    }
};