import React from "react";
import {Route, Switch} from "react-router";

import {ContractorFinanceIncomeIndividualAwaiting} from "./awaiting";
import {ContractorFinanceIncomeIndividualCompleted} from "./completed";
import {ContractorFinanceIncomeIndividualInProgress} from "./in-progress";
import {ContractorFinanceIncomeIndividualRegistryPayments} from "./registry-payments";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_REGISTRY_PAYMENTS_LIST,
} from "../../../../constants/links";

export const ContractorFinanceIncomeIndividualRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST}
                component={ContractorFinanceIncomeIndividualCompleted}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST}
                component={ContractorFinanceIncomeIndividualInProgress}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST}
                component={ContractorFinanceIncomeIndividualAwaiting}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_REGISTRY_PAYMENTS_LIST}
                component={ContractorFinanceIncomeIndividualRegistryPayments}
            />
        </Switch>
    );
};