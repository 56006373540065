import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isBoolean} from "lodash";
import * as yup from "yup";

import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import {SelectVariantsTable} from "../../../../../components/ActualComponents/SelectVariantsTable";
import Tabs from "../../../../../components/ActualComponents/Tabs";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmButton from "../../../../../components/NmButton";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as TelegramIcon} from "../../../../../images/telegram_20.svg";
import {NotificationsSubscribesDescription} from "../description";

import {useAppModal} from "../../../../../hooks/useAppModal";
import {useSubscribeNotifications} from "../../hooks/useSubscribe";

import {handleFormString} from "../../../../../utils/stringHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {
    addNotificationSubscription,
    editNotificationSubscription,
    getNotificationSubscriptionById,
    getSubscribeNotificationsClientUsers,
    isNotificationAuthInTelegram,
    updateNotificationsSubscribeStore,
} from "../../../../../ducks/bff/notifications/subscribe/actionCreators";
import {
    notificationSubscribesCardSelector,
    notificationSubscribesClientUsersOptionsSelector,
    notificationSubscribesIsAuthedInTelegramSelector,
    notificationSubscribesProgressActionSelector,
    notificationSubscribesProgressCardSelector,
    notificationSubscribesTypesOptionsSelector,
} from "../../../../../ducks/bff/notifications/subscribe/selectors";
import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";

export const NotificationsSubscribesEdit = (props) => {
    const {
        isAllSubscriptions,
    } = props;

    const dispatch = useDispatch();

    const subscribesTypesOptions = useSelector(notificationSubscribesTypesOptionsSelector);
    const progressAction = useSelector(notificationSubscribesProgressActionSelector);
    const progressCard = useSelector(notificationSubscribesProgressCardSelector);
    const card = useSelector(notificationSubscribesCardSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);
    const authedInTelegram = useSelector(notificationSubscribesIsAuthedInTelegramSelector);
    const clientUserOptions = useSelector(notificationSubscribesClientUsersOptionsSelector);
    const isAuthedInTelegram = isBoolean(authedInTelegram) && authedInTelegram;
    const isVisibleButton = isBoolean(isAuthedInTelegram) && !isAuthedInTelegram && !isAllSubscriptions;

    const {
        modalData,
        onCloseModal,
    } = useAppModal();

    const [isOpenQR, setOpenQR] = useState(false);

    useEffect(() => {
        if (!modalData.subscribeId) {
            return;
        }

        dispatch(isNotificationAuthInTelegram({
            clientUserId: modalData.clientUserId,
        }));

        dispatch(getNotificationSubscriptionById({
            subscribeId: modalData.subscribeId,
            clientId: modalData.clientId,
        }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(updateNotificationsSubscribeStore({
                card: {},
                isAuthedInTelegram: null,
                clientUsers: [],
            }));
        };
    }, []);

    const [isMarketplace, setMarketplace] = useState(false);
    const clientUserId = isAllSubscriptions ? modalData.clientUserId : currentMember.clientUserId;

    const {
        onClickSubscribeNotifications,
    } = useSubscribeNotifications();

    const {
        values,
        touched,
        errors,
        setValues,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        initialValues: {
            clientUserId: clientUserId || "",
            recruitmentSubscribesEmail: card.recruitmentSubscribesEmail || [],
            recruitmentSubscribesTelegram: card.recruitmentSubscribesTelegram || [],
            marketplaceSubscribesEmail: card.marketplaceSubscribesEmail || [],
            marketplaceSubscribesTelegram: card.marketplaceSubscribesTelegram || [],
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            clientUserId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        onSubmit,
    });

    const onSearchChange = (fioFilter) => {
        dispatch(getSubscribeNotificationsClientUsers({
            clientId: modalData.clientId,
            fioFilter: handleFormString(fioFilter),
        }));
    };

    const onChange = ({name, selectedIds}) => {
        setValues({
            ...values,
            [name]: selectedIds,
        });
    };

    function onSubmit() {
        if (modalData.subscribeId) {
            dispatch(editNotificationSubscription({
                isAllSubscriptions,
                subscribeId: modalData.subscribeId,
                clientId: modalData.clientId,
                ...values,
            }));

            return;
        }

        dispatch(addNotificationSubscription({
            isAllSubscriptions,
            clientId: modalData.clientId,
            ...values,
        }));
    }

    const getList = () => {
        const variants = [
            {
                content: "На почту",
                name: isMarketplace
                    ? "marketplaceSubscribesEmail"
                    : "recruitmentSubscribesEmail",
                selectedIds: isMarketplace
                    ? values.marketplaceSubscribesEmail
                    : values.recruitmentSubscribesEmail,
            },
            {
                content: "В TG",
                disabled: !modalData.subscribeId || !isAuthedInTelegram,
                name: isMarketplace
                    ? "marketplaceSubscribesTelegram"
                    : "recruitmentSubscribesTelegram",
                selectedIds: isMarketplace
                    ? values.marketplaceSubscribesTelegram
                    : values.recruitmentSubscribesTelegram,
                tooltipProps: isAuthedInTelegram
                    ? undefined
                    : {
                        text: "Для активации подключите уведомления в Telegram",
                    },
            },
        ];

        return (
            <SelectVariantsTable
                rows={subscribesTypesOptions}
                variantsDescription="Типы уведомлений"
                allVariantsName="Все категории"
                isHideAllVariantsCheckbox={false}
                variants={variants}
                onChange={onChange}
            />
        );
    };

    const getTitleText = () => {
        if (isAllSubscriptions) {
            if (modalData?.subscribeId) {
                return `Подписки на уведомления ${modalData.clientUserName}`;
            }

            return "Добавление новой подписки на уведомления";
        }

        if (modalData?.subscribeId) {
            return "Мои подписки на уведомления";
        }

        return "Добавление подписки на уведомления";
    };

    if (isOpenQR) {
        return <NotificationsSubscribesDescription onClose={onCloseModal} />;
    }

    {return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {getTitleText()}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="column"
                    onClose={onCloseModal}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    submitBtnContent="Сохранить"
                />
            }
            loading={progressAction || progressCard}
            onClose={onCloseModal}
            classNameContent="flex-column"
        >
            {
                isAllSubscriptions && !modalData?.subscribeId &&
                <NmDropdownV2
                    required={true}
                    value={values.clientUserId}
                    onChange={(_event, {value}) => {
                        setFieldValue("clientUserId", value);
                    }}
                    className="mb-3 mb-md-4"
                    label="ФИО сотрудника"
                    placeholder="Не выбрано"
                    onSearchChange={onSearchChange}
                    options={clientUserOptions}
                    search={true}
                    error={touched.clientUserId && errors.clientUserId}
                />
            }
            <Tabs
                className="mb-4 mb-md-6"
                panes={[
                    // {
                    //     name: "Маркетплейс",
                    //     active: isMarketplace,
                    //     onClick: () => {
                    //         setMarketplace(true);
                    //     },
                    // },
                    {
                        name: "Рекрутмент",
                        active: !isMarketplace,
                        onClick: () => {
                            setMarketplace(false);
                        },
                    },
                ]}
            />
            {getList()}
            {
                isVisibleButton &&
                <div>
                    <NmButton
                        size="lg"
                        color="grey"
                        className="mt-4 mt-md-6"
                        icon={<TelegramIcon />}
                        onClick={() => {
                            onClickSubscribeNotifications({
                                openModal: () => {
                                    setOpenQR(true);
                                },
                            });
                        }}
                    >
                        Подключить уведомления в Telegram
                    </NmButton>
                </div>
            }
        </NmModal>
    );}
};