import React, {FC} from "react";
import {useMedia} from "react-media";
import {nanoid} from "nanoid";

import Box from "../ActualComponents/Box";
import ContextMenu from "../ActualComponents/ContextMenu";
import {MediaButtons} from "../ActualComponents/MediaControls";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmLoader from "../NmLoader";
import SortElement from "../SortElement";
import TableSelectedLabel from "../TableSelectedLabel";
import {CheckboxListExtendedContent} from "./ExtendedContent";
import {Loader} from "semantic-ui-react";

import {useLoadScrollPosition} from "../../hooks/useLoadScrollPosition";

import bem from "../../utils/bem";

import {CheckBoxList, CheckBoxListRowType} from "./types";
import PropTypes from "prop-types";

import "./style.sass";

const CheckboxList: FC<CheckBoxList> = (props) => {
    const {
        header,
        rows = [],
        rowClassName = "",
        checkBoxClassName = "",
        headerCheckBoxClassName = "",
        classNameContent = "",
        classNameAvatar = "",
        loading,
        className = "",
        onSelectedRows,
        actionOptions,
        onClickActionItem,
        additionalActions,
        disabledMobileContextMenu,
        mediaControls,
        count,
        isShowCheckboxLabel,
        sort,
        sortOptions,
        onClickSort,
        withCheckbox = true,
        isShowMassActions = true,
        rightContent,
        isShowRightContent,
        rightContentLink,
        rightContentId,
        infiniteScrollProps,
        infiniteProgress,
        rightContentClassName,
        contentRef,
        loaderContent,
        isFullMinHeight = true,
        maxSelected,
        isVisibleLeftMassActions = true,
        isVisibleRightMassActions,
    } = props;
    const [block, element] = bem("checkbox-list", className);
    const isMobile = useMedia({query: {maxWidth: 767}});

    const isShowCheckbox = Boolean(onSelectedRows) && withCheckbox;

    useLoadScrollPosition();

    const onSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>, isAllSelected: boolean) => {
        event.stopPropagation();

        const _rows = rows.map((item, index) => {
            const conditionMaxSelected = maxSelected ? index < maxSelected : true;

            return {
                ...item,
                isSelected: item.showCheckBox !== undefined
                        && item.showCheckBox
                        && isAllSelected
                        && !item.disabledCheckBox
                        && conditionMaxSelected,
            };
        },
        );

        if (onSelectedRows) {
            onSelectedRows(_rows, isAllSelected);
        }
    };

    const getAllCheckboxValue = () => {
        const shownCheckboxCount = rows.filter(value => value.showCheckBox && !value.disabledCheckBox).length;
        const selectedCheckBoxCount = rows.filter(value => value.isSelected).length;

        return shownCheckboxCount !== 0 && shownCheckboxCount === selectedCheckBoxCount;
    };

    const getDisabledHeaderCheckbox = () => {
        const length = rows.filter(value => value.disabledCheckBox).length;

        return length === rows.length;
    };

    const onSelectRow = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, index: number) => {
        const _rows = [...rows];
        _rows[index]["isSelected"] = checked;

        if (onSelectedRows) {
            onSelectedRows(_rows);
        }
    };

    const getCheckboxLabel = (isSelected?: boolean) => {
        if (!isShowCheckboxLabel) {
            return null;
        }

        return isSelected ? "Выбрано" : "Не выбрано";
    };

    const getRowsClassName = (item: CheckBoxListRowType) => {
        const {
            dynamicRowClassName = "",
            hoverRow,
            error,
            isErrors,
            warning,
            isActive,
        } = item;
        const bemClass = element("row", {
            hover: hoverRow,
            error,
            errors: isErrors,
            warning,
            active: isActive,
            rightContent: Boolean(rightContent),
        });

        return [bemClass, rowClassName, dynamicRowClassName].join(" ");
    };

    const getCheckBox = (row: CheckBoxListRowType, index: number) => {
        const {
            disabledCheckBox = false,
            isSelected,
            showCheckBox,
        } = row;

        if (!showCheckBox) {
            return null;
        }

        return (
            <NmCheckboxV2
                classNameLabel="checkbox-list__row-checkbox-label"
                label={getCheckboxLabel(isSelected)}
                onChange={(event, {checked}) => {
                    onSelectRow(event, checked, index);
                }}
                className={`${checkBoxClassName} checkbox-list__row-checkbox`}
                disabled={disabledCheckBox}
                checked={isSelected ? isSelected : false}
            />
        );
    };

    const getRowBoxContent = (row: CheckBoxListRowType, index: number) => {
        const {
            contentRow,
            renderContentRow,
            avatar,
            zIndexAvatar,
        } = row;

        if (renderContentRow) {
            return renderContentRow({
                checkbox: getCheckBox(row, index),
            });
        }

        return (
            <>
                {
                    isShowCheckbox &&
                    <div className="checkbox-list__row-checkbox-container">
                        {getCheckBox(row, index)}
                    </div>
                }
                {
                    avatar &&
                    <div
                        className={`${element("avatar", {
                            withSubheader: Boolean(row.isWithSubHeader),
                            checkbox: isShowCheckbox,
                        })} ${classNameAvatar}`}
                        style={{zIndex: zIndexAvatar}}
                    >
                        {avatar}
                    </div>
                }
                <div
                    className={element("card", {
                        withCheckbox:
                        withCheckbox &&
                        (
                            Boolean(onSelectedRows) ||
                            Boolean(avatar)
                        ),
                        avatar: Boolean(avatar),
                    })}
                >
                    {contentRow}
                </div>
            </>
        );
    };

    const getRows = () => {
        const isRightContent = Boolean(rightContent);

        const _rows = rows.map((row, index) => {
            const {
                key,
                onClick,
            } = row;

            return (
                <Box
                    theme="list"
                    key={key || nanoid(4)}
                    className={getRowsClassName(row)}
                    onClick={onClick}
                    noBorder={isRightContent}
                    noRadius={isRightContent}
                >
                    {getRowBoxContent(row, index)}
                </Box>
            );
        });

        if (infiniteScrollProps) {
            return (
                <>
                    {_rows}
                    {
                        infiniteScrollProps &&
                        <div {...infiniteScrollProps} />
                    }
                    {
                        infiniteProgress &&
                        <div className="checkbox-list__infinite-loader">
                            <Loader
                                active
                                inline
                            />
                        </div>
                    }
                </>
            );
        }

        return _rows;
    };

    const renderHeader = () => {
        return (
            <div className="checkbox-list__header">
                <div className="checkbox-list__header-row">
                    {
                        isVisibleLeftMassActions &&
                        <>
                            <NmCheckboxV2
                                onChange={(event, {checked}) => {
                                    onSelectAllRows(event, checked);
                                }}
                                className={`checkbox-list__header-row-checkbox ${headerCheckBoxClassName}`}
                                disabled={getDisabledHeaderCheckbox()}
                                checked={getAllCheckboxValue()}
                            />
                            {
                                typeof count === "number" &&
                                <TableSelectedLabel
                                    count={count}
                                />
                            }
                        </>
                    }
                    {header}
                    {
                        mediaControls &&
                        <MediaButtons
                            inline
                            buttonOffset="sm"
                            className="checkbox-list__media-buttons"
                            config={mediaControls}
                        />
                    }
                </div>
                {
                    isMobile && !mediaControls && actionOptions &&
                    <ContextMenu
                        disabled={disabledMobileContextMenu}
                        className="checkbox-list__dropdown-mobile"
                        options={actionOptions}
                        onClickItem={onClickActionItem}
                    />
                }
                {
                    !isMobile && (!mediaControls || isVisibleRightMassActions) && additionalActions
                }
            </div>
        );
    };

    const getContent = () => {
        if (rightContent) {
            return (
                <CheckboxListExtendedContent
                    className="checkbox-list__content"
                    leftContent={getRows()}
                    rightContent={rightContent}
                    isShowRightContent={isShowRightContent}
                    rightContentLink={rightContentLink}
                    rightContentInitialId={rows[0]?.key}
                    rightContentId={rightContentId}
                    rightContentClassName={rightContentClassName}
                    leftContentRef={contentRef}
                />
            );
        }

        return (
            <div className={`checkbox-list__content ${classNameContent}`}>
                {getRows()}
            </div>
        );
    };

    return (
        <div
            className={block({
                fullMinHeight: isFullMinHeight,
            })}
        >
            <NmLoader
                classNameDimmer="orders__content-dimmer"
                active={loading}
                content={loaderContent}
                inverted
            />
            {
                sort &&
                <SortElement
                    className="checkbox-list__sort"
                    options={sortOptions}
                    onClickItem={onClickSort}
                />
            }
            {
                (
                    isShowCheckbox && isShowMassActions
                    || isVisibleRightMassActions
                ) &&
                renderHeader()
            }
            {getContent()}
        </div>
    );
};

CheckboxList.propTypes = {
    loading: PropTypes.bool,
    className: PropTypes.string,
    header: PropTypes.node,
    rows: PropTypes.array,
    rowClassName: PropTypes.string,
    checkBoxClassName: PropTypes.string,
    headerCheckBoxClassName: PropTypes.string,
    classNameContent: PropTypes.string,
    onSelectedRows: PropTypes.func,
};

export default CheckboxList;