import React, {FC, FunctionComponent, SVGAttributes, SVGProps} from "react";

import "./style.sass";

interface IconButton extends Partial<SVGAttributes<SVGElement>> {
    icon: FunctionComponent<SVGAttributes<SVGElement>>,
    onClick?: () => void,
}

export const IconButton: FC<IconButton> = (props) => {
    const {
        icon,
        className,
        ...otherProps
    } = props;

    const elementProps = {
        className: `${className} icon-button`,
        ...otherProps,
    } as SVGAttributes<SVGElement>;

    return React.createElement(icon, elementProps);
};
