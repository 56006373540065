import {
    BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE,
} from "./actions";

import {getBasePageState} from "../../../../utils/redux";

const initialState = {
    subscribes: getBasePageState(),
    totalCount: 0,
    clientUsers: [],
    subscribeTypes: {},
    subscriptionCategories: {},
    clientRoles: {},
    channelTypes: {},
    authedInTelegram: null,
    card: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS:
            return {
                ...state,
                totalCount: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS:
            return {
                ...state,
                authedInTelegram: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload.result,
                progressCard: false,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS:
            return {
                ...state,
                channelTypes: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS:
            return {
                ...state,
                clientUsers: payload.clientUsers,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS:
            return {
                ...state,
                clientRoles: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS:
            return {
                ...state,
                subscribeTypes: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS:
            return {
                ...state,
                subscriptionCategories: payload,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST:
        case BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST:
        case BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST:
            return {
                ...state,
                error: null,
                progressAction: true,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS:
        case BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS:
        case BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR:
        case BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR:
        case BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR:
            return {
                ...state,
                error: payload,
                progressAction: false,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST:
        case BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST:
            return {
                ...state,
                subscribes: {
                    ...state.subscribes,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS:
        case BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS:

            return {
                ...state,
                subscribes: {
                    ...state.subscribes,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR:
        case BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR:
            return {
                ...state,
                subscribes: {
                    ...state.subscribes,
                    progress: false,
                },
            };
        case BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE:
            return initialState;
        case BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
