import React from "react";
import {Route, Switch} from "react-router";

import {ContractorFinanceIncomeCrowdAwaiting} from "../income-crowd/awaiting";
import {ContractorFinanceIncomeCrowdCompleted} from "../income-crowd/completed";
import {ContractorFinanceIncomeCrowdInProgress} from "../income-crowd/in-progress";
import {ContractorFinanceIncomeNpdAwaiting} from "./awaiting";
import {ContractorFinanceIncomeNpdCompleted} from "./completed";
import {ContractorFinanceIncomeNpdInProgress} from "./in-progress";
import {ContractorFinanceIncomeNpdRegistryPayments} from "./registry-payments";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS,
} from "../../../../constants/links";

export const ContractorFinanceIncomeNpdRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdCompleted}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS}
                component={ContractorFinanceIncomeCrowdCompleted}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdInProgress}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS}
                component={ContractorFinanceIncomeCrowdInProgress}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdAwaiting}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS}
                component={ContractorFinanceIncomeCrowdAwaiting}
            />
            <Route
                exact
                path={LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS}
                component={ContractorFinanceIncomeNpdRegistryPayments}
            />
        </Switch>
    );
};