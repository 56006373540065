import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {FieldArray} from "formik";

import {
    DroppableContainer,
} from "../../../../../../components/ActualComponents/DroppableContainer";
import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import {
    bffRecruitmentDirectoryProgressActionSelector,
    bffRecruitmentDirectoryStatusOptionsSelector,
} from "../../../../../../ducks/bff/recruitment/directory/selectors";
import {RecruitmentDirectoryFunnelEditFormRow} from "./row";

import {COLOR} from "../../../../../../constants/color";

import "./style.sass";

export const RecruitmentDirectoriesFunnelEditForm = (props) => {
    const {
        handleSubmit,
        values,
        handleChange,
        touched,
        errors,
        setFieldValue,
        onClose,
        isEdit,
    } = props;

    const statusOptions = useSelector(bffRecruitmentDirectoryStatusOptionsSelector);

    const _statusOptions = useMemo(() => {
        return statusOptions.map((item) => {
            const statusList = values.statusList.map(({statusId}) => statusId);

            return {
                ...item,
                disabled: statusList.includes(item.value),
            };
        });
    }, [statusOptions, values.statusList]);

    const progressAction = useSelector(bffRecruitmentDirectoryProgressActionSelector);

    const deleteItem = (index) => {
        const _list = [...values.statusList];

        _list.splice(index, 1);

        setFieldValue("statusList", _list);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const valueCopy = values.statusList.slice();
        const [removed] = valueCopy.splice(result.source.index, 1);

        valueCopy.splice(result.destination.index, 0, removed);

        setFieldValue("statusList", valueCopy);
    };

    const addItem = () => {
        setFieldValue(
            "statusList",
            [
                ...values.statusList,
                {
                    isDefault: false,
                    statusId: "",
                    statusDurationDays: "",
                },
            ],
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование воронки" : "Добавление воронки"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    align="right"
                    onClose={onClose}
                    submit={handleSubmit}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                />
            }
            loading={progressAction}
        >
            <NmForm addMargin>
                <NmInputV2
                    required
                    size="xl"
                    label="Название воронки"
                    placeholder="Введите название"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    maxLength={100}
                    error={touched.name && errors.name}
                />
                <Text.Title
                    level="4"
                    bold={true}
                >
                    Последовательность статусов в воронке
                </Text.Title>
                <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                >
                    Чтобы отслеживать время нахождения кандидатов на статусах воронки, задайте длительность статусам
                </Text>
                <div className="recruitment-directories-funnels-edit-form__header">
                    <Text.Sub
                        medium={true}
                        level="1"
                        className="recruitment-directories-funnels-edit-form__header-name"
                    >
                        Название статуса
                    </Text.Sub>
                    <Text.Sub
                        medium={true}
                        level="1"
                        className="d-flex"
                    >
                        Длительность статуса
                        <HelpTooltip
                            hover={true}
                            info={true}
                            width={20}
                            height={20}
                            className="ms-1"
                            children="Установите желаемый срок нахождения кандидата на каждом этапе: система
                            оповестит вас о кандидатах, которые находятся на этапе дольше установленного времени.
                            Это поможет вам правильно спланировать работу. Настроить уведомления на почту или в
                            телеграм можно в разделе «Уведомления» → «Подписки на уведомления»"
                        />
                    </Text.Sub>
                </div>
                <DroppableContainer onDragEnd={onDragEnd}>
                    <FieldArray name="statusList">
                        {
                            () => {
                                return values.statusList.map((item, index) => {
                                    return (
                                        <RecruitmentDirectoryFunnelEditFormRow
                                            key={index}
                                            index={index}
                                            touched={
                                                touched.statusList &&
                                                touched.statusList[index]
                                            }
                                            values={
                                                values.statusList[index]
                                            }
                                            errors={
                                                errors.statusList &&
                                                errors.statusList[index]
                                            }
                                            isDefault={item.isDefault}
                                            statusOptions={_statusOptions}
                                            setFieldValue={setFieldValue}
                                            className="mb-4"
                                            deleteItem={deleteItem}
                                        />
                                    );
                                });
                            }
                        }
                    </FieldArray>
                </DroppableContainer>
                <div className="col-16 col-md-4 mt-3 mt-md-0">
                    <NmButton
                        size="xl"
                        className="col-16"
                        color="light-green"
                        onClick={addItem}
                    >
                        Добавить статус
                    </NmButton>
                </div>
            </NmForm>
        </NmModal>
    );
};