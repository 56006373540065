import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    getRecruitmentCandidateAvatar,
    getRecruitmentResponseAvatar,
} from "../../ducks/fileStoreRecruitment";
import {ReactComponent as AddedToMyClientGroupIcon} from "../../images/circuit-indicator.svg";
import {ReactComponent as HelpResourceIcon} from "../../images/help-resource-indicator.svg";
import {ReactComponent as KedoIcon} from "../../images/kedo.svg";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import NmModal from "../ActualComponents/NmModal";
import UserAvatarEditForm from "../ActualComponents/UserAvatarEditForm";
import AvatarIndicator from "./Indicator";

import bem from "../../utils/bem";

import {CLIENT_GROUP_TYPE} from "../../constants/clientGroup";

import {
    avatarBase64ImageOriginalSelector,
    getAvatarProgressSelector,
    getUserAvatar,
    updateFieldFilesStore,
} from "../../ducks/fileStore";

import PropTypes from "prop-types";

import "./style.sass";

const empty = require("../../images/avatar-empty.svg").default;

const Avatar = (props) => {
    const {
        base64,
        className = "",
        isEditable = false,
        contractorId,
        candidateId,
        responseId,
        addedToMyClientGroup,
        contractorClientGroupType,
        isKedo,
        size,
        counter,
        showCheckBox = false,
        classNameImageContainer = "",
        isEditIconTopRight,
        isAccessOpenOriginal = true,
        avatarPresent,
        style,
        preview,
        onOpenOriginal,
        originalImageSrc,
        isOriginalImageBase64 = true,
        onCloseOriginalAvatar,
        nameField,
        indicator,
        imageClassName,
    } = props;

    const progress = useSelector(getAvatarProgressSelector);
    const originalAvatar = useSelector(avatarBase64ImageOriginalSelector);

    const [block, element] = bem("avatar", className);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isShowOriginal, setIsShowOriginal] = useState(false);

    const dispatch = useDispatch();

    const indicatorData = useMemo(() => {
        if (isKedo) {
            return {
                icon: (
                    <KedoIcon
                        height={18}
                        width={18}
                    />
                ),
                tooltipText: "Пользователь модуля \"КЭДО\"",
            };
        }

        if (contractorClientGroupType === CLIENT_GROUP_TYPE.HELP_RESOURCES) {
            return {
                icon: <HelpResourceIcon />,
                tooltipText: "Исполнитель в закрытом контуре \"HelpResource\"",
            };
        }

        if (addedToMyClientGroup) {
            return {
                icon: <AddedToMyClientGroupIcon
                    className={element("indicator-icon")}
                />,
                tooltipText: "Исполнитель добавлен в закрытый контур",
            };
        }

        return {};
    }, [addedToMyClientGroup]);

    const closeOriginalAvatarModal = () => {
        setIsShowOriginal(false);

        if (onCloseOriginalAvatar) {
            onCloseOriginalAvatar();

            return;
        }

        dispatch(updateFieldFilesStore({
            avatarBase64ImageOriginal: "",
        }));
    };

    const openOriginalAvatarModal = () => {
        setIsShowOriginal(true);

        if (onOpenOriginal) {
            onOpenOriginal();

            return;
        }

        if (responseId) {
            dispatch(getRecruitmentResponseAvatar({
                responseId,
                nameField,
                thumbnail: false,
            }));

            return;
        }

        if (candidateId) {
            dispatch(getRecruitmentCandidateAvatar({
                candidateId,
                nameField,
                thumbnail: false,
            }));

            return;
        }

        dispatch(getUserAvatar({
            contractorId,
            nameField,
        }));
    };

    const getOriginalAvatarSrc = () => {
        if (originalImageSrc) {
            return originalImageSrc;
        }

        // у кандидата, в отклике храним в одном поле и миниматюру и полное изображние
        if (candidateId || responseId) {
            return base64;
        }

        return originalAvatar;
    };

    const renderOriginalModal = () => {
        if (!isShowOriginal) {
            return null;
        }

        const avatar = getOriginalAvatarSrc();

        return (
            isShowOriginal &&
            <NmModal
                size="sm"
                onClose={closeOriginalAvatarModal}
                loading={progress || props.progress}
            >
                {
                    avatar &&
                    <img
                        alt="originalAvatar"
                        className={element("original-img")}
                        src={
                            isOriginalImageBase64
                                ? `data:image/jpeg;charset=utf-8;base64, ${avatar}`
                                : avatar
                        }
                    />
                }
            </NmModal>
        );
    };

    const getImageSrc = () => {
        if (preview) {
            return preview;
        }

        return avatarPresent
            ? `${process.env.REACT_APP_PROXY}/api/filestore/contractorFiles/avatar/thumbnail/small/get?contractorId=${contractorId}`
            : `data:image/jpeg;charset=utf-8;base64, ${base64}`;
    };

    // TODO: отрефакторить индикаторы (привести к общему виду)
    const getIndicator = () => {
        if (indicator) {
            return (
                <div className={element("indicator")}>
                    {indicator}
                </div>
            );
        }

        return !isEmpty(indicatorData) &&
            <AvatarIndicator
                className={element("indicator")}
                classNameTooltip={element("tooltip-indicator")}
                tooltipText={indicatorData.tooltipText}
                icon={indicatorData.icon}
            />;
    };

    return (
        <>
            {renderOriginalModal()}
            {
                isOpenModal &&
                <UserAvatarEditForm
                    nameField={nameField}
                    contractorId={contractorId}
                    candidateId={candidateId}
                    base64={base64}
                    onClose={() => setIsOpenModal(false)}
                    progress={props.progress}
                />
            }
            <div
                className={block({size})}
                style={style}
            >
                <div className={`avatar__image-container ${classNameImageContainer}`}>
                    {
                        (base64 || avatarPresent || preview) ?
                            <img
                                alt="avatar"
                                className={`${element("img", {cursorZoomIn: isAccessOpenOriginal})} ${imageClassName}`}
                                src={getImageSrc()}
                                onClick={
                                    isAccessOpenOriginal
                                        ? openOriginalAvatarModal
                                        : undefined
                                }
                            /> :
                            <img
                                alt="avatar"
                                className={element("img")}
                                src={empty}
                            />
                    }
                </div>
                {getIndicator()}
                {
                    isEditable &&
                    <ModeIcon
                        className={element("mode-icon", {topRight: isEditIconTopRight})}
                        onClick={() => setIsOpenModal(true)}
                    />
                }
            </div>
            {
                Boolean(counter) &&
                <div className={element("counter", {showCheckBox})}>
                    {counter}
                </div>
            }
        </>
    );
};

Avatar.propTypes = {
    contractorId: PropTypes.string,
    addedToMyClientGroup: PropTypes.bool,
    avatarPresent: PropTypes.bool,
    imageClassName: PropTypes.string,
    size: PropTypes.oneOf([
        "xl",
        "lg",
        "sm",
        "md",
    ]),
};

export default Avatar;