import React from "react";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";

import {guid} from "../../../utils/stringHelper";

interface VerticalMovingListDroppableContainerProps {
    onDragEnd: (result: DropResult) => void,
    children: React.ReactNode,
}

export const DroppableContainer: React.FC<VerticalMovingListDroppableContainerProps> = (props) => {
    const {
        onDragEnd,
        children,
    } = props;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={guid()}>
                {
                    (provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {children}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};