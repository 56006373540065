import React from "react";
import {useSelector} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../components/ActualComponents/NmMiniInfoCard";
import ContractorInfoCardContent from "../../../components/ContractorInfoCardContent";
import NmLoader from "../../../components/NmLoader";
import {STATUS_PASSPORT_INN} from "../../../components/NmStatusPassportInnContractor";
import ContractorFullRegistration from "../full-registration";
import ContractorInfoAdditionalDocumentsChecking from "./components/additional-documents-checking";
import ContractorInfoBlock from "./components/block";
import ContactInfoCard from "./components/contact-info-card";
import CovidVaccinationInfoCard from "./components/covid-vaccination-info-card";
import EmploymentHistoryCard from "./components/employment-history-card";
import FingerprintingInfoCard from "./components/fingerprinting-info-card";
import ContractorIndividualEntrepreneurDataEditModal from "./components/individual-entrepreneur-data-edit-modal";
import MainDocumentInfoCard from "./components/main-document-info-card";
import MedicalBookInfoCard from "./components/medical-book-info-card";
import MedicalPersonalInfoCard from "./components/medical-personal-info-card";
import ContractorOldPersonalData from "./components/old-personal-data";
import PaymentDataInfoCard from "./components/payment-data-info-card";
import PaymentDataInfoEditForm from "./components/payment-data-info-card/components/edit-form";
import ContractorInfoPersonalDataChecking from "./components/personal-data-checking";
import PreviouslyPassportInfoCard from "./components/previously-passport-info-card";
import SnilsInfoCard from "./components/snils-info-card";

import {useModal} from "../../../hooks/useModal";
import {useFetchContractorInfo} from "./hooks/useFetchData";
import {useContractorFullRegistration} from "./hooks/useFullRegistration";
import {useContractorIndividualEntrepreneurCard} from "./hooks/useIndividualEntrepreneurCard";
import {useContractorInnCard} from "./hooks/useInnCard";

import bem from "../../../utils/bem";
import {getDadataAddressObj} from "../../../utils/dadata";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getMainDocumentsCardsList} from "./utils/getMainDocumentsList";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    CONTRACTOR_STATUS,
} from "../../../constants/contractor";
import {
    ADMIN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    RNKO,
} from "../../../constants/roles";

import {
    contractorAdditionalDocumentsAggregationSelector,
} from "../../../ducks/bff/contractor-сard/personal-data/selectors";
import {currentUserRestrictionsSelector} from "../../../ducks/clientUserRestrictions";
import {
    contractorActionProgressSelector,
    contractorMigrationStatusDictSelector,
    contractorVhiPolicyFormatsDictSelector,
    contractorVhiPolicyTypesDictSelector,
} from "../../../ducks/contractor";
import {getPassportTypeDictSelector} from "../../../ducks/dict";

import "./style.sass";

const ContractorInfoPersonalNew = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const migrationStatusDict = useSelector(contractorMigrationStatusDictSelector);
    const vhiPolicyTypesDict = useSelector(contractorVhiPolicyTypesDictSelector);
    const vhiPolicyFormatsDict = useSelector(contractorVhiPolicyFormatsDictSelector);
    const passportTypeDict = useSelector(getPassportTypeDictSelector);
    const progressAction = useSelector(contractorActionProgressSelector);
    const currentUserRestrictions = useSelector(currentUserRestrictionsSelector);
    const additionalDocumentsAggregation = useSelector(contractorAdditionalDocumentsAggregationSelector);

    const [block, element] = bem("contractor-info-personal-new");
    const role = ls(USER_ROLE);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        contractor,
        isAdditionalDocuments,
        isContractorLoaded,
        fetchContractorInfo,
    } = useFetchContractorInfo({
        contractorId,
        role,
    });

    const {
        onOpenFullRegistration,
        fullRegistrationData,
        onCloseFullRegistration,
    } = useContractorFullRegistration({
    });

    const {
        innCard,
    } = useContractorInnCard({
        card: contractor,
    });

    const {
        card: individualEntrepreneurCard,
    } = useContractorIndividualEntrepreneurCard({
        contractor,
        onOpenModal,
        onCloseModal,
        fetchContractorInfo,
    });

    const {
        citizenship,
        age,
        registrationStatus,
        locationAddressInfo,
        lastLocationAnalysisDateTime,
    } = contractor;

    const isRu = contractor.citizenship === citizenshipsDict.RU.value;

    const getIsEditable = () => {
        const {
            archived,
        } = contractor;

        if (isClientUser(role) || archived) {
            return false;
        }

        return (
            !currentUserRestrictions.includes(CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractors)
            && ![NM_COORDINATOR, RNKO, NM_CHIEF_ACCOUNTANT].includes(role)
            && !isClientUser(role)
            && !archived
        );
    };

    const getIsVisibleParentDocumentScans = () => {
        if ((age < 14 || age >= 18)) {
            return false;
        }

        const successStatuses = [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_PASSED, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES, CONTRACTOR_STATUS.BANK_CHECK_PASSED];

        return ([ADMIN, NM_MANAGER].includes(role) || successStatuses.includes(registrationStatus));
    };

    const mainDocumentsList = getMainDocumentsCardsList({
        card: contractor,
        isVisibleParentDocumentScans: getIsVisibleParentDocumentScans(),
        migrationStatusDict,
        vhiPolicyTypesDict,
        vhiPolicyFormatsDict,
        passportTypeDict,
        onOpenFullRegistration,
        innCard,
        individualEntrepreneurCard,
        additionalDocumentsAggregation,
    });

    const renderFullRegistration = () => {
        if (!fullRegistrationData) {
            return null;
        }

        return (
            <ContractorFullRegistration
                contractorId={contractorId}
                onClose={onCloseFullRegistration}
                step={fullRegistrationData.step}
            />
        );
    };

    const renderConfirm = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={modalData.content}
                onCancel={onCloseModal}
                onConfirm={modalData.onConfirm}
                confirmButton={modalData.confirmButton}
                cancelButton={modalData.cancelButton}
                isNeedClosing={false}
                disabled={progressAction}
            />
        );
    };

    const renderIndividualEntrepreneurDataEditForm = () => {
        return (
            modalData?.isOpenIndividualEntrepreneurDataEditForm &&
            <ContractorIndividualEntrepreneurDataEditModal
                contractorId={contractorId}
                card={contractor}
                onClose={onCloseModal}
                fetchContractorInfo={fetchContractorInfo}
            />
        );
    };

    const renderIndividualEntrepreneurPaymentDataEditForm = () => {
        return (
            modalData?.isOpenIndividualEntrepreneurPaymentDataEditForm &&
            <PaymentDataInfoEditForm
                contractorId={contractorId}
                onClose={onCloseModal}
                isIndividualEntrepreneurForm={true}
                fetchContractorInfo={fetchContractorInfo}
            />
        );
    };

    return (
        !isContractorLoaded ?
            <NmLoader active /> :
            <div className={block()}>
                {renderConfirm()}
                {renderIndividualEntrepreneurPaymentDataEditForm()}
                {renderIndividualEntrepreneurDataEditForm()}
                {renderFullRegistration()}
                {
                    [
                        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
                        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
                    ].includes(contractor.registrationStatus) &&
                    [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) &&
                    <ContractorInfoPersonalDataChecking
                        className={element("info-block")}
                    />
                }
                {
                    isAdditionalDocuments &&
                    [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) &&
                    <ContractorInfoAdditionalDocumentsChecking className={element("info-block")} />
                }
                <ContractorInfoBlock className={element("info-block")}>
                    <ContactInfoCard
                        isEditable={getIsEditable()}
                    />
                    {
                        locationAddressInfo && !isClientUser(role) &&
                        <NmMiniInfoCard
                            className="mt-4"
                            title="Адрес местонахождения по геолокации Наймикс"
                            titleLevel="4"
                            lastUpdate={lastLocationAnalysisDateTime}
                            lastUpdateText="Последний запуск"
                        >
                            <ContractorInfoCardContent
                                labels={[
                                    {
                                        label: "Адрес",
                                        labelTooltip: "Адрес местонахождения заполняется системой автоматически на основании анализа массива точек геолокации, переданных с устройства исполнителя",
                                        tooltipPosition: "bottom-left",
                                        text: getDadataAddressObj(locationAddressInfo, true).text,
                                    },
                                ]}
                            />
                        </NmMiniInfoCard>
                    }
                    <PaymentDataInfoCard
                        className="mt-4"
                        isEditable={getIsEditable()}
                    />
                    {
                        mainDocumentsList.map((item) => {
                            return (
                                <MainDocumentInfoCard
                                    {...item}
                                    contractor={contractor}
                                    onOpenFullRegistration={onOpenFullRegistration}
                                    isEditable={getIsEditable()}
                                />
                            );
                        })
                    }
                    {
                        !isClientUser(role) &&
                        isRu &&
                        [STATUS_PASSPORT_INN.NOT_EXIST.VALUE].includes(contractor.innPassportDataValidStatus) &&
                        <ContractorOldPersonalData
                            className="mt-4"
                            isEditable={getIsEditable()}
                        />
                    }
                    <SnilsInfoCard
                        className="mt-4"
                        isEditable={getIsEditable()}
                    />
                    <EmploymentHistoryCard
                        className="mt-4"
                        isEditable={getIsEditable()}
                    />
                    {
                        (!citizenship || !isRu) &&
                        <MedicalPersonalInfoCard
                            className="mt-4"
                            isEditable={getIsEditable()}
                        />
                    }
                    <MedicalBookInfoCard
                        className="mt-4"
                        isEditable={getIsEditable()}
                    />
                    {
                        (!citizenship || !isRu) &&
                        <FingerprintingInfoCard
                            className="mt-4"
                            isEditable={getIsEditable()}
                        />
                    }
                    <CovidVaccinationInfoCard
                        className="mt-4"
                        isEditable={getIsEditable()}
                    />
                    {
                        (!citizenship || isRu) &&
                        <PreviouslyPassportInfoCard
                            className="mt-4"
                            isEditable={getIsEditable()}
                        />
                    }
                </ContractorInfoBlock>
            </div>
    );
};

export default ContractorInfoPersonalNew;