import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {MediaButtons} from "../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../components/ActualComponents/NmDateRangePickerV2";
import NmDivider from "../../components/ActualComponents/NmDivider";
import NmDropdownV2 from "../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../components/ActualComponents/NmForm";
import NmLabelText from "../../components/ActualComponents/NmLabelText";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import NmModal from "../../components/ActualComponents/NmModal";
import NmPagination from "../../components/ActualComponents/NmPagination";
import Text from "../../components/ActualComponents/Text";
import CheckboxList from "../../components/CheckboxList";
import NmButton from "../../components/NmButton";
import NmCheck from "../../components/NmCheck";
import NmTitle from "../../components/NmTitle";
import PhotoReport from "../../components/PhotoReport";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import {ReactComponent as СalendarIcon} from "../../images/calendar_today.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete_24.svg";
import {ReactComponent as EditIcon} from "../../images/mode.svg";
import {TimeSheetClientTimeEditModal} from "./components/client-time-edit-modal";
import {TimeSheetPayTimeEditModal} from "./components/pay-time-edit-modal";
import TimeSheetNewModal from "./time-sheet-new-modal";

import bem from "../../utils/bem";
import dateFormat, {
    compareDatesWithoutTime,
    convertMinutesToHours,
    formatLocalDate,
    getBeginningWeekAndEndWeekByCurrentDate,
    getEndDate,
    getStartDate,
} from "../../utils/dateFormat";
import {
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../utils/localstorage";
import {
    isNullOrWhitespace,
} from "../../utils/stringHelper";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../constants/color";
import {WORK_LOG_TYPE} from "../../constants/contractorInfo";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../constants/roles";
import {STATUS} from "../../constants/status";

import {
    clearOrderContractorWorkLogStore,
    deleteOrderContractorWorkInfo,
    deleteOrderContractorWorkInfoClientTime,
    getPageOrderContractorWorkLog,
    removeOrderContractorWorkLogCommonInfoPayTime,
} from "../../ducks/bff/orders/contractor-work-log/actionCreators";
import {
    orderContractorWorkLogListSelector,
    orderContractorWorkLogSelectorInfoSelector,
    orderContractorWorkLogSelectorProgressActionSelector,
    orderContractorWorkLogSelectorProgressSelector,
    orderContractorWorkLogTotalCountSelector,
    orderContractorWorkLogTotalPagesSelector,
} from "../../ducks/bff/orders/contractor-work-log/selectors";
import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {
    contractorProgressApplicationsSelector,
    contractorsApplicationsOptionsSelector,
    contractorsTimeSheetSelector,
    getContractorsByOrder,
    updateFieldContractorStore,
} from "../../ducks/contractor";
import {
    workLogProgressExpiredSelector,
} from "../../ducks/contractorWorkLog";
import {orderCardSelector} from "../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

import {SUB_PAGE_ORDER_CONTRACTOR} from "../../constants/link-params";

class TimeSheet extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        clientId: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        contractorId: PropTypes.string.isRequired,
        contractorStatus: PropTypes.string,
        checkInRequired: PropTypes.bool,
        checkOutRequired: PropTypes.bool,
        isOpenOrder: PropTypes.bool,
        needFetchContractors: PropTypes.bool,
    };

    static defaultProps = {
        needFetchContractors: true,
    };

    constructor(props) {
        super(props);

        const {
            monday,
            sunday,
        } = getBeginningWeekAndEndWeekByCurrentDate();
        const {
            contractorId,
            contractorFullName,
            contractorStatus,
            order: {
                workStartDate,
                workEndDate,
            },
        } = props;

        const _workStartDate = getStartDate(workStartDate);
        const _workEndDate = getEndDate(workEndDate);

        this.state = {
            contractorId,
            contractorStatus,
            startDateFilter: monday > _workEndDate ? _workStartDate : monday,
            endDateFilter: sunday > _workEndDate ? _workEndDate : sunday,
            workLog: {},
            isOpenPhotoReport: false,
            dataPhotoReport: {},
            isOpenFormWorkLog: false,
            includingDataForm: {},
            clientTimeData: {},
            confirmData: {},
            pageNum: 1,
        };

        const [block, element] = bem("time-sheet");

        this.block = block;
        this.element = element;

        this.role = ls(USER_ROLE);
        this.isAccessAdd = [
            ADMIN,
            NM_MANAGER,
            CLIENT_ADMIN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            FOREMAN,
            CLIENT_ACCOUNTANT,
        ].includes(this.role);
        this.isAccessEdit = [
            ADMIN,
            NM_MANAGER,
            CLIENT_ADMIN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            FOREMAN,
        ].includes(this.role);
        this.currentUserId = ls(CURRENT_CLIENT_USER_ID);
        this.currentClientId = ls(CURRENT_CLIENT_ID);
        this.contractorInitialOption = {
            key: contractorId,
            value: contractorId,
            text: contractorFullName,
        };
    }

    componentDidMount() {
        const {needFetchContractors} = this.props;

        if (needFetchContractors) {
            this.fetchContractors();
        }

        this.fetchList();
    }

    componentWillUnmount() {
        const {
            updateFieldContractorStore,
            clearOrderContractorWorkLogStore,
        } = this.props;

        clearOrderContractorWorkLogStore({workLog: []});
        updateFieldContractorStore({timeSheetContractors: []});
    }

    fetchContractors = () => {
        const {
            getContractorsByOrder,
            orderId,
            clientId,
        } = this.props;

        getContractorsByOrder({
            clientId,
            orderId,
            tabType: SUB_PAGE_ORDER_CONTRACTOR.HIRED.TAB_TYPE,
            pageNum: 1,
            pageSize: 100,
            nameField: "timeSheetContractors",
        });
    };

    fetchList = () => {
        const {
            getPageOrderContractorWorkLog,
            clientId,
            orderId,
        } = this.props;
        const {
            startDateFilter,
            endDateFilter,
            contractorId,
            pageNum,
        } = this.state;

        getPageOrderContractorWorkLog({
            clientId,
            orderId: orderId,
            contractorId: contractorId,
            pageNum,
            pageSize: 10,
            startDateFilter: isNullOrWhitespace(startDateFilter) ? "" : getStartDate(new Date(startDateFilter)),
            endDateFilter: isNullOrWhitespace(endDateFilter) ? "" : getEndDate(new Date(endDateFilter)),
        });
    };

    handleOnChangeFilter = (event, {value, name}) => {
        if (name === "contractorId") {
            const {contractors} = this.props;

            const contractorInfo = contractors.find(item => (item.contractorId === value)) || {};

            const {contractorStatus, contractorId} = contractorInfo;

            this.setState({
                [name]: value,
                contractorStatus,
                contractorId,
            }, this.fetchList);

            return;
        }

        this.setState({
            [name]: value,
        }, this.fetchList);
    };

    deleteWorkLog = (contractorWorkLogId) => {
        const {
            deleteOrderContractorWorkInfo,
            clientId,
            orderId,
        } = this.props;
        const {contractorId} = this.state;

        deleteOrderContractorWorkInfo({
            contractorWorkLogId,
            clientId,
            orderId,
            contractorId,
            onSuccess: () => {
                this.toggleConfirm();
                this.fetchList();
            },
        });
    };

    deletePayTime = () => {
        const {
            removeOrderContractorWorkLogCommonInfoPayTime,
            clientId,
            orderId,
        } = this.props;
        const {contractorId} = this.state;

        removeOrderContractorWorkLogCommonInfoPayTime({
            clientId,
            orderId,
            contractorId,
            onSuccess: () => {
                this.toggleConfirm();
                this.fetchList();
            },
        });
    };

    deleteWorkLogClientTime = (contractorWorkLogId, type) => {
        const {
            clientId,
            orderId,
            deleteOrderContractorWorkInfoClientTime,
        } = this.props;
        const {contractorId} = this.state;

        deleteOrderContractorWorkInfoClientTime({
            clientId,
            orderId,
            contractorId,
            contractorWorkLogId,
            type,
            onSuccess: () => {
                this.toggleConfirm();
                this.fetchList();
            },
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    openFormWorkLog = (event, data = {}) => {
        const {currentMember: {canEditContractorWorkLog}} = this.props;

        if ([CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog) {
            return;
        }

        this.setState({
            isOpenFormWorkLog: true,
            includingDataForm: {...data},
        });
    };

    toggleOpenPayTimeEditModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenPayTimeEditModal: !prevState.isOpenPayTimeEditModal,
        }));
    };

    toggleOpenClientTimeEditModal = (data) => {
        this.setState(prevState => ({
            ...prevState,
            isOpenClientTimeEditModal: !prevState.isOpenClientTimeEditModal,
            clientTimeData: prevState.isOpenClientTimeEditModal && data
                ? {}
                : data,
        }));
    };

    closeFormWorkLog = () => {
        this.setState({
            isOpenFormWorkLog: false,
        });
    };

    toggleConfirm = (confirmData) => {
        this.setState(prevState => ({
            ...prevState,
            confirmData: confirmData ? confirmData : {},
            isOpenConfirm: !prevState.isOpenConfirm,
        }));
    };

    openPhotoReport = (dataPhotoReport) => {
        this.setState({
            isOpenPhotoReport: true,
            dataPhotoReport,
        });
    };

    closePhotoReport = () => {
        this.setState({
            isOpenPhotoReport: false,
            dataPhotoReport: {},
        });
    };

    getDataByWorkLogType = ({type, item}) => {
        if (type === WORK_LOG_TYPE.IN) {
            return {
                datetime: item.inLocalDatetime,
                clientDatetime: item.clientInLocalDatetime,
                clientDatetimeUserName: item.clientInRegClientUserName,
                clientRegDatetime: item.clientInRegDatetime,
                regClientUserClientId: item.inRegClientUserClientId,
                fio: item.fio,
                photoInfo: item.inPhotoInfo,
                longitude: item.inLongitude,
                latitude: item.inLatitude,
                distanceToObject: item.inDistanceToObject,
            };
        }

        return {
            datetime: item.outLocalDatetime,
            clientDatetime: item.clientOutLocalDatetime,
            clientDatetimeUserName: item.clientOutRegClientUserName,
            clientRegDatetime: item.clientOutRegDatetime,
            regClientUserClientId: item.outRegClientUserClientId,
            fio: item.fio,
            photoInfo: item.outPhotoInfo,
            longitude: item.outLongitude,
            latitude: item.outLatitude,
            distanceToObject: item.outDistanceToObject,
        };
    };

    renderWorkLogBlockInfo = (params) => {
        const {
            type,
            label,
            required,
            item,
        } = params;

        const {
            datetime,
            clientDatetime,
            clientDatetimeUserName,
            clientRegDatetime,
            fio,
            photoInfo,
            longitude,
            latitude,
            distanceToObject,
            regClientUserClientId,
        } = this.getDataByWorkLogType({
            type,
            item,
        });

        const clientDatetimeFormat = (
            datetime
            && clientDatetime
            && !compareDatesWithoutTime(datetime, clientDatetime)
        )
            ? "dd.MM.yyyy HH:mm"
            : "HH:mm";

        return (
            <div className="flex-column col-16 col-md-7">
                <NmCheck
                    blockClassName="mb-2"
                    isCheck={true}
                    isNotPresent={!required}
                    labelProps={{
                        level: "3",
                    }}
                    label={label}
                    additionalText={!required && "(Фиксация не требуется)"}
                />
                {
                    required &&
                    <>
                        <div className="d-flex align-items-center mb-2">
                            <СalendarIcon
                                className="me-2"
                                width={18}
                                height={18}
                                color={COLOR.SECONDARY_45}
                            />
                            <Text level="2">
                                {dateFormat(datetime || clientDatetime, "dd.MM")}
                            </Text>
                        </div>
                        <NmLabelText
                            className="mb-2"
                            label="Время исполнителя"
                            text={
                                datetime
                                    ? <div className="d-flex">
                                        {dateFormat(datetime, "HH:mm")}
                                        {
                                            (
                                                photoInfo
                                                || distanceToObject
                                            ) &&
                                            <div
                                                className="flex pointer"
                                                onClick={() => {
                                                    this.openPhotoReport({
                                                        fio,
                                                        link: photoInfo,
                                                        longitude,
                                                        latitude,
                                                        distanceToObject,
                                                    });
                                                }}
                                            >
                                                {
                                                    photoInfo &&
                                                    <Text
                                                        className="ms-2"
                                                        underline={true}
                                                        color={COLOR.PASSIVE_100}
                                                    >
                                                        Фото
                                                    </Text>
                                                }
                                                {
                                                    !regClientUserClientId &&
                                                    <Text
                                                        className="ms-2"
                                                        underline={true}
                                                        color={COLOR.PASSIVE_100}
                                                    >
                                                        {`До объекта ${distanceToObject || 0}м`}
                                                    </Text>
                                                }
                                            </div>
                                        }
                                    </div>
                                    : "-"
                            }
                        />
                        <div>
                            <NmLabelText
                                className="mb-2"
                                label="Время заказчика"
                                text={
                                    clientDatetime
                                        ? dateFormat(clientDatetime, clientDatetimeFormat)
                                        : "-"
                                }
                            />
                            {
                                clientDatetime &&
                                <Text
                                    level="2"
                                    className="mb-2"
                                    color={COLOR.SECONDARY_45}
                                >
                                    {"от "}
                                    {clientDatetimeUserName || clientDatetimeUserName}
                                    {" "}
                                    {formatLocalDate(clientRegDatetime, "dd.MM HH:mm")}
                                </Text>
                            }
                            <MediaButtons
                                className="ms-auto"
                                inline={true}
                                config={{
                                    size: "sm",
                                    renderCount: {
                                        desktop: 3,
                                        tablet: 3,
                                        mobile: 3,
                                    },
                                    buttons: [
                                        {
                                            component: COMPONENT.BUTTON,
                                            props: {
                                                onClick: () => this.toggleOpenClientTimeEditModal({
                                                    type,
                                                    clientDatetime,
                                                    contractorWorkLogId: item.contractorWorkLogId,
                                                }),
                                                color: "grey",
                                                children: "Добавить время заказчика",
                                            },
                                            visible: !clientDatetime && this.isAccessAdd,
                                        },
                                        {
                                            component: COMPONENT.BUTTON,
                                            props: {
                                                onClick: () => this.toggleOpenClientTimeEditModal({
                                                    type,
                                                    clientDatetime,
                                                    contractorWorkLogId: item.contractorWorkLogId,
                                                }),
                                                onlyIcon: true,
                                                color: "grey",
                                                icon: <EditIcon />,
                                            },
                                            visible: Boolean(clientDatetime) && this.isAccessEdit,
                                        },
                                        {
                                            component: COMPONENT.BUTTON,
                                            props: {
                                                onClick: () => this.toggleConfirm({
                                                    content: "Вы действительно хотите удалить время заказчика из журнала?",
                                                    onConfirm: () => this.deleteWorkLogClientTime(item.contractorWorkLogId, type),
                                                }),
                                                onlyIcon: true,
                                                color: "grey",
                                                icon: <DeleteIcon />,
                                            },
                                            visible: Boolean(clientDatetime) && this.isAccessEdit,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </>
                }
            </div>
        );
    };

    getRows = () => {
        const {
            list,
            checkInRequired,
            checkOutRequired,
        } = this.props;

        return list.map(item => {
            const {
                inLocalDatetime,
                clientInLocalDatetime,
                workTimeMinutes,
                contractorWorkLogId,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        primaryHeader={`Запись от ${dateFormat(inLocalDatetime || clientInLocalDatetime, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {label: "Время на объекте", text: convertMinutesToHours(workTimeMinutes, "ч.", "мин.")},
                        ]}
                        otherContent={
                            <div className="d-flex flex-column-reverse flex-md-row mt-4 col-16">
                                {this.renderWorkLogBlockInfo({
                                    type: WORK_LOG_TYPE.IN,
                                    label: "Прибытие",
                                    required: checkInRequired,
                                    item,
                                })}
                                <NmDivider
                                    className="mt-4 mb-4 mt-md-0 mb-md-0 ms-md-4 me-md-4"
                                    color={COLOR.SECONDARY_5}
                                    vertical="md"
                                />
                                {this.renderWorkLogBlockInfo({
                                    type: WORK_LOG_TYPE.OUT,
                                    label: "Уход",
                                    required: checkOutRequired,
                                    item,
                                })}
                            </div>
                        }
                        isFixedActions={true}
                        mediaControls={{
                            renderCount: {
                                mobile: 0,
                                tablet: 1,
                                desktop: 1,
                            },
                            className: "ms-1",
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        onClick: () => this.toggleConfirm({
                                            content: "Вы действительно хотите удалить запись?",
                                            onConfirm: () => this.deleteWorkLog(contractorWorkLogId),
                                        }),
                                        color: "grey",
                                        onlyIcon: true,
                                        icon: (
                                            <DeleteIcon />
                                        ),
                                    },
                                    asset: {
                                        mobile: {
                                            children: "Удалить",
                                        },
                                    },
                                    visible: this.isAccessEdit,
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    renderHeader() {
        const {
            options,
            checkOutRequired,
            checkInRequired,
            includingData: {
                totalMinutes,
                payTimeMinutes,
                payTimeMinutesRegClientUserName,
                payTimeMinutesRegAt,
            },
            progressWorkLog,
            progressContractors,
            t,
        } = this.props;
        const {
            contractorId,
            startDateFilter,
            endDateFilter,
        } = this.state;

        return (
            <div className="row gx-4">
                <div className="row gx-0 gx-md-4 gy-4 gy-md-0 col-16 col-xl-9">
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            label={t("time-sheet.full-name")}
                            disabled={progressContractors}
                            size="xl"
                            options={options}
                            initialOption={this.contractorInitialOption}
                            value={contractorId}
                            name="contractorId"
                            onChange={this.handleOnChangeFilter}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmDateRangePickerV2
                            size="xl"
                            disabled={progressWorkLog || progressContractors}
                            label={t("time-sheet.period")}
                            onChange={this.handleOnChangeFilter}
                            startFieldName="startDateFilter"
                            endFieldName="endDateFilter"
                            dateFormat="dd.MM.yy"
                            value={{
                                startDateFilter,
                                endDateFilter,
                            }}
                            isCurrentDateMax
                        />
                    </div>
                </div>
                {
                    checkInRequired &&
                    checkOutRequired &&
                    <div className="d-flex flex-column flex-md-row col-16 col-xl-7 mt-5">
                        <div className="d-flex flex-column col-16 col-xl-5 col-md-3">
                            <Text
                                level="3"
                            >
                                Время по заказу
                            </Text>
                            <Text
                                level="4"
                                bold={true}
                            >
                                {isNullOrWhitespace(totalMinutes) ? "0 ч" : convertMinutesToHours(totalMinutes)}
                            </Text>
                        </div>
                        <NmDivider
                            className="mt-4 mb-4 mt-md-0 mb-md-0 ms-md-4 me-md-4"
                            color={COLOR.SECONDARY_5}
                            vertical="md"
                        />
                        <div className="d-flex flex-column col-16 col-md-5 col-xl-9 ms-md-2">
                            <div className="d-flex col-16">
                                <div className="d-flex flex-column col-10">
                                    <Text
                                        level="3"
                                    >
                                        Время для оплаты
                                    </Text>
                                    <Text
                                        level="4"
                                        bold={true}
                                    >
                                        {
                                            isNullOrWhitespace(payTimeMinutes)
                                                ? "0 ч"
                                                : convertMinutesToHours(payTimeMinutes)
                                        }
                                    </Text>
                                </div>
                                <MediaButtons
                                    className="ms-auto"
                                    inline={true}
                                    config={{
                                        size: "lg",
                                        renderCount: {
                                            desktop: 2,
                                            tablet: 2,
                                            mobile: 0,
                                        },
                                        buttons: [
                                            {
                                                component: COMPONENT.BUTTON,
                                                props: {
                                                    onClick: this.toggleOpenPayTimeEditModal,
                                                    onlyIcon: true,
                                                    color: "grey",
                                                    icon: <EditIcon />,
                                                },
                                                asset: {
                                                    mobile: {
                                                        children: "Редактировать",
                                                    },
                                                },
                                                visible: this.isAccessEdit,
                                            },
                                            {
                                                component: COMPONENT.BUTTON,
                                                props: {
                                                    onClick: () => this.toggleConfirm({
                                                        content: "Вы действительно хотите удалить время для оплаты?",
                                                        onConfirm: this.deletePayTime,
                                                    }),
                                                    onlyIcon: true,
                                                    color: "grey",
                                                    icon: <DeleteIcon />,
                                                },
                                                asset: {
                                                    mobile: {
                                                        children: "Удалить",
                                                    },
                                                },
                                                visible: Boolean(payTimeMinutes) && this.isAccessEdit,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                            {
                                Boolean(payTimeMinutes) &&
                                <Text
                                    level="2"
                                    color={COLOR.SECONDARY_45}
                                >
                                    {"от "}
                                    {payTimeMinutesRegClientUserName}
                                    {" "}
                                    {formatLocalDate(payTimeMinutesRegAt, "dd.MM HH:mm")}
                                </Text>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderAddTimeSheetRecord(t) {
        const {
            isOpenOrder,
            currentMember: {
                canEditContractorWorkLog,
            },
        } = this.props;
        const {contractorStatus} = this.state;

        return (
            (
                ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role) &&
                ![STATUS.BLOCKED, STATUS.COMPLETED].includes(contractorStatus) &&
                isOpenOrder
            ) &&
            <NmButton
                className="col-16 col-md-auto mt-4"
                color="light-green"
                size="xl"
                disabled={[CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role) && !canEditContractorWorkLog}
                onClick={this.openFormWorkLog}
                icon={
                    <AddIcon />
                }
                alwaysDisplayText={true}
            >
                {t("time-sheet.add-note")}
            </NmButton>
        );
    }

    renderConfirm = () => {
        const {
            progressExpired,
            progressAction,
        } = this.props;
        const {confirmData} = this.state;

        return (
            <NmConfirmV2
                disabled={progressExpired || progressAction}
                isNeedClosing={false}
                onCancel={this.toggleConfirm}
                onConfirm={confirmData.onConfirm}
                content={confirmData.content}
                confirmButton="Продолжить"
                cancelButton="Отменить"
            />
        );
    };

    render() {
        const {
            onClose,
            checkOutRequired,
            checkInRequired,
            clientId,
            orderId,
            includingData,
            progressWorkLog,
            progressContractors,
            totalPages,
            totalCount,
            t,
        } = this.props;
        const {
            contractorId,
            isOpenPhotoReport,
            dataPhotoReport,
            isOpenFormWorkLog,
            includingDataForm,
            isOpenPayTimeEditModal,
            isOpenClientTimeEditModal,
            isOpenConfirm,
            clientTimeData,
            pageNum,
        } = this.state;
        const {
            objectLongitude,
            objectLatitude,
        } = includingData;

        if (isOpenPhotoReport) {
            return (
                <PhotoReport
                    data={{
                        ...dataPhotoReport,
                        objectLongitude,
                        objectLatitude,
                    }}
                    onClose={this.closePhotoReport}
                />
            );
        }

        if (isOpenFormWorkLog) {
            return (
                <TimeSheetNewModal
                    data={{
                        contractorId,
                        clientId,
                        orderId,
                        ...includingDataForm,
                        checkOutRequired,
                        checkInRequired,
                    }}
                    checkinData={{
                        checkOutRequired,
                        checkInRequired,
                    }}
                    onClose={this.closeFormWorkLog}
                />
            );
        }

        if (isOpenFormWorkLog) {
            return (
                <TimeSheetNewModal
                    data={{
                        contractorId,
                        clientId,
                        orderId,
                        ...includingDataForm,
                        checkOutRequired,
                        checkInRequired,
                    }}
                    checkinData={{
                        checkOutRequired,
                        checkInRequired,
                    }}
                    onClose={this.closeFormWorkLog}
                />
            );
        }

        if (isOpenPayTimeEditModal) {
            return (
                <TimeSheetPayTimeEditModal
                    data={{
                        contractorId,
                        clientId,
                        orderId,
                        ...includingData,
                    }}
                    isEdit={Boolean(includingData.payTimeMinutes)}
                    onClose={this.toggleOpenPayTimeEditModal}
                    fetchInfo={this.fetchList}
                />
            );
        }

        if (isOpenClientTimeEditModal) {
            return (
                <TimeSheetClientTimeEditModal
                    data={{
                        contractorId,
                        clientId,
                        orderId,
                        ...clientTimeData,
                    }}
                    isEdit={Boolean(includingData.payTimeMinutes)}
                    onClose={this.toggleOpenClientTimeEditModal}
                    fetchInfo={this.fetchList}
                />
            );
        }

        if (isOpenConfirm) {
            return this.renderConfirm();
        }

        return (
            <NmModal
                size="lg"
                onClose={onClose}
                className="time-sheet"
                header={
                    <NmTitle size="lg">
                        Журнал учета времени
                    </NmTitle>
                }
                loading={progressWorkLog || progressContractors}
            >
                <NmForm>
                    {this.renderHeader()}
                    <div className="time-sheet__content">
                        {this.renderAddTimeSheetRecord(t)}
                        {
                            <CheckboxList
                                className="mt-5"
                                rows={this.getRows()}
                            />
                        }
                        <NmPagination
                            className="mt-2 mt-md-5 mt-xxl-7"
                            totalCount={totalCount}
                            pageNum={pageNum}
                            totalPages={totalPages}
                            onChangePagination={this.handlePaginationChange}
                        />
                    </div>
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        options: contractorsApplicationsOptionsSelector(state),
        contractors: contractorsTimeSheetSelector(state),
        list: orderContractorWorkLogListSelector(state),
        totalCount: orderContractorWorkLogTotalCountSelector(state),
        totalPages: orderContractorWorkLogTotalPagesSelector(state),
        includingData: orderContractorWorkLogSelectorInfoSelector(state),
        progressAction: orderContractorWorkLogSelectorProgressActionSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        progressWorkLog: orderContractorWorkLogSelectorProgressSelector(state),
        progressContractors: contractorProgressApplicationsSelector(state),
        progressExpired: workLogProgressExpiredSelector(state),
        order: orderCardSelector(state),
    }),
    {
        getPageOrderContractorWorkLog,
        deleteOrderContractorWorkInfo,
        getContractorsByOrder,
        clearOrderContractorWorkLogStore,
        updateFieldContractorStore,
        removeOrderContractorWorkLogCommonInfoPayTime,
        deleteOrderContractorWorkInfoClientTime,
    },
)(withTranslation()(TimeSheet));
