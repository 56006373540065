import React from "react";
import {
    useDispatch,
    useSelector,
} from "react-redux";

import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmModalCardList from "../../../../../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../../../../../components/ActualComponents/NmPagination";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import OrderActRegistryCardEditPayment from "../edit-payment";

import {usePagination} from "../../../../../../hooks/usePagination";
import useDataForm from "./hooks/useFormData";

import {getFullName, phoneFormat} from "../../../../../../utils/stringFormat";

import {PHONE_MASK} from "../../../../../../constants/phone";
import {
    ACT_REGISTRY_CONTRACTOR_SEARCH_TYPE_OPTIONS,
    CONTRACTOR_SEARCH_TYPE,
} from "../../../../../../constants/registry";

import {
    checkForbiddenContractor,
    contractorProgressSelector,
    contractorSearchListSelector,
    contractorTotalCountSelector,
    contractorTotalPagesSelector,
} from "../../../../../../ducks/contractor";

export const ACT_REGISTRY_SEARCH_STEP = {
    STEP_1: "STEP_1",
    STEP_2: "STEP_2",
    STEP_3: "STEP_3",
};

function ActRegistryAddContractorForm(props) {
    const {
        clientId,
        registryId,
        fetchList,
        onClose,
        editPaymentData,
    } = props;

    const list = useSelector(contractorSearchListSelector);
    const totalPages = useSelector(contractorTotalPagesSelector);
    const totalCount = useSelector(contractorTotalCountSelector);
    const progressSearch = useSelector(contractorProgressSelector);

    const {
        pageNum,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        setFieldTouched,
        selectContractor,
        step,
        onSetStep,
        contractorData,
        prevStep,
    } = useDataForm({
        clientId,
        list,
        setPagination,
        totalCount,
        progressSearch,
        pageNum,
        onClose,
    });

    const {
        searchType,
        phoneNumber,
        fullName,
        inn,
    } = values;

    const dispatch = useDispatch();

    const getCards = () => {
        return list.map(item => {
            return {
                ...item,
                singleValues: [
                    {
                        label: "ФИО",
                        value: item.lastName ? getFullName(item.lastName, item.firstName, item.patronymic) : item.fullName,
                    },
                    {label: "Телефон", value: phoneFormat(item.phone)},
                    {label: "ИНН", value: item.inn},
                ],
                actions: (
                    <NmButton
                        color="light-green"
                        className="registry-found-contractors__pick"
                        onClick={() => {
                            dispatch(checkForbiddenContractor({
                                clientId,
                                contractorId: item.contractorId,
                                onSuccess: () => {
                                    selectContractor(item);
                                },
                            }));
                        }}
                    >
                        Выбрать
                    </NmButton>
                )
                ,
            };
        });
    };

    const contractorListForm = () => {
        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Добавление исполнителя
                    </NmTitle>
                }
                footer={
                    <div className="flex flex-content-spaced fluid-flex-grow">
                        <NmButton
                            color="grey"
                            size="xl"
                            onClick={() => {
                                onSetStep(ACT_REGISTRY_SEARCH_STEP.STEP_1);
                            }}
                        >
                            Назад
                        </NmButton>
                        <NmButton
                            color="grey"
                            size="xl"
                            onClick={onClose}
                        >
                            Отменить
                        </NmButton>
                    </div>
                }
                onClose={onClose}
            >
                <NmForm>
                    <NmModalCardList
                        list={getCards()}
                        pagination={
                            <NmPagination
                                pageNum={pageNum}
                                totalPages={totalPages}
                                onChangePagination={onPaginationChange}
                            />
                        }
                    />
                </NmForm>
            </NmModal>
        );
    };

    const renderSearchTypeInput = () => {
        if (searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE) {
            return (
                <NmInputV2
                    size="xl"
                    className="ms-3"
                    placeholder="Телефон"
                    mask={PHONE_MASK.FOREIGN}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("phoneNumber", true)}
                    maskChar={null}
                    name="phoneNumber"
                    value={phoneNumber}
                    error={touched?.phoneNumber && errors.phoneNumber}
                />
            );
        }

        if (searchType === CONTRACTOR_SEARCH_TYPE.FULL_NAME.VALUE) {
            return (
                <NmInputV2
                    size="xl"
                    className="ms-3"
                    placeholder="ФИО"
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("fullName", true)}
                    maskChar={null}
                    name="fullName"
                    value={fullName}
                    error={touched?.fullName && errors?.fullName}
                />
            );
        }

        return (
            <NmInputV2
                size="xl"
                className="ms-3"
                placeholder="ИНН"
                maskChar={null}
                onChange={handleChange}
                onBlur={() => setFieldTouched("inn", true)}
                name="inn"
                value={inn}
                error={touched?.inn && errors?.inn}
            />
        );
        
    };


    const renderSearchForm = () => {
        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                      Поиск исполнителя
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        isHiddenCancelOnMobile
                        mobile="column"
                        loading={progressSearch}
                        onClose={onClose}
                        submit={handleSubmit}
                        cancelBtnContent="Отменить"
                        submitBtnContent="Подтвердить"
                    />
                }
                onClose={onClose}
            >
                <NmForm
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-content-spaced fluid-flex-grow">
                        <NmDropdownV2
                            size="xl"
                            name="searchType"
                            value={searchType}
                            onChange={(e, {name, value}) => setFieldValue(name, value)}
                            options={ACT_REGISTRY_CONTRACTOR_SEARCH_TYPE_OPTIONS}
                        />
                        {renderSearchTypeInput()}
                    </div>
                </NmForm>
            </NmModal>
        );
    };

    const renderModalByStep = () => {
        switch (step) {
            case ACT_REGISTRY_SEARCH_STEP.STEP_1: {
                return renderSearchForm();
            }
            case ACT_REGISTRY_SEARCH_STEP.STEP_2: {
                return contractorListForm();
            }
            case ACT_REGISTRY_SEARCH_STEP.STEP_3: {
                return (
                    <OrderActRegistryCardEditPayment
                        clientId={clientId}
                        registryId={registryId}
                        fetchList={fetchList}
                        prevStep={prevStep}
                        onSetStep={onSetStep}
                        onClose={onClose}
                        contractorData={contractorData}
                        editData={editPaymentData}
                    />
                );
            }
            // no default
        }

        return null;
    };

    return renderModalByStep();
}

export default ActRegistryAddContractorForm;