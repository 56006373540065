import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {convertUtcToLocal, getEndDate, getStartDate} from "../../../../../utils/dateFormat";
import {handleNumber} from "../../../../../utils/stringHelper";

import {getRegistryPaymentStatuses} from "../../../../../ducks/registryPaymentStatuses";

export const useContractorFinanceIncomeFetch = (params) => {
    const {
        contractorId,
        pageNum,
        pageSize,
        filter,
        fetchList,
        clearStore,
        isCrowd,
        isIndividual,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        onFetchList();
        onFetchAmountData();
    }, [pageNum, pageSize, filter]);

    useEffect(() => {
        dispatch(getRegistryPaymentStatuses({
            isIndividual,
        }));

        return () => {
            dispatch(clearStore({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    const onFetchAmountData = () => {
        // Ожидание доработка Лени
        // dispatch(getContractorInProgressNpdFinancePayments({
        //     fromDate: filter.fromDateFilter,
        //     toDate: filter.toDateFilter,
        //     contractorId,
        // }));
    };

    const onFetchList = () => {
        const _filter = isCrowd ? {
            clientIds: !isEmpty(filter.clientId) ? [filter.clientId] : undefined,
            statuses: !isEmpty(filter.statusFilter) ? [filter.statusFilter] : undefined,
            operationDateFrom: convertUtcToLocal(getStartDate(filter.operationDateFrom)),
            operationDateTo: convertUtcToLocal(getEndDate(filter.operationDateTo)),
            paymentNumber: handleNumber(filter.paymentNumber),
            taskNumber: handleNumber(filter.taskNumber),
            amountFrom: handleNumber(filter.amountFrom),
            amountTo: handleNumber(filter.amountTo),
            commissionFrom: handleNumber(filter.commissionFrom),
            commissionTo: handleNumber(filter.commissionTo),
        } : {
            ...filter,
            paymentStatusFilter: !isEmpty(filter.paymentStatusFilter) ? [filter.paymentStatusFilter] : undefined,
        };

        dispatch(fetchList({
            ..._filter,
            pageSize,
            pageNum,
            contractorId,
        }));
    };

    return {
        onFetchList,
    };
};