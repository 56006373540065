import {all, put, takeEvery} from "redux-saga/effects";

import {
    ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS,
    ADD_CONTRACTOR_EDUCATION_ERROR,
    ADD_CONTRACTOR_EDUCATION_REQUEST,
    ADD_CONTRACTOR_EDUCATION_SUCCESS,
    ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR,
    ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
    ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS,
    ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR,
    ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
    ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR,
    ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
    BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_ERROR,
    BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_REQUEST,
    BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_SUCCESS,
    DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR,
    DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS,
    DELETE_CONTRACTOR_EDUCATION_ERROR,
    DELETE_CONTRACTOR_EDUCATION_REQUEST,
    DELETE_CONTRACTOR_EDUCATION_SUCCESS,
    DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR,
    DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
    DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS,
    DELETE_CONTRACTOR_PORTFOLIO_ERROR,
    DELETE_CONTRACTOR_PORTFOLIO_REQUEST,
    DELETE_CONTRACTOR_PORTFOLIO_SUCCESS,
    DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR,
    DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
    GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR,
    GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST,
    GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS,
    GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR,
    GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST,
    GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS,
    GET_CONTRACTOR_PORTFOLIO_FILE_ERROR,
    GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST,
    GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS,
    GET_CONTRACTOR_PROFILE_ERROR,
    GET_CONTRACTOR_PROFILE_REQUEST,
    GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR,
    GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST,
    GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS,
    GET_CONTRACTOR_PROFILE_SUCCESS,
    GET_DRIVER_LICENSE_DICTIONARY_ERROR,
    GET_DRIVER_LICENSE_DICTIONARY_REQUEST,
    GET_DRIVER_LICENSE_DICTIONARY_SUCCESS,
    GET_EDUCATION_LEVEL_DICT_ERROR,
    GET_EDUCATION_LEVEL_DICT_REQUEST,
    GET_EDUCATION_LEVEL_DICT_SUCCESS,
    MERGE_CONTRACTOR_PROF_SKILLS_ERROR,
    MERGE_CONTRACTOR_PROF_SKILLS_REQUEST,
    MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS,
    UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR,
    UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST,
    UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS,
    UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR,
    UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST,
    UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS,
    UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR,
    UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST,
    UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS,
    UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR,
    UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST,
    UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS,
    UPDATE_CONTRACTOR_EDUCATION_ERROR,
    UPDATE_CONTRACTOR_EDUCATION_REQUEST,
    UPDATE_CONTRACTOR_EDUCATION_SUCCESS,
    UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR,
    UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST,
    UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS,
    UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR,
    UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST,
    UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS,
    UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR,
    UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST,
    UPDATE_CONTRACTOR_MAIN_WORK_CITY_SUCCESS,
    UPDATE_CONTRACTOR_MAIN_WORK_ERROR,
    UPDATE_CONTRACTOR_MAIN_WORK_REQUEST,
    UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS,
    UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR,
    UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST,
    UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS,
    UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR,
    UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST,
    UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
} from "./actions";

import {blobToFile, downloadLocalFile} from "../../../../utils/downloadBlob";
import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const controllers = {
    client: "/client-adm/contractors/contractor-card/profile",
    admin: "/adm/contractors/contractors-registry/contractor-card/profile",
};

const getContractorProfile = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;

        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}/get`,
                adminRolesUrl: `${controllers.admin}/get`,
            },
        );

        const result = yield request.bff.get(url, {params});

        yield getResult();

        yield put({type: GET_CONTRACTOR_PROFILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PROFILE_ERROR, payload: error});
    }
};

const addContractorWorkExperience = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/work-experience/add`, data);

        yield getResult();

        yield put({type: ADD_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const updateContractorWorkExperience = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.put(`${controllers.admin}/work-experience/update`, data);

        yield getResult();

        yield put({type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const deleteContractorWorkExperience = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.delete(`${controllers.admin}/work-experience/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR, payload: error});
    }
};

const deleteAllContractorWorkExperience = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;
        const result = yield request.bff.delete(`${controllers.admin}/work-experience/delete/all`, {params});

        yield getResult();

        yield put({
            type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_ERROR,
            payload: error,
        });
    }
};

const getContractorEducationLevelDict = function* () {
    try {
        const result = yield request.bff.get("/adm/common/dicts/contractor-education-level");

        yield put({
            type: GET_EDUCATION_LEVEL_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EDUCATION_LEVEL_DICT_ERROR,
            payload: error,
        });
    }
};

const addContractorEducation = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/education/add`, data);

        yield getResult();

        yield put({type: ADD_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

const updateContractorEducation = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.put(`${controllers.admin}/education/update`, data);

        yield getResult();

        yield put({type: UPDATE_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

const deleteContractorEducation = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.delete(`${controllers.admin}/education/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_EDUCATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_EDUCATION_ERROR, payload: error});
    }
};

const getDriverLicenseDictionary = function* () {
    try {
        const result = yield request.bff.get("/adm/common/dicts/driver-license");

        yield put({type: GET_DRIVER_LICENSE_DICTIONARY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_DRIVER_LICENSE_DICTIONARY_ERROR, payload: error});
    }
};

const updateContractorDriverLicense = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/driver-licence/update
`, data);

        yield getResult(result.errorMessage);

        yield put({type: UPDATE_CONTRACTOR_DRIVER_LICENSE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_DRIVER_LICENSE_ERROR, payload: error});
    }
};

const getContractorLanguagesDictionary = function* () {
    try {
        const result = yield request.bff.get("/adm/common/dicts/contractor-languages");

        yield put({type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_LANGUAGES_DICTIONARY_ERROR, payload: error});
    }
};

const updateContractorForeignLanguage = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/foreign-language/update`, data);

        yield getResult(result.errorMessage);

        yield put({type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR, payload: error});
    }
};

const deleteContractorForeignLanguage = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/foreign-language/delete`, data);

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_FOREIGN_LANGUAGE_ERROR, payload: error});
    }
};

const addContractorCourseCertificate = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/course-certificate/add`, data);

        yield getResult(result);

        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

const updateContractorCourseCertificate = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.put(`${controllers.admin}/course-certificate/update`, data);

        yield getResult({
            ...result,
            courseCertificateId: data.courseCertificateId,
        });

        yield put({type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

const addContractorCourseCertificateFile = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/course-certificate-file/add`, formData, {...getMultipartHeaders()});

        yield getResult(result.errorMessage);

        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR, payload: error});
    }
};

const deleteContractorCourseCertificate = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.delete(`${controllers.admin}/course-certificate/delete`, {data});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_COURSE_CERTIFICATE_ERROR, payload: error});
    }
};

const getContractorCourseCertificateFile = function* ({payload}) {
    try {
        const {
            getResult,
            fileName,
            certificateId,
            contractorId,
        } = payload;
        const result = yield request.getFile(`${window.location.origin}/api/filestore/contractorFiles/course/certificate/get?certificateId=${certificateId}&contractorId=${contractorId}`);

        const blob = yield result.blob();

        yield getResult(blobToFile(blob, fileName));

        yield put({type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_ERROR, payload: error});
    }
};

const updateContractorFamilyStatus = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/family-status/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_FAMILY_STATUS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_FAMILY_STATUS_ERROR, payload: error});
    }
};

const mergeContractorProfessionalSkills = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.put(`${controllers.admin}/key-skill/update`, data);

        yield getResult(result);

        yield put({type: MERGE_CONTRACTOR_PROF_SKILLS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: MERGE_CONTRACTOR_PROF_SKILLS_ERROR, payload: error});
    }
};

const addContractorPortfolioFile = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/portfolio/add`, formData, {...getMultipartHeaders()});

        yield getResult(result);

        yield put({type: ADD_CONTRACTOR_PORTFOLIO_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: ADD_CONTRACTOR_PORTFOLIO_FILE_ERROR, payload: error});
    }
};

const getContractorPortfolioFile = function* ({payload}) {
    try {
        const {
            getResult,
            fileName,
            portfolioId,
            contractorId,
        } = payload;
        const result = yield request.getFile(`${window.location.origin}/api/filestore/contractorFiles/portfolio/get?portfolioId=${portfolioId}&contractorId=${contractorId}`);

        const blob = yield result.blob();

        yield getResult(blobToFile(blob, fileName));

        yield put({type: GET_CONTRACTOR_PORTFOLIO_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PORTFOLIO_FILE_ERROR, payload: error});
    }
};

const deleteContractorPortfolio = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;
        const result = yield request.bff.delete(`${controllers.admin}/portfolio/delete`, {params});

        yield getResult();

        yield put({type: DELETE_CONTRACTOR_PORTFOLIO_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_CONTRACTOR_PORTFOLIO_ERROR, payload: error});
    }
};

const updateContractorWorkContraindication = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/work-contraindications/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_ERROR, payload: error});
    }
};

const updateContractorAdditionalSpecialities = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/additional-specialities/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_ADDITIONAL_WORK_ERROR, payload: error});
    }
};

const updateContractorMainSpeciality = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/main-speciality/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_ERROR, payload: error});
    }
};

const updateContractorMainWorkAddress = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const result = yield request.bff.post(`${controllers.admin}/work-address/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_MAIN_WORK_CITY_ERROR, payload: error});
    }
};

const updateDepartureAddresses = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${controllers.admin}/departure-address/update`, data);

        yield getResult(result);

        yield put({type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_ERROR, payload: error});
    }
};

const getContractorProfileResumeFile = function* ({payload}) {
    const {
        fileName,
        contractorId,
        isDownload = true,
        onSuccess,
    } = payload;

    try {
        const result = yield request.getFile(`${window.location.origin}/api/documents/getContractorResumeFile?contractorId=${contractorId}`);

        const blob = yield result.blob();

        if (isDownload) {
            yield downloadLocalFile(blob, fileName);
        }

        if (onSuccess) {
            onSuccess(blob);
        }

        yield put({type: GET_CONTRACTOR_PROFILE_RESUME_FILE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_CONTRACTOR_PROFILE_RESUME_FILE_ERROR, payload: error});
    }
};

const getForbiddenOrderClientListSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${controllers.admin}/forbidden-order-clients/get-list`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CONTRACTOR_PROFILE_REQUEST, getContractorProfile),
        takeEvery(ADD_CONTRACTOR_WORK_EXPERIENCE_REQUEST, addContractorWorkExperience),
        takeEvery(UPDATE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, updateContractorWorkExperience),
        takeEvery(DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, deleteContractorWorkExperience),
        takeEvery(ALL_DELETE_CONTRACTOR_WORK_EXPERIENCE_REQUEST, deleteAllContractorWorkExperience),
        takeEvery(GET_EDUCATION_LEVEL_DICT_REQUEST, getContractorEducationLevelDict),
        takeEvery(ADD_CONTRACTOR_EDUCATION_REQUEST, addContractorEducation),
        takeEvery(UPDATE_CONTRACTOR_EDUCATION_REQUEST, updateContractorEducation),
        takeEvery(DELETE_CONTRACTOR_EDUCATION_REQUEST, deleteContractorEducation),
        takeEvery(GET_DRIVER_LICENSE_DICTIONARY_REQUEST, getDriverLicenseDictionary),
        takeEvery(UPDATE_CONTRACTOR_DRIVER_LICENSE_REQUEST, updateContractorDriverLicense),
        takeEvery(GET_CONTRACTOR_LANGUAGES_DICTIONARY_REQUEST, getContractorLanguagesDictionary),
        takeEvery(UPDATE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST, updateContractorForeignLanguage),
        takeEvery(DELETE_CONTRACTOR_FOREIGN_LANGUAGE_REQUEST, deleteContractorForeignLanguage),
        takeEvery(ADD_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, addContractorCourseCertificate),
        takeEvery(UPDATE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, updateContractorCourseCertificate),
        takeEvery(ADD_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST, addContractorCourseCertificateFile),
        takeEvery(DELETE_CONTRACTOR_COURSE_CERTIFICATE_REQUEST, deleteContractorCourseCertificate),
        takeEvery(GET_CONTRACTOR_COURSE_CERTIFICATE_FILE_REQUEST, getContractorCourseCertificateFile),
        takeEvery(UPDATE_CONTRACTOR_FAMILY_STATUS_REQUEST, updateContractorFamilyStatus),
        takeEvery(MERGE_CONTRACTOR_PROF_SKILLS_REQUEST, mergeContractorProfessionalSkills),
        takeEvery(ADD_CONTRACTOR_PORTFOLIO_FILE_REQUEST, addContractorPortfolioFile),
        takeEvery(GET_CONTRACTOR_PORTFOLIO_FILE_REQUEST, getContractorPortfolioFile),
        takeEvery(DELETE_CONTRACTOR_PORTFOLIO_REQUEST, deleteContractorPortfolio),
        takeEvery(UPDATE_CONTRACTOR_WORK_CONTRAINDICATION_REQUEST, updateContractorWorkContraindication),
        takeEvery(UPDATE_CONTRACTOR_ADDITIONAL_WORK_REQUEST, updateContractorAdditionalSpecialities),
        takeEvery(UPDATE_CONTRACTOR_MAIN_WORK_REQUEST, updateContractorMainSpeciality),
        takeEvery(UPDATE_CONTRACTOR_DEPARTURE_ADDRESSES_REQUEST, updateDepartureAddresses),
        takeEvery(UPDATE_CONTRACTOR_MAIN_WORK_CITY_REQUEST, updateContractorMainWorkAddress),
        takeEvery(GET_CONTRACTOR_PROFILE_RESUME_FILE_REQUEST, getContractorProfileResumeFile),
        takeEvery(BFF_GET_FORBIDDEN_ORDER_CLIENT_LIST_REQUEST, getForbiddenOrderClientListSaga),
    ]);
}