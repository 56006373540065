import {all, put, select, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {getRegistryPayments} from "./actionCreators";
import {
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

import {
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
} from "../../../../constants/links";
import {REGISTRY_TOAST_TEXT} from "../../../../constants/registry";

const getController = () => {
    return getBffUrl({
        [LINK_FINANCE_REGISTRY_PAYMENTS_ROWS]: "/adm/finances/registry-payments/common-registry-cards",
        isClientCard: true,
        clientRolesUrl: "/client-adm/finances/registry-payments/common-registry-cards",
        adminRolesUrl: "/adm/clients/client-card/finances/registry-payments/common-registry-cards",
    });
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/get-registry-payment-page
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/get-registry-payment-page
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/get-registry-payment-page
// Получение общего списка реестровых выплат
const getRegistryPaymentsSaga = function* ({payload}) {
    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/get-registry-payment-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/get-thumbnail-list
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/get-thumbnail-list
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/get-thumbnail-list
// Получение списка маленьких миниатюр аватара исполнителя в формате base64
const getRegistryPaymentsThumbnailList = function* ({payload}) {
    const {
        contractorIds,
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/get-thumbnail-list`, contractorIds);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/update
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/update
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/update
// Редактировать
const updateRegistryPayment = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/update`, reqData);
        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const state = yield select();

        yield put(getRegistryPayments(state.bff.registryPayments.pageData));

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}
// GET /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}
// GET /bff/client-adm/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}
// Получение информации о реестре для редактирования
const getRegistryPaymentCard = function* ({payload}) {
    const {
        clientId,
        registryId,
        registrySeqNum,
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.get(`${url}/${clientId}/${registryId}/${registrySeqNum}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}/cancel
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}/cancel
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/{clientId}/{registryId}/{registrySeqNum}/cancel
// Отменить платёж
const cancelRegistryPayment = function* ({payload}) {
    const {
        clientId,
        registryId,
        registrySeqNum,
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/${clientId}/${registryId}/${registrySeqNum}/cancel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const state = yield select();

        yield put(getRegistryPayments(state.bff.registryPayments.pageData));

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/refresh-bank-cache
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/refresh-bank-cache
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/refresh-bank-cache
// Обновить платежные данные
const refreshRegistryPaymentBankCache = function* ({payload}) {
    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/refresh-bank-cache`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/pay-payment
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/pay-payment
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/pay-payment
// Повторить платёж
const payRegistryPayment = function* ({payload}) {
    const {
        handleError,
        clientId,
        registryId,
        registrySeqNum,
        ...data
    } = payload;

    const url = `${getController()}/${clientId}/${registryId}/${registrySeqNum}/pay-payment`;

    try {
        const result = yield request.bff.post(url, data);
        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            handleError(result);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.PAY_SUCCESS);

        const state = yield select();

        yield put(getRegistryPayments(state.bff.registryPayments.pageData));

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR,
            payload: error,
        });
    }
};

export let findRegistryPaymentBankCheckListCancelToken = axios.CancelToken.source();

// POST /bff/adm/finances/registry-payments/common-registry-cards/find-bank-check-list
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/find-bank-check-list
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/find-bank-check-list
const findRegistryPaymentBankCheckList = function* ({payload}) {
    const {
        onRequest,
        contractorIds,
        clientId,
    } = payload;

    if (onRequest) {
        onRequest();
    }

    findRegistryPaymentBankCheckListCancelToken = axios.CancelToken.source();

    const url = getController();

    try {
        const result = yield request.bff.axios({
            method: "POST",
            url: `${url}/find-bank-check-list`,
            data: {contractorIds, clientId},
            cancelToken: findRegistryPaymentBankCheckListCancelToken.token,
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        //handleResponse(data);

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_ERROR,
        });
    }
};

// GET /bff/adm/finances/registry-payments/common-registry-cards/is-frame-contract-signed
// GET /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/is-frame-contract-signed
// GET /bff/client-adm/finances/registry-payments/common-registry-cards/is-frame-contract-signed
// Получение информации о подписании рамочного договора с исполнителем
const isRegistryPaymentFrameContractSigned = function* ({payload}) {
    const {
        clientId,
        contractorId,
        onSuccess,
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.get(`${url}/is-frame-contract-signed`, {params: {clientId, contractorId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_ERROR,
        });
    }
};

// GET /bff/adm/finances/registry-payments/common-registry-cards/contractor/{contractorId}
// GET /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/contractor/{contractorId}
// GET /bff/client-adm/finances/registry-payments/common-registry-cards/contractor/{contractorId}
// Получение информации об исполнителе
const getRegistryPaymentContractor = function* ({payload}) {
    const {
        contractorId,
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.get(`${url}/contractor/${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR,
        });
    }
};

// POST /bff/adm/finances/registry-payments/common-registry-cards/contractors/search
// POST /bff/adm/clients/client-card/finances/registry-payments/common-registry-cards/contractors/search
// POST /bff/client-adm/finances/registry-payments/common-registry-cards/contractors/search
// Получение информации об исполнителе
const searchRegistryPaymentContractors = function* ({payload}) {
    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/contractors/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST, getRegistryPaymentsSaga),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST, getRegistryPaymentsThumbnailList),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST, updateRegistryPayment),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST, cancelRegistryPayment),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST, refreshRegistryPaymentBankCache),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST, payRegistryPayment),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST, findRegistryPaymentBankCheckList),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST, getRegistryPaymentCard),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST, isRegistryPaymentFrameContractSigned),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST, getRegistryPaymentContractor),
        takeEvery(BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST, searchRegistryPaymentContractors),
    ]);
}