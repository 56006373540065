import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import NmTitle from "../../../../components/NmTitle";
import {DEPOSIT_SUB_PAGE} from "../../../deposit/client-list";
import ClientCardFinancesRoute from "./Route";

import {filterDynamicLinks} from "../../../../utils/clientHelper";
import {
    CURRENT_CLIENT_ID,
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

import {
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_FINANCES_REGISTRY,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LinkNavigator,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {getClientCardProperties} from "../../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

const ClientCardFinances = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const role = ls(USER_ROLE);
    const clientIdForQuery = clientId || ls(CURRENT_CLIENT_ID);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        registryPaymentsAvailable,
        civilRegistryPaymentsAvailable,
        individualRegistryPaymentsAvailable = false,
        insuranceAvailable,
    } = useSelector(clientCardPropertiesSelector);

    useEffect(() => {
        dispatch(getClientCardProperties({clientId:clientIdForQuery}));
    }, []);

    const getTabLinks = () => {
        const financePaymentListLink = LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId);
        const financeTaskPaymentListLink = LINK_CLIENT_PAYMENTS_TASKS_LIST.replace(":clientId", clientId).replace(":paymentNumberFilter?", "");
        const financeRegistryPaymentListLink = LINK_CLIENT_REGISTRY_PAYMENTS_LIST.replace(":clientId", clientId).replace(":archived", "false").replace("/:paymentNumberFilter?", "");
        const deposit = LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);
        const civilDeposit = LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);

        const links = [
            {
                id: "deposit",
                name: "Депозит (НПД)",
                link: deposit,
                active: [
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING),
                    LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT),
                    LinkNavigator.client.finance.depositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                ],
            },
            {
                id: "civilDeposit",
                name: "Депозит (НДФЛ)",
                link: civilDeposit,
                active: [
                    LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
                    LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS),
                    LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS),
                    LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.PAYMENTS_PENDING),
                    LinkNavigator.client.finance.civilDepositRegistryOrders
                        .replace(":clientId", clientId)
                        .replace(":subPage", DEPOSIT_SUB_PAGE.SPENT_LIMIT)
                        .replace(":subTab", DEPOSIT_SUB_PAGE.REGISTRY_ORDERS),
                ],
            },
            {
                link: financePaymentListLink,
                active: [
                    financePaymentListLink,
                    financeTaskPaymentListLink,
                ],
                name: "Выплаты исполнителям (НПД)",
            },
            {
                active: LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId),
                link: LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId),
                name: "Выплаты исполнителям (НДФЛ)",
            },
            {
                active: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId),
                link: LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId),
                name: "Выплаты исполнителям (ИП)",
                isVisible: individualRegistryPaymentsAvailable,
            },
            {
                id: "registryPayments",
                active: LINK_CLIENT_FINANCES_REGISTRY.replace(":clientId", clientId),
                link: financeRegistryPaymentListLink,
                name: t("finance-payment-common.registry-list"),
            },
            {
                id: "insurance",
                name: "Страхование",
                link: LINK_CLIENT_INSURANCE_HISTORY.replace(":clientId", clientId),
                active: LINK_CLIENT_INSURANCE_HISTORY.replace(":clientId", clientId),
            },
            {
                id: "cancelledChecks",
                name: "Аннулированные чеки",
                link: LINK_CLIENT_CANCELLED_CHECKS.replace(":clientId", clientId),
                active: LINK_CLIENT_CANCELLED_CHECKS.replace(":clientId", clientId),
            },
        ];

        return links.filter((item) => filterDynamicLinks({
            item,
            checks: {
                role,
                registryPaymentsAvailable,
                insuranceAvailable,
                civilRegistryPaymentsAvailable,
                individualRegistryPaymentsAvailable,
            },
        }));
    };

    return (
        <div className="client-card-finance">
            {
                ![CLIENT_ADMIN, CLIENT_ACCOUNTANT, OBJECT_MANAGER, PROJECT_MANAGER, FOREMAN].includes(role) &&
                <>
                    <NmTitle size="xl">
                        Финансы
                    </NmTitle>
                    <Tabs
                        {...props}
                        className="client-card-finance__tabs"
                        panes={getTabLinks()}
                        secondary
                    />
                </>
            }
            <ClientCardFinancesRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardFinances;
