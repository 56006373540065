import React from "react";
import {useSelector} from "react-redux";

import ContractorFinanceIncome from "../income";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS,
} from "../../../../constants/links";

import {
    contractorFinanceIncomeNpdListSelector,
    contractorFinanceIncomeNpdProgressSelector,
    contractorFinanceIncomeNpdTotalCountSelector,
    contractorFinanceIncomeNpdTotalPagesSelector,
    contractorSummaryFinanceIncomeNpdSelector,
    getSummaryContractorFinanceIncomeNpd,
    updateContractorFinanceIncomeNpdStore,
} from "../../../../ducks/contractorFinanceIncomeNpd";

export const ContractorFinanceIncomeNpd = (props) => {
    const list = useSelector(contractorFinanceIncomeNpdListSelector);
    const progress = useSelector(contractorFinanceIncomeNpdProgressSelector);
    const totalPages = useSelector(contractorFinanceIncomeNpdTotalPagesSelector);
    const totalCount = useSelector(contractorFinanceIncomeNpdTotalCountSelector);

    return (
        <ContractorFinanceIncome
            tabsLinks={{
                awaiting: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
                completed: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
                inProcess: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
                registryPayments: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS,
            }}
            list={list}
            progress={progress}
            totalPages={totalPages}
            totalCount={totalCount}
            clearStore={updateContractorFinanceIncomeNpdStore}
            balanceSelector={contractorSummaryFinanceIncomeNpdSelector}
            fetchSummaryData={getSummaryContractorFinanceIncomeNpd}
            {...props}
        />
    );
};
