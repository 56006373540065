import React from "react";
import {Route, Switch} from "react-router";

import Tabs from "../../components/ActualComponents/Tabs";
import {CheckRoleRoute} from "../../components/CheckRoleRoute";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import {FinanceRegistryPayments} from "./payments";
import RegistryList from "./registry-list";

export const FinanceRegistries = (props) => {
    const {
        clientId,
    } = props.match.params;

    const registryPath = `${props.match.path}/:archived`;
    const registryPaymentsPath = `${props.match.path}/payments`;
    const registryPaymentsLink = `${registryPaymentsPath}`
        .replace(":clientId", clientId)
        .replace(":archived", "false");
    const registryPaymentsListLink = `${props.match.path}/:archived`
        .replace(":clientId", clientId)
        .replace(":archived", "false")
        .replace("/:paymentNumberFilter?", "");

    return (
        <NmPage
            noPadding={Boolean(clientId)}
            className="instruction"
            header={
                <NmPageHeader text="Реестровые выплаты" />
            }
            subHeader={
                <Tabs
                    panes={[
                        {
                            active: registryPaymentsListLink,
                            exceptions: [
                                registryPaymentsLink,
                            ],
                            link: registryPaymentsListLink,
                            name: "Реестры оплат",
                        },
                        {
                            active: registryPaymentsLink,
                            link: registryPaymentsLink,
                            name: "Строки реестров",
                        },
                    ]}
                />
            }
        >
            <Switch>
                <CheckRoleRoute
                    exact
                    path={registryPaymentsPath}
                    component={FinanceRegistryPayments}
                />
                <Route
                    exact
                    path={registryPath}
                    component={RegistryList}
                />
            </Switch>
        </NmPage>
    );
};