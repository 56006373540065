export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST = "BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS";
export const BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ERROR = "BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ERROR";

export const BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE = "BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE";

export const BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE = "BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE";