import React from "react";
import copy from "copy-to-clipboard";

import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as LinkCopyIcon} from "../../../../../images/link_copy_24.svg";

import {toastSuccess} from "../../../../../utils/toastHelper";

import {COLOR} from "../../../../../constants/color";
import {LINK_TELEGRAM_BOT} from "../../../../../constants/links";

export const NotificationsSubscribesDescription = (props) => {
    const {
        onClose,
    } = props;

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Ссылка на Telegram-бот
                </NmTitle>
            }
            onClose={onClose}
            classNameContent="flex-column"
            footer={
                <ApplyButtons
                    submit={onClose}
                    submitBtnContent="Закрыть"
                />
            }
        >
            <Text
                level="3"
                color={COLOR.SECONDARY_100}
                className="mb-4"
            >
                Скопируйте ссылку, чтобы отправить на устройство, где установлен Telegram, или отсканируйте QR-код с
                мобильного устройства
            </Text>
            <div className="d-flex align-items-end">
                <a
                    title="Перейти в telegram"
                    rel="noopener noreferrer"
                    href={LINK_TELEGRAM_BOT}
                    target="_blank"
                >
                    <Text
                        level="3"
                        color={COLOR.PASSIVE_100}
                        isCursorPointer={true}
                    >
                        {LINK_TELEGRAM_BOT}
                    </Text>
                </a>
                <LinkCopyIcon
                    title="Скопировать ссылку"
                    style={{cursor: "pointer"}}
                    color={COLOR.PASSIVE_100}
                    width={24}
                    height={24}
                    className="ms-2"
                    onClick={() => {
                        copy(LINK_TELEGRAM_BOT);
                        toastSuccess("Ссылка скопирована");
                    }}
                    ё
                />
            </div>
            <div className="d-flex justify-content-center">
                <img
                    width="200"
                    height="200"
                    alt="qr-code"
                    src="/images/qr-telegram-bot.png"
                    className="mt-6"
                />
            </div>
        </NmModal>
    );
};