import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmMiniInfoCard from "../../../../components/ActualComponents/NmMiniInfoCard";
import Text from "../../../../components/ActualComponents/Text";
import NmBadge from "../../../../components/NmBadge";
import ContractorWorkCitiesEditModal from "./components/contractor-work-cities-edit-modal";

import {updateDepartureAddresses} from "../../../../ducks/bff/contractor-сard/profile/actionCreators";
import {
    contractorDepartureAddressesInfoListSelector,
    contractorDepartureAddressesInfoSelector,
} from "../../../../ducks/bff/contractor-сard/profile/selectors";

export const WORK_CITIES_OPTION = {
    EDIT: "EDIT",
    REMOVE: "REMOVE",
};

export const OPTIONS = [
    {
        key: WORK_CITIES_OPTION.EDIT,
        text: "Редактировать",
        value: WORK_CITIES_OPTION.EDIT,
    },
    {
        key: WORK_CITIES_OPTION.REMOVE,
        text: "Удалить",
        value: WORK_CITIES_OPTION.REMOVE,
    },
];

export default function ContractorWorkCities(props) {
    const {
        className,
        getProfile,
        contractorId,
        accessEdit,
    } = props;

    const dispatch = useDispatch();

    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [confirmData, setConfirmData] = useState({});

    const {onConfirm, confirmContent} = confirmData;

    const list = useSelector(contractorDepartureAddressesInfoListSelector);
    const {departureAddressesPresent} = useSelector(contractorDepartureAddressesInfoSelector);

    const onClickAction = ({value}, item) => {
        if (value === WORK_CITIES_OPTION.EDIT) {
            toggleEditModal({
                departureAddressesFiasIds: isEmpty(list) ? [] : list.map(({key}) => key),
                initialOptions: isEmpty(list) ? [] : list.map(({key, text}) => ({key, value: key, text})),
                hasDepartureAddresses: !departureAddressesPresent,
            });
        }

        if (value === WORK_CITIES_OPTION.REMOVE) {
            setConfirmData({
                onConfirm: handleDelete,
                confirmContent: "Вы уверены что хотите удалить сведения?",
            });
        }
    };

    const handleDelete = () => {
        dispatch(updateDepartureAddresses({
            contractorId,
            departureAddressesFiasIds: [],
            hasDepartureAddresses: true,
            getResult: () => {
                getProfile();
                handleCloseConfirm();
            },
        }));
    };

    const handleCloseConfirm = () => {
        setConfirmData({});
    };

    const toggleEditModal = (item = {}) => {
        setEditData(item);
        setIsShowEditModal(prevState => !prevState);
    };

    function mapContent() {
        if (isEmpty(list)) {
            return <Text level="1">
                Сведения пока не добавлены
            </Text>;
        }

        return (
            <div className="d-flex flex-wrap">
                {list.map(item => {
                    const {key} = item;
                    
                    return (
                        <NmBadge
                            key={key}
                            maxContent
                            className="ms-xl-1 mt-xl-1"
                            mod="gray"
                            text={item.text}
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <>
            {
                isShowEditModal &&
                <ContractorWorkCitiesEditModal
                    onClose={() => toggleEditModal()}
                    getProfile={getProfile}
                    editData={editData}
                    contractorId={contractorId}
                />
            }
            {
                !isEmpty(confirmData) &&
                <NmConfirmV2
                    onCancel={handleCloseConfirm}
                    onConfirm={onConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    content={confirmContent}
                    size="sm"
                />
            }
            <NmMiniInfoCard
                accessEdit={accessEdit}
                className={className}
                title="Города для выезда"
                onClickAdd={isEmpty(list) ? () => onClickAction({value: WORK_CITIES_OPTION.EDIT}) : null}
                options={!isEmpty(list) ? OPTIONS : null}
                onClickItem={onClickAction}
            >
                {mapContent()}
            </NmMiniInfoCard>
        </>
    );
}