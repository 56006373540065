import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../../components/ActualComponents/Text";
import {AppContext} from "../../../../../components/AppContext";
import Avatar from "../../../../../components/Avatar";
import ContractorNoteForClientTooltip from "../../../../../components/ContractorNoteForClientTooltip";
import ExtLink from "../../../../../components/ExtLink";
import LocatedOutsideRussiaTooltip from "../../../../../components/LocatedOutsideRussiaTooltip";
import RegistryPaymentError from "../../../../../components/RegistryPaymentError";
import SelfEmployedIndicator from "../../../../../components/SelfEmployedIndicator";
import {ReactComponent as BlockedIcon} from "../../../../../images/lock_24.svg";
import {ReactComponent as NoOnIcon} from "../../../../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../../images/yes_on.svg";
import RegistryPaymentDocumentTablesModal from "../../../registry-card/document-tables-modal";
import {AddContractorPayment} from "../add-contractor-payment";
import {EditRegistryPayment} from "../edit";

import {useRegistryPaymentsAction} from "../../hooks/useAction";

import dateFormat from "../../../../../utils/dateFormat";
import {getFcStatusForRegistryItem} from "../../../../../utils/fcRegistries";
import {formatAmountWithNullChecking, phoneFormat} from "../../../../../utils/stringFormat";
import {getRegistryContractorName, isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {getRegistryPaymentStatus} from "../../../registry-card/utils/getStatus";

import {COLOR} from "../../../../../constants/color";
import {
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../../../constants/finance";
import {
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST, LINK_CLIENT_INFO,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST, LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
} from "../../../../../constants/links";
import {REGISTRY_PAYMENTS_STATUS_DICT} from "../../../../../constants/registry";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {
    bffFinancesRegistryPaymentsProgressActionSelector,
    bffFinancesRegistryPaymentsUserAvatarDictSelector,
} from "../../../../../ducks/bff/finances/registry-payments/selectors";
import {registryPaymentStatusesDictSelector} from "../../../../../ducks/registryPaymentStatuses";

export const RegistriesPaymentsListCard = (props) => {
    const {
        uniqKey,
        item,
        isHide,
        isClientCard,
        isAllPayments,
        isCheckbox,
        isOpenFilter,
        isEmptyPayments,
    } = props;
    const {
        contractorLastName,
        contractorFirstName,
        contractorPatronymic,
        contractorFio,
        orderWorkStartDate,
        orderWorkEndDate,
        applicationDate,
        errors,
        orderContractPaymentType,
        documentTablesEnabled,
        registrySeqNum,
        status,
        blocked,
        blockedReason,
        contractorNoteForClient,
        contractFrameDate,
        actualFrameContract,
        contractNumber,
        frameSignDate,
        expirationDatetime,
        confirmationErrorDetails,
        message,
        civilContractAmount,
        orderAmountForPayment,
        orderAmount,
        paymentNumber,
        registryId,
        registryNumber,
        orderDescriptionFns,
        comment,
        commentForContractor,
        contractorPhone,
        workExecutionAddress,
        orderWorkDescription,
        orderDescription,
        contractorInn,
        smzTaxOffer,
        //hasPaymentMethodCard,
        individualEntrepreneurBankRequisitesPresent,
        locatedOutsideRussia,
        clientName,
        clientId,
        taxStatus,
        //contractorId,
        //validData,
        //number,
        //showCheckBox,
        //disabledCheckBox,
        //isSelected,
        //deletionStart,
    } = item;

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useContext(AppContext);

    const {
        getMediaControls,
    } = useRegistryPaymentsAction({
        clientId,
        isEmptyPayments,
        isAllPayments,
        uniqKey,
    });

    const progressAction = useSelector(bffFinancesRegistryPaymentsProgressActionSelector);
    const paymentStatuses = useSelector(registryPaymentStatusesDictSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const userAvatarDict = useSelector(bffFinancesRegistryPaymentsUserAvatarDictSelector);
    const primaryHeader = getRegistryContractorName(contractorLastName, contractorFirstName, contractorPatronymic, contractorFio);
    const date = !isNullOrWhitespace(applicationDate) ? dateFormat(applicationDate, "dd.MM.yyyy") : "-";
    const actDate = !isNullOrWhitespace(item.actDate) ? dateFormat(item.actDate, "dd.MM.yyyy") : "-";
    const isIndividualEntrepreneurRegistry = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

    const getShowMore = (content, title = "") => {
        return (
            <NmShowMoreText
                lines={1}
                more="Подробнее"
                anchor="blue"
                title={title}
            >
                {content || "-"}
            </NmShowMoreText>
        );
    };

    const getRDContent = () => {
        const msg = getFcStatusForRegistryItem(item);
        const isExternalDocument = Boolean(contractNumber);

        return (
            <div className="d-flex align-items-center">
                <NmAdvancedTooltip
                    trigger={
                        <div className="registry-card__fc-status">
                            {
                                actualFrameContract ?
                                    <YesOnIcon className="registry-card__fc-status-done" /> :
                                    <NoOnIcon className="registry-card__fc-status-error" />
                            }
                        </div>
                    }
                    hover
                >
                    {msg}
                </NmAdvancedTooltip>
                {
                    (contractFrameDate && !isExternalDocument) &&
                    <div className="ms-1">
                        {dateFormat(contractFrameDate, "dd.MM.yyyy")}
                    </div>
                }
                {
                    isExternalDocument &&
                    <div className="d-flex align-items-center ms-1">
                        <Text>
                            Внешний договор
                        </Text>
                        <HelpTooltip
                            className="ms-1"
                            hover={false}
                            height={18}
                            width={18}
                            text={
                                <div className="mt-1 mb-1">
                                    <div>
                                        Номер договора:
                                        {" "}
                                        {contractNumber}
                                    </div>
                                    <div className="mt-1">
                                        Дата договора:
                                        {" "}
                                        {dateFormat(expirationDatetime ? expirationDatetime : frameSignDate, "dd.MM.yyyy")}
                                    </div>
                                </div>
                            }
                            position="bottom-left"
                        />
                    </div>
                }
            </div>
        );
    };

    const getLabels = () => {
        const labelAddress = !isNullOrWhitespace(workExecutionAddress) ?
            [{label: "Адрес выполнения работ", columnOnMobile: true, text: getShowMore(workExecutionAddress)}] :
            [];
        const labelRD = `Договор ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]}`;
        const descriptionFNS = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ ? [
            {
                label: "Описание выполненных работ для чека ФНС",
                columnOnMobile: true,
                text: getShowMore(orderDescriptionFns),
            },
        ] : [];
        const taxOfferLabel = {
            label: "Автоматическая уплата налогов",
            text: (
                smzTaxOffer ?
                    <YesOnIcon
                        width={18}
                        height={18}
                        color={COLOR.PRIMARY_100}
                    /> :
                    <NoOnIcon
                        width={18}
                        height={18}
                        color={COLOR.NEGATIVE_100}
                    />
            ),
            alignItems: "center",
            noWrap: false,
        };

        const generalLabels = [
            !isClientCard && {
                label: "Компания",
                text: (
                    <ExtLink
                        title="Перейти в карточку компании"
                        to={LINK_CLIENT_INFO.replace(":clientId", item.clientId)}
                    >
                        {clientName}
                    </ExtLink>
                ),
            },
            {
                label: "Номер реестра оплат и позиция в реестре",
                columnOnMobile: true,
                text: (
                    <>
                        <ExtLink
                            className="me-1"
                            to={
                                LINK_CLIENT_REGISTRY_PAYMENTS_CARD
                                    .replace(":registryId", registryId)
                                    .replace(":archived", "false")
                                    .replace(":clientId", clientId)
                                    .replace("/:paymentNumberFilter?", "")
                            }
                        >
                            {`№${registryNumber}`}
                        </ExtLink>
                        {`строка № ${registrySeqNum}`}
                    </>
                ),
            },
            {
                label: "Телефон исполнителя",
                text: phoneFormat(contractorPhone, locatedOutsideRussia),
                classNameText: "flex align-items-center",
                textOverflowUnset: true,
                textTooltip: locatedOutsideRussia && <LocatedOutsideRussiaTooltip />,
            },
            contractorInn && {label: "ИНН исполнителя", text: contractorInn},
            {label: labelRD, text: getRDContent(), alignItems: "center", noWrap: false},
            orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ && {
                label: "Самозанятый",
                noWrap: false,
                alignItems: "center",
                text: (
                    <SelfEmployedIndicator
                        isHideText={true}
                        isClearStyles={true}
                        isAlignedTip={true}
                        taxStatus={taxStatus}
                        iconProps={{
                            width: 18,
                            height: 18,
                        }}
                    />
                ),
            },
            // {
            //     label: "Паспорт проверен",
            //     text: (
            //         <ContractorPassportStatus
            //             contractor={item}
            //             size="md"
            //             isOnlyIcon
            //         />
            //     ),
            //     alignItems: "center",
            //     noWrap: false,
            // },
            // !isIndividualEntrepreneurRegistry && {
            //     label: "Средство платежа",
            //     text: (
            //         <InstrumentPaymentIndicator
            //             value={hasPaymentMethodCard}
            //             size="md"
            //             showLabel={false}
            //         />
            //     ),
            //     alignItems: "center",
            //     noWrap: false,
            // },
            isIndividualEntrepreneurRegistry && {
                label: "Платежные данные",
                text: individualEntrepreneurBankRequisitesPresent ? "р/с ИП" : "-",
            },
            !isIndividualEntrepreneurRegistry && taxOfferLabel,
        ];

        if (isHide) {
            return generalLabels;
        }

        return [
            ...generalLabels,
            ...labelAddress,
            {
                label: "Описание работ",
                columnOnMobile: true,
                fluidText: true,
                text: getShowMore(orderWorkDescription),
            },
            {
                label: "Описание выполненных работ для акта",
                columnOnMobile: true,
                fluidText: true,
                text: getShowMore(orderDescription),
            },
            ...descriptionFNS,
            {label: "Комментарий", fluidText: true, text: getShowMore(comment)},
            {
                label: "Уведомление для исполнителя",
                fluidText: true,
                text: getShowMore(commentForContractor, "Уведомление для исполнителя"),
            },
            documentTablesEnabled && {
                label: "Таблицы работ",
                fluidText: true,
                text: (
                    <Link
                        className="registry-card__link-table-modal"
                        onClick={() => {
                            onOpenModal({
                                uniqKey,
                                isOpenTablesOfWork: true,
                                data: {
                                    registryNum: registrySeqNum,
                                    orderContractPaymentType,
                                    payment: item,
                                },
                            });
                        }}
                    >
                        Посмотреть таблицу
                    </Link>
                ),
            },
        ];
    };

    const getStatusMessage = () => {
        if (!message && status === REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE) {
            return REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.MESSAGE;
        }

        if (message && status === REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE) {
            return "";
        }

        return message;
    };

    const renderStatus = () => {
        if (!status) {
            return "-";
        }

        const message = getStatusMessage();
        const statusText = getRegistryPaymentStatus(paymentStatuses, status);

        return (
            <RegistryPaymentError
                color={REGISTRY_PAYMENTS_STATUS_DICT[status] && REGISTRY_PAYMENTS_STATUS_DICT[status].COLOR}
                statusText={statusText}
                error={confirmationErrorDetails || message}
            />
        );
    };

    const getPaymentLink = () => {
        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL) {
            return LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId);
        }

        if (orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
            return LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST.replace(":clientId", clientId);
        }

        return LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId);
    };

    const getPaymentNumber = () => {
        if (!paymentNumber) {
            return null;
        }

        const link = getPaymentLink();

        return (
            <ExtLink
                filterData={{
                    paymentNumber,
                }}
                isLoadDataTarget
                historyEnabled
                to={link}
            >
                {paymentNumber}
            </ExtLink>
        );
    };

    const getCards = () => {
        const {
            contractorIndividualRegistryPaymentsCommission,
        } = clientProperties;

        const amount = orderAmount || 0;
        const contractorCommission = amount * contractorIndividualRegistryPaymentsCommission;
        const rsAmount = amount - contractorCommission;

        // НДФЛ реестр
        const isNdfl = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL;

        const classNameCivilCards = isNdfl ? "col-16 col-md-5 mb-xxl-5" : "col-16 col-md-4 mb-xxl-5";
        const classNameCards = isNdfl || isIndividualEntrepreneurRegistry ?
            "col-16 col-md-5" :
            `${isOpenFilter ? "col-md-3" : "col-md-4"} col-16`;
        const dateClassNameCards = `${classNameCards} ${isIndividualEntrepreneurRegistry ? "mb-md-5" : ""}`;

        const cardSum = [
            !isNdfl && isIndividualEntrepreneurRegistry && {
                title: "Сумма по договору, ₽",
                value: formatAmountWithNullChecking(orderAmount, "-"),
                className: classNameCards,
            },
            !isNdfl && !isIndividualEntrepreneurRegistry && {
                title: "Сумма на карту, Р",
                value: formatAmountWithNullChecking(orderAmount, "-"),
                className: classNameCards,
            },
            isIndividualEntrepreneurRegistry && !isAllPayments && !isNdfl && {
                title: "Сумма на р/с, Р",
                value: orderAmount ? formatAmountWithNullChecking(rsAmount) : "-",
                className: classNameCards,
            },
        ];
        const dateCards = [
            {
                title: "Дата заявки",
                value: date,
                className: dateClassNameCards,
            },
            {
                title: "Дата акта",
                value: actDate,
                className: dateClassNameCards,
            },
        ];
        const statusCards = [
            {
                title: "Статус и номер оплаты",
                subTitle: renderStatus(),
                value: getPaymentNumber(),
                className: classNameCards,
                wrapValue: true,
            },
        ];
        const civilCards = [
            isNdfl && {
                title: "Сумма по договору, ₽",
                value: formatAmountWithNullChecking(civilContractAmount, "-"),
                boldValue: true,
                className: classNameCivilCards,
            },
            isNdfl && {
                title: "На руки исполнителю, ₽",
                value: formatAmountWithNullChecking(orderAmountForPayment, "-"),
                boldValue: true,
                className: classNameCivilCards,
            },
            !isAllPayments && {
                title: "На карту исполнителю, ₽",
                value: formatAmountWithNullChecking(orderAmount, "-"),
                boldValue: true,
                className: classNameCivilCards,
            },
        ];

        const cards = isIndividualEntrepreneurRegistry
            ? [
                ...cardSum,
                ...statusCards,
                ...dateCards,
            ]
            : [
                ...civilCards,
                ...dateCards,
                ...cardSum,
                ...statusCards,
            ];

        return cards.filter(Boolean);
    };

    const renderBlockedInfo = () => {
        return (
            <div className="d-flex align-items-center">
                {
                    blocked &&
                    <NmAdvancedTooltip
                        className="d-flex align-items-center pointer"
                        trigger={
                            <BlockedIcon
                                width={24}
                                height={24}
                                color="red"
                            />
                        }
                        hover
                    >
                        {blockedReason}
                    </NmAdvancedTooltip>
                }
                <ContractorNoteForClientTooltip
                    className="ms-2"
                    note={contractorNoteForClient}
                />
            </div>
        );
    };

    const getModals = () => {
        if (!modalData || modalData?.uniqKey !== uniqKey) {
            return null;
        }

        const {
            confirmData,
            isOpenContractorParamsEdit,
            isOpenSearchContractor,
            isOpenTablesOfWork,
        } = modalData;

        if (confirmData) {
            return (
                <NmConfirmV2
                    title={modalData.confirmData.confirmTitle}
                    content={modalData.confirmData.confirmText}
                    contentAlign={modalData.confirmData.contentAlign}
                    isNeedClosing={false}
                    onCancel={onCloseModal}
                    disabled={progressAction}
                    onConfirm={modalData.confirmData.onConfirm}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                />
            );
        }

        if (isOpenContractorParamsEdit) {
            return (
                <EditRegistryPayment
                    isEditRealContractor={modalData.isEditRealContractor}
                    contractorData={modalData.contractorData}
                    paymentParams={modalData.paymentParams}
                    onClose={onCloseModal}
                    isEdit={true}
                />
            );
        }

        if (isOpenTablesOfWork) {
            return (
                <RegistryPaymentDocumentTablesModal
                    data={modalData.data}
                    onClose={onCloseModal}
                />
            );
        }

        if (isOpenSearchContractor) {
            return (
                <AddContractorPayment
                    clientId={clientId}
                    onClose={onCloseModal}
                    modalData={modalData}
                />
            );
        }

        return null;
    };

    const startDate = dateFormat(orderWorkStartDate, "dd.MM.yyyy");
    const endDate = dateFormat(orderWorkEndDate, "dd.MM.yyyy");
    const cardsContainerClassName = `col-16 col-md-16 col-xl-15 ${isOpenFilter ? "col-xxl-15 mt-xxl-4" : "col-xxl-8"} mt-md-4 mt-xxl-0`;

    return (
        <React.Fragment>
            {getModals()}
            <NmListCard
                avatarComponent={
                    <Avatar
                        className="fluid"
                        addedToMyClientGroup={item.addedToMyClientGroup}
                        contractorId={item.contractorId}
                        base64={userAvatarDict[item.contractorId]}
                    />
                }
                checkbox={isCheckbox}
                alignItems="flex-end"
                errors={errors}
                classNameMainContent="col-16 col-xxl-7"
                secondaryHeader={`Период работ ${startDate}–${endDate}`}
                primaryHeader={primaryHeader}
                primaryHeaderTooltip={renderBlockedInfo()}
                labels={getLabels()}
                cards={getCards()}
                cardsWithContainer={true}
                cardsContainerRowClassName={isOpenFilter ? "justify-content-xxl-start" : ""}
                cardsContainerClassName={cardsContainerClassName}
                cardsWrapReverse={isIndividualEntrepreneurRegistry}
                actionsClassName="col-1"
                mediaControls={getMediaControls(item)}
            />
        </React.Fragment>
    );
};