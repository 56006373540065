import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {SSE_EVENT_TYPE} from "../../../../constants/sse";

import {
    getAllSubscribeNotifications,
    getMySubscribeNotifications,
} from "../../../../ducks/bff/notifications/subscribe/actionCreators";
import {sseEventDataSelector} from "../../../../ducks/serverSentEvents";

export const useNotificationsSubscribesFetch = (params) => {
    const {
        filterData = {},
        pageSize,
        pageNum,
        contractorId,
        isAllSubscriptions,
        clientId,
    } = params;

    const dispatch = useDispatch();

    const event = useSelector(sseEventDataSelector);

    useEffect(() => {
        if (event.eventType === SSE_EVENT_TYPE.UPDATE_SUBSCRIBE_NOTIFICATIONS) {
            fetchList();
        }
    }, [
        event.eventType,
    ]);

    useEffect(() => {
        fetchList();
    }, [
        pageSize,
        pageNum,
        filterData,
    ]);

    const fetchList = () => {
        const data = {
            pageNum,
            pageSize,
            clientId,
            contractorId,
            ...filterData,
        };

        if (isAllSubscriptions) {
            dispatch(getAllSubscribeNotifications(data));

            return;
        }

        dispatch(getMySubscribeNotifications(data));
    };

    return {
        fetchList,
    };
};