import React from "react";
import {isEmpty} from "lodash";

import {CitizenshipWithFlag} from "../components/CitizenshipWithFlag";
import {ReactComponent as BlockedIcon} from "../images/lock_24.svg";
import {formatDateForChart} from "./dateFormat";
import {pluralizeDays} from "./pluralize";
import {isNullOrWhitespace} from "./stringHelper";

import {CONTRACTOR_TYPE_BLOCKED} from "../constants/contractor";

import {FILTER_TYPE} from "../constants/ mailings/segmentationType";

export const dictionaryToOptions = (units, hasAllValue = false) => {
    const options = Object.keys(units).map(value => ({
        key: value,
        value,
        text: units[value],
    }));
    const allOption = {
        key: FILTER_TYPE.ALL,
        value: FILTER_TYPE.ALL,
        text: "Все",
    };
    return hasAllValue ? [allOption, ...options] : options;
};

export const simpleArrayToOptions = (units) => {
    return Object.keys(units).map(value => ({
        key: units[value],
        value: units[value],
        text: units[value],
    }));
};

export const edmPositionsToOptions = (positions) => {
    return positions.map(({positionId, value}) => ({
        key: positionId,
        value: positionId,
        text: value,
    }));
};

export const edmSubdivisionsToOptions = (positions) => {
    return positions.map(({subdivisionId, value}) => ({
        key: subdivisionId,
        value: subdivisionId,
        text: value,
    }));
};

export const edoDocumentStatusesToOptions = (documentStatuses, hasAllValue) => {
    const options = documentStatuses.map(({name, description}) => ({
        key: name,
        value: name,
        text: description,
    }));

    return hasAllValue ? [{
        key: "all",
        value: undefined,
        text: "Все",
    }, ...options] : options;
};

export const edoDocumentStatusesToDictionary = (documentStatuses) => {
    return documentStatuses.reduce((obj, {name, description}) => {
        return {
            ...obj,
            [name]: description,
        };
    }, {});
};

export const edoDocumentTypesToOptions = (documentTypes) => {
    return documentTypes.map(({id, value: text}) => ({
        key: id,
        value: id,
        text,
    }));
};

export const edoDocumentTypesToOptionsUploadEDO = (documentTypes) => {
    return documentTypes.map(({id, value: text, templateType}) => ({
        key: id,
        value: id,
        text,
        templateType,
    }));
};

export const edoStaffListToOptions = (documentTypes) => {
    return documentTypes.map(({staffId, fio: text, contractorBlockedCause, clientUserId}) => ({
        key: staffId,
        value: staffId,
        text,
        clientUserId,
        disabled: contractorBlockedCause === CONTRACTOR_TYPE_BLOCKED.SUSPICIOUS_ACTIVITY,
        icon: contractorBlockedCause && <BlockedIcon
            width="18px"
            height="18px"
            color="red"
        />,
    }));
};

export const edoStaffListByClientUserToOptions = (documentTypes) => {
    return documentTypes.map(({clientUserId, staffId, fio: text}) => ({
        key: staffId,
        value: clientUserId,
        text,
    }));
};


export const getValueFromOptionsByKey = (list, key) => {
    const resultObject = list.filter((item) => {
        return item.key === key;
    });
    return resultObject && resultObject.value;
};

export function citizenshipToOptions(units) {
    return Object.keys(units).map(value => ({
        key: value,
        value,
        text: units[value],
        customContent: <CitizenshipWithFlag
            citizenship={value}
        />,
    }));
}

export function phoneCodeListToOptions(list) {
    return list.map(item => ({
        key: item.codeId,
        value: item.codeId,
        text: item.countryName,
        domain: item.domain,
        customContent: <CitizenshipWithFlag
            citizenship={item.domain}
            countryName={item.countryName}
        />,
    }));
}

export const filterObject = (array, card) => {
    const contacts = {};

    for (const key in card) {
        if (!card.hasOwnProperty(key)) {continue;}

        if (array.indexOf(key) !== -1) {
            contacts[key] = card[key];
        }
    }

    return contacts;
};

export function getPartObj(sourceObject, keys) {
    const newObject = {};
    keys.forEach((key) => {
        if (key) {
            newObject[key] = sourceObject[key];
        }
    });
    return newObject;
}

export function getText(property) {
    if (isNullOrWhitespace(property)) {
        return "";
    }
    return property;
}

export function getTextByProperty(obj, property) {
    if (!isNullOrWhitespace(obj) && !isNullOrWhitespace(obj[property])) {
        return obj[property];
    }
    return "";
}

export function getArchiveOption(archived) {
    return {
        key: "ARCHIVE",
        text: archived ? "Список" : "Архив",
        value: "ARCHIVE",
    };
}

/***
 * Определяет значение ключа региона из словаря по значению региона из спарвочника дадата
 *
 * @param regionStr - string, значение региона из Дадата справочника в полном виде
 * @param dict - object, наш словарь регионов ОВМ формата ключ - значение, (цель найти ключ)
 * @returns {string|undefined}
 */
export function getKeyFromMigrationDictByDadata(regionStr, dict = {}) {
    let resultKey = "MSK";

    const words = regionStr.split(" ");
    let search = words[0] === "Республика" ? words[1] : words[0];

    if (search === "Нижний") {
        search = "Ниже";
    }

    if (search === "Саха") {
        search = "Якутия";
    }

    let lengthForSlice = search.length < 5 ? search.length : 5;

    if (search.indexOf("Красноя") !== -1 || search.indexOf("Краснод") !== -1) {
        lengthForSlice = 7;
    }

    const key = Object.keys(dict).find((key) => dict[key].toLowerCase().indexOf(search.toLowerCase().slice(0, lengthForSlice)) !== -1);

    if (key) {
        resultKey = key;
    }

    return resultKey || undefined;
}

export const deepClone = anyData => JSON.parse(JSON.stringify(anyData));

export const translateHeaders = (headers, namespace, t) => {
    return headers.map(item => {
        if (item.key === "action") {
            return {
                ...item,
                content: "",
            };
        }

        return {
            ...item,
            content: t(`${namespace}.${item.key}`),
        };
    });
};

export const detectMaxKeywordsLength = keywords => {
    const maxLength = 44;
    let factLength = 0;
    let count = 0;

    keywords.forEach(value => {

        factLength += value.length;
        if (factLength < maxLength) {
            count++;
        }

        if (factLength > maxLength) {
            return count;
        }
    });

    return count;
};

const groupBy = (array, keySelector) => {
    const map = new Map();
    for (let i = 0, count = array.length; i < count; ++i) {
        const val = array[i], key = keySelector(val);
        if (map.has(key)) {
            map.get(key).push(val);
        } else {
            map.set(key, [val]);
        }
    }
    return [...map];
};

export const mapMetricForChart = (metric) => {
    const {metrics = []} = metric;

    if (isEmpty(metrics)) {
        return {
            ...metric,
            dates: [],
            contractorsCounts: [],
        };
    }

    const _metrics = [...metrics].sort((a, b) => {
        if (a.date > b.date) {
            return 1;
        }
        if (a.date < b.date) {
            return -1;
        }

        return 0;
    });

    const dates = Array.from(new Set(_metrics.map(({date}) => formatDateForChart(date))));
    const contractorsCounts = groupBy(_metrics, e => e.date).map(([, values]) => values[values.length - 1] ? values[values.length - 1].contractorsCount : 0);

    return {
        ...metric,
        dates,
        contractorsCounts,
    };
};

export const retailObjectsToOptions = (objects) => {
    return objects.map(({uuid, name}) => ({
        key: uuid,
        value: uuid,
        text: name,
    }));
};

// Для сортировки по алфавиту списка
export const compareManagers = (a, b) => {
    if (a.text > b.text) {
        return 1;
    }

    if (a.text < b.text) {
        return -1;
    }

    return 0;
};
// инициализация касаний полей для формика
//ToDo: найти решение для проверки вложенных объектов
export const getInitialTouched = (initialValues, checkChilds = false) => {
    const array = Object.keys(initialValues);
    const obj = {};

    array.forEach(key => {
        const value = initialValues[key];

        obj[key] =
            value &&
            checkChilds &&
            typeof value === "object" &&
            !Array.isArray(value) ?
                getInitialTouched(value) :
                true;
    });

    return obj;
};

/***
 * Является ли текущий перебираемый элементы крайним в массиве
 * @param array
 * @param index
 * @returns {boolean}
 */
export const isLastElementInArray = (array, index) => {
    return index + 1 === array.length;
};

/***
 * Сортировать options по алфавиту
 * @param list
 * @returns {*}
 */
export const sortOptionsAlphabetically = (list) => {
    return list.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
            return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
            return 1;
        }
        return 0;
    });
};

export const filterEmptyValues = (data) => {
    return Object.keys(data)
        .filter(key => {
            if (Array.isArray(data[key])) {
                return !isEmpty(data[key]);
            }

            if (typeof data[key] === "boolean") {
                return true;
            }

            return data[key];
        })
        .reduce((result, key) => {
            return {
                ...result,
                [key]: data[key],
            };
        }, {});
};

export const setNullToEmptyValues = (data) => {
    return Object.keys(data)
        .reduce((result, key) => {
            return {
                ...result,
                [key]: (data[key] || typeof data[key] === "boolean") ? data[key] : null,
            };
        }, {});
};

/***
 * Приведение приходящего с BE справочника в виде Enum к формату опций для фильтров
 * @param data
 * @returns {*}
 */
export const formatEnumToFilterOptions = (data) => {
    return data.map(item => {
        return {
            key: item,
            text: item,
            value: item,
        };
    });
};

export const getDaysOptions = (params) => {
    const {
        count,
        isVisibleNotSelected,
    } = params;

    return [
        isVisibleNotSelected && {
            key: null,
            value: null,
            text: "Не выбрано",
        },
        ...Array(count).keys().map(item => {
            return {
                key: String(item + 1),
                value: String(item + 1),
                text: pluralizeDays(item + 1),
            };
        }),
    ].filter(Boolean);
};

export const getWeekOptions = (params = {}) => {
    return getDaysOptions({...params, count: 14});
};