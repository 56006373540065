import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {filterEmptyValues} from "../../../../utils/objectHelper";
import {handleFormString} from "../../../../utils/stringHelper";

import {getSubscribeNotificationsClientUsers} from "../../../../ducks/bff/notifications/subscribe/actionCreators";
import {
    notificationSubscribesCategoriesTypesOptionsSelector,
    notificationSubscribesChannelTypesOptionsSelector,
    notificationSubscribesClientRolesOptionsSelector,
    notificationSubscribesClientUsersOptionsSelector,
    notificationSubscribesTypesOptionsSelector,
} from "../../../../ducks/bff/notifications/subscribe/selectors";

export const useNotificationsSubscribesFilter = (params) => {
    const {
        setPagination,
        pageSize,
        clientId,
    } = params;

    const dispatch = useDispatch();

    const clientUserOptions = useSelector(notificationSubscribesClientUsersOptionsSelector);
    const subscribesTypesOptions = useSelector(notificationSubscribesTypesOptionsSelector);
    const subscriptionCategoriesOptions = useSelector(notificationSubscribesCategoriesTypesOptionsSelector);
    const clientRolesOptions = useSelector(notificationSubscribesClientRolesOptionsSelector);
    const channelTypesOptions = useSelector(notificationSubscribesChannelTypesOptionsSelector);

    const {
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        mapFilterDto,
        setPagination,
        pageSize,
    });

    function mapFilterDto(filter) {
        const {
            channelTypes,
            roles,
            clientUserId,
            subscriptionType,
            subscriptionCategory,
        } = filter;

        return filterEmptyValues({
            clientUserId: handleFormString(clientUserId),
            roles: isEmpty(roles) ? undefined : roles,
            subscriptionType: handleFormString(subscriptionType),
            subscriptionCategory: handleFormString(subscriptionCategory),
            channelTypes: isEmpty(channelTypes) ? undefined : channelTypes,
        });
    }

    const onSearchChange = (fioFilter) => {
        dispatch(getSubscribeNotificationsClientUsers({
            clientId,
            fioFilter: handleFormString(fioFilter),
        }));
    };

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Получатель",
                        placeholder: "Показать все",
                        name: "clientUserId",
                        onSearchChange,
                        options: clientUserOptions,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Роль получателя",
                        placeholder: "Показать все",
                        name: "roles",
                        options: clientRolesOptions,
                        multiple: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Категория уведомлений",
                        placeholder: "Показать все",
                        name: "subscriptionCategory",
                        options: subscriptionCategoriesOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Тип уведомлений",
                        placeholder: "Показать все",
                        name: "subscriptionType",
                        options: subscribesTypesOptions,
                        search: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Канал уведомления",
                        placeholder: "Показать все",
                        name: "channelTypes",
                        options: channelTypesOptions,
                        multiple: true,
                    },
                ],
            },
        ];
    }, [
        clientUserOptions,
        subscribesTypesOptions,
        subscriptionCategoriesOptions,
        clientRolesOptions,
        channelTypesOptions,
    ]);

    return {
        isSearch,
        filters,
        onSearch,
        onClear,
        filterData,
    };
};