export const BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST = "BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_REQUEST";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS = "BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_SUCCESS";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_ERROR = "BFF_COMMON_DICTS_GET_ON_BOARDING_SCENARIO_FRAME_CONTRACT_TYPE_DICT_ERROR";

export const BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST = "BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_REQUEST";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS = "BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_SUCCESS";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_ERROR = "BFF_COMMON_DICTS_GET_ON_BOARDING_CONTRACTOR_SOURCE_DICT_ERROR";

export const BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST = "BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_REQUEST";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS = "BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_SUCCESS";
export const BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_ERROR = "BFF_COMMON_DICTS_GET_ON_BOARDING_LEAD_REGISTRATION_STATUS_DICT_ERROR";

export const BFF_COMMON_DICTS_MENU_DESIGN_REQUEST = "BFF_COMMON_DICTS_MENU_DESIGN_REQUEST";
export const BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS = "BFF_COMMON_DICTS_MENU_DESIGN_SUCCESS";
export const BFF_COMMON_DICTS_MENU_DESIGN_ERROR = "BFF_COMMON_DICTS_MENU_DESIGN_ERROR";

export const BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST = "BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_REQUEST";
export const BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS = "BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_SUCCESS";
export const BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_ERROR = "BFF_COMMON_DICTS_GET_PHONE_CODE_ALL_ERROR";

export const BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST = "BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_REQUEST";
export const BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS = "BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR = "BFF_COMMON_DICTS_FLASH_CALL_GET_PAGE_ERROR";

export const BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST = "BFF_COMMON_DICTS_FLASH_CALL_UPDATE_REQUEST";
export const BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS = "BFF_COMMON_DICTS_FLASH_CALL_UPDATE_SUCCESS";
export const BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR = "BFF_COMMON_DICTS_FLASH_CALL_UPDATE_ERROR";

export const BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST = "BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_REQUEST";
export const BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS = "BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_SUCCESS";
export const BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_ERROR = "BFF_COMMON_DICTS_GET_CLIENT_WORK_TYPE_ERROR";

export const BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST = "BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_REQUEST";
export const BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS = "BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_ERROR = "BFF_COMMON_DICTS_GET_SUBDIVISIONS_PAGE_ERROR";

export const BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST = "BFF_COMMON_DICTS_GET_POSITIONS_PAGE_REQUEST";
export const BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS = "BFF_COMMON_DICTS_GET_POSITIONS_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_GET_POSITIONS_PAGE_ERROR = "BFF_COMMON_DICTS_GET_POSITIONS_PAGE_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_ERROR = "BFF_COMMON_DICTS_GET_KEDO_ROLE_DICT_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_STATES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_FILE_SOURCE_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_PARTICIPANT_STATES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENT_HISTORY_EVENT_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_ACTION_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURE_TRANSACTIONS_DECIDE_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_DOCUMENTS_CREATED_VIA_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_SIGNATURES_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_OFFICE_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_TASK_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_TASK_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_TASK_TYPES_ERROR = "BFF_COMMON_DICTS_GET_TASK_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_STATE_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_ERROR = "BFF_COMMON_DICTS_GET_KEDO_EXPORT_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_ERROR = "BFF_COMMON_DICTS_GET_CUSTOM_DOCUMENT_TEMPLATE_LOG_EVENT_TYPES_ERROR";

export const BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST = "BFF_COMMON_DICTS_CLIENT_USERS_FIO_REQUEST";
export const BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS = "BFF_COMMON_DICTS_CLIENT_USERS_FIO_SUCCESS";
export const BFF_COMMON_DICTS_CLIENT_USERS_FIO_ERROR = "BFF_COMMON_DICTS_CLIENT_USERS_FIO_ERROR";

export const BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST = "BFF_COMMON_DICTS_CLIENT_NAMES_REQUEST";
export const BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS = "BFF_COMMON_DICTS_CLIENT_NAMES_SUCCESS";
export const BFF_COMMON_DICTS_CLIENT_NAMES_ERROR = "BFF_COMMON_DICTS_CLIENT_NAMES_ERROR";

export const BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST = "BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_REQUEST";
export const BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS = "BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_SUCCESS";
export const BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_ERROR = "BFF_COMMON_DICTS_CUSTOM_DOCUMENT_TEMPLATE_NAMES_ERROR";

export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_REQUEST";
export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_ERROR = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_ORDER_TYPES_ERROR";

export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_REQUEST";
export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_ERROR = "BFF_COMMON_DICTS_TEMPLATE_DOCUMENT_CLIENT_USAGES_LOG_TYPES_ERROR";

export const BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST = "BFF_COMMON_DICTS_GET_CLIENT_BRANDS_REQUEST";
export const BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS = "BFF_COMMON_DICTS_GET_CLIENT_BRANDS_SUCCESS";
export const BFF_COMMON_DICTS_GET_CLIENT_BRANDS_ERROR = "BFF_COMMON_DICTS_GET_CLIENT_BRANDS_ERROR";

export const BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST = "BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_REQUEST";
export const BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS = "BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_SUCCESS";
export const BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_ERROR = "BFF_COMMON_DICTS_GET_CLIENT_CATEGORIES_ERROR";

export const BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST = "BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_REQUEST";
export const BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS = "BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_ERROR = "BFF_COMMON_DICTS_GET_CONTRACTORS_APPLICATION_TYPES_ERROR";

export const BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST = "BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_REQUEST";
export const BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS = "BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_ERROR = "BFF_COMMON_DICTS_KEDO_ALL_DOCUMENT_CODES_ERROR";

export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_REQUEST";
export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_ERROR = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_NAMES_ERROR";

export const GET_DICTIONARIES_TASK_STATUSES_REQUEST = "GET_DICTIONARIES_TASK_STATUSES_REQUEST";
export const GET_DICTIONARIES_TASK_STATUSES_SUCCESS = "GET_DICTIONARIES_TASK_STATUSES_SUCCESS";
export const GET_DICTIONARIES_TASK_STATUSES_ERROR = "GET_DICTIONARIES_TASK_STATUSES_ERROR";

export const GET_DICTIONARIES_TASK_TYPES_REQUEST = "GET_DICTIONARIES_TASK_TYPES_REQUEST";
export const GET_DICTIONARIES_TASK_TYPES_SUCCESS = "GET_DICTIONARIES_TASK_TYPES_SUCCESS";
export const GET_DICTIONARIES_TASK_TYPES_ERROR = "GET_DICTIONARIES_TASK_TYPES_ERROR";

export const BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST = "BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_REQUEST";
export const BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS = "BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_ERROR = "BFF_COMMON_DICTS_KEDO_STAFF_FIO_PAGE_ERROR";

export const GET_DICTIONARIES_EDUCATION_FORM_REQUEST = "GET_DICTIONARIES_EDUCATION_FORM_REQUEST";
export const GET_DICTIONARIES_EDUCATION_FORM_SUCCESS = "GET_DICTIONARIES_EDUCATION_FORM_SUCCESS";
export const GET_DICTIONARIES_EDUCATION_FORM_ERROR = "GET_DICTIONARIES_EDUCATION_FORM_ERROR";

export const GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST = "GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_REQUEST";
export const GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS = "GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_SUCCESS";
export const GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_ERROR = "GET_DICTIONARIES_CONTRACTOR_LANGUAGE_LEVELS_ERROR";

export const BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST = "BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_REQUEST";
export const BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS = "BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_ERROR = "BFF_COMMON_DICTS_KEDO_STAFF_POSITIONS_PAGE_ERROR";

export const BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST = "BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_REQUEST";
export const BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS = "BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_ERROR = "BFF_COMMON_DICTS_KEDO_DOCUMENT_TYPE_GET_ALL_ERROR";

export const BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST = "BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_REQUEST";
export const BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS = "BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_ERROR = "BFF_COMMON_DICTS_KEDO_DOCUMENT_ROUTE_GET_PAGE_ERROR";

export const BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST = "BFF_COMMON_DICTS_KEDO_LOG_TYPES_REQUEST";
export const BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS = "BFF_COMMON_DICTS_KEDO_LOG_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_LOG_TYPES_ERROR = "BFF_COMMON_DICTS_KEDO_LOG_TYPES_ERROR";

export const BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST = "BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_REQUEST";
export const BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS = "BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR = "BFF_COMMON_DICTS_KEDO_UPLOADED_DOCUMENT_TYPES_GET_PAGE_ERROR";

export const BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST = "BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_REQUEST";
export const BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS = "BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR = "BFF_COMMON_DICTS_KEDO_TEMPLATE_DOCUMENT_TYPES_GET_PAGE_ERROR";

export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_REQUEST";
export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_SUCCESS";
export const BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_ERROR = "BFF_COMMON_DICTS_KEDO_DEPARTMENT_LOG_MERGE_TYPES_ERROR";

export const BFF_GET_REGISTRY_PAYMENT_STATUSES_REQUEST = "BFF_GET_REGISTRY_PAYMENT_STATUSES_REQUEST";
export const BFF_GET_REGISTRY_PAYMENT_STATUSES_SUCCESS = "BFF_GET_REGISTRY_PAYMENT_STATUSES_SUCCESS";
export const BFF_GET_REGISTRY_PAYMENT_STATUSES_ERROR = "BFF_GET_REGISTRY_PAYMENT_STATUSES_ERROR";

export const BFF_COMMON_DICTS_UPDATE_STORE = "BFF_COMMON_DICTS_UPDATE_STORE";
