import React, {ReactNode, RefObject, SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import {isEmpty, uniqWith} from "lodash";
import {nanoid} from "nanoid";

import {ReactComponent as IconCancel} from "../../../images/cancel_24.svg";
import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";
import {ReactComponent as IconClose} from "../../../images/close.svg";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {ReactComponent as IconSearch} from "../../../images/search.svg";
import Text from "../../ActualComponents/Text";
import NmButton from "../../NmButton";
import ErrorTooltip from "../ErrorTooltip";
import NmCheckboxV2 from "../NmCheckboxV2";
import NmLabel, {INmLabel} from "../NmLabel";
import {Loader} from "semantic-ui-react";

import {useClickOutside} from "../../../hooks/useClickOutside";
import {useDebounceFetch} from "../../../hooks/useDebounceFetch";
import {useGetPrevValue} from "../../../hooks/useGetPrevValue";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {getListUniqueOptions} from "../../../utils/listHelper";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {COLOR} from "../../../constants/color";

import "./style.sass";

const getSelectedTitle = (items: TOptions) => (
    items.map(({text}: OptionType) => `${text}; `).join("")
);

interface IButtons {
    onSubmit: () => void;
    onCancel: () => void;
}

const Buttons = (props: IButtons) => {
    const {
        onSubmit,
        onCancel,
    } = props;

    return (
        <div className="nm-dropdown-v2__buttons">
            <NmButton
                color="light-green"
                className="nm-dropdown-v2__submit"
                onClick={onSubmit}
            >
                Показать
            </NmButton>
            <NmButton
                className="nm-dropdown-v2__cancel"
                onClick={onCancel}
                color="grey"
            >
                Очистить
            </NmButton>
        </div>
    );
};

export type TOptions = Array<OptionType> | [];

export interface INmDropdownV2 extends INmLabel {
    options: TOptions,
    value: string | null | Array<string | null>,
    name?: string,
    size?: "xl" | "lg",
    emptyOptionText?: string,
    search?: boolean,
    additionalContent?: any,
    downloadLinkText?: string,
    onClickDownloadLink?: () => void,
    placeholder?: string,
    disabled?: boolean,
    className?: string,
    error?: Array<string> | string | null,
    onFocus?: (event?: any) => void,
    isCustomField?: boolean,
    valueWhichNotInOptions?: string,
    multiple?: Boolean,
    isActiveStyle?: boolean,
    fullWidth?: boolean,
    saveSpecialityByEnter?: (value: string) => void,
    isAllSelectionShown?: boolean,
    required?: boolean,
    direction?: "bottom" | "top",
    onChange: (event: any, params: any) => void,
    onBlur?: (event: any) => void,
    onSubmit?: () => void,
    onCancel?: () => void,
    onSearchChange?: (event: any, params: any) => void,
    children?: never,
    inline?: string,
    classNameContent?: string,
    classNameError?: string,
    dadata?: boolean,
    searchQuery?: string,
    isClearable?: boolean,
    isClearSearchOnBlur?: boolean,
    minSearchLength?: number,
    nameSearchQuery?: string,
    onChangeInput?: (event?: any, params?: any) => void,
    initialOptions?: any,
    initialOption?: OptionType,
    // Для ситуаций, когда необходимо не зачищать поиск
    isNeedClearShowedText?: boolean,
    // Для ситуаций, когда необходима также логика работы инпута с дропдауном
    // Отключено обновление отображающегося значения взависимости от изменения value, options
    // Реализовано для одиночного варианта
    inputLogic?: boolean,
    // Массив id (value) с итемами, которые нужно залочить
    // Реализовано для множественного варианта
    disabledItems?: Array<string>,
    skipItemsAllSelected?: Array<string | null>,
    // Объект содержащий какие-либо подписи для пунктов дропдауна, key = id (value)
    warnings?: { [key: string]: string },
    isMountLoad?: boolean,
    // желтые рамки
    changed?: boolean,
    suggestion?: ReactNode,
    onMouseEnter?: (event: any) => void,
    tooltip?: ReactNode,
    // Для ситуаций, когда необходимо строго задать направление открытия дропдауна
    strongDirection?: boolean,
    isVisibleTooltip?: boolean,
    active?: boolean,
    isOpen?: boolean,
    onKeyDown?: (event: any) => void,
    isResetValueFilter?: boolean,
    isVisibleSearchPrompt?: boolean,
    searchPromptSubjectText?: string,
    isLoading?: boolean,
    isSetTextCustomContent?: boolean,
    isLocalSearchFilter?: boolean,
    optionsTotalCount?: number,
    searchCount?: number,
    noResultMessage?: string,
    isClearSearchDependingValue?: boolean,
}

const NmDropdownV2 = (props: INmDropdownV2) => {
    const {
        size = "xl",
        label,
        additionalContent,
        onChange,
        placeholder,
        name,
        value,
        search,
        onBlur,
        disabled,
        className = "",
        error,
        onFocus,
        isCustomField,
        valueWhichNotInOptions,
        emptyOptionText,
        multiple = false,
        onSearchChange,
        onSubmit,
        onCancel,
        isActiveStyle,
        fullWidth,
        saveSpecialityByEnter,
        isAllSelectionShown = true,
        required,
        direction = "bottom",
        inline = false,
        classNameContent = "",
        classNameError = "",
        dadata = false,
        searchQuery = "",
        isClearable = false,
        minSearchLength = 3,
        nameSearchQuery,
        onChangeInput,
        isClearSearchOnBlur,
        initialOptions,
        initialOption,
        isNeedClearShowedText,
        inputLogic = false,
        isMountLoad = true,
        disabledItems,
        changed,
        warnings,
        suggestion,
        onMouseEnter,
        strongDirection,
        tooltip,
        isVisibleTooltip,
        active = false,
        isOpen = false,
        onKeyDown = (e: any) => {
        },
        downloadLinkText,
        onClickDownloadLink = () => {
        },
        skipItemsAllSelected = [],
        isResetValueFilter,
        isVisibleSearchPrompt = false,
        searchPromptSubjectText = "",
        isLoading = false,
        isSetTextCustomContent = true,
        isLocalSearchFilter = true,
        optionsTotalCount,
        searchCount,
        noResultMessage,
        isClearSearchDependingValue = false,
    } = props;

    const $block = useRef<HTMLDivElement>(null);

    const [_direction, setDirection] = useState(direction);
    const [open, setOpen] = useState(isOpen);
    const [text, setText] = useState<string | ReactNode>("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [countSelected, setCountSelected] = useState(0);
    const [selected, setSelected] = useState<TOptions>([]);
    const [openSelected, setOpenSelected] = useState(false);
    const prevSearchQuery = useGetPrevValue(searchQuery);
    const prevValue= useGetPrevValue(value);

    const [inpValue, setInpValue] = useState("");
    const {
        setValueFilter: setInputDebounceValue,
        valueFilter,
    } = useDebounceFetch({
        isMountLoad,
        nameSearchQuery,
        onChange: onChangeInput,
        fetchCallback: onSearchChange,
        initialValue: searchQuery,
        isResetValueFilter,
        minSearchLength,
    });

    const setInputValue = onSearchChange ? setInputDebounceValue : setInpValue;
    const inputValue = onSearchChange ? valueFilter : inpValue;

    const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);

    const [block, element] = bem("nm-dropdown-v2", className);

    const options = useMemo(() => {
        const {
            options = [],
        } = props;

        if (initialOption) {
            return uniqWith(
                [
                    ...options,
                    initialOption,
                ],
                (arrVal, othVal) => {
                    return arrVal.key === othVal.key;
                });
        }

        return options;
    }, [
        props.options,
        initialOption,
    ]);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isClearSearchDependingValue && isEmpty(value) && !isEmpty(prevValue)) {
            setInputValue("");
        }
    }, [value]);

    useEffect(() => {
        // Логика для очистки поля поиска в дропдауне при очистке формы из родителя
        if (search && prevSearchQuery !== searchQuery && searchQuery === "") {
            setInputValue("");
        }
    }, [searchQuery]);

    useEffect(() => {
        if (inputLogic && searchQuery) {
            setText(searchQuery);
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(initialOptions)) {
            setText(initialOptions.map((item: OptionType) => item.text).join(", "));
            setSelected(initialOptions);
        }
    }, [initialOptions]);

    useEffect(() => {
        // Одиночный выбор
        if (!multiple && !isEmpty(options) && !inputLogic) {
            const {text, customContent} = options.find((item) => (item.value === value)) || {};

            if (customContent && isSetTextCustomContent) {
                setText(customContent);

                return;
            }

            setText(text);
        }

        // Подгрузка значений при мультивыборе 1 раз
        if (multiple && !isOptionsLoaded && options.length !== 0 && !isEmpty(value)) {
            setIsOptionsLoaded(true);

            const selectedOptions = options.filter((item) => value?.includes(item.value));
            const _selected = getListUniqueOptions([...new Set([
                ...selected.filter((item) => value?.includes(item.value)),
                ...selectedOptions,
            ])]);

            setText(_selected.map((item: { text: any; }) => item.text).join(", "));
            setSelected(_selected);
        }
    }, [options]);

    useEffect(() => {
        // Мультивыбор
        if (multiple) {
            if (Array.isArray(value)) {
                setCountSelected(value.length);

                // Подгрузка значений при мультивыборе
                if (value.length !== 0 && options.length !== 0) {
                    const selectedOptions = options.filter((item) => value.includes(item.value));
                    const _selected = getListUniqueOptions([...new Set([
                        ...selected.filter((item) => value.includes(item.value)),
                        ...selectedOptions,
                    ])]);

                    setText(_selected.map((item: { text: any; }) => item.text).join(", "));
                    !onSearchChange && setSelected(_selected);
                }
            }

            // Для стирания
            if (isEmpty(value)) {
                setCountSelected(0);
                setOpenSelected(false);
                setSelected([]);
                setText("");
            }
        }

        // Одиночный выбор
        if (!multiple && !inputLogic) {
            const {text, customContent} = options.find(item => (item.value === value)) || {};

            if (customContent && isSetTextCustomContent) {
                setText(customContent);
            } else {
                setText(text);
            }

            // При одинарном дропдауне с поиском значений с бэка при выборе значения из списка при непустом значении поиска
            // приводило к ошибке, значение сбрасывалось, т к при очистке инпута выполнялся запрос на подтягивание списка
            // и выбранного значения в options уже не было
            if (isClearSearchOnBlur || !onSearchChange) {
                setInputValue("");
            }
        }
    }, [value]);

    useEffect(() => {
        if (isNeedClearShowedText) {
            setText("");
            setInputValue("");
        }
    }, [isNeedClearShowedText]);

    useEffect(() => {
        if (valueWhichNotInOptions) {
            setText(valueWhichNotInOptions);
        }
    }, [valueWhichNotInOptions]);

    useEffect(() => {
        if (isCustomField && valueWhichNotInOptions && !open && inputRef && inputRef.current) {
            inputRef.current.blur();
        }

        if (search && open && inputRef && inputRef.current) {
            inputRef.current.focus();
        }

        if (inputLogic && search) {
            setText(prevState => (isNullOrWhitespace(value) ? inputValue : prevState));
        }
    }, [open]);

    const handleClickOutsideWithValueWhichNotInOptions = (target: Element) => {
        // Определяем в какое место сделан клик, нужно для правильной логики отображаещегося значения
        // В момент клика по значению из списка В случае если есть значение valueWhichNotInOptions, которого нет в списке ->
        // дропдаун закрывается -> useClickOutside отрабатывает раньше,
        // чем обновляется значение в родителе valueWhichNotInOptions (затирание) -
        // что приводит к отображению valueWhichNotInOptions в закрытом дродауне, что является неверным
        const isClickedItem = target.classList.contains(element("item"));

        // Если был произведен выбор из списка не сохраняем отображаемое значение в дропдаун
        if (isClickedItem) {
            return;
        }

        // Отображаем в закрытом состянии дропдауна значение из родителя
        // т к оно не добавлено (не был прожат энтер)
        setText(valueWhichNotInOptions);
    };

    const rootRef: RefObject<HTMLDivElement> = useClickOutside((e: SyntheticEvent) => {
        const target = e.target as Element;

        const isException = target.classList.contains(element("word-icon"));

        if (open && !isException) {
            onBlur && onBlur(e);
            setOpen(false);
            // Обрабатываем клик вне дропдауна если есть значение, которого нет в списке
            if (valueWhichNotInOptions) {
                handleClickOutsideWithValueWhichNotInOptions(target);

                return;
            }

            // Если значения подтягиваются с бэка в список, при наличии в инпуте значения,
            // обновляем список к дефолтному значению только в случае множественного выбора,
            // по текущей логике в случае обнуления у немножественного выбора приводит к ошибке
            // toDO: надо подумать над этим тщательней
            if (onSearchChange && inputValue && multiple) {
                onSearchChange(null, {searchQuery: ""});
            }

            if (
                isClearSearchOnBlur
                // При одинарном дропдауне с поиском значений с бэка при выборе значения из списка при непустом значении поиска
                // приводило к ошибке, значение сбрасывалось, т к при очистке инпута выполнялся запрос на подтягивание списка
                // и выбранного значения в options уже не было
                || !onSearchChange || multiple
            ) {
                setInputValue("");
            }
        }
    }, open);

    const toggle = () => {
        if (disabled) {
            return;
        }

        // При окрытии дродауна в случае если есть значение которого нет в options,
        // зачищаем отображаемое значение, так как оно переходит в input
        if (!open && valueWhichNotInOptions) {
            setText("");
            setInputValue(valueWhichNotInOptions);
        }

        if (!open) {
            setOpen(true);
        }
    };

    const handleClick = (opt: OptionType) => {
        if (multiple) {
            onChangeCheckbox(opt);

            return;
        }

        // При выборе проставляем отображающееся значение, до написания этого условия проставлялся только
        // в useEffect, происходил скачок:
        // после клика по значению дропдаун оставался неактивным ->
        // спустя некоторое время значение обновлялось и дропдаун перекрашивался в активный цвет
        if (!multiple) {
            const {text, customContent} = opt;

            if (customContent && isSetTextCustomContent) {
                setText(customContent);
            } else {
                setText(text);
            }
        }

        onChange(null, {name, ...opt});
        setOpen(false);
    };

    const handleClickAllOptions = () => {
        const filteredOptions = options.filter(({value}) => !skipItemsAllSelected.includes(value));

        if (filteredOptions.length === value?.length) {
            setSelected([]);
            setText("");
            onChange(null, {name, value: []});

            return;
        }

        const parseOptions = filteredOptions.map(({value, text, key}: OptionType) => ({value, text, key}));
        const parseText = filteredOptions.map(({text}: OptionType) => (text)).join(", ");
        const parseValue = filteredOptions.map(({value}: OptionType) => (value));

        setSelected(parseOptions);
        setText(() => parseText);
        onChange(null, {name, value: parseValue});
    };

    const onClickSelected = () => {
        if (value?.length === 0) {
            return;
        }

        setOpenSelected(!openSelected);
    };

    const deleteWord = (id: string) => {
        if (!value) {
            return;
        }

        const selectedList = [...value];
        const _selected = [...selected];

        const index = selectedList.findIndex(item => (item === id));

        selectedList.splice(index, 1);
        _selected.splice(index, 1);

        setSelected(_selected);
        setText(_selected.map(item => item.text).join(", "));
        onChange(null, {name, value: selectedList});
    };

    const getSelected = () => {
        if (!multiple) {
            return null;
        }

        return (
            <div className={element("selected-area", {empty: options.length === 0})}>
                <div className={element("selected-container", {direction: _direction})}>
                    <div
                        onClick={onClickSelected}
                        className={element("selected", {active: (value && value.length > 0)})}
                    >
                        <div className={element("selected-text")}>
                            Выбрано:
                            {" "}
                            {countSelected}
                        </div>
                        <IconChevron
                            className={element("selected-icon", {open: openSelected})}
                        />
                    </div>
                    {
                        openSelected &&
                        <div className={element("words")}>
                            {
                                selected.map((item: OptionType) => {
                                    const {
                                        text,
                                        value,
                                    } = item;

                                    return (
                                        <div
                                            key={nanoid(3)}
                                            className={element("word")}
                                            title={typeof text === "string" ? text : ""}
                                        >
                                            <div
                                                className={element("word-text")}
                                            >
                                                {text}
                                            </div>
                                            <IconClose
                                                onClick={() => {
                                                    deleteWord(value);
                                                }}
                                                className={element("word-icon")}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        );
    };

    const onChangeCheckbox = (opt: OptionType) => {
        const {value: val} = opt;
        const selectedList = value && [...value] || [];
        const _selected = [...selected];

        if (selectedList.includes(val)) {
            const index = selectedList.findIndex(item => item === val);

            _selected.splice(index, 1);

            selectedList.splice(index, 1);
        } else {
            const result = options.find(item => (item.value === val));

            result && _selected.push({
                text: result.text,
                value: result.value,
                key: result.key,
            });

            selectedList.push(val);
        }

        setSelected(_selected);
        setText(_selected.map(item => item.text).join(", "));

        onChange(null, {
            name,
            ...opt,
            value: selectedList,

        });
    };

    const getOptions = () => {
        if (isLoading) {
            return (
                <div className="flex align-items-center flex-content-center col-16 mt-4 mb-4">
                    <Loader
                        className="me-2"
                        active
                        inline
                        size="tiny"
                    />
                    Поиск...
                </div>
            );
        }

        if (isVisibleSearchPrompt && valueFilter.length < minSearchLength) {
            const minSearchText = `Введите более ${minSearchLength} символов`;
            return (
                <Text
                    className="ms-4 mt-2 mb-2"
                    level="3"
                    color={COLOR.PASSIVE_50}
                >
                    {minSearchText}
                </Text>
            );
        }

        if (!options.length && noResultMessage) {
            return (
                <Text
                    className="ms-4 mt-2 mb-2"
                    level="3"
                    color={COLOR.SECONDARY_45}
                >
                    {noResultMessage}
                </Text>
            );
        }

        let _options: any = options;

        if (search && !dadata && isLocalSearchFilter) {
            _options = options.filter(value =>
                search &&
                typeof value.text === "string" &&
                value.text.toLowerCase().includes(inputValue.toLowerCase().trim()),
            );
        }

        if (emptyOptionText && !_options.some((option: any) => isNullOrWhitespace(option.key))) {
            const emptyOption = {
                text: emptyOptionText,
                key: null,
                value: null,
            };
            _options.unshift(emptyOption);
        }

        const isAllSelected = !skipItemsAllSelected.length ? options.length === value?.length :
            typeof value !== "string" &&
            (options && options.filter(({value}) => !skipItemsAllSelected.includes(value)).length) === (value && value.filter((item) => !skipItemsAllSelected.includes(item)).length);

        return (
            <>
                {
                    isAllSelectionShown &&
                    (multiple && options.length > 1) &&
                    <div
                        className={element("item", {
                            selected: isAllSelected,
                            multiple,
                        })}
                        onClick={() => {
                            if (multiple) {
                                return;
                            }

                            handleClickAllOptions();
                        }}
                    >
                        <div className={element("item-content")}>
                            <NmCheckboxV2
                                classNameLabel="nm-dropdown-v2__checkbox-label"
                                classNameBox="nm-dropdown-v2__checkbox-box"
                                onChange={handleClickAllOptions}
                                label="Выбрать все"
                                checked={isAllSelected}
                                className={element("checkbox")}
                            />
                        </div>
                    </div>
                }
                {
                    optionsTotalCount &&
                    searchCount &&
                    searchPromptSubjectText &&
                    optionsTotalCount > searchCount &&
                    <Text
                        className="ms-4 mt-2 mb-2"
                        level="3"
                        color={COLOR.PASSIVE_50}
                    >
                        {`Уточните поиск. Найдено > ${searchPromptSubjectText}`}
                    </Text>
                }
                {
                    _options.map((opt: OptionType) => {
                        const {
                            key,
                            value: _value,
                            text,
                            customContent,
                            icon,
                            disabled,
                            tooltipText,
                            className: optionClassName,
                        } = opt;

                        return (
                            <div
                                key={key}
                                className={element("item", {
                                    selected: !isEmpty(value) && value === _value,
                                    multiple,
                                    disabled,
                                })}
                                onClick={() => {
                                    if (multiple || disabled) {
                                        return;
                                    }

                                    handleClick(opt);
                                }}
                            >
                                <div
                                    title={typeof text !== "string" ? undefined : text}
                                    className={element("item-content")}
                                >
                                    {
                                        warnings && !isEmpty(warnings) && warnings[_value] &&
                                        <div className={element("warning")}>
                                            {warnings[_value]}
                                        </div>
                                    }
                                    {
                                        multiple ?
                                            <>
                                                <NmCheckboxV2
                                                    classNameLabel="nm-dropdown-v2__checkbox-label"
                                                    classNameBox="nm-dropdown-v2__checkbox-box"
                                                    onChange={() => {
                                                        handleClick(opt);
                                                    }}
                                                    disabled={
                                                        (disabledItems && disabledItems.includes(_value)) || disabled
                                                    }
                                                    label={customContent ? customContent : text}
                                                    checked={value?.includes(_value)}
                                                    className={element("checkbox", {"visible-icon": Boolean(icon)})}
                                                    isVisibleTooltip={true}
                                                    tooltipText={tooltipText}
                                                    info={true}
                                                />
                                                {Boolean(icon) && icon}
                                            </> :
                                            <>
                                                <div
                                                    className={`${element("options-text", {
                                                        disabled,
                                                        "visible-icon": Boolean(icon),
                                                    })} ${optionClassName}`}
                                                >
                                                    {customContent ? customContent : text}
                                                </div>
                                                {Boolean(icon) && icon}
                                            </>
                                    }
                                </div>
                            </div>
                        );
                    })}
            </>
        );

    };

    const _onChangeInput = (event: any) => {
        setInputValue(event.target.value);
    };

    /***
     * Значение, которое отображается в дропдауне после выбора
     */
    const getDisplayText = () => {
        // if (text && valueWhichNotInOptions) {
        //     return text;
        // }

        // Если пользователь вводит что либо в поиске
        if (search && open && inputValue) {
            return "";
        }

        // Если выбранно значение (есть value),
        // возвращаем текст выбранного значения в дропдауне
        if (text) {
            return text;
        }

        // Если значение не выбранно, но пользователь ввел/вводит что-либо в поиске,
        // возвращаемм пустую строку, т к в текущий момент значение отображается в инпуте
        if (search && inputValue) {
            return "";
        }

        // Если значение не выбрано, но настроен плейсхолдер, возвращаем его
        if (!text && placeholder) {
            return placeholder;
        }

        return "";
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            updateAfterPressEnter();
        }
    };

    const updateAfterPressEnter = () => {
        const filteredOptions = options.filter((value: OptionType) =>
            typeof value.text === "string" && value.text.toLowerCase().includes(inputValue.toLowerCase()));

        if (inputValue && filteredOptions.length === 1 && !multiple) {
            const [opt] = filteredOptions;

            handleClick(opt);
            setOpen(false);

            if (inputRef && inputRef.current && text === inputValue) {
                inputRef.current.blur();

                // При одинарном дропдауне с поиском значений с бэка при выборе значения из списка при непустом значении поиска
                // приводило к ошибке, значение сбрасывалось, т к при очистке инпута выполнялся запрос на подтягивание списка
                // и выбранного значения в options уже не было
                !onSearchChange && setInputValue("");

                return;
            }

            if (inputRef && inputRef.current) {
                inputRef.current.blur();
            }
        }

        if (filteredOptions.length !== 0) {
            return;
        }

        const isAddedOfferedSpeciality = saveSpecialityByEnter && saveSpecialityByEnter(inputValue);

        if (isAddedOfferedSpeciality) {
            setText(inputValue);
        }

        setOpen(false);
    };

    const handleFocus = () => {
        if (onFocus) {
            onFocus(inputValue);
        }
    };

    const getInputIcon = () => open && (
        <div className={element("input-icon-container")}>
            <IconSearch className={element("input-icon")} />
        </div>
    );

    /***
     * Для определения активного состояния по значению (черные рамки когда выбрано значение)
     */
    const getActiveValue = () => {
        // При одиночном выборе
        if ((Boolean(value) || (text && text !== emptyOptionText)) && !multiple) {
            return true;
        }

        // Мультивыбор
        if ((multiple && !isEmpty(value)) || isActiveStyle) {
            return true;
        }

        if (valueWhichNotInOptions) {
            return true;
        }

        return false;
    };

    const _onCancel = () => {
        if (onCancel) {
            onCancel();
        }

        if (multiple) {
            setSelected([]);
            setText("");
            setInputValue("");
        }

        onChange(null, {
            name,
            value: multiple ? [] : "",
        });
    };

    const _onClick = () => {
        if (strongDirection || !$block || !$block.current) {
            return;
        }

        // Расстояние от нижнего края элемента блока до нижней границы экрана
        const difference = document.documentElement.clientHeight - $block.current.getBoundingClientRect().bottom;
        // высота одного пункта
        const ITEM_HEIGHT = 40;
        // максимальное количество значений в списке
        const ITEMS_COUNT_MAX = 8;
        // filter buttons height
        const FILTER_BUTTONS_HEIGHT = 60;
        // максимальная высота блока со значениями
        const ITEMS_MAX_HEIGHT = 320;
        // Учитываем опциональность элемента выбрать все
        const countShowedItems = isAllSelectionShown ? options.length + 1 : options.length;

        if (
            options &&
            options.length !== 0
        ) {
            let dropdownHeight = 0;

            if (onSubmit) {
                dropdownHeight += FILTER_BUTTONS_HEIGHT;
            }

            dropdownHeight += options.length <= ITEMS_COUNT_MAX ? ITEM_HEIGHT * countShowedItems : ITEMS_MAX_HEIGHT;

            if (dropdownHeight >= difference) {
                setDirection("top");

                return;
            }
        }

        setDirection("bottom");
    };

    const onClearValue = (event: any) => {
        event.stopPropagation();

        const value = multiple ? [] : "";

        setInputValue("");

        onChange(null, {name, value});
    };

    return (
        <div
            ref={$block}
            onClick={_onClick}
            className={block({
                open,
                error: Boolean(error),
                size,
                inline,
                fullWidth,
            })}
            onKeyDown={handleKeyDown}
            id={name}
        >
            {
                label &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    tooltip={tooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            <div
                ref={rootRef}
                onClick={toggle}
                tabIndex={0}
                onKeyDown={onKeyDown}
                onMouseEnter={onMouseEnter}
                className={element("content", {
                    open,
                    active: open || getActiveValue() || active,
                    disabled,
                    size,
                    direction: _direction,
                    search,
                    clearable: isClearable,
                    changed,
                })}
                onFocus={handleFocus}
                title={(selected && selected.length !== 0) ? getSelectedTitle(selected) : ""}
            >
                <div className={element("icon-container")}>
                    {
                        isClearable && !isEmpty(value) && !disabled && !open &&
                        <IconCancel
                            width={20}
                            height={20}
                            onClick={onClearValue}
                            className={element("icon-clear")}
                        />
                    }
                    <IconChevron
                        onClick={() => {
                            setOpen(false);
                        }}
                        className={element("icon", {open})}
                    />
                </div>
                <div className={element("text", {active: getActiveValue()})}>
                    {getDisplayText()}
                </div>
                {
                    search && open &&
                    <div className={element("input-container")}>
                        <div className={element("relative-container")}>
                            {getInputIcon()}
                            <input
                                ref={inputRef}
                                type="text"
                                onChange={_onChangeInput}
                                value={inputValue}
                                disabled={disabled}
                                name={name}
                            />
                        </div>
                    </div>
                }
                {
                    open &&
                    <div
                        className={`${element("list", {emptyInline: inline && options.length === 0})} ${classNameContent}`}
                    >
                        <div className={element("list-wrapper")}>
                            {getSelected()}
                            <div className={element("list-container")}>
                                {getOptions()}
                            </div>
                        </div>
                        {
                            additionalContent && additionalContent
                        }
                        {
                            onSubmit &&
                            <Buttons
                                onSubmit={onSubmit}
                                onCancel={_onCancel}
                            />
                        }
                    </div>
                }
                {
                    suggestion &&
                    <div className={element("suggestion")}>
                        {suggestion}
                    </div>
                }
            </div>
            {
                downloadLinkText &&
                <a
                    className={element("download-link")}
                    onClick={onClickDownloadLink}
                >
                    <FileDownloadIcon
                        width={24}
                        height={24}
                    />
                    {downloadLinkText}
                </a>
            }
            {
                error &&
                <ErrorTooltip
                    className={classNameError}
                    error={error}
                />
            }
        </div>
    );
};

export default NmDropdownV2;