import React from "react";

import HelpTooltip from "../HelpTooltip";
import NmCheckboxV2 from "../NmCheckboxV2";
import Text from "../Text";

import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";
import {isLastElementInArray} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";
import {COMMON_ALL_OPTION} from "../../../constants/common";

import "./style.sass";

interface SelectVariantsTableProps {
    rows: Array<ItemType>,
    variants: Array<VariantType>,
    variantsDescription?: string | React.ReactNode,
    onChange: (data: OnChangeParamsType) => void,
    isHideAllVariantsCheckbox?: boolean,
    allVariantsName?: string,
}

interface ItemType {
    text: string | React.ReactNode,
    value: string,
    isAllCheckbox?: boolean,
}

interface VariantType {
    disabled?: boolean,
    content: string | React.ReactNode,
    selectedIds: Array<string>,
    [key: string]: any,
}

type OnChangeParamsType = {checked: boolean} & ItemType & VariantType;

export const SelectVariantsTable: React.FC<SelectVariantsTableProps> = (props) => {
    const {
        rows,
        variantsDescription,
        variants,
        isHideAllVariantsCheckbox,
        allVariantsName,
    } = props;

    const [block, element] = bem("select-variants-table");

    const onChange = (data: OnChangeParamsType) => {
        if (data.isAllCheckbox) {
            const selectedIds = data.checked
                ? rows.map(item => item.value)
                : [];

            props.onChange({...data, selectedIds});

            return;
        }

        const selectedIds = data.checked
            ? [...new Set([...data.selectedIds, data.value])]
            : data.selectedIds.filter(id => id !== data.value);

        props.onChange({...data, selectedIds});
    };

    const getHeader = () => {
        return (
            <div className="select-variants-table__header">
                <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                    className="select-variants-table__description"
                >
                    {variantsDescription}
                </Text>
                {
                    variants.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={element("variant", {title: true})}
                            >
                                <Text
                                    level="3"
                                    key={index}
                                    color={COLOR.SECONDARY_100}
                                    className={element("param")}
                                >
                                    {item.content}
                                </Text>
                                {
                                    item.tooltipProps &&
                                    <HelpTooltip
                                        className="ms-1"
                                        hover={true}
                                        height={20}
                                        width={20}
                                        position="bottom-right"
                                        {...item.tooltipProps}
                                    />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const getRows = () => {
        const _rows = isHideAllVariantsCheckbox
            ? rows
            : [
                {
                    text: allVariantsName,
                    isAllCheckbox: true,
                    value: COMMON_ALL_OPTION.key,
                },
                ...rows,
            ];

        return _rows.map((item, index, array) => {
            const isLast = isLastElementInArray(array, index);

            return (
                <div
                    key={index}
                    className={getClassNames([
                        "select-variants-table__row",
                        !isLast && "mb-2",
                    ])}
                >
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_70}
                        className="select-variants-table__name"
                    >
                        {item.text}
                    </Text>
                    {
                        variants.map((variant, index) => {
                            return (
                                <div
                                    key={index}
                                    className="select-variants-table__variant"
                                >
                                    <NmCheckboxV2
                                        key={index}
                                        disabled={variant.disabled}
                                        onChange={(_event, params) => {
                                            onChange({
                                                ...params,
                                                ...item,
                                                ...variant,
                                            });
                                        }}
                                        checked={
                                            item.isAllCheckbox
                                                ? variant.selectedIds.length === rows.length
                                                : variant.selectedIds.includes(item.value)
                                        }
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            );
        });
    };

    return (
        <div className={block()}>
            {getHeader()}
            {getRows()}
        </div>
    );
};