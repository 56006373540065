import {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../utils/objectHelper";
import {getNumberFromFormattedAmount, removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {COMMON_ALL_OPTION} from "../../../../constants/common";
import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS} from "../../../../constants/finance";
import {MASK_PHONE} from "../../../../constants/mask";

import {bffCommonRegistryPaymentStatusesOptionsSelector} from "../../../../ducks/bff/common/dicts/selectors";

export const useFinancePaymentsFilter = (params) => {
    const {
        clientId,
        pageSize,
        setPagination,
    } = params;

    const paymentStatusesOptions = useSelector(bffCommonRegistryPaymentStatusesOptionsSelector);

    const {
        filter,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter: {
            clientId: "",
            createdDateTimeFrom: null,
            createdDateTimeTo: null,
            orderContractPaymentTypeFilter: COMMON_ALL_OPTION.value,
            contractorInnFilter: "",
            phoneFilter: handleFormString(),
            contractorIdFilter: "",
            //registryPaymentFilterStatuses: [],
            registryPaymentFilterStatuses: [],
            orderAmountSumFilter: "",
            //orderAmountForPaymentFilter: "",
            //civilContractAmountFilter: "",
        },
        pageSize,
        setPagination,
        mapFilterDto: (values) => {
            return filterEmptyValues({
                ...values,
                createdDateTimeFrom: getStartFilterDateWithTimeInUtc(values.createdDateTimeFrom),
                createdDateTimeTo: getEndFilterDateWithTimeInUtc(values.createdDateTimeTo),
                orderContractPaymentTypeFilter: values.orderContractPaymentTypeFilter === EMPTY_OPTION_KEY.toUpperCase()
                    ? undefined
                    : handleFormString(values.orderContractPaymentTypeFilter),
                contractorInnFilter: handleFormString(values.contractorInnFilter),
                phoneFilter: handleFormString(removePhoneMask(values.phoneFilter)),
                contractorIdFilter: values.contractorIdFilter ? [values.contractorIdFilter] : undefined,
                registryPaymentFilterStatuses: isEmpty(values.registryPaymentFilterStatuses)
                    ? undefined
                    : values.registryPaymentFilterStatuses,
                orderAmountSumFilter: getNumberFromFormattedAmount(values.orderAmountSumFilter),
                // orderAmountForPaymentFilter: values.orderAmountForPaymentFilter
                //     ? getNumberFromFormattedAmount(values.orderAmountForPaymentFilter)
                //     : undefined,
                // civilContractAmountFilter: values.civilContractAmountFilter
                //     ? getNumberFromFormattedAmount(values.civilContractAmountFilter)
                //     : undefined,
                paymentNumberFilter: values.paymentNumberFilter
                    ? +handleFormString(values.paymentNumberFilter)
                    : undefined,
                clientId: handleFormString(values.clientId),
            });
        },
    });

    const filters = useMemo(() => {
        return [
            !clientId && {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        name: "clientId",
                        isClearable: true,
                        label: "Заказчик",
                        placeholder: "Не выбрано",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "createdDateTimeFrom",
                        endFieldName: "createdDateTimeTo",
                        isClearable: true,
                        label: "Дата создания строки реестра",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ИНН исполнителя из реестра",
                        placeholder: "Введите ИНН",
                        size: "lg",
                        maskChar: null,
                        mask: "999999999999",
                        name: "contractorInnFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Телефон исполнителя из реестра",
                        placeholder: "Введите телефон",
                        name: "phoneFilter",
                        mask: MASK_PHONE,
                        maskChar: null,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.CONTRACTOR,
                        label: "Исполнитель",
                        name: "contractorIdFilter",
                        relatedClientId: clientId,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Тип договора",
                        placeholder: "Все",
                        name: "orderContractPaymentTypeFilter",
                        options: ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS,
                    },
                ],
            },
            // {
            //     row: [
            //         {
            //             component: FILTER.DROPDOWN,
            //             multiple: true,
            //             isActiveStyle: true,
            //             label: "Статус строки реестра",
            //             placeholder: "Все",
            //             name: "registryPaymentFilterStatuses",
            //             options: statusOptions,
            //         },
            //     ],
            // },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        multiple: true,
                        isActiveStyle: true,
                        label: "Статус оплаты",
                        placeholder: "Все",
                        name: "registryPaymentFilterStatuses",
                        options: paymentStatusesOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.AMOUNT_INPUT,
                        label: "Сумма на карту (для НПД)",
                        placeholder: "Введите сумму",
                        name: "orderAmountSumFilter",
                        showFieldDependsName: "orderContractPaymentTypeFilter",
                        showFieldDependsValues: [
                            COMMON_ALL_OPTION.value,
                            ORDER_WORK_REPORT_TYPE.SMZ,
                        ],
                    },
                ],
            },
            // {
            //     row: [
            //         {
            //             component: FILTER.AMOUNT_INPUT,
            //             label: "Сумма по договору (для НДФЛ, ИП)",
            //             placeholder: "Введите сумму",
            //             name: "civilContractAmountFilter",
            //             showFieldDependsName: "orderContractPaymentTypeFilter",
            //             showFieldDependsValues: [
            //                 COMMON_ALL_OPTION.value,
            //                 ORDER_WORK_REPORT_TYPE.CIVIL,
            //                 ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     row: [
            //         {
            //             component: FILTER.AMOUNT_INPUT,
            //             label: "На руки исполнителя (для НДФЛ)",
            //             placeholder: "Введите сумму",
            //             name: "orderAmountForPaymentFilter",
            //             showFieldDependsName: "orderContractPaymentTypeFilter",
            //             showFieldDependsValues: [
            //                 COMMON_ALL_OPTION.value,
            //                 ORDER_WORK_REPORT_TYPE.CIVIL,
            //             ],
            //         },
            //     ],
            // },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        multiple: true,
                        label: "Номер оплаты",
                        placeholder: "Введите номер",
                        name: "paymentNumberFilter",
                        maskChar: null,
                        mask: "999999999999",
                    },
                ],
            },
        ].filter(Boolean);
    }, [
        paymentStatusesOptions,
    ]);

    return {
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};