import React from "react";
import {Formik} from "formik";
import {isEmpty} from "lodash";

import {RecruitmentDirectoriesFunnelEditForm} from "../edit-form";

import useRecruitmentDirectoriesFunnelEditForm from "./hooks/useForm";

const RecruitmentDirectoriesFunnelEditModal = (props) => {
    const {
        onClose,
        fetchList,
        editData,
        clientId,
    } = props;

    const isEdit = !isEmpty(editData);

    const {
        initialValues,
        validationSchema,
        onSubmit,
    } = useRecruitmentDirectoriesFunnelEditForm({
        onClose,
        fetchList,
        isEdit,
        editData,
        clientId,
    });

    const renderContent = (formikProps) => {
        return (
            <RecruitmentDirectoriesFunnelEditForm
                {...formikProps}
                isEdit={isEdit}
                onClose={onClose}
            />
        );
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnBlur={true}
            enableReinitialize={true}
        >
            {renderContent}
        </Formik>
    );
};

export default RecruitmentDirectoriesFunnelEditModal;