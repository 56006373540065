export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_THUMBNAIL_LIST_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_PAY_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_PAY_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST = "BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST";
export const BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS = "BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_SUCCESS";
export const BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR = "BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_ERROR";

export const BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE = "BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE";

export const BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE = "BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE";