import React from "react";
import {useSelector} from "react-redux";

import ContractorFinanceIncome from "../income";

import {
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS,
} from "../../../../constants/links";

import {updateStoreCrowdPayment} from "../../../../ducks/bff/crowd/payment/actionCreators";
import {
    bffCrowdPaymentListSelector,
    bffCrowdPaymentProgressSelector,
    bffCrowdPaymentTotalCountSelector,
    bffCrowdPaymentTotalPagesSelector,
} from "../../../../ducks/bff/crowd/payment/selectors";
import {
    contractorSummaryFinanceIncomeNpdSelector,
    getSummaryContractorFinanceIncomeNpd,
} from "../../../../ducks/contractorFinanceIncomeNpd";

export const ContractorFinanceIncomeCrowd = (props) => {
    const list = useSelector(bffCrowdPaymentListSelector);
    const progress = useSelector(bffCrowdPaymentProgressSelector);
    const totalPages = useSelector(bffCrowdPaymentTotalPagesSelector);
    const totalCount = useSelector(bffCrowdPaymentTotalCountSelector);

    return (
        <ContractorFinanceIncome
            tabsLinks={{
                awaiting: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS,
                completed: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS,
                inProcess: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS,
                registryPayments: LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_REGISTRY_PAYMENTS_LIST_ORDERS,
            }}
            list={list}
            progress={progress}
            totalPages={totalPages}
            totalCount={totalCount}
            clearStore={updateStoreCrowdPayment}
            balanceSelector={contractorSummaryFinanceIncomeNpdSelector}
            fetchSummaryData={getSummaryContractorFinanceIncomeNpd}
            isCrowd={true}
            {...props}
        />
    );
};