import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router";

import {CheckRoleRoute} from "../../components/CheckRoleRoute";
import AdvertisementRoute from "../advertisement/card/route";
import AdvertisementList from "../advertisement/list";
import ChatClientList from "../chats/chat-client-list";
import ContractorResources from "../contractor/contractor-resources";
import NmContractorList from "../contractor/nm-contractor-list";
import InviteContractorsToFcRegistryForm
    from "../contractor/nm-contractor-list/invite-contractor-to-fc-registry-form/list/index";
import TicketCard from "../crm/ticket/card";
import CrmTicketList from "../crm/ticket/list";
import EdmStaffCardCommon from "../document-management/document-management-personnel-card/edm-staff-card-common";
import EdfStaffCardRoute from "../document-management/document-management-personnel-card/edm-staff-card-route";
import OnboardingScenarioCard from "../onboarding/components/card";
import OrderAllApplications from "../order/order-all-applications";
import OrderCommon from "../order/order-list/order-common";
import PatentsRegistryCard from "../patents/card";
import ProjectsCard from "../projects/projects-card";
import ProjectsObjectCard from "../projects/projects-object-card";
import RegistryCard from "../registries/registry-card";
import StopList from "../stop-list";
import ClientCardCompany from "./card/company";
import ClientCardContractors from "./card/contractors";
import ClientCardCrowd from "./card/crowd";
import ClientCardDisputes from "./card/disputes";
import ClientCardDocuments from "./card/documents";
import ClientCardEdo from "./card/edo";
import ClientCardFinances from "./card/finances";
import ClientCardKedo from "./card/kedo";
import ClientCardNotifications from "./card/notifications";
import ClientCardPatents from "./card/patents";
import ClientCardRecruitment from "./card/recruitment";
import ClientCardReports from "./card/reports";
import ClientMemberCard from "./client-members-card";
import {ClientMailingsList} from "./mailings";

import {ls, USER_ROLE} from "../../utils/localstorage";

import {
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_CARD,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD,
    LINK_CLIENT_CARD_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_CLIENT_INVITING_CONTRACTORS_LIST,
    LINK_CLIENT_KEDO,
    LINK_CLIENT_MAILINGS_LIST,
    LINK_CLIENT_MEMBERS,
    LINK_CLIENT_MEMBERS_CARD,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_REGISTRY_FC_ADD_ITEM_FORM,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_FEEDBACKS,
    LINK_FORBIDDEN_PAGE,
    LinkNavigator,
} from "../../constants/links";
import {HR_MANAGER, NM_CHIEF_ACCOUNTANT, NM_CONSULTANT, NM_COORDINATOR, NM_OPERATOR} from "../../constants/roles";

const ClientRoute = (props) => {
    const {
        clientId,
        isArchived,
        location: {pathname},
    } = props;

    const role = ls(USER_ROLE);
    const isEditable = !isArchived && ![NM_COORDINATOR, HR_MANAGER, NM_OPERATOR].includes(role);

    if (
        role === NM_CONSULTANT
        && pathname.indexOf("info") === -1
        && pathname.indexOf(LINK_CLIENT_MEMBERS) === -1
        && pathname.indexOf(LINK_FEEDBACKS) === -1
        && pathname.indexOf("advertisement") === -1
        && pathname.indexOf(LINK_CLIENT_BRANDS.replace(":clientId", clientId)) === -1
    ) {
        return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    }

    // if (isArchived && pathname.indexOf("projects/card") !== -1) {
    //     return <Redirect to={LINK_FORBIDDEN_PAGE} />;
    // }

    // //Временная заглушка для Кари
    // if ([CLIENT_ADMIN, PROJECT_MANAGER].includes(role) && pathname.indexOf("deposit-list") !== -1 && clientId === "7ced1c3f-7def-4fef-8319-c2d7fa520ac3") {
    //     return <Redirect to={LINK_FORBIDDEN_PAGE}/>;
    // }

    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_INFO}
                render={props => (
                    <ClientCardCompany
                        {...props}
                        selectedClientId={clientId}
                        isEditable={isEditable && ![NM_CONSULTANT, NM_CHIEF_ACCOUNTANT].includes(role)}
                    />
                )}
            />
            <Route
                exact
                path={LINK_CLIENT_MEMBERS_LIST}
                render={() => (
                    <ClientCardCompany
                        {...props}
                        selectedClientId={clientId}
                        isEditable={isEditable}
                    />
                )}
            />
            <Route
                exact
                path={LINK_DISPUTES_CLIENT_ADMIN}
                component={ClientCardDisputes}
            />
            <Route
                exact
                path={LINK_CLIENT_NOTIFICATIONS_LIST}
                component={ClientCardNotifications}
            />
            <Route
                exact
                path={LINK_CLIENT_SETTING_NOTIFICATIONS_LIST}
                component={ClientCardNotifications}
            />
            <Route
                path={LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS}
                component={ClientCardNotifications}
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_LIST}
                component={ClientCardCompany}
            />
            <Route
                exact
                path={LINK_CLIENT_OBJECTS_LIST}
                component={ClientCardCompany}
            />
            <Route
                path={LINK_CLIENT_BRIGADE}
                component={ClientCardCompany}
            />
            <Route
                path={LINK_CLIENT_FEEDBACKS}
                component={ClientCardCompany}
            />
            <Route
                path={LINK_CLIENT_BRANDS}
                component={ClientCardCompany}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT}
                component={ClientCardEdo}
            />
            <Route
                exact
                path={LinkNavigator.client.edo.documentsRegistriesCardAddItem}
                component={ClientCardEdo}
            />
            <Route
                exact
                path={LINK_CLIENT_PATENTS}
                component={ClientCardPatents}
            />
            <Route
                exact
                path={LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST}
                component={ClientCardPatents}
            />
            <Route
                exact
                path={LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS}
                component={ClientCardPatents}
            />
            <Route
                exact
                path={LINK_CLIENT_PAYMENTS_ORDERS_LIST}
                component={ClientCardFinances}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_PAYMENTS_TASKS_LIST}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_NDFL_PAYMENTS_LIST}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_PAYMENTS_LIST}
                component={ClientCardFinances}
            />
            <Route
                path={LINK_CLIENT_DEPOSIT_LIST}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_NDFL_DEPOSIT_LIST}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LinkNavigator.client.finance.civilDepositRegistryOrders}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_INSURANCE_HISTORY}
                component={ClientCardFinances}
            />
            <Route
                path={LINK_CLIENT_CONTRACTORS_LIST}
                component={ClientCardContractors}
            />
            <Route
                // ?
                exact
                path={LINK_CLIENT_CONTRACTORS_RESOURCES}
                component={ClientCardContractors}
            />
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_FC_ADD_ITEM_FORM}
                component={InviteContractorsToFcRegistryForm}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD}
                component={EdmStaffCardCommon}
            />
            <Route
                exact
                path={LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD}
                component={EdfStaffCardRoute}
            />
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_PAYMENTS_CARD}
                component={RegistryCard}
            />
            <Route
                exact
                path={LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD}
                component={PatentsRegistryCard}
            />
            <Route
                path={`${LINK_CLIENT_CARD}/order/`}
                component={OrderCommon}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD}
                component={ProjectsObjectCard}
            />
            <Route
                path={LINK_CLIENT_PROJECTS_CARD}
                component={ProjectsCard}
            />
            <Route
                path={LINK_CLIENT_CARD_CONTRACTORS_RESOURCES}
                component={ContractorResources}
            />
            <Route
                path={LINK_CLIENT_INVITING_CONTRACTORS_LIST}
                component={NmContractorList}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD}
                component={OnboardingScenarioCard}
            />
            <Route
                path={LINK_CLIENT_REPORTS}
                component={ClientCardReports}
            />
            <Route
                exact
                path={LINK_CLIENT_FINANCE_UPLOAD}
                component={ClientCardReports}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDERS_ANALYTICS}
                component={ClientCardReports}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_FINANCE_EXPORT_FULL}
                component={ClientCardReports}
            />
            <Route
                path={LINK_CLIENT_DOCUMENT_ROUTE}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_OPERATOR_REPORTS}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_AGENCY_CONTRACT}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_STANDART_DOCUMENTS}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_TEMPLATES_LIST}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS}
                component={ClientCardDocuments}
            />
            <Route
                exact
                path={LINK_CLIENT_STOP_LIST}
                component={StopList}
            />
            <Route
                exact
                path={LINK_CLIENT_MEMBERS_CARD}
                component={ClientMemberCard}
            />
            <Route
                exact
                path={LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS}
                component={OrderAllApplications}
            />
            <Route
                exact
                path={LINK_CLIENT_CANCELLED_CHECKS}
                component={ClientCardFinances}
            />
            <Route
                exact
                path={LINK_CLIENT_ADVERTISEMENT}
                component={AdvertisementList}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL}
                component={AdvertisementRoute}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_ADVERTISEMENT}
                component={AdvertisementRoute}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CARD_CRM_TICKET_LIST}
                component={CrmTicketList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CARD_CRM_TICKET_CARD}
                component={TicketCard}
            />
            <Route
                exact
                path={LINK_CLIENT_CHAT_LIST}
                component={ChatClientList}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_RECRUITMENT}
                component={ClientCardRecruitment}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_CROWD}
                component={ClientCardCrowd}
            />
            <Route
                path={LINK_CLIENT_KEDO}
                component={ClientCardKedo}
            />
            <Route
                path={LINK_CLIENT_MAILINGS_LIST}
                component={ClientMailingsList}
            />
        </Switch>
    );
};

export default withRouter(ClientRoute);