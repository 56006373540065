import {
    BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE,
} from "./actions";

export function getSubscribeNotificationsClientUsers(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST,
        payload,
    };
}

export function getAllSubscribeNotifications(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST,
        payload,
    };
}

export function getMySubscribeNotifications(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST,
        payload,
    };
}

export function getSubscribeNotificationsTypes(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST,
        payload,
    };
}

export function getNotificationsTypes(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST,
        payload,
    };
}

export function getSubscribeNotificationsClientRoles(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST,
        payload,
    };
}

export function getSubscribeNotificationsChannelTypes(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST,
        payload,
    };
}

export function addNotificationSubscription(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST,
        payload,
    };
}


export function editNotificationSubscription(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST,
        payload,
    };
}


export function getNotificationSubscriptionById(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST,
        payload,
    };
}

export function isNotificationAuthInTelegram(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST,
        payload,
    };
}

export function deleteNotificationSubscription(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST,
        payload,
    };
}

export function clearNotificationsSubscribeStore(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_CLEAR_STORE,
        payload,
    };
}

export function getSubscribeNotificationsCount(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST,
        payload,
    };
}

export function updateNotificationsSubscribeStore(payload) {
    return {
        type: BFF_NOTIFICATION_SUBSCRIBE_UPDATE_STORE,
        payload,
    };
}