import copy from "copy-to-clipboard";

import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {isMobileDevice} from "../../../../utils/window";

import {LINK_TELEGRAM_BOT} from "../../../../constants/links";

export const useSubscribeNotifications = () => {
    const onClickSubscribeNotifications = (params) => {
        const {
            openModal,
        } = params;

        if (isMobileDevice()) {
            copy(LINK_TELEGRAM_BOT);
            openLinkByUrl(LINK_TELEGRAM_BOT);

            return;
        }

        openModal();
        openLinkByUrl(LINK_TELEGRAM_BOT);
    };

    return {
        onClickSubscribeNotifications,
    };
};