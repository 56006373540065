import React, {useContext, useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmHorizontalToggleV2 from "../../../../../components/ActualComponents/NmHorizontalToggleV2";
import {AppContext} from "../../../../../components/AppContext";
import CheckboxList from "../../../../../components/CheckboxList";
import SelectionCountWithAction from "../../../../../components/SelectionCountWithAction";
import {RegistriesPaymentsListCard} from "../payments-list-card";

import {useSelectedList} from "../../../../../hooks/useSelectedList";
import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";
import {useRegistryPaymentsAction} from "../../hooks/useAction";

import {getUserRole} from "../../../../../utils/access";

import {REGISTRY_OPERATION, REGISTRY_STATUS_DICT} from "../../../../../constants/registry";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_OPERATOR, PROJECT_MANAGER} from "../../../../../constants/roles";

import {
    bffFinancesRegistryPaymentsErrorSelector,
    bffFinancesRegistryPaymentsProgressActionSelector,
} from "../../../../../ducks/bff/finances/registry-payments/selectors";

export const RegistriesPaymentsList = (props) => {
    const {
        isVisibleMassActions,
        card,
        list,
        isCardPayments,
        clientId,
        isCheckbox,
        isOpenFilter,
        isAllPayments,
    } = props;

    const role = getUserRole();

    const error = useSelector(bffFinancesRegistryPaymentsErrorSelector);
    const progressAction = useSelector(bffFinancesRegistryPaymentsProgressActionSelector);

    const [isHide, setHide] = useState(false);
    const isEmptyPayments = isEmpty(list);

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
    } = useSelectedList({
        list,
    });

    const {
        onCloseModal,
    } = useContext(AppContext);

    useSuccessActionCallback({
        callback: onCloseModal,
        progressAction,
        error,
    });

    const {
        deleteRow,
    } = useRegistryPaymentsAction({
        clientId,
        isEmptyPayments,
        card,
    });

    const getMassAction = () => {
        if (!isCardPayments) {
            return null;
        }

        if (card.status === REGISTRY_STATUS_DICT.DRAFT.VALUE) {
            return {
                key: REGISTRY_OPERATION.DELETE_ROW,
                text: "Удалить строку",
                value: REGISTRY_OPERATION.DELETE_ROW,
                onClick: () => {
                    deleteRow();
                },
                disabled: countSelected === 0,
            };
        }

        if (
            card.status !== REGISTRY_STATUS_DICT.DRAFT.VALUE &&
            [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
            ].includes(role)
        ) {
            return {
                key: REGISTRY_OPERATION.DELETE_ROW,
                text: "Отменить выплаты",
                value: REGISTRY_OPERATION.DELETE_ROW,
                disabled: countSelected === 0,
            };
        }

        return {
            text: "",
            key: "",
            value: "",
        };
    };

    const getMobileActionOptions = () => {
        if ([NM_OPERATOR].includes(role)) {
            return null;
        }

        const actions = [
            {
                key: REGISTRY_OPERATION.HIDE_DESCRIPTION,
                text: isHide ? "Показать описание работ" : "Скрыть описание работ",
                value: REGISTRY_OPERATION.HIDE_DESCRIPTION,
                onClick: () => {
                    setHide(!isHide);
                },
            },
        ];

        if (!isCardPayments) {
            return actions;
        }

        const massAction = getMassAction();

        if (massAction.text) {
            actions.push(massAction);
        }

        return actions;
    };

    const getRows = () => {
        return selectedList.map((item) => {
            const {
                showCheckBox,
                disabledCheckBox,
                isSelected,
            } = item;

            const key = `${item.registryId}-${item.registrySeqNum}`;

            return {
                ...item,
                key,
                number: item.registrySeqNum,
                showCheckBox,
                disabledCheckBox,
                isSelected,
                // С Вадимом решено пока убрать
                //isErrors: !validData,
                contentRow: (
                    <RegistriesPaymentsListCard
                        uniqKey={key}
                        item={item}
                        isAllPayments={isAllPayments}
                        isHide={isHide}
                        isCheckbox={isCheckbox}
                        isOpenFilter={isOpenFilter}
                        isEmptyPayments={isEmptyPayments}
                        isClientCard={!!clientId}
                    />
                ),
            };
        });
    };

    return (
        <CheckboxList
            header={
                isVisibleMassActions &&
                <SelectionCountWithAction
                    adaptiveLogic
                    count={countSelected}
                    onClick={deleteRow}
                    disabled={countSelected === 0}
                    buttonContent={getMassAction().text}
                />
            }
            isVisibleLeftMassActions={false}
            isVisibleRightMassActions={true}
            additionalActions={
                <NmHorizontalToggleV2
                    noWrapLabel
                    leftLabel="Скрыть описание работ"
                    single
                    onChange={() => {
                        setHide(!isHide);
                    }}
                    checked={isHide}
                    duplex={false}
                />
            }
            actionOptions={getMobileActionOptions()}
            onSelectedRows={
                isCheckbox
                && ![NM_OPERATOR].includes(role)
                && handleSelectedRows
            }
            withCheckbox={!!isCheckbox}
            rows={getRows()}
        />
    );
};