import {toastError} from "./toastHelper";

/***
 * Для открытия нового окна с учетом safari браузера
 * @param url
 * @param params
 */
export const windowOpen = (url, params = {}) => {
    const {
        target = "",
    } = params;

    const settings = getSettings(params);
    const newWindow = window.open(url, target, settings);

    const userAgent = navigator.userAgent;
    const isIosDevice = /\b(iPad|iPhone|iPod)\b/.test(userAgent);
    const isSafariAgent = userAgent.indexOf("Safari") > -1;

    if (
        isIosDevice
        && isSafariAgent
        && (
            !newWindow
            || newWindow.closed
            || typeof newWindow.closed === "undefined"
        )
    ) {
        toastError("Отключите блокировку всплывающих окон");

        return false;
    }

    return true;
};

const getSettings = (params) => {
    const {
        top,
        left,
    } = getCenterPosition(params.height, params.width);

    const settingsList = getWindowFunctionsFromObject({...params, top, left});

    return [
        ...settingsList,
    ].join(", ");
};

const getCenterPosition = (height, width) => {
    const top = window.top.outerHeight / 2 + window.top.screenY - ( height / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - ( width / 2);

    return {
        top,
        left,
    };
};

const getWindowFunctionsFromObject = (settings) => {
    return Object.keys(settings).map(propertyName => {
        const propertyValue = settings[propertyName];

        return `${propertyName}=${propertyValue}`;
    });
};

export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};