import React from "react";

import {ContractorFinanceIncomeIndividual} from "../index";

import {getContractorRegistryPaymentsFinanceIndividualPayments} from "../../../../../ducks/contractorFinanceIncomeIndividual";

export const ContractorFinanceIncomeIndividualRegistryPayments = (props) => {
    return (
        <ContractorFinanceIncomeIndividual
            fetchList={getContractorRegistryPaymentsFinanceIndividualPayments}
            isShowItemActions={false}
            isRegistryPaymentsPage={true}
            isHiddenFilter={true}
            {...props}
        />
    );
};