/**
 * склонение числительных
 *
 *  const result = pluralize(1, ['значение, значения, значений']);
 *  result - '1 значение'
 *
 *  const result = pluralize(2, ['значение, значения, значений']);
 *  result - '2 значения'
 *
 *  const result = pluralize(5, ['значение, значения, значений']);
 *  result - '5 значений'
 *
 *  const result = pluralize(2, ['значение, значения, значений'], {displayNumber: false});
 *  result - 'значения'
 *
 **/

const pluralRulesRu = (n) => {
    if (n % 10 === 1 && n % 100 !== 11) {
        return 0;
    }
    return n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
};

const pluralize = (number, forms, displayNumber = true) => (
    `${displayNumber ? `${number.toString(10)}\u00A0` : ""}${forms[pluralRulesRu(number)]}`
);

export default pluralize;

export const pluralizeYears = (number) => {
    return pluralize(number, ["год", "года", "лет"]);
};
export const pluralizeMonth = (number) => {
    return pluralize(number, ["месяц", "месяца", "месяцев"]);
};

export const pluralizeSymbols = (number) => {
    return pluralize(number || 0, ["символ", "символа", "символов"]);
};

export const pluralizeValues = (number) => {
    return pluralize(number || 0, ["значение", "значения", "значений"]);
};

export const pluralizeDays = (number) => {
    return pluralize(number || 0, ["день", "дня", "дней"]);
};