import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../utils/objectHelper";
import {getFullName} from "../../../../utils/stringFormat";

export const subscribesSelector = state => state.bff.subscribeNotifications;
const notificationSubscribesSelector = state => state.bff.subscribeNotifications.subscribes;

export const notificationSubscribesTotalPagesSelector = createSelector(
    notificationSubscribesSelector,
    ({totalCount, pageData: {pageSize = 0}}) => {
        return getTotalPages(totalCount, pageSize);
    },
);
export const notificationSubscribesListSelector = createSelector(
    notificationSubscribesSelector,
    ({list}) => list,
);
export const notificationSubscribesTotalCountSelector = createSelector(
    notificationSubscribesSelector,
    ({totalCount}) => totalCount,
);
export const notificationSubscribesProgressSelector = createSelector(
    notificationSubscribesSelector,
    ({progress}) => progress,
);
export const notificationSubscribesClientUsersOptionsSelector = createSelector(
    subscribesSelector,
    ({clientUsers}) => clientUsers.map(item => {
        return {
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(item.lastName, item.firstName, item.patronymic),
        };
    }),
);
export const notificationSubscribesTypesOptionsSelector = createSelector(
    subscribesSelector,
    ({subscribeTypes}) => dictionaryToOptions(subscribeTypes),
);
export const notificationSubscribesCategoriesTypesOptionsSelector = createSelector(
    subscribesSelector,
    ({subscriptionCategories}) => dictionaryToOptions(subscriptionCategories),
);
export const notificationSubscribesClientRolesOptionsSelector = createSelector(
    subscribesSelector,
    ({clientRoles}) => dictionaryToOptions(clientRoles),
);
export const notificationSubscribesChannelTypesOptionsSelector = createSelector(
    subscribesSelector,
    ({channelTypes}) => dictionaryToOptions(channelTypes),
);
export const notificationSubscribesProgressActionSelector = createSelector(
    subscribesSelector,
    ({progressAction}) => progressAction,
);
export const notificationSubscribesProgressCardSelector = createSelector(
    subscribesSelector,
    ({progressCard}) => progressCard,
);
export const notificationSubscribesCardSelector = createSelector(
    subscribesSelector,
    ({card}) => card,
);
export const notificationSubscribesErrorSelector = createSelector(
    subscribesSelector,
    ({error}) => error,
);
export const notificationSubscribesIsAuthedInTelegramSelector = createSelector(
    subscribesSelector,
    ({authedInTelegram}) => authedInTelegram,
);
export const notificationSubscribesAllTotalCountSelector = createSelector(
    subscribesSelector,
    ({totalCount}) => totalCount,
);