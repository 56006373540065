import React from "react";

import {ContractorFinanceIncomeNdfl} from "../index";

import {getContractorRegistryPaymentsFinanceNdflPayments} from "../../../../../ducks/contractorFinanceIncomeNdfl";

export const ContractorFinanceIncomeNdflRegistryPayments = (props) => {
    return (
        <ContractorFinanceIncomeNdfl
            fetchList={getContractorRegistryPaymentsFinanceNdflPayments}
            isShowItemActions={false}
            isRegistryPaymentsPage={true}
            isHiddenFilter={true}
            isNdfl={true}
            {...props}
        />
    );
};