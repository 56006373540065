import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {
    getAllSubscribeNotifications,
    getMySubscribeNotifications,
    getSubscribeNotificationsCount,
} from "./actionCreators";
import {
    BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR, BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS,
    BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_ERROR,
    BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST,
    BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS,
} from "./actions";
import {subscribesSelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const getController = () => {
    return getBffUrl({
        isClientCard: true,
        clientRolesUrl: "/client-adm/notifications/subscribe-notifications",
        adminRolesUrl: "/adm/clients/client-card/notifications/subscribe-notifications",
    });
};

// POST /bff/adm/clients/client-card/notifications/subscribe-notifications/getMySubscribesPage
// POST /bff/client-adm/notifications/subscribe-notifications/getMySubscribesPage
// Получить страницу моих подписок на уведомления
const getMySubscribeNotificationsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getMySubscribesPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/notifications/subscribe-notifications/getAllSubscribesPage
// POST /bff/client-adm/notifications/subscribe-notifications/getAllSubscribesPage
// Получить страницу всех подписок на уведомления компании
const getAllSubscribeNotificationsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getAllSubscribesPage`,payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/notifications/subscribe-notifications/getClientUserPage
// POST /bff/client-adm/notifications/subscribe-notifications/getClientUserPage
// Получить страницу сотрудников компании
const getSubscribeNotificationsClientUsers = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getClientUserPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getSubscriptionCategories
// GET /bff/client-adm/notifications/subscribe-notifications/getSubscriptionCategories
// Получить словарь категорий уведомлений
const getSubscribeNotificationsTypes = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getSubscriptionCategories`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getChannelType
// GET /bff/client-adm/notifications/subscribe-notifications/getChannelType
// Получить словарь каналов уведомлений
const getSubscribeNotificationsChannelTypes = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getChannelType`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getClientRoles
// GET /bff/client-adm/notifications/subscribe-notifications/getClientRoles
// Получить словарь клиентских ролей
const getSubscribeNotificationsClientRoles = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getClientRoles`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getNotificationTypes
// GET /bff/client-adm/notifications/subscribe-notifications/getNotificationTypes
// Получить словарь типо уведомлений
const getNotificationsTypes = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getNotificationTypes`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/notifications/subscribe-notifications/add
// POST /bff/client-adm/notifications/subscribe-notifications/add
// Добавить подписку на уведомления
const addNotificationSubscription = function* ({payload}) {
    const {
        isAllSubscriptions,
        ...reqData
    } = payload;
    const {clientId} = payload;

    try {
        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_ADD_SUCCESS,
            payload: result,
        });

        toastSuccess("Подписки на уведомления успешно созданы");

        const state = yield select();
        const pageData = subscribesSelector(state).subscribes.pageData;

        if (isAllSubscriptions) {
            yield put(getAllSubscribeNotifications(pageData));
        } else {
            yield put(getMySubscribeNotifications(pageData));
        }

        yield put(getSubscribeNotificationsCount({clientId}));
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_ADD_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/notifications/subscribe-notifications/edit
// PUT /bff/client-adm/notifications/subscribe-notifications/edit
// Редактировать подписку на уведомления
const editNotificationSubscription = function* ({payload}) {
    const {
        isAllSubscriptions,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${getController()}/edit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Подписки на уведомления успешно обновлены");

        const state = yield select();
        const pageData = subscribesSelector(state).subscribes.pageData;

        if (isAllSubscriptions) {
            yield put(getAllSubscribeNotifications(pageData));
        } else {
            yield put(getMySubscribeNotifications(pageData));
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_EDIT_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getById
// GET /bff/client-adm/notifications/subscribe-notifications/getById
// Получить подписку на уведомления по id
const getNotificationSubscriptionById = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getById`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getAuthInTelegramBot
// GET  /bff/client-adm/notifications/subscribe-notifications/getAuthInTelegramBot
// Получить признак авторизации пользователя в ТГ-боте
const isNotificationAuthInTelegram = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getAuthInTelegramBot`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/clients/client-card/notifications/subscribe-notifications/delete
// DELETE /bff/client-adm/notifications/subscribe-notifications/delete
// Удалить подписку на уведомления
const deleteNotificationSubscription = function* ({payload: data}) {
    const {clientId} = data;

    try {
        const result = yield request.bff.delete(`${getController()}/delete`, {data});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_DELETE_SUCCESS,
            payload: result,
        });

        toastSuccess("Подписки на уведомления удалены");

        const state = yield select();
        const pageData = subscribesSelector(state).subscribes.pageData;

        yield put(getAllSubscribeNotifications(pageData));
        yield put(getSubscribeNotificationsCount({clientId}));
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_DELETE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/notifications/subscribe-notifications/getCountByClientId
// GET /bff/client-adm/notifications/subscribe-notifications/getCountByClientId
// Получить количество подписок клиента
const getSubscribeNotificationsCountSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getCountByClientId`, {params: payload});

        if (!result) {
            yield put({
                type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_USER_PAGE_REQUEST, getSubscribeNotificationsClientUsers),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_ALL_SUBSCRIBES_REQUEST, getAllSubscribeNotificationsSaga),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_MY_SUBSCRIBES_REQUEST, getMySubscribeNotificationsSaga),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_SUBSCRIBES_TYPES_REQUEST, getSubscribeNotificationsTypes),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_NOTIFICATION_TYPES_REQUEST, getNotificationsTypes),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_CHANNEL_TYPES_REQUEST, getSubscribeNotificationsChannelTypes),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_CLIENT_ROLES_REQUEST, getSubscribeNotificationsClientRoles),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_ADD_REQUEST, addNotificationSubscription),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_EDIT_REQUEST, editNotificationSubscription),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_BY_ID_REQUEST, getNotificationSubscriptionById),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_AUTH_IN_TELEGRAM_BOT_REQUEST, isNotificationAuthInTelegram),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_DELETE_REQUEST, deleteNotificationSubscription),
        takeEvery(BFF_NOTIFICATION_SUBSCRIBE_GET_COUNT_REQUEST, getSubscribeNotificationsCountSaga),
    ]);
}