import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useContractorAdditionalDocuments} from "./useAdditionalDocuments";

import {useGetPrevValue} from "../../../../hooks/useGetPrevValue";
import {useMigrationStatusDict} from "../../../../hooks/useMigrationStatusDict";

import {
    getContractorAdditionalScans,
    getContractorParentDocumentScans,
    getContractorScans,
} from "../../../../utils/contractors";

import {CONTRACTOR_FILE_TYPES} from "../../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    RNKO,
} from "../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {getInfoContractorCard} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {
    getContractorBankStatus,
    getPendingPersonalData,
} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {contractorCardInfoSelector} from "../../../../ducks/bff/contractor-сard/selectors";
import {getClientUserRestrictionsList} from "../../../../ducks/clientUserRestrictions";
import {
    getContractorEdmPassportType,
    getWorkRegions,
} from "../../../../ducks/contractor";
import {
    getContractorAdditionalDocumentFile,
    getFileContractorAdditionalScan,
    getFileContractorScan,
    updateFieldFilesStore,
} from "../../../../ducks/fileStore";

export const useFetchContractorInfo = ({contractorId, role}) => {
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const contractor = useSelector(contractorCardInfoSelector);
    const isContractorLoaded = Boolean(contractor.contractorId);

    const {
        citizenship,
        migrationStatus,
        locatedOutsideRussia,
        hasPendingPersonalData,
        passportType: contractorPassportType,
        edmPassportType,
    } = contractor;
    const {hasAccessToContractorPDFiles} = clientProperties;

    const prevHasPendingPersonalData = useGetPrevValue(hasPendingPersonalData);

    const dispatch = useDispatch();

    const {
        isAdditionalDocuments,
    } = useContractorAdditionalDocuments({
        contractorId,
        isFetchAggregation: true,
    });

    useEffect(() => {
        dispatch(getWorkRegions());
        dispatch(getContractorEdmPassportType());
        getBankStatus();

        dispatch(getClientUserRestrictionsList({
            restrictions: [
                CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractors,
                CLIENT_USER_RESTRICTIONS_VARIABLE.removeCheckContractors,
            ],
        }));
        
        return () => {
            dispatch(updateFieldFilesStore({
                contractorScanObj: {},
                contractorOriginalScanObj: {},
            }));
        };
    }, []);

    const {
    } = useMigrationStatusDict({
        citizenship,
        nameField: "contractorMigrationStatusDict",
    });

    useEffect(() => {
        if (typeof hasPendingPersonalData === "boolean" && prevHasPendingPersonalData !== hasPendingPersonalData) {
            if (hasPendingPersonalData && ![NM_COORDINATOR, RNKO, NM_CHIEF_ACCOUNTANT].includes(role) && !isClientUser(role)) {
                fetchPersonalPendingData();

                return;
            }

            fetchContractorScan({
                citizenship,
                migrationStatus,
                locatedOutsideRussia,
            });
        }
    }, [hasPendingPersonalData]);

    const fetchPersonalPendingData = () => {
        dispatch(getPendingPersonalData({
            contractorId,
            getResult: ({personalData = {}}) => {
                const citizenship = personalData.citizenship ? personalData.citizenship : contractor.citizenship;
                const passportType = personalData.passportType ? personalData.passportType : contractor.passportType;

                fetchContractorScan({
                    citizenship,
                    migrationStatus: personalData.migrationStatus,
                    locatedOutsideRussia,
                    passportType,
                    hasPendingPersonalData: true,
                });
            },
        }));
    };

    const fetchContractorScan = (params) => {
        if (isClientUser() && !hasAccessToContractorPDFiles) {
            return;
        }
        const {
            citizenship,
            migrationStatus,
            locatedOutsideRussia,
            passportType,
            hasPendingPersonalData = false,
        } = params;

        getContractorScans({
            contractorId,
            getFileContractorScan: (reqData) => dispatch(getFileContractorScan(reqData)),
            getContractorAdditionalDocumentFile: (reqData) => dispatch(getContractorAdditionalDocumentFile(reqData)),
            citizenship,
            migrationStatus,
            locatedOutsideRussia,
            passportType: passportType || contractorPassportType,
            edmPassportType,
            hasPendingPersonalData,
            exceptionsForFetch: {
                [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: isClientUser(),
            },
        });

        getContractorParentDocumentScans(
            contractor,
            (reqData) => dispatch(getFileContractorScan(reqData)),
            hasPendingPersonalData,
        );

        getContractorAdditionalScans(
            contractor,
            (reqData) => dispatch(getFileContractorAdditionalScan(reqData)),
        );
    };

    const getBankStatus = () => {
        dispatch(getContractorBankStatus({contractorId}));
    };

    const fetchContractorInfo = () => {
        dispatch(getInfoContractorCard(contractorId));
    };

    return {
        contractor,
        isAdditionalDocuments,
        isContractorLoaded,
        fetchContractorInfo,
    };
};