import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import StandartDocuments from "../../components/StandartDocuments";

import {
    getDocumentsPlatformCustomerSds,
    mapActivesDocumentsPlatformCustomerSds,
} from "../../utils/strapiService";

import {CLIENT_GROUP_TYPE} from "../../constants/clientGroup";

import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";

const StandartDocumentsContainer = () => {
    const {location} = useSelector(({router}) => router);
    const {
        clientId,
        clientGroupType,
    } = useSelector(clientCardInfoSelector);

    const [exemplaryActual, setExemplaryActual] = useState([]);
    const [exemplaryArchive, setExemplaryArchive] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const list = await getDocumentsPlatformCustomerSds({
                isHoReCa: clientGroupType === CLIENT_GROUP_TYPE.HO_RE_CA,
            });

            const exemplaryActualStrapi = await mapActivesDocumentsPlatformCustomerSds(list);
            const exemplaryArchiveStrapi = await mapActivesDocumentsPlatformCustomerSds(list,"archive");

            setExemplaryActual(exemplaryActualStrapi);
            setExemplaryArchive(exemplaryArchiveStrapi);
        }

        if (clientId) {
            fetchData();
        }
    }, [clientId]);

    const {pathname} = location;

    return (
        <StandartDocuments
            pathname={pathname}
            clientId={clientId}
            actualList={exemplaryActual}
            archiveList={exemplaryArchive}
        />
    );
};

export default StandartDocumentsContainer;