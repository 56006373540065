import React, {useState} from "react";
import {useSelector} from "react-redux";

import Filter from "../../../components/ActualComponents/Filter";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {RegistriesPaymentsList} from "./components/payments-list";

import {usePagination} from "../../../hooks/usePagination";
import {useRegistryPaymentsAction} from "./hooks/useAction";
import {useRegistryPaymentsFetch} from "./hooks/useFetch";
import {useFinancePaymentsFilter} from "./hooks/useFilter";

import {
    bffFinancesRegistryPaymentsListSelector,
    bffFinancesRegistryPaymentsProgressSelector,
    bffFinancesRegistryPaymentsTotalCountSelector,
    bffFinancesRegistryPaymentsTotalPagesSelector,
} from "../../../ducks/bff/finances/registry-payments/selectors";

export const FinanceRegistryPayments = (props) => {
    const {clientId} = props.match.params;

    const progress = useSelector(bffFinancesRegistryPaymentsProgressSelector);
    const list = useSelector(bffFinancesRegistryPaymentsListSelector);
    const totalCount = useSelector(bffFinancesRegistryPaymentsTotalCountSelector);
    const totalPages = useSelector(bffFinancesRegistryPaymentsTotalPagesSelector);

    const [isOpenFilter, setOpenFilter] = useState(false);

    const {
        contractorIds,
    } = useRegistryPaymentsAction({
        clientId,
    });

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        filter,
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useFinancePaymentsFilter({
        clientId,
        pageSize,
        setPagination,
    });

    useRegistryPaymentsFetch({
        clientId,
        pageNum,
        pageSize,
        filterData,
        contractorIds,
    });

    return (
        <NmPage
            noPadding={!clientId}
            onOpenFilter={setOpenFilter}
            header={
                <NmPageHeader
                    text="Строки реестров"
                    totalCount={totalCount}
                />
            }
            widthByFilter={true}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    filterState={filter}
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
            isEmptyPage={list.length === 0}
            emptyPageProps={{
                fetchProgress: progress,
                isSearch,
            }}
            className="relative"
            // mediaControls={{
            //     renderCount: {
            //         desktop: 0,
            //         mobile: 0,
            //         tablet: 0,
            //     },
            //     buttons: [
            //         {
            //             component: COMPONENT.BUTTON,
            //             className: "ms-2",
            //             props: {
            //                 onClick: () => {
            //                     refreshPaymentPossibility();
            //                 },
            //                 children: "Обновить платежные данные",
            //             },
            //         },
            //     ],
            // }}
        >
            <RegistriesPaymentsList
                isAllPayments={true}
                isOpenFilter={isOpenFilter}
                clientId={clientId}
                list={list}
            />
        </NmPage>
    );
};