import {useContext} from "react";

import {AppContext} from "../components/AppContext";

export const useAppModal = () => {
    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useContext(AppContext);

    return {
        onOpenModal,
        onCloseModal,
        modalData,
    };
};