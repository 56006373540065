import React from "react";

import {ContractorFinanceIncomeNpd} from "../index";

import {getContractorRegistryPaymentsFinanceNpdPayments} from "../../../../../ducks/contractorFinanceIncomeNpd";

export const ContractorFinanceIncomeNpdRegistryPayments = (props) => {
    return (
        <ContractorFinanceIncomeNpd
            fetchList={getContractorRegistryPaymentsFinanceNpdPayments}
            isShowItemActions={false}
            isRegistryPaymentsPage={true}
            isHiddenFilter={true}
            {...props}
        />
    );
};