import {
    ADD_CLIENT_REF_PROGRAM_ERROR,
    ADD_CLIENT_REF_PROGRAM_REQUEST,
    ADD_CLIENT_REF_PROGRAM_SUCCESS,
    CHECK_CLIENT_PROMOCODE_ERROR,
    CHECK_CLIENT_PROMOCODE_REQUEST,
    CHECK_CLIENT_PROMOCODE_SUCCESS,
    CLEAR_FIELDS_CLIENT_CARD_INFO_STORE,
    GET_CLIENT_INFO_ERROR,
    GET_CLIENT_INFO_REQUEST,
    GET_CLIENT_INFO_SUCCESS,
    GET_CLIENT_PROPERTIES_ERROR,
    GET_CLIENT_PROPERTIES_REQUEST,
    GET_CLIENT_PROPERTIES_SUCCESS,
    GET_CLIENT_REF_PROGRAM_HISTORY_ERROR,
    GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST,
    GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS,
    GET_CLIENT_REF_PROGRAM_SUCCESS,
    GET_CLIENT_SPECIALITIES_ERROR,
    GET_CLIENT_SPECIALITIES_REQUEST,
    GET_CLIENT_SPECIALITIES_SUCCESS,
    GET_CURRENT_CLIENT_INFO_SUCCESS,
    UPDATE_CLIENT_INFO_ERROR,
    UPDATE_CLIENT_INFO_REQUEST,
    UPDATE_CLIENT_INFO_SUCCESS,
    UPDATE_CLIENT_PROPERTIES_ERROR,
    UPDATE_CLIENT_PROPERTIES_REQUEST,
    UPDATE_CLIENT_PROPERTIES_SUCCESS,
    UPDATE_CLIENT_REQUISITES_ERROR,
    UPDATE_CLIENT_REQUISITES_REQUEST,
    UPDATE_CLIENT_REQUISITES_SUCCESS,
    UPDATE_FIELDS_CLIENT_CARD_INFO_STORE,
} from "./actions";

import {REF_TYPE} from "../../../../constants/refProgram";

const initial = {
    card: {},
    currentClient: {},
    properties: {},
    progress: false,
    progressAction: false,
    totalCount: 0,
    [REF_TYPE.CLIENTS]: {
        pageData: {},
        totalCount: 0,
        list: [],
        progress: false,
    },
    [REF_TYPE.CONTRACTORS]: {
        pageData: {},
        totalCount: 0,
        list: [],
        progress: false,
    },
    clientRefData: {
        promocodeForContractor: {},
        promocodeForClient: {},
    },
    specialities: [],
    progressSpecialities: false,
    checkPromocodeProgress: false,
    errorMessagePromocodeCheck: null,
    isPromocodeCheckSuccess: false,
};

export default (state = initial, {type, payload}) => {
    const {
        typeFilter,
    } = payload || {};

    const {
        [typeFilter]: historyRefData,
    } = state;

    switch (type) {
        case GET_CLIENT_INFO_REQUEST:
        case GET_CLIENT_PROPERTIES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_CLIENT_INFO_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case GET_CLIENT_PROPERTIES_SUCCESS:
            return {
                ...state,
                progress: false,
                properties: payload,
                migrantLicensePaymentEnabled: payload.migrantLicensePaymentEnabled,
            };
        case GET_CLIENT_INFO_ERROR:
        case GET_CLIENT_PROPERTIES_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case GET_CURRENT_CLIENT_INFO_SUCCESS:
            return {
                ...state,
                currentClient: payload,
            };
        case UPDATE_CLIENT_INFO_REQUEST:
        case UPDATE_CLIENT_PROPERTIES_REQUEST:
        case UPDATE_CLIENT_REQUISITES_REQUEST:
        case ADD_CLIENT_REF_PROGRAM_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case UPDATE_CLIENT_INFO_SUCCESS:
        case UPDATE_CLIENT_INFO_ERROR:
        case UPDATE_CLIENT_PROPERTIES_SUCCESS:
        case UPDATE_CLIENT_PROPERTIES_ERROR:
        case UPDATE_CLIENT_REQUISITES_SUCCESS:
        case UPDATE_CLIENT_REQUISITES_ERROR:
        case ADD_CLIENT_REF_PROGRAM_SUCCESS:
        case ADD_CLIENT_REF_PROGRAM_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST:
            return {
                ...state,
                [typeFilter]: {
                    ...historyRefData,
                    progress: true,
                    pageData: payload,
                },
            };
        case GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS:
            const {
                clientReferralHistoryModels,
                totalCount,
            } = payload;

            return {
                ...state,
                [typeFilter]: {
                    ...historyRefData,
                    progress: false,
                    list: clientReferralHistoryModels,
                    totalCount,
                },
            };
        case GET_CLIENT_REF_PROGRAM_HISTORY_ERROR:
            return {
                ...state,
                error: payload,
                [typeFilter]: {
                    ...historyRefData,
                    progress: false,
                },
            };
        case GET_CLIENT_REF_PROGRAM_SUCCESS:
            return {
                ...state,
                clientRefData: {
                    promocodeForContractor: payload.promocodeForContractor || {},
                    promocodeForClient: payload.promocodeForClient || {},
                },
                progress: false,
            };
        case UPDATE_FIELDS_CLIENT_CARD_INFO_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_CLIENT_SPECIALITIES_REQUEST:
            return {
                ...state,
                progressSpecialities: true,
            };
        case GET_CLIENT_SPECIALITIES_SUCCESS:
            return {
                ...state,
                specialities: payload,
                progressSpecialities: false,
            };
        case GET_CLIENT_SPECIALITIES_ERROR:
            return {
                ...state,
                progressSpecialities: false,
            };
        case CHECK_CLIENT_PROMOCODE_REQUEST: {
            return {
                ...state,
                checkPromocodeProgress: true,
            };
        }
        case CHECK_CLIENT_PROMOCODE_SUCCESS: {
            return {
                ...state,
                checkPromocodeProgress: false,
                errorMessagePromocodeCheck: payload.errorMessage,
                isPromocodeCheckSuccess: payload.success,
            };
        }
        case CHECK_CLIENT_PROMOCODE_ERROR:
            return {
                ...state,
                checkPromocodeProgress: false,
                errorMessagePromocodeCheck: payload,
            };
        case CLEAR_FIELDS_CLIENT_CARD_INFO_STORE:
            return initial;
        default:
            return state;
    }
};